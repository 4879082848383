import s from "styled-components";
import Paper from "_c/Paper";
import BaseModal from "../base-modal";

export const Modal = s(BaseModal)`
  overflow-y: auto;
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  position: fixed !important; 
  display: flex;
`;
export const CloseOverlay = s.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;
export const Wrap = s(Paper)`
  position: relative;
  color: var(--font-color-main);
  margin: auto auto;
  width: 500px;
  @media(max-width:520px) {
    width: 95%;
  }
`;
export const Title = s.div`
  font-weight: 400;
  margin-bottom: 15px;
`;
export const Buttons = s.div`
  button {
    margin: 5px;
  }
`;
