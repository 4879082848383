import { connect } from "react-redux";
import { Selectors } from "@/store/messenger/selectors";
import MessengerPage from "./loadable";

const mSp = (state) => ({
  activeDialogId: Selectors.getActiveDialogId(state),
});

const connected = connect(mSp)(MessengerPage);
export default connected;
