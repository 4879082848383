import { useEffect, useState, useCallback } from "react";
import throttle from "lodash/throttle";

const SHOW_BP = 50;
const SCROLL_AMOUNT = 200;
const SCROLL_INNACURACY = 50;
const SCROLL_DELAY = 200;
export const useScrollButtons = ({ ref, disabled }) => {
  const [visibleButtons, setVisibleButtons] = useState({
    left: false,
    right: false,
  });
  const [reloadValue, setReloadValue] = useState(null);
  const goPrev = useCallback(() => {
    const $el = ref.current;
    $el.scrollTo({
      left: $el.scrollLeft - SCROLL_AMOUNT,
      behavior: "smooth",
    });
  }, [ref]);
  const goNext = useCallback(() => {
    const $el = ref.current;
    const width = $el.scrollWidth;
    const scroll = $el.scrollLeft;
    const shouldScrollTilEnd =
      SCROLL_AMOUNT + SCROLL_INNACURACY + scroll >= width;
    $el.scrollTo({
      left: shouldScrollTilEnd ? width : scroll + SCROLL_AMOUNT,
      behavior: "smooth",
    });
  }, [ref]);
  const reload = useCallback(() => {
    setReloadValue(Math.random());
  }, []);
  useEffect(() => {
    const $el = ref.current;
    if ($el && !disabled) {
      const updateState = () => {
        const width = $el.scrollWidth;
        const scroll = $el.scrollLeft;
        const visibleWidth = $el.getBoundingClientRect().width;

        setVisibleButtons({
          left: scroll > SHOW_BP,
          right: width - SHOW_BP > scroll + visibleWidth,
        });
      };
      updateState();
      const onScroll = throttle(updateState, SCROLL_DELAY);
      $el.addEventListener("scroll", onScroll);
      return () => $el.removeEventListener("scroll", onScroll);
    }
  }, [ref, reloadValue, disabled]);
  return {
    visibleButtons,
    goPrev,
    goNext,
    reload,
  };
};
