import { createSelector } from "@reduxjs/toolkit";
import last from "lodash/last";
import first from "lodash/first";
import countBy from "lodash/countBy";

const isDialogsLoading = (state) => state.messenger.dialogsLoading;
const isMessagesLoading = (state) => state.messenger.messagesLoading;
const getActiveDialogId = (state) => state.messenger.activeDialog;
const getDialogs = (state) => state.messenger.dialogs;
const getActiveDialog = createSelector(
  [getDialogs, getActiveDialogId],
  (dialogs, id) => {
    if (!id) return null;
    return dialogs.find(({ _id }) => _id === id);
  }
);
const getFreshMessages = (state) => state.messenger.fresh;
const getFreshMessagesCount = (state) => state.messenger.fresh.length;
const hasFreshMessages = (state) => state.messenger.fresh.length > 0;
const getCountedFreshForDialogs = createSelector(getFreshMessages, (fresh) => {
  return countBy(fresh, "dialog");
});
const getOldestDialog = createSelector(getDialogs, (dialogs) => {
  return last(dialogs);
});
const getDialogsLength = createSelector(
  getDialogs,
  (dialogs) => dialogs.length
);
const isDialogsEmpty = createSelector(getDialogsLength, (len) => len === 0);
const getMessages = createSelector(
  getActiveDialog,
  (dialog) => dialog?.messages || []
);
const getMessagesLength = createSelector(
  getMessages,
  (messages) => messages.length
);
const getOldestMessage = createSelector(getMessages, (messages) => {
  return first(messages);
});
const getInput = (state) => state.messenger.input;
const getDialogInput = createSelector(
  [getActiveDialogId, getInput],
  (dialog, input) => input[dialog] || ""
);
const getPhotoInput = (state) => state.messenger.photoInput;
const getDialogPhotoInput = createSelector(
  [getActiveDialogId, getPhotoInput],
  (dialog, input) => input[dialog]
);
const isDialogLoaded = (state, dialog) =>
  dialog in state.messenger.loadedDialogs;

export const Selectors = {
  getFreshMessages,
  getCountedFreshForDialogs,
  isDialogLoaded,
  isDialogsEmpty,
  getDialogInput,
  getDialogPhotoInput,
  getFreshMessagesCount,
  getOldestDialog,
  getOldestMessage,
  hasFreshMessages,
  isDialogsLoading,
  isMessagesLoading,
  getMessagesLength,
  getActiveDialog,
  getActiveDialogId,
  getDialogs,
  getDialogsLength,
  getMessages,
};
