import React, { memo } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useMasterId } from "@/hooks/useMasterId";
import { useSelector } from "react-redux";
import ProfileMenu from "_c/ProfileMenu";
import { Spin } from "_c/preloader";
import { useTranslation } from "react-i18next";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";
import { ORDERS_NS } from "@/i18n/ns";
import WarehousePage from "./loadable";

const WarehousePageContainer = () => {
  const { t, ready } = useTranslation(["common", ORDERS_NS, "errors"], {
    useSuspense: false,
  });
  const { child } = useParams();
  const routePrefix = useSelector(LinksSelectors.getRoutePrefix);
  const menu = useSelector(LinksSelectors.getWarehouseChildren);
  const masterId = useMasterId();
  if (!ready) {
    return <Spin size="xl" />;
  }
  if (!child) {
    return <Redirect to={`${routePrefix}/warehouse/products`} />;
  }
  const renderMenu = () => (
    <ProfileMenu
      css={`
        @media (max-width: ${({ theme }) => theme.mobileBp}) {
          margin-top: 45px;
          margin-bottom: 0;
        }
      `}
      t={t}
      items={menu}
    />
  );
  return (
    <WarehousePage
      activeTab={child}
      master={masterId}
      renderMenu={renderMenu}
      t={t}
    />
  );
};

export default memo(WarehousePageContainer);
