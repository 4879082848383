import { ProfileSelectors } from "./profile";
import { createSelector } from "@reduxjs/toolkit";

const BASE_URL = "/cities";
const places = [
  {
    title: "Санкт-Петербург",
    image: "/spb.jpg",
    coords: { lat: 59.9396916, lng: 29.528934 },
  },
  {
    title: "Москва",
    image: "/moscow.jpeg",
    coords: { lat: 55.6340762, lng: 36.7673553 },
  },
  {
    title: "Екатеринбург",
    image: "/ekb.jpg",
    coords: { lat: 56.8140001, lng: 60.5145085 },
  },
  {
    title: "Нижний новгород",
    image: "/novgorod.jpg",
    coords: { lat: 56.2928511, lng: 43.7863201 },
  },
  {
    title: "Новосибирск",
    image: "/novosib.jpg",
    coords: { lat: 54.9700476, lng: 82.6685426 },
  },
  {
    title: "Ростов-на-Дону",
    image: "/rostov.jpg",
    coords: { lat: 47.2611557, lng: 39.4875737 },
  },
  {
    title: "Казань",
    image: "/kazan.jpg",
    coords: { lat: 55.7956145, lng: 48.9328779 },
  },
  {
    title: "Самара",
    image: "/samara.jpg",
    coords: { lat: 53.2609888, lng: 49.9172137 },
  },
];

function toRad(Value) {
  return (Value * Math.PI) / 180;
}
function calcCrow(coords1, coords2) {
  var R = 6371;
  var dLat = toRad(coords2.lat - coords1.lat);
  var dLon = toRad(coords2.lng - coords1.lng);
  var lat1 = toRad(coords1.lat);
  var lat2 = toRad(coords2.lat);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

const DIST = 100;

const getPhoto = (userCoords) => {
  const found = places.find(
    ({ coords }) => calcCrow(coords, userCoords) <= DIST
  );
  return found ? `${BASE_URL}/${found.image}` : null;
};
export const getCityPhoto = createSelector(
  ProfileSelectors.getCoords,
  (coords) => {
    if (!coords) return null;
    return getPhoto({
      lng: coords[0],
      lat: coords[1],
    });
  }
);
