import moment from "moment";
import { IS_SERVER, DEFAULT_TIMEZONE } from "@/misc/constants";
import { DEFAULT_LOCALE } from "@/config";
import orderBy from "lodash/orderBy";

export const isToday = (date) => moment(date).isSame(moment(), "day");
export const isSameDay = (date1, date2) =>
  moment(date1).isSame(moment(date2), "day");
export const compareAsc = (date1, date2) => {
  var diff = new Date(date1) - new Date(date2);
  if (diff < 0) {
    return -1;
  } else if (diff > 0) {
    return 1;
  } else {
    return diff;
  }
};
export const compareDesc = (date1, date2) => {
  var diff = new Date(date1) - new Date(date2);
  if (diff > 0) {
    return -1;
  } else if (diff < 0) {
    return 1;
  } else {
    return diff;
  }
};

export const formatDuration = (dur) =>
  moment.utc(dur.as("milliseconds")).format("HH:mm");
export const formatMs = (ms) =>
  formatDuration(moment.duration({ milliseconds: ms }));
export const formatMinutes = (min) =>
  formatDuration(moment.duration({ minutes: min }));
export const formatMinutesInterval = (from, to) =>
  formatMinutes(from) + "-" + formatMinutes(to);
export const getMinutesFromDate = (date) => {
  return date.get("hour") * 60 + date.get("minute");
};

export const detectTimezone = () => {
  if (IS_SERVER || !Intl) {
    return DEFAULT_TIMEZONE;
  } else {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE;
  }
};
export const sortMoments = (arr, dir = "asc") =>
  orderBy(
    arr,
    ({ date }) => {
      return moment(date, "YYYY-MM-DD").format("YYYYMMDD");
    },
    dir
  );

export const msToHours = (ms) => (ms ? ms / 3.6e6 : 0);

export const changeMomentLocale = (locale) => {
  const supportedLocales = moment.locales();
  if (locale === "en" || supportedLocales.includes(locale)) {
    moment.locale(locale);
  } else {
    moment.locale(DEFAULT_LOCALE);
  }
};
