import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const SettingsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "settings-page" */
      "."
    ),
  {
    ssr: false,
    fallback: <Spin size="xl" />,
  }
);
export default SettingsPage;
