import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header, FooterV2, Content } from "_c/Modals";
import { useForm, FormContext } from "react-hook-form";
import { AbstractField, AbstractControlledField } from "_c/form/Field";
import { useMut } from "@/hooks/useMut";
import { WarehouseTxnsService } from "@/services/warehouse";
import TextareaAutosize from "react-textarea-autosize";
import { inputMixin } from "_c/form/TextInput";
import { i18nNs } from "./config";
import pick from "lodash/pick";
import { useCurrency } from "@/hooks/useCurrency";
import NumberFormat from "react-number-format";
import TextInput from "_c/form/TextInput";
import moment from "moment";
import Calendar from "_c/Calendar";
import ProductField from "./ProductField";
import AmountField from "./AmountField";

const WarehouseTxnEditor = (props) => {
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const { t, ready } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  const currency = useCurrency();
  const saloon = props.txn?.saloon || props.saloon;
  const master = props.txn?.master || props.master;
  const isNew = !props.txn;
  const mut = useMut(
    (data) => {
      if (props.txn) {
        return WarehouseTxnsService.updateTxn({
          txn: props.txn._id,
          data,
        });
      } else {
        return WarehouseTxnsService.createTxn({ data });
      }
    },
    {
      t,
      onSuccess() {
        props.onClose();
      },
      notifyOnError: true,
    }
  );
  const methods = useForm({
    defaultValues: {
      amount: props.txn?.amount,
      date: [
        moment(props.txn?.date || props.defaultDate || new Date())
          .seconds(0)
          .milliseconds(0)
          .toDate(),
      ],
      note: props.txn?.note || "",
      price: props.txn?.price || 0,
      product: props.txn?.product || props.defaultProduct || null,
      client: props.txn?.client || null,
    },
  });
  const direction = props.txn?.direction || props.defaultDirection || "in";
  const onSubmit = (form) => {
    if (isFormDisabled) return;
    let finalForm = {
      ...pick(form, ["amount", "note", "price"]),
      date: moment(form.date[0]).toISOString(),
      ...(isNew ? (saloon ? { saloon } : { master }) : {}),
      ...(isNew
        ? {
            direction,
            product: form.product?._id,
            task: props.defaultTask,
          }
        : {}),
    };
    mut.mutate(finalForm);
  };
  if (!ready) return null;
  const formId = "warehouse-txn-form";
  return (
    <>
      <Header onClick={props.onClose}>{t("common:close")}</Header>
      <FormContext {...methods}>
        <Content
          onSubmit={methods.handleSubmit(onSubmit)}
          id={formId}
          as="form"
        >
          <AbstractControlledField
            id={formId + "-product"}
            name="product"
            t={t}
            label={t(i18nNs + "product")}
            Component={ProductField}
            isRequired
            isNew={isNew}
            master={master}
            disableForm={() => setIsFormDisabled(true)}
            enableForm={() => setIsFormDisabled(false)}
            saloon={saloon}
          />
          <AbstractControlledField
            isRequired
            Component={AmountField}
            t={t}
            id={formId + "-amount"}
            name="amount"
            label={t(i18nNs + "qty")}
          />
          <AbstractControlledField
            Component={NumberFormat}
            customInput={TextInput}
            allowNegative={false}
            isNumericString={true}
            onChangeName="onValueChange"
            onChange={([{ floatValue }]) => floatValue}
            placeholder={t(i18nNs + "price_holder")}
            id={formId + "-price"}
            name="price"
            suffix={" " + currency}
            thousandSeparator
            t={t}
            label={t(
              i18nNs +
                (direction === "out" ? "price_withdraw" : "price_deposit")
            )}
          />
          <AbstractControlledField
            Component={Calendar}
            t={t}
            label={t(i18nNs + "date")}
            className="flatpickr-input"
            id={formId + "-date"}
            name="date"
            options={{
              altInput: true,
              altFormat: "F j, Y H:i",
              dateFormat: "Y-m-d",
              time_24hr: true,
              enableTime: true,
              minuteIncrement: 1,
            }}
          />
          <AbstractField
            t={t}
            id={formId + "-note"}
            name="note"
            maxLength={1024}
            label={t(i18nNs + "note")}
            Component={TextareaAutosize}
            placeholder={t(i18nNs + "note_holder")}
            maxRows={10}
            css={`
              ${inputMixin}
            `}
            data-scroll-lock-scrollable
          />
        </Content>
      </FormContext>
      <FooterV2
        disabled={mut.isLoading || isFormDisabled}
        buttonType="submit"
        buttonForm={formId}
      >
        {props.txn
          ? t("common:change")
          : t(
              `common:warehouse.actions.${
                direction === "in" ? "deposit" : "withdraw"
              }`
            )}
      </FooterV2>
    </>
  );
};

export default WarehouseTxnEditor;
