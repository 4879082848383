import React, { memo } from "react";
import { Link } from "react-router-dom";
import { SpecsList } from "./index-styled";
import PT from "prop-types";
import qs from "query-string";
import { useSearchableSpecsQuery } from '@/hooks/queries/useSearchableSpecsQuery';

const FooterSpecs = ({ hl }) => {
  const specs = useSearchableSpecsQuery().data;
  if(!specs) return null;
  return <SpecsList>
    {specs.map(({ _id, title }) => (
      <li key={_id}>
        <Link
          to={qs.stringifyUrl({
            url: "/search",
            query: {
              spec: _id,
              hl,
            },
          })}
        >
          {title}
        </Link>
      </li>
    ))}
  </SpecsList>
};
FooterSpecs.displayName = "FooterSpecs";
FooterSpecs.propTypes = {
  hl: PT.string,
};

export default memo(FooterSpecs);
