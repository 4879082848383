import React from "react";
import PT from "prop-types";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";
import Modal from "_c/Modals";

const ClientEditor = loadable(
  () => import(/* webpackChunkName: "client-editor" */ "./main"),
  { ssr: false, fallback: <Spin size="xl" /> }
);
const ClientEditorModal = ({ isOpen, ...props }) => {
  return (
    <Modal onClose={props.onClose} isOpen={isOpen}>
      <ClientEditor {...props} />
    </Modal>
  );
};
ClientEditorModal.propTypes = {
  isOpen: PT.bool.isRequired,

  onClose: PT.func.isRequired,
};
export default ClientEditorModal;
