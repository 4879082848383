import { useInfiniteQuery } from "react-query";
import { WarehouseAPI } from "@/api/warehouse";
import isempty from "lodash/isEmpty";
import type { iGetWarehouseProductsArgs } from "@/types";
import { WarehouseProductsCacheService } from "@/services/warehouse/products/cache";

type tArgs = {
  master?: string;
  saloon?: string;
  title: string;
};
const perPage = 10;
export const useWarehouseProductsQuery = ({ master, saloon, title }: tArgs) => {
  const finalParams: iGetWarehouseProductsArgs = {
    master,
    saloon,
    title_search: title,
    per_page: perPage,
    sort_by: "title",
  };
  return useInfiniteQuery(
    WarehouseProductsCacheService.buildSearchQueryKey(finalParams),
    async ({ pageParam = 0 }) => {
      const { products } = await WarehouseAPI.getProducts({
        ...finalParams,
        page: pageParam,
      });
      return {
        products,
        next:
          isempty(products) || products.length < perPage
            ? undefined
            : pageParam + 1,
      };
    },
    {
      keepPreviousData: true,
      getNextPageParam: (l) => l?.next,
    }
  );
};
