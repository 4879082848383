import { createSelector } from "@reduxjs/toolkit";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import PlusIcon from "_c/icon/PlusRound";
import { DOMAIN } from "./slice";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";
import QuestionIcon from "_c/icon/Question";
import { createStructuredSelector } from "reselect";

const supportLink = {
  Icon: QuestionIcon,
  key: "support",
  route: "/support",
};
const getSupportLink = () => supportLink;
const taskLink = {
  Icon: PlusIcon,
  key: "add_task",
  route: "/add_task",
};
const getTaskLink = () => taskLink;

const createNavigationSelector = (cb) =>
  createSelector(
    [
      UserSelectors.isClientMode,
      UserSelectors.isBusinessMode,
      createStructuredSelector({
        support: getSupportLink,
        dashboard: LinksSelectors.getDashboard,
        saloonDashboard: LinksSelectors.getSaloonDashboardWithChildren,
        warehouse: LinksSelectors.getWarehouseWithChildren,
        todos: LinksSelectors.getTodos,
        profile: LinksSelectors.getProfile,
        sms: LinksSelectors.getSmsNotificationsWithChildren,
        messenger: LinksSelectors.getMessenger,
        schedule: LinksSelectors.getSchedule,
        orders: LinksSelectors.getOrdersWithChildren,
        clients: LinksSelectors.getClientsWithChildren,
        fav: LinksSelectors.getFavoritesWithChildren,
        clientDiscounts: LinksSelectors.getClientDiscountsWithChildren,
        bonuses: LinksSelectors.getBonusesWithChildren,
        finance: LinksSelectors.getFinanceWithChildren,
        achieves: LinksSelectors.getAchieves,
        settings: LinksSelectors.getSettingsWithChildren,
        blog: LinksSelectors.getBlog,
        notes: LinksSelectors.getNotes,
      }),
    ],
    (...args) => cb(...args).filter(Boolean)
  );

const getMobileNavigation = createNavigationSelector(
  (isClientMode, isBusinessMode, links) => {
    if (isClientMode) {
      return [
        links.schedule,
        links.messenger,
        links.orders,
        links.profile,
        links.fav,
        links.bonuses,
        links.achieves,
        links.settings,
      ];
    } else if (isBusinessMode) {
      return [
        links.dashboard,
        links.schedule,
        links.clients,
        links.saloonDashboard,
        links.sms,
        links.warehouse,
        links.profile,
        links.todos,
        links.fav,
        links.bonuses,
        links.orders,
        //  links.achieves,
        links.notes,
        links.finance,
        links.settings,
        links.messenger,
      ];
    } else {
      return [
        links.dashboard,
        links.schedule,
        links.todos,
        links.saloonDashboard,
        links.notes,
        links.finance,
        links.settings,
        links.messenger,
      ];
    }
  }
);
const getDesktopNavigation = createNavigationSelector(
  (isClientMode, isBusinessMode, links) => {
    if (isClientMode) {
      return [
        links.schedule,
        links.messenger,
        links.orders,
        links.profile,
        links.fav,
        links.bonuses,
        links.achieves,
        links.settings,
      ];
    } else if (isBusinessMode) {
      return [
        links.saloonDashboard,
        links.dashboard,
        links.settings,
        links.messenger,
        links.schedule,
        links.clients,
        links.todos,
        links.sms,
        links.warehouse,
        links.finance,
        links.orders,
        links.profile,
        links.bonuses,
        links.fav,
        //  links.achieves,
        links.notes,
      ];
    } else {
      return [
        links.saloonDashboard,
        links.dashboard,
        links.settings,
        links.messenger,
        links.schedule,
        links.todos,
        links.finance,
        links.notes,
      ];
    }
  }
);

const getDesktopHeaderLinks = createSelector(
  [
    UserSelectors.isMaster,
    LinksSelectors.getSearch,
    getTaskLink,
    UserSelectors.isStandardMode,
  ],
  (isMaster, searchLink, taskLink, isStandardMode) => {
    if (isStandardMode) return [];
    return [isMaster && taskLink, !isMaster && searchLink].filter(Boolean);
  }
);
const isLinksCollapsed = (state) => state[DOMAIN].linksCollapsed;
export const Selectors = {
  getSupportLink,
  getMobileNavigation,
  getDesktopNavigation,
  isLinksCollapsed,
  getDesktopHeaderLinks,
};
