import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import SettingsPage from "./loadable";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";

const mSp = (state) => ({
  isBusinessMode: UserSelectors.isBusinessMode(state),
  menu: LinksSelectors.getSettingsChildren(state),
  routePrefix: LinksSelectors.getRoutePrefix(state),
});

const connected = connect(mSp)(SettingsPage);
export default connected;
