import { createSlice } from "@reduxjs/toolkit";

export const DOMAIN = "appShell";
const initialState = {
  linksCollapsed: false,
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    toggleLinksCollapsed(state) {
      state.linksCollapsed = !state.linksCollapsed;
    },
  },
});
export const Actions = slice.actions;
export default slice.reducer;
