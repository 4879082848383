import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";
const AchievesPage = loadable(
  () => import(/* webpackChunkName: "achieves-page" */ "."),
  {
    ssr: false,
    fallback: <Spin size="xl" />,
  }
);

export default AchievesPage;
