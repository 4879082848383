import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import OrdersPage from "./loadable";

const mSp = (state) => ({
  isAuth: AuthSelectors.isAuth(state),
  isMaster: UserSelectors.isMaster(state),
  masterId: UserSelectors.getMasterId(state),
  currency: UserSelectors.getCurrency(state),
  menu: LinksSelectors.getOrdersChildren(state),
  routePrefix: LinksSelectors.getRoutePrefix(state),
  locale: LocaleSelectors.getLocale(state),
});

const connected = connect(mSp)(OrdersPage);
export default connected;
