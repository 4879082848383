import styled from "styled-components";

export const Wrap = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  -webkit-overflow-scrolling: touch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
`;
export const Item = styled.li`
  :first-child {
    margin-inline-start: 0;
  }
  :last-child {
    margin-inline-end: 0;
  }
  margin: 4px;
`;

export const Chip = styled.button.attrs({
  className: "ripple-btn",
})`
  border-radius: var(--root-br);
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  background-color: ${({ isActive }) =>
    isActive ? "var(--bg-touchable-active)" : "var(--bg-touchable)"};
  color: ${({ isActive }) =>
    isActive
      ? "var(--font-color-touchable-active)"
      : "var(--font-color-touchable)"};
  padding: 12px 15px;
`;
