import React from "react";
import * as S from "./styled";
import { Link } from "react-router-dom";
import { generateAvatar } from "@/utils";
import LogoutIcon from "_c/icon/Logout";
import PT from "prop-types";
import { useToggle } from "@/hooks/useToggle";

const AppShellUserProfile = (props) => {
  const [isLogoutDisabled, toggleLogoutDisabled] = useToggle();
  const renderUserInfo = () => {
    return (
      <>
        <img
          alt="avatar"
          src={generateAvatar({ url: props.avatar, sex: props.sex })}
        />
        <span>{props.name}</span>
      </>
    );
  };
  return (
    <S.Wrap>
      {props.profileLink ? (
        <Link
          to={props.profileLink}
          title={props.t("profile_upper_menu.avatar")}
        >
          {renderUserInfo()}
        </Link>
      ) : (
        <span>{renderUserInfo()}</span>
      )}
      <button
        disabled={isLogoutDisabled}
        onClick={() => {
          toggleLogoutDisabled();
          props.logout();
        }}
      >
        <LogoutIcon color="var(--font-color-accent)" width="18" />
      </button>
    </S.Wrap>
  );
};
AppShellUserProfile.propTypes = {
  profileLink: PT.string,
  name: PT.string,
  avatar: PT.string,
  sex: PT.bool,

  logout: PT.func.isRequired,
  t: PT.func.isRequired,
};
export default AppShellUserProfile;
