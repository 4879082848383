import { API_URL } from "@/misc/constants";
import { get, post, put, remove } from "./";

// TODO:: cache
const BASE = `${API_URL}/user`;
const findById = (id) => {
  return get(`${BASE}/${id}`);
};
const getProfile = (id) => {
  return get(`${BASE}/${id}/profile`, { cache: false });
};
const updateById = (id, payload) => {
  return post(`${BASE}/${id}`, payload);
};
const deleteById = (id, reason) => {
  return remove(`${BASE}/${id}`, {
    params: { reason },
  });
};

const updateLocale = (id, locale) => {
  return post(`${BASE}/${id}/locale`, {
    locale,
  });
};
const updateCurrency = (id, currency) => {
  return post(`${BASE}/${id}/currency`, {
    currency,
  });
};
const updatePhone = (phone) => {
  return post(`${BASE}/phone`, { phone });
};
const updateFeedOptions = async (id, payload) => {
  const res = await post(`${BASE}/${id}/feed/options`, payload);
  return res;
};
const verifyPhone = (code) => {
  return post(`${BASE}/phone/verify`, { code });
};
const isEmailExists = (email) => {
  return get(`${BASE}/email/isExists/${email}`, { cache: false });
};
const isPhoneExists = (phone) => {
  return get(`${BASE}/phone/isExists/${phone}`, { cache: false });
};
const updateEmail = (email) => {
  return post(`${BASE}/email`, { email });
};
const verifyEmail = (code) => {
  return post(`${BASE}/email/verify/${code}`);
};
const getTelegramToken = (userId) => {
  return get(`${BASE}/${userId}/tgToken`);
};
const restorePassword = (token, payload) => {
  return post(`${BASE}/password/restore/${token}`, payload);
};
const requestRestorePasswordLink = (payload) => {
  return post(`${BASE}/password/restore/link`, payload);
};
const createUser = (payload) => {
  return put(BASE, payload);
};
const getFeed = (id, params) => {
  return get(`${BASE}/${id}/feed`, {
    params,
  });
};

export const UserAPI = {
  createUser,
  deleteById,
  isEmailExists,
  isPhoneExists,
  getFeed,
  getTelegramToken,
  findById,
  getProfile,
  updateById,
  updatePhone,
  updateEmail,
  updateFeedOptions,
  verifyEmail,
  verifyPhone,
  restorePassword,
  requestRestorePasswordLink,
  updateLocale,
  updateCurrency,
};
