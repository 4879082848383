import { ClientsAPI } from "@/api/clients";
import { queryClient } from "@/network/queryClient";
import { ClientsCacheService as Cache } from "./cache";
import debounce from "lodash/debounce";
import type { tBizClient } from "@/types";

export const ClientMutationHandler = {
  clientCreated({ client }: { client: tBizClient }) {
    Cache.addClient(client);
  },
  clientUpdated({ updated_client: client }: { updated_client: tBizClient }) {
    Cache.replaceClient(client);
  },
  clientRemoved({ client }: { client: tBizClient }) {
    Cache.removeClient(client);
  },
  async clientStatsChanged({ client_id }: { client_id: string }) {
    let handler = statsHandlersMap.get(client_id);
    if (!handler) {
      handler = debounce(async () => {
        try {
          const client = await queryClient.fetchQuery(
            Cache.buildSingleClientQueryKey(client_id),
            () => ClientsAPI.findById(client_id).then((d) => d.data),
            {
              staleTime: 0,
            }
          );
          Cache.replaceClient(client);
        } catch (e) {
          console.error(e);
        }
      }, 250);
      statsHandlersMap.set(client_id, handler);
    }
    handler();
  },
};

const statsHandlersMap = new Map<string, tVoidFn>();
