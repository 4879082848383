import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const NotificationsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: "event-notifications" */
      "./"
    ),
  { ssr: false, fallback: <Spin size="xl" /> }
);

export default NotificationsLoadable;
