import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const WarehousePageLoadable = loadable(
  () => import(/* webpackChunkName: "warehouse-page" */ "./"),
  {
    ssr: false,
    fallback: <Spin size="xl" />,
  }
);

export default WarehousePageLoadable;
