import React from "react";

function SvgComponent({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98.38 100"
      {...props}
    >
      <path d="M81.05 52.7l17.33-23.22L75.16 46.8l17.33-23.22-23.23 17.33 17.33-23.22L63.37 35 80.7 11.79 57.47 29.12 74.8 5.89 51.58 23.23 68.91 0 45.68 17.33a9.71 9.71 0 000 13.71L67.35 52.7a9.67 9.67 0 0013.7 0zM66.37 58.05L42 33.63a4.37 4.37 0 00-6.71.43L1.86 77.18a9.47 9.47 0 00.77 12.18l8 8a9.47 9.47 0 0012.18.77l43.13-33.37a4.37 4.37 0 00.43-6.71zM29 81.89L18.11 71 35 54.13 45.88 65z" />
      <path d="M26.843 68.01l5.155-5.155 5.155 5.155-5.155 5.155z" />
    </svg>
  );
}

export default SvgComponent;
