import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Avatar from "_c/Avatar";
import { useCloudinaryWidget } from "@/hooks/useCloudinaryWidget";
import CameraIcon from "_c/icon/Camera";
import { CircleButton } from "_c/button";
import { analyticsAction } from "@/utils/analytics";

const ProductPhotoField = ({ isSaloon }) => {
  const { uploadPhoto } = useCloudinaryWidget();
  const { register, watch, setValue } = useFormContext();
  const photo = watch("photo");
  const onUpload = async () => {
    const photo = await uploadPhoto();
    setValue("photo", photo);
    analyticsAction({
      category: "warehouse",
      action: isSaloon ? "good_photo_added_salon" : "good_photo_added",
    });
  };
  useEffect(() => {
    register({ name: "photo" });
  }, [register]);
  return (
    <div
      css={`
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      `}
    >
      {photo ? (
        <div
          css={`
            cursor: pointer;
          `}
          role="button"
          onClick={onUpload}
        >
          <Avatar avatar={photo} size="lg" />
        </div>
      ) : (
        <CircleButton
          css={`
            width: 64px;
            height: 64px;
          `}
          type="button"
          onClick={onUpload}
          large
        >
          <CameraIcon width="26" color="white" />
        </CircleButton>
      )}
    </div>
  );
};

export default ProductPhotoField;
