import React from "react";

const SvgAngleRight = ({ color, ...props }) => (
  <svg width="6" viewBox="0 0 6.406 10" {...props}>
    <path
      data-name="\u0424\u0456\u0433\u0443\u0440\u0430 1"
      d="M1 9l4-4-4-4"
      fill="none"
      stroke={color || "currentColor"}
      strokeLinecap="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgAngleRight;
