import EventEmitter from "wolfy87-eventemitter";
import { SYNC_DB_EVENTS as EVENTS } from "./constants";
import { REMOTE_URL } from "./constants";
import { v4 as genUUID } from "uuid";
import omit from "lodash/omit";
import type { tSyncDbRawDoc, tSyncDbFinalDoc } from "@/types";
import { SyncDbSearch } from "./searchEngine";

type tConstructorsArgs = {
  searchEngineDbName: string;
};
export abstract class SyncDb extends EventEmitter {
  public isReady = false;
  public searchEngine: SyncDbSearch;
  protected static Pouch: Maybe<PouchDB.Static> = null;
  protected _local: Maybe<PouchDB.Database> = null;
  protected _remote: Maybe<PouchDB.Database> = null;
  private _changesListener: Maybe<PouchDB.Core.Changes<tSyncDbRawDoc>> = null;

  constructor(args: tConstructorsArgs) {
    super();
    this.searchEngine = new SyncDbSearch(args.searchEngineDbName);
  }

  public abstract init(...args: any): Promise<void>;
  public async destroy() {
    if (this._remote) this._remote.close();
    if (this._local) this._local.close();
    this.isReady = false;
    this.emit(EVENTS.READY, false);
    if (this._changesListener) {
      this._changesListener.cancel();
    }
    if (this.searchEngine) {
      this.searchEngine.destroy();
    }
  }
  public async getDoc<T extends tAnyDict = {}>(
    id: string
  ): Promise<Maybe<tSyncDbFinalDoc<T>>> {
    if (!this._local) return null;
    try {
      const doc = await this._local.get<tSyncDbFinalDoc<T>>(id);
      return doc;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  public async updateDoc(doc: tSyncDbRawDoc): Promise<void> {
    if (!this._local) return;
    if (!doc._rev && doc._id) {
      const oldDoc = await this.getDoc(doc._id);
      if (oldDoc) {
        doc._rev = oldDoc._rev;
      }
    }
    await this.addDoc(doc);
  }
  public async updateDocs(docs: tSyncDbRawDoc[]): Promise<void> {
    if (!this._local) return;
    await this._local.bulkDocs(docs.map(this.normalizeDoc));
  }
  public normalizeDoc(doc: tSyncDbRawDoc): tSyncDbFinalDoc {
    if (!doc._id) {
      doc._id = genUUID();
    }
    if (!doc.created_at) {
      doc.created_at = new Date().toISOString();
    }
    // strip all the lokijs crap
    return omit(doc, ["$loki", "meta"]) as tSyncDbFinalDoc;
  }
  public async addBulk(docs: tSyncDbRawDoc[]): Promise<{ id: string }[]> {
    if (!this._local) return [];
    const res = await this._local.bulkDocs(docs.map(this.normalizeDoc));
    return res.map((v) => ({ id: v.id! }));
  }
  public async addDoc(rawDoc: tSyncDbRawDoc): Promise<{ id: string }> {
    const db = this._local!;
    const doc = this.normalizeDoc(rawDoc);
    try {
      const res = await db.put(doc);
      return { id: res.id };
    } catch (e) {
      console.error(e);
      if ((e as any).name === "conflict") {
        const oldDoc = await this.getDoc(doc._id);
        if (oldDoc) {
          doc._rev = oldDoc._rev;
          const res = await db.put(doc);
          console.log("conflict resolved");
          return { id: res.id };
        }
      }
      throw e;
    }
  }
  public async removeDoc(id: string) {
    if (!this._local) return;
    const doc = await this.getDoc(id);
    if (doc) {
      await this._local.remove(doc);
    }
  }
  public waitTilReady(): Promise<void> {
    if (this.isReady) return Promise.resolve();
    return new Promise((res) => {
      const l = (ready: boolean) => {
        if (ready) {
          this.off(EVENTS.READY, l);
          res();
        }
      };
      this.on(EVENTS.READY, l);
    });
  }

  protected async _bootstrap() {
    if (SyncDb.Pouch) return;
    const { default: Pouch } = await import("pouchdb");
    SyncDb.Pouch = Pouch;
  }
  protected _createLocalDb(name: string) {
    if (!SyncDb.Pouch) return;
    this._local = new SyncDb.Pouch(name);
  }
  protected _createRemoteDb(
    path: string,
    params?: PouchDB.AdapterWebSql.Configuration
  ) {
    if (!SyncDb.Pouch) return;
    this._remote = new SyncDb.Pouch(REMOTE_URL + path, params);
  }
  protected _makeReady() {
    this.isReady = true;
    this.emit(EVENTS.READY, true);
  }
  protected async _setupSearch() {
    if (!this._local) return;
    const docs = await this._local.allDocs<tSyncDbRawDoc>({
      include_docs: true,
    });
    const collections = docs.rows.reduce((acc, d) => {
      const doc = d.doc;
      if (!doc) return acc;
      if (!doc.type) return acc;
      if (doc.type in acc) {
        acc[doc.type].push(doc);
      } else {
        acc[doc.type] = [doc];
      }
      return acc;
    }, {} as Record<string, tSyncDbRawDoc[]>);
    if (!this.searchEngine.destroyed) {
      this.searchEngine.destroy();
    }
    if (this._changesListener) {
      this._changesListener.cancel();
    }
    this.searchEngine.destroyed = false;
    for (const collName in collections) {
      const coll = collections[collName];
      this.searchEngine.addCollection(collName);
      this.searchEngine.add(collName, coll);
    }
    this._changesListener = this._local
      .changes<tSyncDbRawDoc>({
        since: "now",
        live: true,
        include_docs: true,
      })
      .on("change", (change) => {
        if (change.deleted) {
          if (change.doc) {
            this.searchEngine.removeById(null, change.doc._id);
          }
        } else if (change.doc) {
          if (change.doc.type) {
            if (!this.searchEngine.hasCollection(change.doc.type)) {
              this.searchEngine.addCollection(change.doc.type);
            }
            this.searchEngine.addOrUpdate(change.doc.type, change.doc);
          }
        } else {
          console.error("[Pouchdb]:: Unknown changes: ", change);
        }
      })
      .on("error", (err) => {
        console.error("[Pouchdb]:: changes error: ", err);
        // handle errors
      });
  }
}
