import { Actions } from "./slice";
import { IS_SERVER } from "@/misc/constants";
import { Effects as UserEffects } from "@/store/user/effects";
import { changeMomentLocale } from "@/utils/time";

const changeLocale = (locale, maybeCtx) => (dispatch) => {
  dispatch(Actions.changeLocale(locale));
  if (!IS_SERVER) {
    document.cookie = `locale=${locale}`;
    changeMomentLocale(locale);
  } else if (maybeCtx) {
    maybeCtx.cookies.set("locale", locale, { httpOnly: false });
  }
  dispatch(UserEffects.updateLocale(locale));
};

export const Effects = {
  changeLocale,
};
