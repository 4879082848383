import { API_URL } from "@/misc/constants";
import { post } from "./";
import { mastersCache } from "@/misc/httpCache";

const BASE = `${API_URL}/marketplace`;
const register = async (data) => {
  const res = await post(`${BASE}/register`, data);
  mastersCache.reset();
  return res;
};

export const MarketplaceAPI = {
  register,
};
