import s from "styled-components";
import { CircleButton } from "_c/button";

export const Wrap = s.div`
  border-radius: 9px;
  height: auto;
  padding: 15px;
  padding-right: 7px;
  background-color: var(--bg-elevate);
  transition: height .25s ease;
  margin-bottom: 15px;
  @media(max-width: 1199px) {
    padding-top: 10px;
    background-size: 115% auto;
  }
  @media(max-width: 991px) {
    background-size: 43% auto;
  }
  @media(max-width: 767px) {
    background-size: 56% auto;
  }
  @media(max-width: 575px) {
    background-size: 300px auto;
  }
`;
export const HeadWrap = s.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media(max-width: 991px) {
    margin-bottom: 25px;
  }
`;
export const Head = s.h3`
  margin: 0;
  margin-inline-end: 16px;
  color: var(--font-color-emp);
  font-size: 18px;
  font-weight: 700;
`;
export const InfoButton = s(CircleButton).attrs({
  light: true,
  medium: true,
  as: "span",
})`
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 700;
  user-select: none;
`;
export const Content = s.span`
  display: block;
  margin-bottom: 5px;
  color: var(--font-color-accent);
  font-weight: 400;
  font-size: 16px;
`;
