import { ProfileSelectors } from "./profile";
import { getCityPhoto } from "./cityPhoto";

const isLoading = (state) => state.clientProfile.loading;
const getError = (state) => state.clientProfile.error;
const getRedirectMeta = (state) => state.clientProfile.redirect;

export const Selectors = {
  ...ProfileSelectors,
  getCityPhoto,
  getRedirectMeta,
  isLoading,
  getError,
};
