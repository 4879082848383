import React from "react";
import PT from "prop-types";
import Modal from "_c/Modals";
import NoteEditor from "./main";

const NoteEditorModal = ({ isOpen, ...props }) => {
  return (
    <Modal onClose={props.onClose} isOpen={isOpen}>
      <NoteEditor {...props} />
    </Modal>
  );
};
NoteEditorModal.propTypes = {
  isOpen: PT.bool.isRequired,

  onClose: PT.func.isRequired,
};
export default NoteEditorModal;
