import React from "react";
import styled from "styled-components";
import Avatar from "_c/Avatar";
import loadable from "@loadable/component";

const BaseSelect = loadable(() => import("react-select"));
const BaseAsyncSelect = loadable(() => import("react-select/async"));
const BaseAsyncCreatableSelect = loadable(() =>
  import("react-select/async-creatable")
);

export const SelectUserOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  img {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }
`;
const UserOptionsRenderer = (option) => {
  return (
    <SelectUserOption>
      <div
        css={`
          max-width: 200px;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          padding-bottom: 2px;
          span:first-child {
            white-space: nowrap;
          }
          span:last-child {
            font-size: 14px;
          }
          @media (max-width: 400px) {
            max-width: 200px;
            overflow: hidden;
          }
        `}
      >
        <span>{option.label}</span>
        {option.phone && <span>{option.phone}</span>}
      </div>
      <Avatar
        style={{ lineHeight: 0, display: "flex", paddingTop: "2px" }}
        size="sm"
        sex={option.sex}
        avatar={option.avatar}
      />
    </SelectUserOption>
  );
};

const Select = (props) => {
  return (
    <BaseSelect
      classNamePrefix="simpo-select"
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};
export const AsyncSelect = (props) => {
  return (
    <BaseAsyncSelect
      classNamePrefix="simpo-select"
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};
export const AsyncCreatableSelect = (props) => {
  return (
    <BaseAsyncCreatableSelect
      classNamePrefix="simpo-select"
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};
export const UserSelect = (props) => (
  <Select {...props} formatOptionLabel={UserOptionsRenderer} />
);

export default Select;
