const getRegisterLoading = (state) => state.auth.registerLoading;
const isAuth = (state) => state.auth.isAuth;
const getRegisterError = (state) => state.auth.registerError;
const getSocketToken = (state) => state.auth.socketToken;
const getCouchdbToken = (state) => state.auth.couchdbToken;

export const Selectors = {
  getRegisterError,
  getRegisterLoading,
  getSocketToken,
  getCouchdbToken,
  isAuth,
};
