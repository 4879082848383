import React from "react";
import { matchRoute } from "@/prefetch-routes";
import qs from "query-string";
import { Route, Switch, withRouter } from "react-router-dom";
import MasterContainer from "@/containers/master";
import ClientContainer from "@/containers/client";
import SaloonContainer from "@/containers/saloon";
import SafeDeal from "_p/safe-deal/container";
import Partners from "_p/partners/container";
import Ambassador from "_p/ambassador/container";
import ThankYou from "_p/thankyou/container";
import BlogContainer from "@/containers/blog";
import HomeAndSearchContainer from "@/containers/home-and-search";
import Portfolio from "_p/portfolio/container";
import PrivacyPolicy from "_p/privacy-policy";
import Agreement from "_p/agreement";
import VerifyEmail from "_p/verify-email/container";
import VerifyPhone from "_p/verify-phone/container";
import RestorePassword from "_p/restore-password";
import Review from "_p/review/container";
import NotFound from "_p/404";
import VerifyClientInvite from "_p/verify-client-invite/container";
import VerifySaloonInvite from "_p/verify-saloon-invite/container";
import { ProgressEmitter } from "_c/GlobalProgress/emitter";
import ViberSubscribe from "_p/viber-subscribe";
import MsgrSubPage from "_p/messenger-sub";
import Entrypoint from "_p/entrypoint";

class Router extends React.Component {
  _extractPath(loc) {
    return loc.pathname + loc.search;
  }

  async componentDidUpdate(prevProps) {
    const navigated =
      this._extractPath(prevProps.location) !==
      this._extractPath(this.props.location);

    if (navigated) {
      const [matched, matchedParams] = matchRoute(this.props.location.pathname);
      // TODO:: catch error
      if (matched) {
        ProgressEmitter.start();
        await matched.prefetch({
          locale: window.__i18n.language,
          params: matchedParams,
          store: window.__reduxStore,
          loadLocale(locale) {
            return window.__i18n.loadNamespaces(locale);
          },
          ctx: {
            status: 200,
            redirect() {
              // TODO
            },
            request: {
              // TODO
              query: {},
            },
            throw(_code, error) {
              throw new Error(error);
            },
            query: qs.parse(this.props.location.search),
          },
        });
        setTimeout(() => ProgressEmitter.end(), 1);
      }
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomeAndSearchContainer} />
        <Route path="/search" component={HomeAndSearchContainer} />
        <Route path="/messenger/sub/:token" component={MsgrSubPage} />
        <Route path="/entrypoint" component={Entrypoint} />
        <Route path="/master" component={MasterContainer} />
        <Route path="/app" component={MasterContainer} />
        <Route path="/client" component={ClientContainer} />
        <Route path="/salon" component={SaloonContainer} />
        <Route path="/blog" component={BlogContainer} />
        <Route path="/portfolio/:id" component={Portfolio} />
        <Route path="/review/:id" component={Review} />
        <Route exact path="/safe-deal" component={SafeDeal} />
        <Route exact path="/ambassador" component={Ambassador} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/user-agreement" component={Agreement} />
        <Route exact path="/thankyou" component={ThankYou} />
        <Route path="/viber/subscribe/:token" component={ViberSubscribe} />
        <Route path="/verify-email/:code" component={VerifyEmail} />
        <Route
          path="/verify-client-invite/:code"
          component={VerifyClientInvite}
        />
        <Route path="/verify-phone/:code" component={VerifyPhone} />
        <Route
          path="/verify-saloon-invite/:code"
          component={VerifySaloonInvite}
        />
        <Route path="/restore-password/:code" component={RestorePassword} />
        <Route
          render={({ staticContext }) => {
            if (staticContext) {
              staticContext.notFound = true;
            }
            return <NotFound />;
          }}
        />
      </Switch>
    );
  }
}
export default withRouter(Router);
