import React from "react";
import BottomSheet from "_c/BottomSheet";
import Main from "./main";

const WarehouseProductPickerModal = ({
  t,
  onSelect: onSelectProp,
  isOpen,
  onClose,
  master,
  saloon,
}) => {
  const onSelect = (data) => {
    onSelectProp(data);
    onClose();
  };
  return (
    <>
      <BottomSheet
        zIndex={1}
        disablePortal
        defaultHeight={window.innerHeight * 0.75}
        header={<div />}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div
          css={`
            color: var(--font-color-main);
            padding: 10px;
          `}
        >
          <Main t={t} master={master} saloon={saloon} onSelect={onSelect} />
        </div>
      </BottomSheet>
    </>
  );
};

export default WarehouseProductPickerModal;
