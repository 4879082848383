import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "locale";

const slice = createSlice({
  name: DOMAIN,
  initialState: null,
  reducers: {
    changeLocale(_state, { payload }) {
      return payload;
    }
  }
});
export const Actions = slice.actions;
export default slice.reducer;
