import React from "react";
import { ToastContainer as Toastify, Slide, toast } from "react-toastify";
import { useColorScheme } from "@/hooks/useColorScheme";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "styled-components";

const ToastContainer = () => {
  const colorScheme = useColorScheme();
  const theme = useTheme();
  return (
    <Toastify
      icon={false}
      theme={colorScheme}
      position="top-center"
      transition={Slide}
      autoClose={5000}
      draggablePercent={50}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={!!theme.rtl}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
  );
};
export default ToastContainer;
