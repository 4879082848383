import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";
import { tSmsAccount } from "@/types";

const buildBaseQueryKey = () => QueryKeys.smsAccount;
export const SmsAccountCacheService = {
  replaceAccount(account: tSmsAccount) {
    queryClient.setQueryData(buildBaseQueryKey(), account);
  },
};
