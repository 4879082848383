import { Selectors as UserSelectors } from "@/store/user/selectors";
import { SaloonStaffCacheService } from "./cache";
import { Actions as UserActions } from "@/store/user/slice";
import { SchedulerCacheService } from "../scheduler/cache";
import { TasksCacheService } from "../tasks/cache";
import { FreeSlotsCacheService } from "../freeSlots/cache";
import { SaloonsCacheService } from "../saloons/cache";

export const SaloonStaffMutationHandler = {
  adminRemoved({ admin_id, saloon_id }) {
    SaloonStaffCacheService.removeAdmin({ id: admin_id, saloonId: saloon_id });

    const reduxStore = window.__reduxStore;
    const reduxState = reduxStore.getState();
    const authUserId = UserSelectors.getUserId(reduxState);
    if (authUserId === admin_id) {
      SaloonsCacheService.invalidateMySaloons();
    }
  },
  masterRemoved({ master_id, saloon_id }) {
    SaloonStaffCacheService.removeMaster({
      id: master_id,
      saloonId: saloon_id,
    });
    const reduxStore = window.__reduxStore;
    const reduxState = reduxStore.getState();
    const authMasterId = UserSelectors.getMasterId(reduxState);
    if (authMasterId === master_id) {
      SchedulerCacheService.invalidateAll();
      TasksCacheService.invalidateAll();
      FreeSlotsCacheService.invalidateAll();
      reduxStore.dispatch(
        UserActions.updateMaster({
          saloon: null,
          saloon_meta: null,
        })
      );
    }
  },
};
