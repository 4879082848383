import React from "react";
import { useForm, FormContext } from "react-hook-form";
import { TextField } from "_c/form/Field";
import { useTranslation } from "react-i18next";
import { restorePasswordSchema } from "@/validation/restore-password";
import { UserAPI } from "@/api/user";
import { useHistory, useParams } from "react-router-dom";
import Modal, { Content, Footer } from "_c/Modals";
import { useMut } from "@/hooks/useMut";
import { Actions as IdentityModalActions } from "@/store/identityModal/slice";
import { useDispatch } from "react-redux";

const RestorePasswordPage = () => {
  const dispatch = useDispatch();
  const { t, ready } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  const history = useHistory();
  const { code } = useParams();
  const mut = useMut(
    ({ password }) =>
      UserAPI.restorePassword(code, {
        password,
      }),
    {
      t,
      notifyOnError: true,
      toast: t("common:restore_pass.success"),
      onSuccess() {
        dispatch(IdentityModalActions.changeMode("auth"));
        history.push("/");
      },
      onError(e) {
        setError("server", "serverError", e);
      },
    }
  );
  const methods = useForm({
    validationSchema: restorePasswordSchema,
  });
  const { handleSubmit, setError } = methods;
  const onSubmit = (form) => {
    mut.mutate(form);
  };
  if (!ready) return null;
  const formId = "restore-password-form";
  return (
    <Modal isOpen>
      <Content
        css={`
          color: var(--font-color-main);
        `}
      >
        <FormContext {...methods}>
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              autoFocus
              type="password"
              id="pass"
              label={t("common:restore_pass.new")}
              t={t}
              name="password"
              autoComplete="new-password"
            />
            <TextField
              autoComplete="new-password"
              type="password"
              id="repeat"
              t={t}
              label={t("common:restore_pass.repeat")}
              name="repeat_password"
            />
          </form>
        </FormContext>
      </Content>
      <Footer form={formId} disabled={mut.isLoading} type="submit">
        {t("common:restore_pass.submit")}
      </Footer>
    </Modal>
  );
};

export default RestorePasswordPage;
