import { createSelector } from "@reduxjs/toolkit";
import { Selectors as BasketSelectors } from "@/store/basket/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { DOMAIN } from "./slice";

const $ = (s: tReduxRootState) => s[DOMAIN];

const isSupportOpen = (s: tReduxRootState) => $(s).isSupportOpen;
const isAuthOpen = (s: tReduxRootState) => $(s).isAuthOpen;
const isAuthReallyOpen = createSelector(
  [AuthSelectors.isAuth, isAuthOpen],
  (isAuth, isOpen) => isOpen && !isAuth
);
const isBottomOpen = createSelector(
  BasketSelectors.isBasketEmpty,
  (empty) => !empty
);
const isBookingOpen = (s: tReduxRootState) => $(s).isBookingOpen;

const isTechOpen = (s: tReduxRootState) => $(s).isTechOpen;
const isShareOpen = (s: tReduxRootState) => $(s).share.isOpen;
const getShareLink = (s: tReduxRootState) => $(s).share.link;
const getTechType = (s: tReduxRootState) => $(s).techType;
const isPremiumOpen = (s: tReduxRootState) => $(s).isPremiumOpen;
const isCreateTaskOpen = (s: tReduxRootState) => $(s).createTask.isOpen;
const getCreateTaskParams = (s: tReduxRootState) => $(s).createTask.params;
const isSendNotificationOpen = (s: tReduxRootState) =>
  $(s).sendNotification.isOpen;
const getSendNotificationData = (s: tReduxRootState) =>
  $(s).sendNotification.data;

const isTodoEditorOpen = (s: tReduxRootState) => $(s).todoEditor.isOpen;
const getTodoEditorParams = (s: tReduxRootState) => $(s).todoEditor.params;

const isExpenseEditorOpen = (s: tReduxRootState) => $(s).expenseEditor.isOpen;
const getExpenseEditorParams = (s: tReduxRootState) =>
  $(s).expenseEditor.params;

const isNoteEditorOpen = (s: tReduxRootState) => $(s).noteEditor.isOpen;
const getNoteEditorParams = (s: tReduxRootState) => $(s).noteEditor.params;

const isAccountingTxnEditorOpen = (s: tReduxRootState) =>
  $(s).accountingTxnEditor.isOpen;
const getAccountingTxnEditorParams = (s: tReduxRootState) =>
  $(s).accountingTxnEditor.params;
const isWarehouseProductEditorOpen = (s: tReduxRootState) =>
  $(s).warehouseProductEditor.isOpen;
const getWarehouseProductEditorParams = (s: tReduxRootState) =>
  $(s).warehouseProductEditor.params;
const isWarehouseTxnEditorOpen = (s: tReduxRootState) =>
  $(s).warehouseTxnEditor.isOpen;
const getWarehouseTxnEditorParams = (s: tReduxRootState) =>
  $(s).warehouseTxnEditor.params;
const getImagesGalleryParams = (s: tReduxRootState) =>
  $(s).imagesGallery.params;
const isImagesGalleryOpen = (s: tReduxRootState): boolean => {
  if (!$(s).imagesGallery.isOpen) return false;
  const params = getImagesGalleryParams(s);
  return Boolean(
    params.activeIndex !== -1 &&
      params.images.length &&
      params.images[params.activeIndex!]
  );
};
const isClientEditorOpen = (s: tReduxRootState) => $(s).clientEditor.isOpen;
const getClientEditorParams = (s: tReduxRootState) => $(s).clientEditor.params;

const isExternalNotificationTmplEditorOpen = (s: tReduxRootState) =>
  $(s).externalNotiticationTmplEditor.isOpen;
const getExternalNotificationTmplEditorParams = (s: tReduxRootState) =>
  $(s).externalNotiticationTmplEditor.params;

export const Selectors = {
  isSupportOpen,
  isPremiumOpen,
  getShareLink,
  getTechType,
  isAuthOpen: isAuthReallyOpen,
  isBottomOpen,
  isBookingOpen,
  isTechOpen,
  isShareOpen,
  isCreateTaskOpen,
  getCreateTaskParams,
  isSendNotificationOpen,
  getSendNotificationData,
  isTodoEditorOpen,
  getTodoEditorParams,
  isExpenseEditorOpen,
  getExpenseEditorParams,
  isNoteEditorOpen,
  getNoteEditorParams,
  isAccountingTxnEditorOpen,
  getAccountingTxnEditorParams,
  isWarehouseProductEditorOpen,
  getWarehouseProductEditorParams,
  isWarehouseTxnEditorOpen,
  getWarehouseTxnEditorParams,
  getImagesGalleryParams,
  isImagesGalleryOpen,
  isClientEditorOpen,
  getClientEditorParams,
  isExternalNotificationTmplEditorOpen,
  getExternalNotificationTmplEditorParams,
};
