import styled, { css } from "styled-components";
import type { StyledConfig } from "styled-components";

export type tProps = {
  withIcon?: boolean;
  centered?: boolean;

  large?: boolean;
  small?: boolean;
  medium?: boolean;

  paper?: boolean;
  light?: boolean;
  primary?: boolean;
  primaryNoGrad?: boolean;
  greenish?: boolean;
  negative?: boolean;
  outline?: boolean;
  transparent?: boolean;

  flat?: boolean;
};
const shouldForwardProp = (prop: any, fn: (prop: any) => any) => {
  return (
    prop !== "large" &&
    prop !== "medium" &&
    prop !== "small" &&
    prop !== "flat" &&
    prop !== "primary" &&
    prop !== "greenish" &&
    prop !== "negative" &&
    prop !== "paper" &&
    prop !== "light" &&
    prop !== "outline" &&
    prop !== "primaryNoGrad" &&
    fn(prop)
  );
};
const buttonMixin = css<tProps>`
  overflow: hidden;
  outline: none;
  :disabled {
    opacity: 0.5;
  }
  ${({ withIcon }) =>
    withIcon
      ? `
    > span {
      margin-inline-start: 8px;
    }
  `
      : ""};
  margin: ${({ centered }) => (centered ? "0 auto" : "")};
  > * {
    vertical-align: middle;
  }
  font-size: ${(props) => {
    if (props.large) {
      return "16px";
    } else if (props.small) {
      return "14px";
    } else if (props.medium) {
      return "14px";
    } else {
      return "16px";
    }
  }};
  color: ${(props) => {
    if (props.transparent) {
      return "var(--font-color-main)";
    } else if (props.paper) {
      return "var(--font-color-acent)";
    } else if (props.outline) {
      if (props.theme.__name__ === "dark") {
        return "#fff";
      }
      return props.theme.color.primary;
    } else if (props.light) {
      return props.theme.color.primary;
    } else {
      return "white";
    }
  }};
  background: ${(props) => {
    if (props.transparent) {
      return "transparent";
    } else if (props.paper) {
      return props.theme.bg.paper;
    } else if (props.primary) {
      return props.theme.gradient.primary.normal;
    } else if (props.primaryNoGrad) {
      return props.theme.color.primary;
    } else if (props.greenish) {
      return props.theme.color.positive;
    } else if (props.light) {
      return "white";
    } else if (props.outline) {
      return "transparent";
    } else if (props.negative) {
      return props.theme.color.negative;
    } else {
      return props.theme.gradient.primary.normal;
    }
  }};
  background-color: ${(props) => {
    if (props.primary) {
      return props.theme.color.primary;
    } else {
      return "";
    }
  }};
  line-height: ${(props) => {
    if (props.small) {
      return "15px";
    } else return "normal";
  }};
  box-shadow: ${(props) => {
    const primaryShadow = `0px 6px 20px -5px ${props.theme.color.primary}`;
    if (props.paper || props.transparent) {
      return "none";
    } else if (props.primary) {
      return primaryShadow;
    } else if (props.primaryNoGrad) {
      return "0px 6px 26px -5px rgba(102,26,136, 0.5)";
    } else if (props.greenish) {
      return `0px 6px 26px -5px ${props.theme.color.positive}`;
    } else if (props.outline) {
      return "0 0 0 2px " + props.theme.color.primary + " inset !important";
    } else if (props.light) {
      return "0px 6px 26px -5px rgba(0,0,0,0.3)";
    } else if (props.negative) {
      return `0px 6px 26px -5px ${props.theme.color.negative}`;
    } else {
      return primaryShadow;
    }
  }};
  border-radius: ${({ flat }) => (flat ? 0 : "var(--root-br)")};
  position: relative;
  transition: box-shadow 0.4s ease-in-out;
  font-weight: 500;
  :hover,
  :focus {
    box-shadow: 0px 6px 26px -5px transparent;
  }
`;

export const Button = styled.button
  .attrs({
    className: "ripple-btn",
  })
  .withConfig({
    shouldForwardProp,
  })`
  ${buttonMixin};
  padding: ${(props) => {
    if (props.large) {
      return "12px 47px";
    } else if (props.medium) {
      return "12px 30px";
    } else if (props.small) {
      return "10px 17px";
    } else {
      return "12px 47px";
    }
  }};
`;

export const CircleButton = styled.button
  .attrs({
    className: "ripple-btn",
  })
  .withConfig({
    shouldForwardProp,
  })`
  --size: ${(props) => {
    if (props.large) {
      return "50px";
    } else if (props.medium) {
      return "30px";
    } else if (props.small) {
      return "18px";
    } else {
      return "30px";
    }
  }};
  ${buttonMixin};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
`;
