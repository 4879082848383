import React from "react";

function SvgComponent({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84.06 100"
      {...props}
    >
      <path d="M72.66 56.65c-7.89-17.6-13.55-26.81-36.46-29.08a20.61 20.61 0 01-2.2-.36c-13.57 6.51-16.84 14.62-22.57 26.9-.12 1.36-.19 2.71-.19 4C11.24 84 33.55 100 42 100s30.69-18.77 30.69-41.86c0-.49-.02-.99-.03-1.49zm-53.08-10A9.39 9.39 0 1129 56a9.39 9.39 0 01-9.42-9.36zM42.14 88c-7.43 0-13.45-3.77-13.45-8.43s13.45-4 13.45-4 13.44-.6 13.44 4S49.56 88 42.14 88zM55 56a9.39 9.39 0 119.39-9.39A9.38 9.38 0 0155 56z" />
      <path d="M77.28 64.69c-1.78 0-3.3-1.39-4.51-4.13C64.2 41.15 59.89 31.4 36.06 29.05c-9.94-1-14-8.24-13.71-15 .35-7.19 6-14.86 15.72-14 16.8 1.48 29.39 8 37.42 19.42 8.37 11.9 8.75 25.06 8.53 30.16-.33 7.51-2.54 15.06-6.74 15.06zM36.6 3c-7 0-11 5.65-11.29 11.24-.25 4.95 2.5 11 11 11.88C61.9 28.62 67 40.19 75.48 59.36c.86 1.94 1.59 2.36 1.8 2.36.89 0 3.4-3.75 3.77-12.2C81.92 29.91 71.11 6 37.81 3H36.6z" />
      <path d="M32.2 26.41c-12.47 6.31-16.1 14.1-21.34 25.38-1.16 2.5-2.37 5.08-3.78 7.9-1 2-1.69 2.18-1.69 2.18-1-.21-3.34-6.25-2.09-16.6.81-6.8 4.54-24.79 20.94-33.47a13.89 13.89 0 011.39-3.89C7.12 16.08 2.07 34.78.83 41.7c-1.78 9.93-.75 21.53 3.62 23a2.93 2.93 0 001 .16c1.25 0 2.81-.82 4.32-3.83 1.43-2.85 2.64-5.46 3.82-8C19.1 41.1 22.6 33.6 37 27.45a13.52 13.52 0 01-4.8-1.04z" />
      <path d="M28.94 50.1a23.17 23.17 0 01-9.12-1.86l.78-1.81a21.57 21.57 0 0016.69 0l.77 1.81a23.1 23.1 0 01-9.12 1.86zM55 50.06a23.16 23.16 0 01-9.12-1.85l.77-1.82a21.48 21.48 0 0016.69 0l.78 1.82A23.16 23.16 0 0155 50.06zm-13 33.4a23.16 23.16 0 01-9.12-1.85l.77-1.82a21.5 21.5 0 0016.7 0l.77 1.82A23.16 23.16 0 0142 83.46z" />
    </svg>
  );
}

export default SvgComponent;
