import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "achieves";

const initialState = {
  loading: false,
  error: null,
  items: [],
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadingStart(state) {
      state.error = null;
      state.loading = true;
    },
    loadingSuccess(state, { payload }) {
      state.loading = false;
      state.items = payload;
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
