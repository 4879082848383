import React, { useMemo, useState } from "react";
import { Header, Content, Footer } from "_c/Modals";
import MessagePreview from "./MessagePreview";
import AvailableVars from "./AvailableVars";
import { useNotify } from "@/hooks/useNotify";
import ChipsList from "_c/ChipsList";
import Label from "_c/form/Label";
import FormSect from "_c/form/Sect";
import { SETTINGS_NS as BASE_NS } from "@/i18n/ns";
import CloseableMessage from "_c/CloseableMessage";
import Info from "_c/atoms/Info";
import Switch from "_c/Switch";
import { useUserId } from "@/hooks/useUserId";
import { useMasterId } from "@/hooks/useMasterId";
import { ExternalNotificationsAPI } from "@/api/externalNotifications";
import type { eExternalNotificationEvent } from "@/types/externalNotifications";
import type { TFunction } from "i18next";
import { useMut } from "@/hooks/useMut";
import { useQueryClient } from "react-query";
import { QueryKeys } from "@/network/queryKeys";
import QueryRequestWithModal from "@/components/QueryRequest/withModal";
import {
  useExternalNotificationTmplsQuery,
  useSupportedExternalNotificationTmplsQuery,
} from "@/hooks/queries/externalNotifications";
import isempty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { inputMixin } from "_c/form/TextInput";
import type { tExternalNotificationTmplPlaceholderGroup } from "@/types";
const ns = `${BASE_NS}:templates`;

type tProps = {
  defaultTab?: string;
  templateId?: string;
  event: eExternalNotificationEvent;
  placeholderGroup: tExternalNotificationTmplPlaceholderGroup;
  messageAuto: string;
  messageManual: string;
  isAutoDisabled?: boolean;
  isManualMsgAvailable: boolean;
  isAutoMsgAvailable: boolean;
  placeholders: tStringDict;
  availableVars: string[];
  onClose: tVoidFn;
  t: TFunction;
};
const TemplateEditor = (props: tProps) => {
  const event = props.event;
  const queryClient = useQueryClient();
  const [tab, setTab] = useState(props.defaultTab || "auto");
  const userId = useUserId();
  const masterId = useMasterId();
  const [messageAuto, setMessageAuto] = useState(props.messageAuto);
  const [messageManual, setMessageManual] = useState(props.messageManual);
  const [isAutoDisabled, setIsAutoDisabled] = useState(!!props.isAutoDisabled);
  const isAutoEnabled = !isAutoDisabled;
  const { notify } = useNotify();
  const mut = useMut(
    async () => {
      const form = {
        event,
        message_auto: messageAuto,
        message_manual: messageManual,
        is_auto_disabled: isAutoDisabled,
      };
      if (props.templateId) {
        await ExternalNotificationsAPI.updateTemplateById(
          props.templateId,
          form
        );
      } else {
        await ExternalNotificationsAPI.createTemplate({
          owner: userId!,
          master: masterId!,
          ...form,
        });
      }
    },
    {
      t: props.t,
      notifyOnError: true,
      onSuccess() {
        notify(props.t(`${ns}.update_tmpl_success`));
        queryClient.invalidateQueries(QueryKeys.externalNotificationTmpls);
        props.onClose();
      },
    }
  );
  const onSubmit = () => mut.mutate();
  const message = tab === "manual" ? messageManual : messageAuto;
  const onMessageChange = (message: string) => {
    if (tab === "manual") {
      setMessageManual(message);
    } else {
      setMessageAuto(message);
    }
  };
  const appendVariable = (value: string) => {
    const begin = message ? message + " " : message;
    onMessageChange(begin + "{{" + value + "}}");
  };
  return (
    <>
      <Header onClick={props.onClose}>{props.t("common:close")}</Header>
      <Content>
        <strong
          css={`
            display: block;
            margin-bottom: 10px;
          `}
        >
          {props.t(`common:ext_notif_events.${event}`)}
        </strong>

        <ChipsList t={props.t} items={chips} active={tab} onChange={setTab} />
        <CloseableMessage
          name={`ext_tmpl_advice:${tab}`}
          message={props.t(`${ns}.${tab}_advice`)}
        />

        {tab === "manual" && !props.isManualMsgAvailable ? (
          <Info isError>{props.t(`${ns}.manual_tmpl_disabled`)}</Info>
        ) : tab === "auto" && !props.isAutoMsgAvailable ? (
          <Info isError>{props.t(`${ns}.auto_tmpl_disabled`)}</Info>
        ) : (
          <>
            {tab === "auto" && (
              <Switch
                checked={isAutoEnabled}
                onChange={(v) => setIsAutoDisabled(!v)}
                label={props.t(
                  `${ns}.${isAutoEnabled ? "auto_enabled" : "auto_disabled"}`
                )}
              />
            )}
            <MessagePreview
              ns={ns}
              t={props.t}
              placeholders={props.placeholders}
              message={message}
            />
            <FormSect>
              <Label htmlFor="template_message">{props.t(`${ns}.msg`)}</Label>
              <TextareaAutosize
                maxLength={1024}
                data-scroll-lock-scrollable
                placeholder={props.t(`${ns}.msg_placeholder`)}
                value={message}
                onChange={({ target }: any) => onMessageChange(target.value)}
                id="template_message"
                autoComplete="off"
                css={`
                  ${inputMixin}
                  resize: none;
                `}
              />
            </FormSect>
            <AvailableVars
              varsGroup={props.placeholderGroup}
              appendVariable={appendVariable}
              t={props.t}
              ns={ns}
              availableVars={props.availableVars}
            />
          </>
        )}
      </Content>
      <Footer onClick={onSubmit} disabled={mut.isLoading}>
        {props.t("common:change")}
      </Footer>
    </>
  );
};
const TemplateEditorDataLoaderProxy = (
  props: Pick<tProps, "onClose" | "event" | "defaultTab">
) => {
  const supportedTmplsQuery = useSupportedExternalNotificationTmplsQuery();
  const tmplsQuery = useExternalNotificationTmplsQuery();
  const templates = tmplsQuery.data;
  const supportedTemplates = supportedTmplsQuery.data;
  const tmpl = useMemo(() => {
    if (isempty(templates) || !props.event) return null;
    return templates!.find((t) => t.event === props.event);
  }, [props.event, templates]);
  const tmplMetadata = useMemo(() => {
    if (isempty(supportedTemplates?.templates) || !props.event) return null;
    return supportedTemplates!.templates.find((t) => t.event === props.event);
  }, [props.event, supportedTemplates, templates]);
  const { t, ready } = useTranslation(["common", "settings", "errors"], {
    useSuspense: false,
  });
  if (!ready) return null;
  return (
    <QueryRequestWithModal
      query={[supportedTmplsQuery, tmplsQuery]}
      onClose={props.onClose}
    >
      {tmplMetadata && (
        <TemplateEditor
          messageManual={tmpl?.message_manual || tmplMetadata.message}
          messageAuto={tmpl?.message_auto || tmplMetadata.message}
          isAutoMsgAvailable={!tmplMetadata.auto_msg_disabled}
          isManualMsgAvailable={!tmplMetadata.manual_msg_disabled}
          templateId={tmpl?._id}
          isAutoDisabled={
            tmpl
              ? tmpl.is_auto_disabled
              : tmplMetadata.auto_turned_off_by_default
          }
          placeholders={
            supportedTemplates!.placeholders[tmplMetadata.placeholders] || {}
          }
          placeholderGroup={tmplMetadata.placeholders}
          availableVars={tmplMetadata.vars}
          t={t}
          {...props}
        />
      )}
    </QueryRequestWithModal>
  );
};

const chips = [
  {
    value: "auto",
    i18nKey: `${ns}.mode_auto`,
  },
  {
    value: "manual",
    i18nKey: `${ns}.mode_manual`,
  },
];

export default TemplateEditorDataLoaderProxy;
