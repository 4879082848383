import React from "react";
const MessageSvg = ({ color, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    stroke={color || "currentColor"}
    strokeWidth="1.5"
    {...props}
  >
    <path
      d="M3 12a9 9 0 119 9 8.4458 8.4458 0 01-4-1l-5 1 1-5a9.008 9.008 0 01-1-4v0zM8.01 10h8M8.01 14h6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    ></path>
  </svg>
);
export default MessageSvg;
