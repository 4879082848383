import { SyncDb } from "./base";
import { toHex } from "./utils";

export class UserSyncDb extends SyncDb {
  private static _instance: Maybe<UserSyncDb> = null;
  public static get instance(): UserSyncDb {
    if (!this._instance) {
      this._instance = new UserSyncDb();
    }
    return this._instance;
  }

  constructor() {
    super({
      searchEngineDbName: "couchdb-search",
    });
  }
  public async init({ token, userId }: { token: string; userId: string }) {
    await this._bootstrap();
    const { Pouch } = SyncDb;
    this._createLocalDb(`userdb-${userId}`);
    this._createRemoteDb(`/userdb-${toHex(userId)}`, {
      // @ts-ignore
      fetch: (url, opts) => {
        opts.headers.set("X-Auth-CouchDB-UserName", userId);
        opts.headers.set("X-Auth-CouchDB-Roles", "users");
        opts.headers.set("X-Auth-CouchDB-Token", token);
        return Pouch!.fetch(url, opts);
      },
    });
    await this._setupSearch();
    this._makeReady();
    this._enableSync();
  }
  private _enableSync() {
    if (!SyncDb.Pouch || !this._local || !this._remote) return;
    SyncDb.Pouch.sync(this._local, this._remote, {
      live: true,
      heartbeat: false,
      timeout: false,
      retry: true,
    });
  }
}
