import React from "react";

const SunIcon = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    stroke={color || "currentColor"}
    strokeWidth="1.5"
    fill="none"
    {...props}
  >
    <path
      d="M12 16c2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 2.2091 1.7909 4 4 4zM12 3v2M12 19v2M21 12h-2M5 12H3M18.364 18.364L16.95 16.95M7.05 7.05L5.636 5.636M5.636 18.364L7.05 16.95M16.95 7.05l1.414-1.414"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
export default SunIcon;
