import { Selectors as UserSelectors } from "@/store/user/selectors";
import { createSelector } from "@reduxjs/toolkit";
import { DOMAIN } from "./slice";
const getRawPortfolio = (state) => state[DOMAIN].items;
const getPortfolio = createSelector(
  [getRawPortfolio, UserSelectors.getUserId],
  (portfolio, userId) => {
    if (!userId) {
      return portfolio;
    }
    return portfolio.map((portfolio) => ({
      ...portfolio,
      isCreator: userId === portfolio.creator,
    }));
  }
);
const isGalleryOpen = (state) => state[DOMAIN].gallery.open;
const getGalleryIdx = (state) => state[DOMAIN].gallery.idx;
const isSingleLoading = (state) => state[DOMAIN].single.loading;
const getSingle = (state) => state[DOMAIN].single.item;
const getSingleError = (state) => state[DOMAIN].single.error;

export const Selectors = {
  getRawPortfolio,
  isSingleLoading,
  getSingle,
  getSingleError,
  getPortfolio,
  isGalleryOpen,
  getGalleryIdx,
};
