import React from "react";
import { ErrorBoundary as VendorErrorBoundary } from "react-error-boundary";
import Info from "_c/atoms/Info";
import { Button } from "_c/button";

function DefaultFallbackRenderer({ error, resetErrorBoundary }) {
  return (
    <>
      <Info isError>
        <p>Oops... Something went wrong</p>
        <p>Hitting the "reset" button below might help</p>
        <pre style={{ whiteSpace: "normal" }}>{error.message}</pre>
      </Info>
      <Button large primary onClick={() => resetErrorBoundary()}>
        Reset
      </Button>
    </>
  );
}

const ErrorBoundary = (props) => {
  return (
    <VendorErrorBoundary
      fallbackRender={props.renderFallback || DefaultFallbackRenderer}
      onReset={props.onReset}
    >
      {props.children}
    </VendorErrorBoundary>
  );
};

export default ErrorBoundary;
