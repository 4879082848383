import { API_URL } from "@/misc/constants";
import { get, remove, post } from "./index";
import { notificationsCache } from "@/misc/httpCache";

const BASE = `${API_URL}/notification`;
const getNotifications = (params) => {
  return get(BASE, { params, cache: notificationsCache });
};
const clearFresh = () => {
  return remove(`${BASE}/fresh`);
};
const scheduleDayOverallStatsNotification = (data) => {
  return post(`${BASE}/dayOverallStatsNotification`, data);
};
const disableDayOverallStatsNotification = () => {
  return remove(`${BASE}/dayOverallStatsNotification`);
};

export const NotificationsAPI = {
  clearFresh,
  getNotifications,
  scheduleDayOverallStatsNotification,
  disableDayOverallStatsNotification,
};
