import React from 'react';

const PencilIcon = props => (
  <svg width="33" viewBox="0 0 33 31" fill="none" {...props}>
    <path
      d="M6.46114 17.4279C3.18011 20.5039 1.11309 24.5349 0.599896 28.8583L0.598021 28.8718C0.491771 29.7671 1.29865 30.5242 2.25365 30.4251C6.87122 29.9471 11.1773 28.0086 14.4624 24.929L19.1749 20.5111L11.1736 13.0099L6.46114 17.4279ZM30.9324 1.98725C28.723 -0.0840378 25.1405 -0.0840378 22.9311 1.98725L21.5711 3.26225L19.2861 1.12065C18.3099 0.205415 16.7268 0.205415 15.7511 1.12065L9.24364 7.22143C8.85302 7.58764 8.85302 8.1812 9.24364 8.54741L10.6574 9.8728C11.048 10.239 11.6811 10.239 12.0718 9.8728L17.5186 4.76635L18.743 5.9142L12.588 11.6839L20.5893 19.1851L30.9324 9.48842C33.1418 7.41713 33.1418 4.05913 30.9324 1.98725Z"
      fill={props.color || "currentColor"}
    />
  </svg>
);

export default PencilIcon;
