import { takeLatest, put, call, select } from "@redux-saga/core/effects";
import { Actions } from "../slice";
import { SearchAPI } from "@/api/search";
import omit from "lodash/omit";
import {
  extractLocMeta,
  buildFinalQuery,
  maybeOrderServicesByPrice,
  searchCache,
} from "./helpers";
import { extractSeoMeta } from "./seoHelpers";
import { Selectors } from "../selectors";
import { SEARCH_PER_PAGE } from "@/misc/constants";

function* loadMastersSaga({ payload: { query, params } }) {
  const locMeta = yield call(extractLocMeta, { query, params });
  if (!locMeta) {
    yield put(Actions.loadingSuccess([]));
  } else {
    try {
      yield put(Actions.setLocMeta(locMeta));
      yield put(Actions.loadingStart());

      const finalQuery = yield call(buildFinalQuery, {
        query: {
          ...query,
          page: 0,
        },
        params,
        locMeta,
      });
      const cached = yield call(searchCache.get, finalQuery);
      if (cached) {
        yield put(
          Actions.loadingSuccess({
            data: cached.data,
            hasMore: cached.hasMore,
          })
        );
      } else {
        const { data } = yield call(SearchAPI.searchMaster, finalQuery);
        const finalData = yield call(maybeOrderServicesByPrice, {
          data,
          query: finalQuery,
        });
        const hasMore = finalData.length >= SEARCH_PER_PAGE;
        yield call(
          searchCache.set,
          {
            data: finalData,
            hasMore,
          },
          finalQuery
        );
        yield put(
          Actions.loadingSuccess({
            data: finalData,
            hasMore,
          })
        );
      }
    } catch (e) {
      console.error(e);
      yield put(Actions.loadingError(e));
    }
    try {
      const seoMeta = yield call(extractSeoMeta, { params, query });
      yield put(Actions.setSeoMeta(seoMeta));
    } catch (e) {
      console.error(e);
    }
  }
}
function* loadMoreMastersSaga({ payload: { query, params } }) {
  const locMeta = yield select(Selectors.getLocMeta);
  try {
    yield put(Actions.loadingStart());
    const currentPage = yield select(Selectors.getCurrentPage);

    const finalQuery = yield call(buildFinalQuery, {
      query: {
        ...query,
        page: currentPage + 1,
      },
      params,
      locMeta,
    });
    const { data } = yield call(SearchAPI.searchMaster, finalQuery);

    const newData = yield call(maybeOrderServicesByPrice, {
      data,
      query: finalQuery,
    });
    const oldData = yield select(Selectors.getResults);
    const finalData = oldData.concat(newData);

    const hasMore = newData.length >= SEARCH_PER_PAGE;
    yield searchCache.set(
      {
        data: finalData,
        hasMore,
      },
      finalQuery
    );
    yield put(
      Actions.loadingSuccess({
        data: finalData,
        hasMore,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(Actions.loadingError(e));
  }
}

function* loadGeoSaga({ payload: { query, params } }) {
  const locMeta = yield call(extractLocMeta, { query, params });
  if (locMeta) {
    yield put(Actions.loadingGeoStart());
    try {
      const finalQuery = yield call(buildFinalQuery, {
        query,
        params,
        locMeta,
      });
      const { data } = yield call(
        SearchAPI.searchMasterGeo,
        omit(finalQuery, ["page", "sort"])
      );
      yield put(Actions.loadingGeoSuccess(data));
    } catch (e) {
      yield put(Actions.loadingGeoError(e));
    }
  }
}

export default function* () {
  yield takeLatest(Actions.loadMasters.type, loadMastersSaga);
  yield takeLatest(Actions.loadMoreMasters.type, loadMoreMastersSaga);
  yield takeLatest(Actions.loadGeo.type, loadGeoSaga);
}
