import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  user-select: none;
  cursor: pointer;
  color: var(--font-color-main);
  margin-inline-start: 10px;
  font-weight: 500;
`;
