import { connect } from "react-redux";
import { Selectors } from "@/store/masterSearch/selectors";
import { Actions } from "@/store/masterSearch/slice";
import SearchPage from "./loadable";

const mSp = (state) => ({
  results: Selectors.getResults(state),
  geoResults: Selectors.getGeoResults(state),
  loading: Selectors.isLoading(state),
  geoLoading: Selectors.isGeoLoading(state),
  isMapOpen: Selectors.isMapOpen(state),
  seo: Selectors.getSeoMeta(state),
});
const mDp = {
  openMapPopup: Actions.openMapPopup,
  toggleMap: Actions.toggleMap,
  resetLocMeta: Actions.resetLocMeta,
};
export default connect(mSp, mDp)(SearchPage);
