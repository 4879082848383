import React from "react";
import Modal from "react-modal";
import PT from "prop-types";
import classnames from "classnames";
//document &&
//  document.getElementById("root") &&
//  Modal.setAppElement("#root");

const BaseModal = (props) => {
  const modalClass = classnames("modal", props.className);
  if (!props.isOpen) return null;
  return (
    <>
      <Modal
        data={{
          "scroll-lock-scrollable": true,
        }}
        ariaHideApp={false}
        onRequestClose={props.onClose}
        shouldCloseOnOverlayClick={true}
        {...props}
        isOpen
        closeTimeoutMS={0}
        className={modalClass}
        overlayClassName={
          props.overlay ? `${props.overlay} modal-overlay` : "modal-overlay"
        }
      />
    </>
  );
};
BaseModal.propTypes = {
  isOpen: PT.bool,
  onClose: PT.func,
};
BaseModal.defaultProps = {
  isOpen: true,
};

export default BaseModal;
