import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin } from "_c/preloader";

const AuthModalLoadable = loadable(
  () => import(/* webpackChunkName: "auth-modal" */ "./main"),
  {
    ssr: false,
    fallback: <FullScreenSpin />,
  }
);

export default AuthModalLoadable;
