import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";

const cache = new Map();
const defaultParsedQuery = {};

export const useParsedQuery = () => {
  const search = useLocation().search;
  return useMemo(() => {
    if (!search) return defaultParsedQuery;
    const fromCache = cache.get(search);
    if (fromCache) return fromCache;
    const parsed = qs.parse(search, { parseBooleans: true });
    cache.set(search, parsed);
    return parsed;
  }, [search]);
};
