import { connect } from "react-redux";
import { Selectors } from "@/store/blog/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { withRouter } from "react-router-dom";
import BlogHeader from ".";

const mSp = (state, { match, location }) => ({
  isAuth: AuthSelectors.isAuth(state),
  isMaster: UserSelectors.isMaster(state),
  isStatic: match.path === "/blog" && match.isExact,
  title: Selectors.getHeaderTitle(state, location.pathname),
  desc: Selectors.getHeaderDesc(state, location.pathname),
  thumb: Selectors.getHeaderThumb(state, location.pathname),
});

export default withRouter(connect(mSp)(BlogHeader));
