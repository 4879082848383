import { useEffect } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

export const useScrollLock = () => {
  useEffect(() => {
    disablePageScroll();
    return () => {
      enablePageScroll();
    };
  }, []);
};
