import React from "react";
import PT from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

const Animated = (props) => {
  return (
    <AnimatePresence>
      {props.visible && (
        <motion.div
          key={props.itemKey || "key"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
Animated.propTypes = {
  visible: PT.bool.isRequired,
};
const ListContainer = AnimatePresence;
const ListItem = ({ children, itemKey, style }) => (
  <motion.div
    style={style}
    key={itemKey}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
  >
    {children}
  </motion.div>
);
export const AnimatedList = {
  Container: ListContainer,
  Item: ListItem,
};

export default Animated;
