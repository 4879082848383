import { QueryKeys } from "../../network/queryKeys";
import { queryClient } from "../../network/queryClient";
import isempty from "lodash/isEmpty";
import { notifyManager } from "react-query";
import type { tBizClient } from "@/types";

const buildSingleClientQueryKey = (clientId?: string) => {
  const base = QueryKeys.singleTask;
  if (!clientId) return base;
  return [base, clientId];
};

type tBuildListQueryKeyArgs = {
  domain: "saloon" | "master";
  saloonId?: string;
  masterId?: string;
};
const buildListQueryKey = (data?: tBuildListQueryKeyArgs) => {
  const base = QueryKeys.clients;
  if (!data) return base;
  return [
    base,
    {
      domain: data.domain,
      ownerId: data.domain === "saloon" ? data.saloonId : data.masterId,
    },
  ];
};
const invalidateSingleClient = (clientId?: string) => {
  queryClient.invalidateQueries(buildSingleClientQueryKey(clientId));
};
const invalidateList = (data?: tBuildListQueryKeyArgs) => {
  queryClient.invalidateQueries(buildListQueryKey(data));
};

export const ClientsCacheService = {
  buildSingleClientQueryKey,
  buildListQueryKey,
  invalidateAll() {
    invalidateSingleClient();
    invalidateList();
  },
  addClient(client: tBizClient) {
    notifyManager.batch(() => {
      queryClient.setQueryDataIfCached(
        buildListQueryKey({
          domain: client.domain,
          saloonId: client.saloon,
          masterId: client.master,
        }),
        (data: tBizClient[]) => {
          if (isempty(data)) return [client];
          return data.concat(client);
        }
      );
      queryClient.setQueryData(buildSingleClientQueryKey(client._id), client);
    });
  },
  replaceClient(client: tBizClient) {
    notifyManager.batch(() => {
      queryClient.setQueryDataIfCached(
        buildListQueryKey({
          domain: client.domain,
          saloonId: client.saloon,
          masterId: client.master,
        }),
        (cache: tBizClient[]) =>
          cache.map((old) => (old._id === client._id ? client : old))
      );
      queryClient.setQueryDataButKeepStale(
        buildSingleClientQueryKey(client._id),
        client
      );
    });
  },
  removeClient(client: tBizClient) {
    queryClient.setQueryDataIfCached(
      buildListQueryKey({
        domain: client.domain,
        saloonId: client.saloon,
        masterId: client.master,
      }),
      (cache: tBizClient[]) => cache.filter((old) => old._id !== client._id)
    );
    queryClient.invalidateQueries(buildSingleClientQueryKey(client._id));
  },
};
