import s from "styled-components";

const BaseHeader = s.header`
  background-color: ${({ theme }) => theme.color.negative};
  min-height: 520px;
  position: relative;
`;
export const ArticleHeader = s(BaseHeader)`
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;
export const StaticHeader = s(BaseHeader)`
  background-image: url(/images/blog_girl.png);
  background-size: 700px auto;
  background-repeat: no-repeat;
  background-position: 65% 115px;
  @media(max-width: 1670px) {
    background-position: 75% 113px;
  }
  @media(max-width: 1500px) {
    background-position: 75% 113px;
  }
  @media(max-width: 1300px) {
    background-position: 75% 108px;
  }
  @media(max-width: 1200px) {
    background-position: 95% 104px;
  }
  @media(max-width: 991px) {
    min-height: 725px;
    background-position: 30% 328px;
  }
  @media(max-width: 850px) {
    background-position: 17% 328px;
  }
  @media(max-width: 770px) {
    background-position: 5% 328px;
  }
  @media(max-width: 720px) {
    background-size: 550px auto;
    min-height: 650px;
    background-position: 30% 335px;
  }
  @media(max-width: 640px) {
    background-size: 450px auto;
    min-height: 550px;
    background-position: calc(50vw - 250px) 292px;
  }
`;
export const HeaderDecor = s.img.attrs({
  role: "presentation",
})`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  width: calc(100% + 1px);
  height: 243px;
  bottom: -75px;
  @media(max-width: 1300px) {
    width: 115%;
    bottom: -70px;
  }
  @media(max-width: 760px) {
    width: 160%;
    bottom: -70px;
  }
  @media(max-width: 500px) {
    width: 200%;
    bottom: -70px;
  }
`;

const BaseHeaderText = s.div`
  color: #ffffff;
  h1 {
    word-wrap: break-word;
    margin-bottom: 10px;
    font-family: ${({ theme }) => theme.font.accent};
    font-size: 76px;
    margin-top: 80px;
    font-weight: 400;
    line-height: 65px;
    @media(max-width: 1300px) {
      font-size: 54px;
      line-height: 48px;
    }
   }
   p {
    word-wrap: break-word;
     margin: 0;
     font-size: 14px;
     font-weight: 500;
     line-height: 22px;
     @media(max-width: 991px) {
       margin: 0 auto;
       max-width: 400px;
       text-align: center;
     }
   }
`;
export const ArticleHeaderText = s(BaseHeaderText)`
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  @media(max-width: 680px) {
    max-width: 85%;  
  }
  h1 {
    @media(max-width: 620px) {
      margin-top: 55px;
    }
    @media(max-width: 500px) {
      font-size: 40px;
      line-height: 36px;
    }
  }
  p {
    marign-top: 15px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 1300px) {
      max-width: 85%;
    }
  }
`;
export const StaticHeaderText = s(BaseHeaderText)`
  max-width: 490px;
  margin-left: 20vw;
  h1 {
    font-size: 54px;
    line-height: 54px;
  }
  @media(max-width: 1500px) {
    margin-left: 15vw;  
  }
  @media(max-width: 1300px) {
    margin-left: 10vw;  
    max-width: 420px;
  }
  @media(max-width: 991px) {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }
  @media(max-width: 630px) {
    padding: 0 15px;
  }
  h1 {
    @media(max-width: 991px) {
      margin-top: 35px;
    }
    @media(max-width: 530px) {
      margin-top: 25px;
      font-size: 42px;
      line-height: 42px;
    }
  }
`;
