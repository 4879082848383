import React, { useCallback, useState } from "react";
import TextInput from "_c/form/TextInput";
import isempty from "lodash/isEmpty";
import InfiniteScrollList from "_c/InfiniteScrollList";
import Empty from "_c/Empty";
import { useWarehouseProductsQuery } from "./useProductsQuery";
import debounce from "lodash/debounce";
import { CircleButton } from "_c/button";
import PlusIcon from "_c/icon/Plus";
import WarehouseProductEditor from "../warehouse-product-editor";
import { useToggle } from "@/hooks/useToggle";

const WarehouseProductPicker = ({ t, onSelect, master, saloon }) => {
  const [isEditorOpen, toggleEditor] = useToggle();
  const [search, _setSearch] = useState("");
  const setSearch = (v) => {
    _setSearch(v);
    setSearchDebounced(v);
  };
  const [searchDebounced, _setSearchDebounced] = useState("");
  const setSearchDebounced = useCallback(
    debounce((v) => {
      _setSearchDebounced(v);
    }, 200),
    []
  );

  const query = useWarehouseProductsQuery({
    master,
    saloon,
    title: searchDebounced.trim().toLowerCase(),
  });
  const renderPages = (pages) => {
    if (isempty(pages?.[0]?.products)) {
      return <Empty message={t("common:empty")} />;
    }
    return (
      <div>
        {pages.map((page) =>
          page.products.map((product) => (
            <Item onSelect={onSelect} key={product._id} item={product} />
          ))
        )}
      </div>
    );
  };
  return (
    <>
      <div
        css={`
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        `}
      >
        <TextInput
          css={`
            flex: 1;
            margin-inline-end: 5px;
          `}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t("common:search")}
        />
        <CircleButton greenish onClick={toggleEditor} large type="button">
          <PlusIcon color="white" />
        </CircleButton>
      </div>
      <InfiniteScrollList query={query}>{renderPages}</InfiniteScrollList>
      <WarehouseProductEditor
        isOpen={isEditorOpen}
        onClose={toggleEditor}
        master={master}
        saloon={saloon}
        onCreate={({ product }) => onSelect(product)}
      />
    </>
  );
};
const Item = ({ onSelect, item }) => {
  return (
    <div
      css={`
        margin-bottom: 7px;
        border-radius: 9px;
        background: var(--bg-touchable);
        color: var(--font-color-touchable);
        padding: 10px;
        cursor: pointer;
        border-radius: var(--root-br);
        cursor: pointer;
      `}
      onClick={() => onSelect(item)}
      role="button"
      type="button"
    >
      <div>{item.title}</div>
      <div
        css={`
          font-size: 14px;
        `}
      >
        {item.amount} {item.unit}
      </div>
    </div>
  );
};
export default WarehouseProductPicker;
