import ms from "ms";

export const ONBOARDING_SHOW_DELAY: number = ms("5 seconds");

export const ONBOARDING_CONFIG: Record<string, { steps: string[]; v: number }> =
  {
    profile: {
      steps: ["about_page", "about_share"],
      v: 2,
    },
    scheduler: {
      steps: ["navigation", "button", "theme"],
      v: 3,
    },
    bookings: {
      steps: ["about_tasks"],
      v: 2,
    },
    clients: {
      steps: ["automate", "segments", "update", "about_reminders"],
      v: 3,
    },
    salon: {
      steps: ["salon_profile", "employees"],
      v: 2,
    },
  };
