import { get, post, remove } from "./";
import { API_URL } from "@/misc/constants";

const BASE_URL = `${API_URL}/pushNotification`;
const getMySubs = () => {
  return get(`${BASE_URL}/mySubscriptions`, {
    cache: false,
  });
};
const removeMySubs = () => {
  return remove(`${BASE_URL}/mySubscriptions`);
};
const subscribe = (data) => {
  return post(`${BASE_URL}/subscribe`, data);
};
const unsubscribe = (data) => {
  return post(`${BASE_URL}/unsubscribe`, data);
};

export const PushNotificationsAPI = {
  getMySubs,
  removeMySubs,
  subscribe,
  unsubscribe,
};
