import React from "react";

function SvgPermanentMakeup({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 42.36"
      {...props}
    >
      <path d="M42.63 42.36C19.33 42.36.78 26.11 0 25.42l2-2.22c.18.16 18.52 16.19 40.66 16.19s40.48-16 40.66-16.19l2 2.22c-.83.69-19.39 16.94-42.69 16.94z" />
      <path d="M84.28 24.31s7.27-5.62 15.72-6.37c-5.37-1.87-17-8.1-17-8.1S61.77-3.24 39.12.76 1 24.31 1 24.31 19.63 7.74 42.63 7.74s41.65 16.57 41.65 16.57z" />
      <path d="M42.63 39.05a14.74 14.74 0 1114.74-14.74 14.75 14.75 0 01-14.74 14.74zm0-26.51A11.77 11.77 0 1054.4 24.31a11.78 11.78 0 00-11.77-11.77z" />
      <path d="M42.63 18.08a6.23 6.23 0 106.23 6.23 6.24 6.24 0 00-6.23-6.23z" />
    </svg>
  );
}

export default SvgPermanentMakeup;
