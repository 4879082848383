import React from "react";

const SvgPin = ({ color, ...props }) => (
  <svg width="13" viewBox="0 0 13 18" {...props}>
    <path
      d="M6.5-.013a6.4 6.4 0 0 0-6.488 6.3c0 3.479 6.488 11.7 6.488 11.7s6.489-8.22 6.489-11.7A6.4 6.4 0 0 0 6.5-.013zm0 15.3s-4.634-6.3-4.634-9a4.637 4.637 0 0 1 9.269 0c0 2.699-4.635 8.999-4.635 8.999zm1.854-8.8a1.855 1.855 0 1 1-1.854-1.8 1.827 1.827 0 0 1 1.854 1.796z"
      fill={color || "currentColor"}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgPin;
