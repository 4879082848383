import React from "react";
import * as S from "./styled";
import { useTranslation } from "react-i18next";
import SpecsList from "_c/SpecsList";

const Header = () => {
  const { t, ready } = useTranslation("common", {
    useSuspense: false,
  });
  if (!ready) return null;
  return (
    <S.HeaderSection>
      <SpecsList color="white" />
      <S.BgCircles>
        <div>
          <span>
            <span />
          </span>
        </div>
      </S.BgCircles>
      <S.Tagline>
        <h2>{t("common:main_head")}</h2>
      </S.Tagline>
      <S.StyledSearch />
    </S.HeaderSection>
  );
};

export default Header;
