import React from "react";
import PT from "prop-types";
import Container from "./container";
import Modal, { Content, Header } from "_c/Modals";

const BookingModal = (props) => {
  const onClose = () => props.onClose();
  return (
    <Modal onClose={onClose} isOpen={props.isOpen}>
      <Container onClose={props.onClose} />
    </Modal>
  );
};
BookingModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
};
export default BookingModal;
