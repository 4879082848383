import { connect } from "react-redux";
import { Selectors } from "@/store/paymentResult/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import ThankyouPage from "./loadable";

const mSp = (state) => ({
  isAuth: AuthSelectors.isAuth(state),
  isMaster: UserSelectors.isMaster(state),
  isLoading: Selectors.isLoading(state),
  isSuccess: Selectors.isSuccess(state),
  task: Selectors.getTask(state),
  error: Selectors.getError(state),
});

export default connect(mSp)(ThankyouPage);
