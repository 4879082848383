import React from "react";
import * as S from "./styled";
import SunIcon from "_c/icon/Sun";
import MoonIcon from "_c/icon/Moon";
import { useColorScheme, useToggleColorScheme } from "@/hooks/useColorScheme";

const AppShellThemeToggler = (props) => {
  const colorScheme = useColorScheme();
  const toggleColorScheme = useToggleColorScheme();
  return (
    <S.Wrap className={props.className} onClick={toggleColorScheme}>
      {colorScheme === "light" ? (
        <MoonIcon width="20" color="rgb(67, 97, 133)" />
      ) : (
        <SunIcon width="24" color="white" />
      )}
    </S.Wrap>
  );
};

export default AppShellThemeToggler;
