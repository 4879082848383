import React from "react";
const QuestionSvg = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 15 15" width="24" {...props}>
    <path
      fill={color}
      d="M7.5 0A7.5 7.5 0 0 0 0 7.5 7.5 7.5 0 0 0 7.5 15 7.5 7.5 0 0 0 15 7.5 7.5 7.5 0 0 0 7.5 0zm.08 3.09a4.32 4.32 0 0 1 1.08.14 2.87 2.87 0 0 1 .93.45 2.18 2.18 0 0 1 .65.76 2.39 2.39 0 0 1 .25 1.11 2.53 2.53 0 0 1-.1.71 2.26 2.26 0 0 1-.28.56 2.48 2.48 0 0 1-.43.49c-.17.15-.36.31-.58.47l-.32.28a1.16 1.16 0 0 0-.21.25 1 1 0 0 0-.1.27 1.72 1.72 0 0 0 0 .34v.32H6.52v-.47a2.49 2.49 0 0 1 .06-.57 1.86 1.86 0 0 1 .19-.46 2.2 2.2 0 0 1 .3-.4 5.52 5.52 0 0 1 .42-.41l.41-.38a2.46 2.46 0 0 0 .35-.38.81.81 0 0 0 .15-.49.81.81 0 0 0-.24-.62.89.89 0 0 0-.66-.24.9.9 0 0 0-.77.35 1.43 1.43 0 0 0-.28.83L4.5 5.76a3.41 3.41 0 0 1 .33-1.17 2.56 2.56 0 0 1 .69-.83 2.83 2.83 0 0 1 .94-.5 3.75 3.75 0 0 1 1.12-.17zm-.12 6.73a1.14 1.14 0 0 1 .54.09 1.09 1.09 0 0 1 .38.25 1 1 0 0 1 .26.36 1.2 1.2 0 0 1 .04.48 1.1 1.1 0 0 1-.34.83 1.23 1.23 0 0 1-1.34.22 1.36 1.36 0 0 1-.38-.24 1.18 1.18 0 0 1-.26-.37 1 1 0 0 1-.1-.46 1.15 1.15 0 0 1 .35-.82A1.3 1.3 0 0 1 7 9.91a1.18 1.18 0 0 1 .46-.09z"
    />
  </svg>
);
export default QuestionSvg;
