import s from "styled-components";
import Paper from "_c/Paper";

export const Wrap = s(Paper)`
  color: white;
  display: flex;
  margin-bottom: 15px;
  padding: 10px;
  line-height: 1.3em;
`;
export const CloseButtonWrap = s.div`
  margin-left: auto;
  button {
    transition: opacity .25s ease;
    :hover, :focus {
      opacity: .6;
    }
  }
`;

