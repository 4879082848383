import { motion } from "framer-motion";
import styled from "styled-components";

export const Header = motion(styled.header`
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  cursor: grab;
  background-color: inherit;
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border: ${({ theme }) => theme.border.light};
`);
export const Dragger = styled.div`
  width: 40px;
  height: 4px;
  background: var(--font-color-accent);
  border-radius: 24px;
  position: absolute;
  left: calc(50% - 20px);
  top: 23px;
`;
export const MotionWrap = motion(styled.div`
  position: fixed;
  ${({ defaultWidth, mobileBp }) => `
    width: ${defaultWidth}px;
    left: calc(50% - ${defaultWidth / 2}px);
    bottom: 0;
    z-index: 1002;
    @media (max-width: ${mobileBp + 50}px) {
      width: 100%;
      left: 0;
    }
  `}
`);
export const Wrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--bg-paper);
  color: var(--font-color-accent);
`;
export const Content = motion(styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border: ${({ theme }) => theme.border.light};
  border-top: none;
`);
