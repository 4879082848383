import { useMutation, focusManager } from "react-query";
import { Translate } from "@/i18n/utils";
import { useNotify } from "@/hooks/useNotify";
import { ProgressEmitter } from "_c/GlobalProgress/emitter";
import type { MutationFunction, UseMutationResult } from "react-query";
import type { TFunction } from "i18next";

class ConfirmError extends Error {
  constructor(message?: any) {
    super(message);
    this.name = "ConfirmError";
  }
}

export function useMut<TData = unknown, TVariables = void, TError = unknown>(
  mutation: MutationFunction<TData, TVariables>,
  args: {
    /**
     * text for success notification
     */
    toast?: string | (() => string);
    /**
     * should prompt user for the confirmation ibefore running the mutation?
     */
    confirm?: boolean;
    /**
     * text for the confirmation prompt
     */
    confirmText?: string;
    /**
     * don't show the progress bar while mutation is running
     */
    hideProgress?: boolean;
    /**
     * should render popup with the error text on error?
     */
    notifyOnError?: boolean;
    onSuccess?(data: TData, vars?: TVariables): any;
    onError?(error: TError): any;
    t: TFunction;
  }
): UseMutationResult<TData, TError, TVariables> {
  const { notify, notifyError } = useNotify();
  const mut = useMutation<TData, TError, TVariables>(mutation, {
    onSuccess(data, vars) {
      // @ts-ignore
      const finalData = data?.data ?? data;
      if (args.toast) {
        if (typeof args.toast === "function") {
          notify(args.toast());
        } else {
          notify(args.toast);
        }
      }
      if (args.onSuccess) {
        args.onSuccess(finalData, vars);
      }
    },
    onMutate() {
      if (args.confirm || args.confirmText) {
        // https://github.com/tannerlinsley/react-query/issues/2868
        focusManager.setFocused(false);
        const confirmed = window.confirm(
          args.confirmText || args.t("common:default_confirm_msg")
        );
        setTimeout(() => focusManager.setFocused(undefined));

        if (!confirmed) {
          throw new ConfirmError();
        }
      }
      if (!args.hideProgress) {
        ProgressEmitter.start();
      }
    },
    onError(e) {
      if (e instanceof ConfirmError) return;
      console.error(e);
      if (args.notifyOnError) {
        notifyError(Translate.serverError(args.t, e));
      }
      if (args.onError) args.onError(e);
    },
    onSettled() {
      if (!args.hideProgress) {
        ProgressEmitter.end();
      }
    },
  });
  return mut;
}
