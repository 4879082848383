import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selectors as ModalSelectors } from "@/store/modals/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import EditorModal from "./";
import { useMasterId } from "@/hooks/useMasterId";

const GlobalAccountingTxnEditorModal = () => {
  const masterId = useMasterId()!;
  const isOpen = useSelector(ModalSelectors.isAccountingTxnEditorOpen);
  const params = useSelector(ModalSelectors.getAccountingTxnEditorParams);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(ModalActions.closeAccountingTxnEditor());
  }, []);
  return (
    <EditorModal
      masterId={masterId}
      onClose={onClose}
      isOpen={isOpen}
      {...params}
    />
  );
};

export default GlobalAccountingTxnEditorModal;
