import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "basket";
const initialState = {
  items: [],
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    addToBasket(state, { payload }) {
      state.items.push(payload);
    },
    removeFromBasket(state, { payload: id }) {
      const idx = state.items.findIndex(({ service }) => service._id === id);
      if (idx !== -1) {
        state.items.splice(idx, 1);
      }
    },
    restoreBasket(state, { payload }) {
      state.items = payload;
    },
    clearBasket(state) {
      state.items = [];
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
