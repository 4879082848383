import { BlogAPI } from "@/api/blog";
import { Actions } from "./slice";
import { Effects as FavEffects } from "@/store/favorites/effects";
import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import { articlesCache } from "@/misc/httpCache";
import { Selectors } from "./selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { CommentsAPI } from "@/api/comments";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import isempty from "lodash/isEmpty";
import pick from "lodash/pick";

const toggleLike = (id) => async (dispatch, getState) => {
  const isFav = FavSelectors.isFavorite(getState(), "articles", id);
  if (isFav) {
    dispatch(FavEffects.removeFavorite("articles", id));
    dispatch(Actions.removeLike(id));
  } else {
    dispatch(Actions.addLike(id));
    dispatch(FavEffects.addFavorite("articles", id));
  }
  articlesCache.reset();
};

const loadArticles = (params) => async (dispatch, getState) => {
  dispatch(Actions.loadArticlesStart());
  const locale = LocaleSelectors.getLocale(getState());
  try {
    const { data } = await BlogAPI.getArticles({
      ...params,
      locale,
      sort_by: "-created_at",
    });
    dispatch(
      Actions.loadArticlesSuccess({
        items: data.docs,
        count: data.count,
        perPage: data.perPage,
      })
    );
  } catch (e) {
    dispatch(Actions.loadArticlesError(e));
  }
};
const loadBestAuthors = () => async (dispatch, getState) => {
  if (isempty(Selectors.getBestAuthors(getState()))) {
    try {
      dispatch(Actions.loadBestAuthorsStart());
      const { data } = await BlogAPI.getBestAuthors();
      dispatch(Actions.loadBestAuthorsSuccess(data));
    } catch (e) {
      dispatch(Actions.loadBestAuthorsError(e));
    }
  }
};
const loadPopularArticles = () => async (dispatch, getState) => {
  if (isempty(Selectors.getPopularArticles(getState()))) {
    dispatch(Actions.loadPopularArticlesStart());
    try {
      const { data } = await BlogAPI.getPopularArticles();
      dispatch(Actions.loadPopularArticlesSuccess(data.docs));
    } catch (e) {
      dispatch(Actions.loadPopularArticlesError(e));
    }
  }
};

const loadArticle = (slug) => async (dispatch) => {
  dispatch(Actions.loadArticleStart());
  try {
    const { data, status } = await BlogAPI.findBySlug(slug);
    if (status === 301) {
      dispatch(Actions.loadArticleRedirect(data));
    } else {
      dispatch(Actions.loadArticleSuccess(data));
    }
  } catch (e) {
    dispatch(Actions.loadArticleError(e));
  }
};
const removeArticle = (id) => async (dispatch) => {
  await BlogAPI.deleteById(id);
  dispatch(Actions.removeArticle());
  articlesCache.reset();
};

const addComment = (payload) => async (dispatch, getState) => {
  const state = getState();
  const article = Selectors.getArticle(state);
  const user = UserSelectors.getProfile(state);
  const { data } = await CommentsAPI.createComment(
    "blog/article",
    article._id,
    payload
  );
  const normComment = {
    ...data,
    author: {
      ...pick(user, [
        "first_name",
        "last_name",
        "_id",
        "sex",
        "avatar",
        "online",
      ]),
      master: user?.master?._id,
    },
  };
  dispatch(Actions.addComment(normComment));
  articlesCache.reset();
};
const removeComment = (id) => async (dispatch, getState) => {
  const article = Selectors.getArticle(getState());
  await CommentsAPI.deleteComment("blog/article", article._id, id);
  dispatch(Actions.removeComment(id));
};

const createOrUpdateArticle = () => async (_dispatch, getState) => {
  const { _id, ...form } = Selectors.getEditorForm(getState());
  if (_id) {
    await BlogAPI.updateById(_id, form);
  } else {
    await BlogAPI.createArticle(form);
  }
};
const initEditor = (slug) => async (dispatch, getState) => {
  if (slug) {
    const { data } = await BlogAPI.findBySlug(slug);
    dispatch(
      Actions.initEditor(
        pick(data, ["content", "title", "desc", "thumb", "tags", "_id", "slug"])
      )
    );
  } else {
    const form = Selectors.getEditorForm(getState());
    if (form._id || form.slug) {
      dispatch(Actions.resetEditor());
    } else {
      dispatch(Actions.initEditor({ _id: null, slug: null }));
    }
  }
};

export const Effects = {
  addComment,
  loadArticle,
  loadArticles,
  initEditor,
  loadBestAuthors,
  loadPopularArticles,
  toggleLike,
  removeArticle,
  removeComment,
  createOrUpdateArticle,
};
