import { useCallback, useEffect, useState } from "react";
import { ProgressEmitter } from "_c/GlobalProgress/emitter";

export const useLoader = ({
  loader,
  defaultValue,
  defaultLoadingState = "idle",
  isEnabled = true,
  showProgress,
  select,
}) => {
  const [count, setCount] = useState(0);
  const [state, setState] = useState({
    loadingState: defaultLoadingState,
    data: defaultValue,
    isLoading: defaultLoadingState === "loading",
    error: null,
  });
  useEffect(() => {
    async function foo() {
      if (isEnabled) {
        if (showProgress) {
          ProgressEmitter.start();
        }
        setState((state) => ({
          ...state,
          loadingState: "loading",
          isLoading: true,
          error: null,
        }));
        try {
          const { data } = await loader();
          setState({
            loadingState: "success",
            isLoading: false,
            data: select ? select(data) : data,
          });
        } catch (error) {
          setState((state) => ({
            ...state,
            loadingState: "error",
            isLoading: false,
            error,
          }));
        } finally {
          if (showProgress) {
            ProgressEmitter.end();
          }
        }
      }
    }
    foo();
  }, [loader, isEnabled, count]);
  const updateData = (newData) => {
    setState((state) => ({
      ...state,
      data: newData,
    }));
  };
  const refetch = useCallback(() => {
    setCount((count) => count + 1);
  }, []);
  return {
    ...state,
    updateData,
    refetch,
  };
};
