import { Effects as UserEffects } from "@/store/user/effects";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Actions } from "./slice";
import { Actions as UserActions } from "@/store/user/slice";
import { UserAPI } from "@/api/user";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
//import { Actions as IdentityModalActions } from "@/store/identityModal/slice";
//import { Actions as ModalActions } from "@/store/modals/slice";
//import { batch } from "react-redux";
import { MarketplaceAPI } from "@/api/marketplace";
import { analyticsAction } from "@/utils/analytics";
import { IS_BROWSER } from "@/misc/constants";
import { MasterAPI } from "@/api/master";
import { FreeSlotsCacheService } from "@/services/freeSlots/cache";

const makeSaloonOwner = () => async (dispatch, getState) => {
  const roles = UserSelectors.getRoles(getState());
  await dispatch(
    UserEffects.updateUser({
      roles: [...roles, "saloonOwner"],
    })
  );
  dispatch(Actions.changeMode("common"));
  return Promise.resolve();
};

const requestPhoneCode = (phone) => async (dispatch) => {
  await UserAPI.updatePhone(phone);
  dispatch(Actions.phoneStepConfirm(phone));
};
const verifyPhone = (code) => async (dispatch) => {
  const { data: phone } = await UserAPI.verifyPhone(code);
  dispatch(
    UserActions.updateUser({
      phone,
    })
  );
  dispatch(Actions.phoneConfirmed());
  analyticsAction({
    category: "phone",
    action: "master_phone_approve",
  });
};
const requestEmailCode = (email) => async () => {
  await UserAPI.updateEmail(email);
};
const verifyEmailCode = (code) => async (dispatch, getState) => {
  const { data: email } = await UserAPI.verifyEmail(code);
  if (AuthSelectors.isAuth(getState())) {
    dispatch(
      UserActions.updateUser({
        email,
      })
    );
  }
  // else {
  //   // after resolving this promise we redirect user to the home page
  //   // but we are closing every modals on route change event at the same time
  //   // so just wait a little bit before open auth modal action
  //   setTimeout(() => {
  //     batch(() => {
  //       dispatch(ModalActions.openAuth());
  //       dispatch(
  //         IdentityModalActions.updateAuthForm({
  //           email,
  //           password: "",
  //         })
  //       );
  //       dispatch(IdentityModalActions.changeStrategy("email"));
  //       dispatch(IdentityModalActions.changeMode("auth"));
  //     });
  //   }, 500);
  // }
};

const registerMarketplaceShop = (payload) => async (dispatch, getState) => {
  const id = UserSelectors.getMasterId(getState());
  const { data } = await MarketplaceAPI.register({
    master: id,
    data: payload,
  });
  dispatch(
    UserActions.updateMaster({
      mp_id: data,
    })
  );
  analyticsAction({
    category: "master",
    action: "reg_shop_master",
  });
};

const updateSoundSettings = (payload) => (_dispatch, getState) => {
  const oldSettings = UserSelectors.getSoundSettings(getState());
  localStorage.setItem(
    "user-settings",
    JSON.stringify({ ...oldSettings, ...payload })
  );
};
const updateFeedOptions = (payload) => async (dispatch, getState) => {
  const id = UserSelectors.getUserId(getState());
  await UserAPI.updateFeedOptions(id, payload);
  dispatch(UserActions.updateUser(payload));
};

const updateSchedule = (schedule) => async (dispatch, getState) => {
  const state = getState();
  const masterId = UserSelectors.getMasterId(state);
  await MasterAPI.updateSchedule(masterId, { schedule });
  FreeSlotsCacheService.invalidateAll();
  dispatch(UserActions.updateMaster({ schedule }));
};

const visitCareer = () => async (dispatch) => {
  if (IS_BROWSER) {
    localStorage.setItem("career-visited", 1);
    dispatch(Actions.setIsCareerVisited(true));
  }
};
const visitNotifications = () => async (dispatch) => {
  if (IS_BROWSER) {
    localStorage.setItem("notifications-visited", 1);
    dispatch(Actions.setIsNotificationsVisited(true));
  }
};
export const Effects = {
  visitCareer,
  visitNotifications,
  updateSchedule,
  updateSoundSettings,
  updateFeedOptions,
  registerMarketplaceShop,
  makeSaloonOwner,
  phone: {
    requestCode: requestPhoneCode,
    verify: verifyPhone,
  },
  email: {
    requestCode: requestEmailCode,
    verify: verifyEmailCode,
  },
};
