import { MessengerAPI } from "@/api/messenger";
import { Actions } from "./slice";
import { Selectors } from "./selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { DIALOGS_PER_PAGE, MESSAGES_PER_PAGE } from "@/misc/constants";

const maybeLoadDialogs = () => async (dispatch, getState) => {
  const len = Selectors.getDialogsLength(getState());
  if (len < DIALOGS_PER_PAGE) {
    await dispatch(loadDialogs());
  }
};
const loadDialogs = () => async (dispatch, getState) => {
  dispatch(Actions.loadDialogsStart());
  try {
    const oldest = Selectors.getOldestDialog(getState());
    const { data } = await MessengerAPI.getDialogs({
      last: oldest?.updated_at || ""
    });
    dispatch(Actions.loadDialogsSuccess(data));
  } catch (e) {
    dispatch(Actions.loadDialogsError(e));
  }
};
const maybeLoadMessages = () => async (dispatch, getState) => {
  const len = Selectors.getMessagesLength(getState());
  if (len < MESSAGES_PER_PAGE) {
    await dispatch(loadMessages());
  }
};
const loadMessages = () => async (dispatch, getState) => {
  const state = getState();
  const loading = Selectors.isMessagesLoading(state);
  if (loading) {
    return;
  }
  const dialog = Selectors.getActiveDialogId(state);
  const oldest = Selectors.getOldestMessage(state);
  dispatch(Actions.loadMessagesStart());
  try {
    const { data } = await MessengerAPI.getMessages({
      dialog: dialog,
      last: oldest?.created_at || ""
    });
    dispatch(Actions.loadMessagesSuccess(data.reverse()));
  } catch (e) {
    dispatch(Actions.loadMessagesError(e));
  }
};
const createMessage = () => async (dispatch, getState) => {
  const state = getState();
  const dialog = Selectors.getActiveDialog(state);
  const text = Selectors.getDialogInput(state);
  const photo = Selectors.getDialogPhotoInput(state);
  const userId = UserSelectors.getUserId(state);
  const { data } = await MessengerAPI.createMessage({
    dialog: dialog._id,
    to: dialog.members.find(({ _id }) => _id !== userId)._id,
    text,
    photo
  });
  dispatch(Actions.addMessage(data));
};
const createMessageOutsideMessenger = (receiverId, text) => async (
  dispatch,
  getState
) => {
  const { data } = await MessengerAPI.createMessage({
    to: receiverId,
    text
  });
  if (Selectors.isDialogLoaded(getState(), data.dialog)) {
    dispatch(Actions.addMessage(data));
  } else {
    const { data: dialog } = await MessengerAPI.findDialogById(data.dialog);
    dialog.messages = [data];
    dispatch(Actions.addDialog(dialog));
  }
};
const updateImportant = (message, important) => async dispatch => {
  dispatch(
    Actions.updateMessage({
      dialog: message.dialog,
      message: message._id,
      updates: {
        important
      }
    })
  );
  await MessengerAPI.updateImportant(message._id, {
    important
  });
};
const removeMessage = message => async dispatch => {
  await MessengerAPI.removeMessage(message._id);
  dispatch(Actions.removeMessage(message));
};
const receiveMessage = (messageId, dialogId) => async dispatch => {
  await MessengerAPI.receiveMessage(messageId);
  dispatch(
    Actions.updateMessage({
      dialog: dialogId,
      message: messageId,
      updates: {
        received: true
      }
    })
  );
  dispatch(Actions.removeFresh(messageId));
};
const addMessageFromSocket = message => async (dispatch, getState) => {
  const state = getState();
  if (Selectors.isDialogLoaded(state, message.dialog)) {
    dispatch(
      Actions.addMessage({
        ...message,
        fresh: true
      })
    );
  } else {
    const { data: dialog } = await MessengerAPI.findDialogById(message.dialog);
    dispatch(
      Actions.addDialog({
        ...dialog,
        messages: [message]
      })
    );
    dispatch(Actions.addFresh(message));
  }
};

export const Effects = {
  addMessageFromSocket,
  createMessage,
  createMessageOutsideMessenger,
  loadDialogs,
  loadMessages,
  maybeLoadMessages,
  maybeLoadDialogs,
  receiveMessage,
  removeMessage,
  updateImportant
};
