import { Dispatch } from "redux";
import { Actions as AuthActions } from "./auth/slice";
import { Actions as UserActions } from "@/store/user/slice";
import { Actions as FavActions } from "@/store/favorites/slice";
import { Actions as BasketActions } from "@/store/basket/slice";
import { Actions as EvNotifActions } from "@/store/eventNotifications/slice";
import { Actions as MessengerActions } from "@/store/messenger/slice";
import { Actions as SaloonDashboardActions } from "@/store/saloonDashboard/slice";
import { Actions as PremiumActions } from "@/store/premium/slice";
import { Actions as SettingsActions } from "@/store/settings/slice";
import { Actions as UserRegisterActions } from "@/store/userRegister/slice";
import { batch } from "react-redux";
import { Actions as PrefsActions } from "@/store/prefs/slice";

export const resetStore = (dispatch: Dispatch) => {
  batch(() => {
    dispatch(AuthActions.globalReset());
    dispatch(BasketActions.globalReset());
    dispatch(FavActions.globalReset());
    dispatch(MessengerActions.globalReset());
    dispatch(PremiumActions.globalReset());
    // @ts-ignore
    dispatch(SaloonDashboardActions.globalReset());
    dispatch(SettingsActions.globalReset());
    dispatch(UserActions.globalReset());
    dispatch(EvNotifActions.reset());
    dispatch(UserRegisterActions.reset());
    dispatch(PrefsActions.globalReset());
  });
};
