import loadable from "@loadable/component";
import { Selectors } from "@/store/appShell/selectors";
import { connect } from "react-redux";

const MobileBottomNav = loadable(
  () =>
    import(
      /*webpackChunkName: "app-shell-mobile-bottom-nav" */
      "./"
    ),
  {
    ssr: false,
  }
);
const mSp = (state) => ({
  links: Selectors.getMobileNavigation(state),
  supportLink: Selectors.getSupportLink(state),
});

export default connect(mSp)(MobileBottomNav);
