import React, { useRef, memo } from "react";
import * as S from "./styled";
import PT from "prop-types";
import { useScrollButtons } from "@/hooks/useScrollButtons";
import { CircleButton } from "_c/button";
import AngleRightIcon from "_c/icon/AngleRight";

const ChipsList = (props) => {
  const ref = useRef();
  const { visibleButtons, goNext } = useScrollButtons({
    ref,
    disabled: props.hideScrollButton,
  });
  const renderChip =
    props.renderChip ||
    (({ isDisabled, onClick, isActive, content }) => (
      <S.Chip disabled={isDisabled} onClick={onClick} isActive={isActive}>
        {content}
      </S.Chip>
    ));
  return (
    <div
      css={`
        position: relative;
      `}
    >
      {!props.hideScrollButton && visibleButtons.right && (
        <CircleButton
          light
          css={`
            position: absolute;
            z-index: 2;
            top: 10px;
            right: 0;
          `}
          onClick={goNext}
          type="button"
        >
          <AngleRightIcon color="black" />
        </CircleButton>
      )}
      <S.Wrap data-scroll-lock-scrollable className={props.className} ref={ref}>
        {props.items.map(({ value: item, i18nKey, isDisabled }) => (
          <S.Item key={item}>
            {renderChip({
              isDisabled,
              onClick: () => props.onChange(item),
              isActive: item === props.active,
              content: props.t(i18nKey),
            })}
          </S.Item>
        ))}
      </S.Wrap>
    </div>
  );
};
ChipsList.propTypes = {
  className: PT.string,
  items: PT.array.isRequired,
  active: PT.string,
  hideScrollButton: PT.bool,

  renderChip: PT.func,
  onChange: PT.func.isRequired,
  t: PT.func.isRequired,
};
export default memo(ChipsList);
