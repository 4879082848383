import React from "react";
import Modal from "_c/Modals";
import { tEditorProps } from "./types";
import Editor from "./main";
import { tAbstractModalProps } from "@/types";

const TemplateEditorModal = (props: tAbstractModalProps<tEditorProps>) => {
  return (
    <Modal
      css={`
        color: var(--font-color-main);
      `}
      isOpen={props.isOpen && !!props.event}
      onClose={props.onClose}
    >
      <Editor
        defaultTab={props.defaultTab}
        event={props.event!}
        onClose={props.onClose}
      />
    </Modal>
  );
};

export default TemplateEditorModal;
