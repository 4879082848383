import { AuthAPI } from "@/api/auth";
import * as cache from "@/misc/httpCache";
//import {getAffiliateCode} from '@/utils';
import { Actions as AuthActions } from "./slice";
import { Actions as UserActions } from "@/store/user/slice";
import { Actions as FavActions } from "@/store/favorites/slice";
import { Actions as EvNotifActions } from "@/store/eventNotifications/slice";
import { Actions as MessengerActions } from "@/store/messenger/slice";
import { Actions as PremiumActions } from "@/store/premium/slice";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as AuthSelectors } from "./selectors";
import { Effects as UserEffects } from "@/store/user/effects";
import { changeMomentLocale, detectTimezone } from "@/utils/time";
import moment from "moment";
import websocket from "@/websocket";
import { batch } from "react-redux";
import { PushNotification as Push } from "@/utils/PushNotification";
import { PushNotificationsAPI } from "@/api/pushNotifications";
import { toast } from "react-toastify";
import { analyticsSetUser } from "@/utils/analytics";
import { UserSyncDb } from "@/syncDb";
import { Actions as PrefsActions } from "@/store/prefs/slice";
import { SERVER_FEATURES_TO_ACTIVATE } from "@/config";
import { DayOverallStatsNotificationService } from "@/services/notifications/DayOverallStats";
import { queryClient } from "@/network/queryClient";
import { resetStore } from "../utils";

const maybeConnectPush = async () => {
  if (!Push.isSupported()) return;
  const manuallyDisabled = localStorage.getItem("prefs:unsub_push");
  if (manuallyDisabled) {
    return;
  }
  const endpoint = await Push.getEndpoint();
  const { data: subs } = await PushNotificationsAPI.getMySubs();
  if (subs.some(({ sub }) => sub?.endpoint === endpoint)) {
    return;
  }
  let perm = Push.getPerm();
  if (perm === "denied") {
    return;
  }
  if (perm !== "granted") {
    perm = await Push.requestPerm();
  }
  if (perm === "granted") {
    const sub = await Push.subscribe();
    await PushNotificationsAPI.subscribe({ sub });
  }
};
export const afterLogin = () => async (dispatch, getState) => {
  const state = getState();
  const timezone = UserSelectors.getTimezone(state);
  const name = UserSelectors.getFirstName(state);
  const locale = UserSelectors.getLocale(state);
  const userId = UserSelectors.getUserId(state);
  const masterId = UserSelectors.getMasterId(state);
  const couchdbToken = AuthSelectors.getCouchdbToken(state);

  const { __i18n: i18n } = window;
  const momentLocale = moment.locale();
  analyticsSetUser(userId);
  UserSyncDb.instance.init({
    userId,
    token: couchdbToken,
  });
  if (i18n) {
    if (i18n.language !== locale) {
      await i18n.changeLanguage(locale);
    }
    toast.success(i18n.t("common:greeting", { name }));
  }
  if (momentLocale !== locale) {
    changeMomentLocale(locale);
  }

  DayOverallStatsNotificationService.schedule();
  DayOverallStatsNotificationService.enableAutoScheduler();

  const maybeNewTimezone = detectTimezone();
  websocket.init();
  // TODO:: create some flag in storage
  // to update tz only if user has allowed it
  if (maybeNewTimezone && maybeNewTimezone !== timezone) {
    dispatch(UserEffects.updateUser({ timezone: maybeNewTimezone }));
  }
  await maybeConnectPush();

  if (!masterId) {
    dispatch(UserEffects.createMaster());
  }
  const features = UserSelectors.getServerFeatures(state);
  for (const required of SERVER_FEATURES_TO_ACTIVATE) {
    if (!features.includes(required)) {
      await dispatch(UserEffects.activateServerFeature(required));
    }
  }
};
export const successLogin = async (data, dispatch) => {
  batch(() => {
    dispatch(EvNotifActions.setFresh(data.freshNotifications));
    dispatch(MessengerActions.setFreshMessages(data.freshMessages));
    const { prefs = {}, ...user } = data.user;
    dispatch(FavActions.setFavorites(data.favorites));
    dispatch(UserActions.setUser(user));
    dispatch(PrefsActions.initPrefs(prefs));
    dispatch(PremiumActions.setPremium(data.premium));
    dispatch(
      AuthActions.loginSuccess({
        socketToken: data.socketToken,
        couchdbToken: data.couchdbToken,
      })
    );
  });
  return Promise.resolve();
};
export const sessionLogin =
  (cookies = {}) =>
  async (dispatch) => {
    const { data } = await AuthAPI.sessionLogin({ cookies });
    await successLogin(data, dispatch);
  };
export const login = (form) => async (dispatch) => {
  const { strategy, ...rest } = form;
  const { data } = await AuthAPI.login(rest);
  await successLogin(data, dispatch);
};

export const logout = () => async (dispatch, getState) => {
  const state = getState();
  const name = UserSelectors.getFirstName(state);
  const { __i18n: i18n } = window;
  if (i18n) {
    toast.success(i18n.t("common:bye", { name }));
  }
  try {
    await DayOverallStatsNotificationService.schedule();
    DayOverallStatsNotificationService.disableAutoScheduler();
    websocket.destroy();
    await AuthAPI.logout();
  } catch (e) {
    console.error(e);
  }
  resetStore(dispatch);
  Object.values(cache).forEach((cache) => {
    if (cache) {
      cache.reset();
    }
  });
  setTimeout(() => {
    queryClient.invalidateQueries();
  }, 75);
  UserSyncDb.instance.destroy();
  //localStorage.removeItem('basket');
};

export const Effects = {
  afterLogin,
  login,
  logout,
  sessionLogin,
};
