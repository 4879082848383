import styled from "styled-components";
import { motion } from "framer-motion";

export const Header = motion(styled.button`
  display: block;
  width: 100%;
  background: var(--grad-primary-n);
  border-radius: var(--root-br);
  display: flex;
  align-items: center;
  padding: 10px 16px;
  box-shadow: ${({ theme }) => `0px 6px 30px 2px ${theme.color.primary}`};
  :focus {
    outline: none;
  }
  strong {
    font-weight: 700;
    margin: 0;
    margin-left: 5px;
    color: white;
    font-size: 16px;
  }
`);
export const IconWrap = motion(styled.span`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
`);
export const Content = motion(styled.div`
  margin-top: 10px;
`);
