import React from "react";
import Layout from "@/layouts/Root";
import * as S from "./index-styled";
import { Container } from "reactstrap";
import loadable from "@loadable/component";
import { Helmet } from "_c/Helmet";

const Content = loadable(() => import("./content"));

const Agreement = () => {
  return (
    <Layout>
      <Helmet title="Пользовательское соглашение Simpo" />
      <Container style={{ position: "relative", zIndex: 21 }}>
        <S.Content>
          <Content />
        </S.Content>
      </Container>
    </Layout>
  );
};

export default Agreement;
