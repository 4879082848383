import { useEffect } from "react";
import debounce from "lodash/debounce";

const DELAY = 250;
export const useCalcViewportHeight = () => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    let prevHeight = window.innerHeight;
    document.documentElement.style.setProperty("--initial-vh", `${vh}px`);
    const setVh = () => {
      const height = window.innerHeight;
      if (height !== prevHeight) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--initial-vh", `${vh}px`);
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
      prevHeight = height;
    };
    const onResize = debounce(setVh, DELAY);
    setVh();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
};
