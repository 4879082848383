import { Actions } from "./slice";
import { ExtMessengerAPI as API } from "@/api/extMessenger";
const loadData = (id) => async (dispatch) => {
  dispatch(Actions.loadingStart());
  try {
    const { data } = await API.getSubPageMeta(id);
    dispatch(Actions.loadingSuccess(data));
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};

export const Effects = {
  loadData,
};
