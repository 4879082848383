import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";
import cloneDeep from "lodash/cloneDeep";
import deepExtend from "@/utils/deepExtend";

const buildMySaloonsQueryKey = () => {
  return QueryKeys.mySaloons;
};
const invalidateMySaloons = () => {
  queryClient.invalidateQueries(buildMySaloonsQueryKey());
};
const invalidateAll = () => {
  invalidateMySaloons();
};

export const SaloonsCacheService = {
  invalidateMySaloons,
  invalidateAll,
  buildMySaloonsQueryKey,
  addSaloon({ saloon }) {
    queryClient.setQueryDataIfCached(buildMySaloonsQueryKey(), (saloons) => {
      return saloons.concat(saloon);
    });
  },
  removeSaloon(id) {
    queryClient.setQueryDataIfCached(buildMySaloonsQueryKey(), (saloons) => {
      return saloons.filter((s) => s._id !== id);
    });
  },
  updateSaloon({ id, updates }) {
    queryClient.setQueryDataIfCached(buildMySaloonsQueryKey(), (saloons) => {
      return saloons.map((saloon) => {
        if (saloon._id === id) {
          const updatedSaloon = cloneDeep(saloon);
          deepExtend(updatedSaloon, updates);
          return updatedSaloon;
        } else {
          return saloon;
        }
      });
    });
  },
};
