import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import FinancePage from "./loadable";

const mSp = (state) => ({
  masterId: UserSelectors.getMasterId(state),
  timezone: UserSelectors.getTimezone(state),
  currency: UserSelectors.getCurrency(state),
});

export default connect(mSp)(FinancePage);
