import { createSlice } from "@reduxjs/toolkit";
import { FEED_PER_PAGE } from "@/misc/constants";

const DOMAIN = "clientFeed";

const initialState = {
  items: [],
  loading: false,
  error: null,
  isMore: true,
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setFeed(state, { payload }) {
      state.items = payload;
      state.isMore = payload.length >= FEED_PER_PAGE;
    },
    setComments(state, { payload }) {
      const item = state.items.find(
        ({ entity_ref, entity }) =>
          entity_ref === payload.entity && entity._id === payload.id
      );
      if (item) {
        item.entity.comments = payload.comments;
      }
    },
    addPortfolio(state, { payload }) {
      state.items.unshift({
        actor: payload.client,
        date: payload.created_at,
        entity_ref: "Portfolio",
        entity: payload,
      });
    },
    addLike(state, { payload }) {
      const item = state.items.find(
        ({ entity_ref, entity }) =>
          entity_ref === payload.entity && entity._id === payload.id
      );
      if (item) {
        item.entity.likes_count += 1;
      }
    },
    removeLike(state, { payload }) {
      const item = state.items.find(
        ({ entity_ref, entity }) =>
          entity_ref === payload.entity && entity._id === payload.id
      );
      if (item) {
        item.entity.likes_count = Math.max(0, item.entity.likes_count - 1);
      }
    },
    removePortfolio(state, { payload: id }) {
      const idx = state.items.findIndex(({ entity, entity_ref }) => {
        return entity_ref === "Portfolio" && id === entity._id;
      });
      if (idx !== -1) {
        state.items.splice(idx, 1);
      }
    },
    addComment(state, { payload }) {
      const item = state.items.find(
        ({ entity_ref, entity }) =>
          entity_ref === payload.entity && entity._id === payload.id
      );
      if (item) {
        item.entity.comments_count += 1;
        if (Array.isArray(item.entity.comments)) {
          item.entity.comments.push(payload.payload);
        } else {
          item.entity.comments = [payload.payload];
        }
      }
    },
    removeComment(state, { payload }) {
      const item = state.items.find(
        ({ entity_ref, entity }) =>
          entity_ref === payload.entity && entity._id === payload.id
      );
      if (item) {
        item.entity.comments_count = Math.max(
          0,
          item.entity.comments_count - 1
        );
        const commentIdx = item.entity.comments.findIndex(
          ({ _id }) => _id === payload.commentId
        );
        if (commentIdx !== -1) {
          item.entity.comments.splice(commentIdx, 1);
        }
      }
    },
    loadFeedStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadFeedSuccess(state, { payload }) {
      state.items = state.items.concat(payload);
      state.isMore = payload.length >= FEED_PER_PAGE;
      state.loading = false;
    },
    loadFeedError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
