import React from "react";

const SvgPlus = ({ color, ...props }) => (
  <svg width="12" viewBox="0 0 12 12" {...props}>
    <defs>
      <path
        id="plus_svg"
        d="M678 1046h4v-4a1 1 0 1 1 2 0v4h4a1 1 0 1 1 0 2h-4v4a1 1 0 0 1-2 0v-4h-4a1 1 0 1 1 0-2z"
      />
    </defs>
    <use
      fill={color || "currentColor"}
      xlinkHref="#plus_svg"
      transform="translate(-677 -1041)"
    />
  </svg>
);

export default SvgPlus;
