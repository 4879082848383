import React from "react";
import PT from "prop-types";
import { InView } from "react-intersection-observer";
import QueryRequest from "../QueryRequest";
import throttle from "lodash/throttle";
import { Spin } from "_c/preloader";

const DELAY = 100;

const onViewChange = throttle((isVisible, query) => {
  if (!isVisible) return;
  if (query.hasNextPage && !query.isLoading && !query.isFetching) {
    query.fetchNextPage();
  }
}, DELAY);
const InfiniteScrollList = (props) => {
  const renderChildren = () => {
    const { query } = props;
    if (!query.data) {
      return null;
    }
    return props.children(query.data.pages);
  };
  return (
    <>
      <QueryRequest query={props.query}>{renderChildren()}</QueryRequest>
      <InView onChange={(inView) => onViewChange(inView, props.query)}>
        <Spin
          style={{
            opacity: props.query.isFetchingNextPage ? 1 : 0,
          }}
        />
      </InView>
    </>
  );
};
InfiniteScrollList.propTypes = {
  query: PT.object.isRequired,
};

export default InfiniteScrollList;
