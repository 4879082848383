import { API_URL } from "@/misc/constants";
import { get, post, put, remove } from "./";
import { articlesCache } from "@/misc/httpCache";
import qs from "query-string";
import { analyticsAction } from "@/utils/analytics";
import { IS_SERVER } from "@/misc/constants";

const cache = IS_SERVER ? false : articlesCache;

const BASE = `${API_URL}/blog`;
const ARTICLE_BASE = `${BASE}/article`;
const findById = (id) => {
  return get(`${ARTICLE_BASE}/${id}`, { cache: cache });
};
const findBySlug = (slug) => {
  return get(`${ARTICLE_BASE}/slug/${slug}`, { cache: cache });
};
const getExcerpt = (id) => {
  return get(`${ARTICLE_BASE}/${id}/excerpt`, { cache: cache });
};
const getArticles = (params) => {
  return get(ARTICLE_BASE, {
    params,
    cache: cache,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};
const getBestAuthors = () => {
  return get(`${BASE}/bestAuthors`, { cache: cache });
};
const getPopularArticles = () => {
  return get(ARTICLE_BASE, {
    cache: cache,
    params: {
      sort_by: "-comments_count",
      page: 0,
    },
  });
};
const createArticle = async (payload) => {
  const res = await put(ARTICLE_BASE, payload);
  analyticsAction({
    category: "blog",
    action: "add_article",
  });
  if (cache) cache.reset();
  return res;
};
const updateById = async (id, payload) => {
  const res = await post(`${ARTICLE_BASE}/${id}`, payload);
  if (cache) cache.reset();
  return res;
};
const deleteById = async (id) => {
  const res = await remove(`${ARTICLE_BASE}/${id}`);
  if (cache) cache.reset();
  return res;
};

export const BlogAPI = {
  createArticle,
  getArticles,
  findBySlug,
  getBestAuthors,
  getPopularArticles,
  getExcerpt,
  findById,
  updateById,
  deleteById,
};
