import { useEffect } from "react";
import { connect } from "react-redux";
import { Actions } from "@/store/canary/slice";
import { IS_BROWSER } from "@/misc/constants";
import { useNotify } from "@/hooks/useNotify";

const Canary = (props) => {
  const { notify } = useNotify();
  useEffect(() => {
    if (IS_BROWSER) {
      window.canary = {
        enable: () => {
          props.enable();
          notify("Канарейка включена");
        },
        disable: () => {
          props.disable();
          notify("Канарейка отключена");
        },
      };
    }
  }, []);
  return null;
};

const mDp = {
  enable: Actions.enable,
  disable: Actions.disable,
};
export default connect(null, mDp)(Canary);
