import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors } from '@/store/achieves/selectors';
import { Effects } from '@/store/achieves/effects';
import AchievesPage from './loadable';

const mSp = state => ({
  userId: UserSelectors.getUserId(state),
  isMaster: UserSelectors.isBusinessMode(state),
  achieves: Selectors.getAchieves(state),
  loading: Selectors.isLoading(state),
});
const mDp = {
  loadAchieves: Effects.loadAchieves,
}

const connected = connect(mSp, mDp)(AchievesPage);
export default connected;
