import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "favorites";

const initialState = {
  articles: [],
  users: [],
  portfolio: [],
  reviews: [],
  saloons: [],
  view: {
    loading: false,
    articles: {
      items: [],
      error: null,
    },
    users: {
      items: [],
      error: null,
    },
    portfolio: {
      items: [],
      error: null,
    },
    saloons: {
      items: [],
      error: null,
    },
  },
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setFavorites(state, { payload }) {
      state.articles = payload.articles;
      state.users = payload.users;
      state.portfolio = payload.portfolio;
      state.reviews = payload.reviews;
      state.saloons = payload.saloons || [];
    },
    removeFavorite(state, { payload }) {
      const items = state[payload.type];
      const idx = items.findIndex((id) => id === payload.id);
      if (idx !== -1) {
        items.splice(idx, 1);
      }
      if (payload.type in state.view) {
        const viewItems = state.view[payload.type].items;
        const viewIdx = viewItems.findIndex(({ _id }) => _id === payload.id);
        if (viewIdx !== -1) {
          viewItems.splice(viewIdx, 1);
        }
      }
    },
    addFavorite(state, { payload }) {
      state[payload.type].push(payload.id);
    },
    loadViewStart(state) {
      state.view.loading = true;
    },
    loadViewSuccess(state, { payload }) {
      state.view.loading = false;
      state.view[payload.type].items = state.view[payload.type].items.concat(
        payload.items
      );
    },
    loadViewError(state, { payload }) {
      state.view.loading = false;
      state.view[payload.type].error = payload;
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
