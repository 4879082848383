import s from 'styled-components';

export const Wrap = s.div`
  @media(max-width: 767px) {
    margin-top: 35px;
  }
`;
export const InnerWrap = s.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  @media(max-width: 767px) {
    align-items: center;
  }
`;
export const NavItem = s.button`
  font-size: 14px;
  font-weight: 500;
  color: var(--font-color-accent);
  text-align: right;
  margin-bottom: 10px;
  transition: opacity .35s ease;
  :hover {
    opacity: .7;
  }
  @media(max-width: 767px) {
    text-align: center;
  }
`;
