import React, { useEffect } from "react";
import * as S from "./index-styled";
import TinySlider from "_c/modules/profile/common/tiny-slider";
import { Link } from "react-router-dom";
import PinIcon from "_c/icon/Pin";
import isempty from "lodash/isEmpty";
import { HeartsPreloader } from "_c/preloader";
import PT from "prop-types";
import provide from "./provider";
import { useTranslation } from "react-i18next";
import { LinkGenerator } from "@/utils/LinkGenerator";
import { useExtractHL } from "@/hooks/useExtractHL";
import qs from "query-string";

const Aside = (props) => {
  const hl = useExtractHL();
  const { t, ready } = useTranslation(["common", "errors", "blog"], {
    useSuspense: false,
  });
  useEffect(() => {
    if (isempty(props.articles) || isempty(props.authors)) {
      props.loadPopularArticles();
      props.loadBestAuthors();
    }
  }, []);
  if (!ready) return null;
  return (
    <S.Aside>
      <section>
        <h3>{t("blog:best_authors")}</h3>
        {props.authorsLoading && <HeartsPreloader size="sm" />}
        {!props.authorsLoading && <TinySlider data={props.authors} />}
      </section>
      <section>
        <h3>{t("blog:popular_articles")}</h3>
        <S.ArticlesList>
          {props.articlesLoading && <HeartsPreloader size="sm" />}
          {!props.articlesLoading &&
            props.articles.map(({ _id, title, slug }) => (
              <li data-id={_id} key={_id}>
                <PinIcon color="var(--font-color-emp)" width="15" />
                <Link
                  to={qs.stringifyUrl({
                    url: LinkGenerator.blogArticle(slug),
                    query: { hl },
                  })}
                >
                  {title}
                </Link>
              </li>
            ))}
        </S.ArticlesList>
      </section>
    </S.Aside>
  );
};
Aside.propTypes = {
  articles: PT.array,
  authors: PT.array,
  authorsLoading: PT.bool,
  articlesLoading: PT.bool,

  loadPopularArticles: PT.func,
  loadBestAuthors: PT.func,
};

export default provide(Aside);
