import s, { createGlobalStyle } from "styled-components";
import BaseModal from "../base-modal";
import Paper from "_c/Paper";

const mobileBp = "500px";
export const GlobalStyle = createGlobalStyle`
  .react-tabs__tab {
    width: 50%;
  }
`;
export const StyledModal = s(BaseModal)`
  overflow-y: auto;
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  position: fixed !important; 
  display: flex;
`;
export const Input = s.input`
  width: 170px;
  margin: 0 5px;
  padding: 9px 18px;
  padding-right: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ error }) => (error ? "var(--color-danger)" : "#ededed")};
  background-color: var(--bg-touchable);
  border-radius: var(--root-br);
  box-shadow: none;
  appearance: none;
  color: var(--font-color-main);
  font-size: 16px;
  transition: border-color .35s ease;
  outline: none;
  ::placeholder {
    color: var(--font-color-accent);
  }
  :focus {
    border-color: ${({ error }) => (error ? "var(--color-danger)" : "white")};
  }

  @media(max-width: ${mobileBp}) {
    width: 285px;
    margin-left: 0;
    margin-right: 0;
  }
`;
export const Wrap = s(Paper)`
  padding: 0;
  padding-bottom: 20px;
  text-align: center;
  position: relative;
  margin: auto auto;
  width: 450px;
  border-radius: var(--root-br);
  @media(max-width: ${mobileBp}) {
    height: 100%;
    border-radius: 0;
    width: 100%;
  }
`;
export const CloseOverlay = s.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;
export const MobileCloseButton = s.button`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--font-color-accent);
  @media(max-width: ${mobileBp}) {
    display: block;
  }
`;

export const Header = s.h3`
  color: var(--font-color-accent);
  font-size: 18px;
  font-weight: 600;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 90%;
`;
export const Body = s.div`
  text-align: center;
`;
export const Label = s.label`
  height: 0;
  opacity: 0;
  font-size: 0;
`;

export const Flash = s.div`
  background: var(--grad-primary-n);
  font-weight: 500;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 10px;
  color: white;
  border-radius: 7px;
  line-height: 20px;
  font-size: 14px;
  opacity: .85;
`;

export const TabsList = s.ul.attrs({
  role: "tablist",
})`
  white-space: nowrap;
  margin-bottom: 15px;
  overflow-x: auto;
`;
export const TabItem = s.li.attrs({
  tabIndex: 0,
  role: "tab",
  "aria-disabled": false,
  className: "ripple-btn",
})`
  width: 50%;
  user-select: none;
  display: inline-block;
  outline: none;
  border: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  padding: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--font-color-main);
  border-bottom-width: 2px;
  -webkit-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease;
  border-bottom-style: solid;
  border-bottom-color: ${({ isSelected }) =>
    isSelected ? "var(--color-cta)" : "transparent"};
`;
