import React from "react";

const TodosPage = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 24 24" width="24" {...props}>
    <path
      fill={color}
      d="M8.8 3.2c-3.3 0-5.9 2.7-5.9 5.9v6.4c0 3.3 2.7 5.9 5.9 5.9h6.4c3.3 0 5.9-2.6 6-5.8V9.1c0-3.3-2.7-5.9-5.9-5.9H8.8zm7.854 3.751a.75.75 0 0 1 .282.078.75.75 0 0 1 .335 1.006s-.605 1.21-3.505 6.81c-.75 1.448-1.27 2.265-2.077 2.653a1.54 1.54 0 0 1-1.3-.006c-.372-.19-.617-.474-.836-.78-.879-1.22-1.557-3.142-2.764-4.263a.75.75 0 0 1-.039-1.058.75.75 0 0 1 1.06-.041c1.594 1.48 2.365 3.659 2.961 4.488.139.192.244.273.278.295.12-.063.689-.635 1.385-1.979 2.899-5.598 3.496-6.789 3.496-6.789a.75.75 0 0 1 .433-.377.75.75 0 0 1 .291-.037z"
    />
  </svg>
);
export default TodosPage;
