import React, { useCallback } from "react";
import EnvelopeIcon from "_c/icon/Envelope";
import BellIcon from "_c/icon/Bell";
import PT from "prop-types";
import {
  LeftCol,
  RightCol,
  Logo,
  Notifier,
  NotifierCount,
  Wrap,
  NotifierWrap,
  RoleSpecificLink,
  RoleSpecificLinks,
} from "./styled";
import { Link } from "react-router-dom";
import Notifications from "../Notifications/container";
import MessagesPageLoadable from "_p/messages/loadable";
import { Helmet } from "_c/Helmet";
import ThemeToggler from "../ThemeToggler";
import UserProfile from "../UserProfile";
import { preloadModules } from "@/utils/loadable";
import { toggleNotificationsAtom } from "../Notifications/atoms/visibility";
import { useUpdateAtom } from "jotai/utils";
import RefreshButton from "../RefreshButton";

const AuthHeader = ({ t, ...props }) => {
  const toggleNotifications = useUpdateAtom(toggleNotificationsAtom);
  const onMessagesHover = useCallback(() => {
    preloadModules([MessagesPageLoadable]);
  }, []);
  const hasMessagesOrNotifications =
    props.freshMessagesCount + props.freshNotificationsCount > 0;
  return (
    <>
      <Helmet
        link={[
          {
            rel: "shortcut icon",
            href: hasMessagesOrNotifications
              ? "/favicon-new.png"
              : "/favicon.png",
            type: "image/x-icon",
          },
        ]}
      />
      <Wrap>
        <LeftCol>
          <Link style={{ display: "block" }} to="/">
            <Logo />
          </Link>
          <RoleSpecificLinks>
            {props.roleSpecificLinks.map(({ route, Icon, key }) => (
              <li key={key}>
                <RoleSpecificLink
                  to={route}
                  onClick={
                    key === "add_task" ? () => props.openCreateTask() : null
                  }
                  as={key === "add_task" ? "button" : null}
                >
                  <Icon color="currentColor" width="24" />
                  <span>{t(`common:profile_links.${key}`)}</span>
                </RoleSpecificLink>
              </li>
            ))}
          </RoleSpecificLinks>
        </LeftCol>
        <RightCol>
          {/*<RefreshButton />*/}
          <ThemeToggler />
          <NotifierWrap>
            <Notifier
              onMouseEnter={onMessagesHover}
              onTouchStart={onMessagesHover}
              title={t("profile_upper_menu.messages")}
              as={Link}
              to={props.messengerLink.route}
            >
              <EnvelopeIcon color="white" />
            </Notifier>
            {props.freshMessagesCount > 0 && (
              <NotifierCount>{props.freshMessagesCount}</NotifierCount>
            )}
          </NotifierWrap>
          <NotifierWrap>
            <Notifier
              title={t("profile_upper_menu.notifications")}
              onClick={toggleNotifications}
            >
              <BellIcon color="white" />
            </Notifier>
            {props.freshNotificationsCount > 0 && (
              <NotifierCount>{props.freshNotificationsCount}</NotifierCount>
            )}
            <Notifications />
          </NotifierWrap>
          <UserProfile
            sex={props.sex}
            profileLink={props.profileLink}
            name={props.name}
            avatar={props.avatar}
            t={t}
            logout={props.logout}
          />
        </RightCol>
      </Wrap>
    </>
  );
};

AuthHeader.propTypes = {
  name: PT.string,
  avatar: PT.string,
  profileLink: PT.string,
  messengerLink: PT.object,
  unreadNotifications: PT.number,
  freshNotificationsCount: PT.number.isRequired,
  freshMessagesCount: PT.number.isRequired,
  roleSpecificLinks: PT.array.isRequired,

  openCreateTask: PT.func.isRequired,
  t: PT.func.isRequired,
  logout: PT.func.isRequired,
};

export default AuthHeader;
