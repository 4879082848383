import React, { useEffect } from "react";
import WarehouseProductPicker from "_c/Modals/warehouse-product-picker";
import { useToggle } from "@/hooks/useToggle";
import { useFormContext } from "react-hook-form";
import PlusIcon from "_c/icon/Plus";
import PencilIcon from "_c/icon/Pencil";
import { i18nNs } from "./config";
import { Button } from "_c/button";

const ProductField = ({
  master,
  saloon,
  t,
  isNew,
  disableForm,
  enableForm,
}) => {
  const [isOpen, toggle] = useToggle();
  const { register, watch, setValue } = useFormContext();
  const product = watch("product");
  useEffect(() => {
    register({ name: "product" });
  }, [register]);
  const onSelect = (v) => setValue("product", v);
  return (
    <>
      <div
        css={`
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          min-height: 40px;
        `}
      >
        <Button
          type="button"
          onClick={
            isNew
              ? () => {
                  toggle();
                  disableForm();
                }
              : undefined
          }
          withIcon
          small
          transparent
          css={`
            padding-inline-start: 0;
            font-size: 16px;
          `}
        >
          {product ? (
            <>
              {isNew && <PencilIcon width="18" />}
              <span>{product.title}</span>
            </>
          ) : (
            <>
              <PlusIcon width="18" />
              <span>{t(i18nNs + "no_product")}</span>
            </>
          )}
        </Button>
      </div>
      <WarehouseProductPicker
        isOpen={isOpen}
        onClose={() => {
          toggle();
          setTimeout(() => enableForm());
        }}
        master={master}
        saloon={saloon}
        t={t}
        onSelect={onSelect}
      />
    </>
  );
};

export default ProductField;
