import React, { useEffect } from "react";
import { FullScreenSpin } from "_c/preloader";
import { useTranslation } from "react-i18next";
import { MASTER_CLIENTS_NS } from "@/i18n/ns";
import PT from "prop-types";
import { analyticsAction } from "@/utils/analytics";
import { useHistory, useParams } from "react-router-dom";
import { useNotify } from "@/hooks/useNotify";

// TODO:: translation
const VerifySaloonInvite = (props) => {
  const { t, ready } = useTranslation([MASTER_CLIENTS_NS, "errors"], {
    useSuspense: false,
  });
  const history = useHistory();
  const { code } = useParams();
  const { notify, notifyError } = useNotify();
  useEffect(() => {
    async function foo() {
      try {
        await props.verifyPhone(code);
        analyticsAction({
          category: "phone",
          action: "master_phone_approve",
        });
        notify(t(`${MASTER_CLIENTS_NS}:accept_by_phone_success`));
      } catch (e) {
        console.error(e);
        notifyError(t([`errors:${e}`, "errors:unspecified_server"]));
      } finally {
        history.push("/");
      }
    }
    if (ready) {
      foo();
    }
  }, [t, ready, code]);
  return <FullScreenSpin size="xl" />;
};
VerifySaloonInvite.propTypes = {
  verifyPhone: PT.func.isRequired,
};

export default VerifySaloonInvite;
