import React, { useEffect } from "react";
import { FullScreenSpin } from "_c/preloader";
import { useTranslation } from "react-i18next";
import PT from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useNotify } from "@/hooks/useNotify";

const VerifyPhone = (props) => {
  const { t, ready } = useTranslation("common", {
    useSuspense: false,
  });
  const { code } = useParams();
  const history = useHistory();
  const { notify, notifyError } = useNotify();
  useEffect(() => {
    async function foo() {
      try {
        await props.verifyPhone(code);
        notify(t("common:imported_master_verify_phone_success"));
      } catch (e) {
        console.error(e);
        notifyError(t([`errors:${e}`, "errors:unspecified_server"]));
      } finally {
        history.push("/");
      }
    }
    if (ready) {
      foo();
    }
  }, [t, ready, code]);
  return <FullScreenSpin size="xl" />;
};
VerifyPhone.propTypes = {
  verifyPhone: PT.func.isRequired,
};

export default VerifyPhone;
