import React, { useCallback } from "react";
import { Selectors } from "@/store/modals/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "@/store/modals/slice";
import ClientEditorModal from "./";

const ClientEditorGlobalModal = () => {
  const isOpen = useSelector(Selectors.isClientEditorOpen);
  const props = useSelector(Selectors.getClientEditorParams);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(Actions.closeClientEditor());
  }, []);
  return <ClientEditorModal onClose={onClose} isOpen={isOpen} {...props} />;
};

export default ClientEditorGlobalModal;
