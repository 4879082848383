import React from "react";
import Header from "./Header/container";
import * as S from "./styled";
import Aside from "_c/modules/blog/aside";
import { Container, Row, Col } from "reactstrap";

const BlogLayout = (props) => {
  return (
    <S.Wrap>
      <Header />
      <S.Content>
        <Container>
          <Row>
            <Col lg={8} xs={12}>
              {props.children}
            </Col>
            <Col lg={4} xs={12}>
              <Aside />
            </Col>
          </Row>
        </Container>
      </S.Content>
    </S.Wrap>
  );
};

export default BlogLayout;
