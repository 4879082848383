import React from "react";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { FullScreenSpin as Spin } from "_c/preloader";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";

const BonusPage = loadable(
  () =>
    import(
      /* webpackChunkName: "bonus-page" */
      "."
    ),
  {
    ssr: false,
    fallback: <Spin size="xl" />,
  }
);

const mSp = (state) => ({
  locale: UserSelectors.getLocale(state),
  menu: LinksSelectors.getBonusesChildren(state),
  routePrefix: LinksSelectors.getRoutePrefix(state),
});

const connected = connect(mSp)(BonusPage);
export default connected;
