import s from "styled-components";

const ButtonGroup = s.div`
  padding-top: 10px;
  > button:first-child {
    margin-inline-start: 0;
  }
  button {
    margin-inline-start: 7px;
    margin-bottom: 7px;
  }
`;
export default ButtonGroup;
