import { connect } from "react-redux";
import { Selectors } from "@/store/portfolio/selectors";
import { Effects } from "@/store/portfolio/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import loadable from "@loadable/component";

const PortfolioPage = loadable(() =>
  import(
    /* webpackChunkName: "portfolio-page" */
    "."
  )
);

const mSp = (state) => ({
  portfolio: Selectors.getSingle(state),
  loading: Selectors.isSingleLoading(state),
  isAuth: AuthSelectors.isAuth(state),
  authUserId: UserSelectors.getUserId(state),
  favorites: FavSelectors.getFavoritePortfolio(state),
});
const mDp = {
  openShareModal: ModalActions.openShare,
  loadPortfolio: Effects.loadSinglePortfolio,
  toggleLike: Effects.toggleLike,
  addComment: Effects.addComment,
  removeComment: Effects.removeComment,
};

export default connect(mSp, mDp)(PortfolioPage);
