import React, { forwardRef } from "react";
import s from "styled-components";

type tStyledProps = {
  error?: boolean;
  warn?: boolean;
};
const Wrap = s.p<tStyledProps>`
  font-weight: 500;
  font-size: 14px;
  color: ${({ error, warn, theme }) => {
    if (error || warn) {
      return theme.color.negative;
    }
    //if (warn) {
    //  return theme.color.warn;
    //}
    return theme.color.positive;
  }};
  margin: 0;
  margin-top: 5px;
  position: relative;
`;

type tProps = tStyledProps & {
  message: string;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
};
const FormMessage = forwardRef<any, tProps>(({ message, ...rest }, ref) => (
  <Wrap ref={ref} {...rest}>
    {message}
  </Wrap>
));

export default FormMessage;
