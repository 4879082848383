import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";
import uniqBy from "lodash/uniqBy";
import type { iWarehouseTxn, IWarehouseTxnEmbeddedProduct } from "@/types";

const buildQueryKey = (key: string, data: any) => {
  switch (key) {
    case QueryKeys.warehouseTxnsSearch:
      return [QueryKeys.warehouseTxnsSearch, data];
    case QueryKeys.warehouseTaskTxns:
      return [QueryKeys.warehouseTaskTxns, data];
    case QueryKeys.warehouseTxns:
      return [QueryKeys.warehouseTxns, data];
    default:
      return [QueryKeys.warehouseTxnsSearch, data];
  }
};

const invalidateSearch = () => {
  queryClient.invalidateQueries(QueryKeys.warehouseTxnsSearch);
  queryClient.invalidateQueries(QueryKeys.warehouseTxns);
};
const invalidateAll = () => {
  invalidateSearch();
  queryClient.invalidateQueries(QueryKeys.warehouseTaskTxns);
};
const updateTaskCache = (
  task: string,
  cb: (data: iWarehouseTxn[]) => iWarehouseTxn[]
) => {
  queryClient.setQueryDataIfCached(
    buildQueryKey(QueryKeys.warehouseTaskTxns, task),
    (data: { txns: iWarehouseTxn[] }) => ({ txns: cb(data.txns) })
  );
};
export const WarehouseTxnsCacheService = {
  buildQueryKey,
  invalidateAll,
  updateEmbeddedProduct(product: IWarehouseTxnEmbeddedProduct) {
    queryClient.setInfiniteQueriesDataIfCached(
      QueryKeys.warehouseTxnsSearch,
      (pages: { txns: iWarehouseTxn[]; next?: number }[]) => {
        return pages.map((page) => {
          return {
            txns: page.txns.map((txn) =>
              txn.product?._id === product._id
                ? {
                    ...txn,
                    product,
                  }
                : txn
            ),
            next: page.next,
          };
        });
      }
    );
  },
  addTxn(added: iWarehouseTxn) {
    invalidateSearch();
    if (added.task) {
      updateTaskCache(added.task, (data) => uniqBy([...data, added], "_id"));
    }
  },
  replaceTxn(txn: iWarehouseTxn) {
    invalidateSearch();
    if (txn.task) {
      updateTaskCache(txn.task, (txns) =>
        txns.map((oldTxn) => (oldTxn._id === txn._id ? txn : oldTxn))
      );
    }
  },
  removeTxn(removed: iWarehouseTxn) {
    invalidateSearch();
    if (removed.task) {
      updateTaskCache(removed.task, (txns) =>
        txns.filter((txn) => txn._id !== removed._id)
      );
    }
  },
};
