import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";
import React from "react";

const MasterPage = loadable(
  () => import(/* webpackChunkName: "master-page" */ "_p/master"),
  {
    fallback: <Spin size="xl" />,
  }
);

export default MasterPage;
