import Loki from "lokijs";
import throttle from "lodash/throttle";

export class SyncDbSearch {
  public collections: Map<string, Loki.Collection>;
  public db: Loki;
  public destroyed: boolean;

  constructor(name: string) {
    this.collections = new Map();
    this.db = new Loki(name);
    this.destroyed = false;
  }
  addCollection(name: string): Loki.Collection {
    if (this.collections.has(name)) return this.collections.get(name)!;
    const coll = this.db.addCollection(name, {
      unique: ["_id"],
      asyncListeners: true,
    });
    this.collections.set(name, coll);
    return coll;
  }
  getCollectionOrAdd(name: string): Loki.Collection {
    const coll = this.getCollection(name);
    if (coll) return coll;
    return this.addCollection(name);
  }
  getCollection(name: string): Maybe<Loki.Collection> {
    return this.collections.get(name) || null;
  }
  hasCollection(name: string): boolean {
    return this.collections.has(name);
  }
  find<TDoc extends tAnyDict = {}>(colName: string, query?: tAnyDict): TDoc[] {
    const coll = this.getCollection(colName);
    if (!coll) return [];
    return coll.find(query);
  }
  findAndSubscribe<TDoc extends tAnyDict = {}>(
    query: tAnyDict,
    colName: string,
    cb: (docs: TDoc[]) => any
  ) {
    const runQuery = () => {
      if (cb) {
        cb(this.find(colName, query));
      }
    };
    runQuery();

    // batch changes
    const onChange = throttle(
      () => {
        runQuery();
      },
      25,
      {
        leading: false,
      }
    );
    const coll = this.getCollectionOrAdd(colName);
    const events = ["insert", "update", "delete"];
    events.forEach((e) => {
      if (coll) {
        coll.addListener(e, onChange);
      }
    });
    const unsubscribe = () => {
      onChange.cancel();
      if (coll) {
        events.forEach((e) => {
          coll.removeListener(e, onChange);
        });
      }
    };
    return unsubscribe;
  }
  findOne(colName: string, query: tAnyDict) {
    const coll = this.getCollection(colName);
    if (!coll) return null;
    return coll.findOne(query);
  }
  add(colName: string, doc: tAnyDict): void {
    const coll = this.getCollectionOrAdd(colName);
    if (coll) {
      coll.insert(doc);
    }
  }
  addOrUpdate(colName: string, doc: tAnyDict) {
    const coll = this.getCollectionOrAdd(colName);
    const existed = this.findById(colName, doc._id);
    if (existed) {
      coll.update({
        ...doc,
        $loki: existed.$loki,
        meta: { ...existed.meta },
      });
    } else {
      this.add(colName, doc);
    }
  }
  findById(colName: string, id: string) {
    const coll = this.getCollection(colName);
    if (!coll) return;
    return coll.by("_id", id);
  }
  removeById(colName: Maybe<string>, id: string) {
    // this is flaw in design
    // Pouchdb doesn't return the whole doc but only an id
    // on document remove, so the doc type is unknown
    // we need to walk through all the collections
    // but _id is unique and it's fast, so whatever
    if (!colName) {
      this.collections.forEach((coll) => {
        const item = coll.by("_id", id);
        if (item) {
          coll.remove(item.$loki);
        }
      });
      return;
    }
    const coll = this.getCollection(colName);
    if (!coll) return;
    const item = this.findById(colName, id);
    if (item) {
      coll.remove(item.$loki);
    }
  }
  destroy() {
    this.collections.forEach((_col, name) => {
      this.db.removeCollection(name);
    });
    this.collections.clear();
    this.destroyed = true;
  }
}
