import * as React from "react";

function SvgNailArtist({ color = "currentColor", ...props }) {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 73.71" {...props}>
      <path d="M9.43 18.31L0 52c23 20.18 43.32 29 57.73 14.61zM34.58 44.8l22.07 22.07C45.29 74.4 29.07 57 34.58 44.8zm46.59-11.97L97.1 16.9a9.9 9.9 0 00-14-14L67.17 18.83z" />
      <path d="M60.095 37.548l23.01-23.01 2.361 2.362-23.01 23.01zM50.73 51.64s-9.21-4.47-8.38-4.5c6.65-.23 14.54-6.39 14.54-6.39l2.37 2.36z" />
    </svg>
  );
}

export default SvgNailArtist;
