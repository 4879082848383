import { createSlice, createAction } from "@reduxjs/toolkit";
import type { tRootProps as tAccountingTxnEditorProps } from "_c/Modals/accounting-txn-editor/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { tTodoEditorProps } from "@/components/Modals/simple-todo-creator/types";
import type { tWarehouseProductEditorProps } from "@/components/Modals/warehouse-product-editor/types";
import type { tWarehouseTxnEditorProps } from "@/components/Modals/warehouse-txn-editor/types";
import type { tImagesGalleryModalProps } from "_c/Modals/photos-gallery/types";
import type { tEditorProps as tExternalNotificationTmplEditorProps } from "_c/Modals/exteral-notification-tmpl-editor/types";
import { tClientEditorProps } from "_c/Modals/client-editor-modal/types";

type tAccountingTxnEditorParams = Omit<tAccountingTxnEditorProps, "onClose">;
export type tImagesGalleryModalParams = Omit<
  tImagesGalleryModalProps,
  "onClose"
>;

export const DOMAIN = "modals";

const initialState = {
  isAuthOpen: false,
  isBookingOpen: false,
  isTechOpen: false,
  isShareOpen: false,
  isPremiumOpen: false,
  isSupportOpen: false,
  todoEditor: {
    isOpen: false,
    params: {} as tTodoEditorProps,
  },
  noteEditor: {
    isOpen: false,
    params: {} as tAnyDict,
  },
  expenseEditor: {
    isOpen: false,
    params: {} as tAnyDict,
  },
  createTask: {
    isOpen: false,
    params: {} as tAnyDict,
  },
  sendNotification: {
    isOpen: false,
    data: null,
  },
  share: {
    isOpen: false,
    link: null as Maybe<string>,
  },
  accountingTxnEditor: {
    isOpen: false,
    params: {} as tAccountingTxnEditorParams,
  },
  warehouseProductEditor: {
    isOpen: false,
    params: {} as tWarehouseProductEditorProps,
  },
  warehouseTxnEditor: {
    isOpen: false,
    params: {} as tWarehouseTxnEditorProps,
  },
  clientEditor: {
    isOpen: false,
    params: {} as tClientEditorProps,
  },
  externalNotiticationTmplEditor: {
    isOpen: false,
    params: {} as tExternalNotificationTmplEditorProps,
  },
  imagesGallery: {
    isOpen: false,
    params: {
      initialState: -1,
      images: [],
    } as tImagesGalleryModalParams,
  },
  techType: null,
};
export type tState = typeof initialState;
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    openExternalNotificationTmplEditor(
      state,
      { payload }: PayloadAction<tExternalNotificationTmplEditorProps>
    ) {
      state.externalNotiticationTmplEditor.isOpen = true;
      state.externalNotiticationTmplEditor.params = payload;
    },
    closeExternalNotificationTmplEditor(state) {
      state.externalNotiticationTmplEditor.isOpen = true;
      state.externalNotiticationTmplEditor.params =
        initialState.externalNotiticationTmplEditor.params;
    },
    openClientEditor(state, { payload }: PayloadAction<tClientEditorProps>) {
      state.clientEditor.isOpen = true;
      state.clientEditor.params = payload;
    },
    closeClientEditor(state) {
      state.clientEditor.isOpen = false;
      state.clientEditor.params = initialState.clientEditor.params;
    },
    openImagesGallery(
      state,
      { payload }: PayloadAction<tImagesGalleryModalParams>
    ) {
      state.imagesGallery.isOpen = true;
      state.imagesGallery.params = payload;
    },
    closeImagesGallery(state) {
      state.imagesGallery.isOpen = false;
      state.imagesGallery.params = initialState.imagesGallery.params;
    },
    openWarehouseTxnEditor(
      state,
      { payload }: PayloadAction<tWarehouseTxnEditorProps>
    ) {
      state.warehouseTxnEditor.isOpen = true;
      state.warehouseTxnEditor.params = payload;
    },
    closeWarehouseTxnEditor(state) {
      state.warehouseTxnEditor.isOpen = false;
      state.warehouseTxnEditor.params = initialState.warehouseTxnEditor.params;
    },
    openWarehouseProductEditor(
      state,
      { payload }: PayloadAction<tWarehouseProductEditorProps>
    ) {
      state.warehouseProductEditor.isOpen = true;
      state.warehouseProductEditor.params = payload;
    },
    closeWarehouseProductEditor(state) {
      state.warehouseProductEditor.isOpen = false;
      state.warehouseProductEditor.params =
        initialState.warehouseProductEditor.params;
    },
    openAccountingTxnEditor(
      state,
      { payload }: PayloadAction<tAccountingTxnEditorParams>
    ) {
      state.accountingTxnEditor.isOpen = true;
      state.accountingTxnEditor.params =
        payload || initialState.accountingTxnEditor.params;
    },
    closeAccountingTxnEditor(state) {
      state.accountingTxnEditor.isOpen = false;
      state.accountingTxnEditor.params =
        initialState.accountingTxnEditor.params;
    },
    openTodoEditor(state, { payload }: PayloadAction<tTodoEditorProps>) {
      state.todoEditor.isOpen = true;
      state.todoEditor.params = payload || initialState.todoEditor.params;
    },
    closeTodoEditor(state) {
      state.todoEditor = initialState.todoEditor;
    },
    openNoteEditor(state, { payload }) {
      state.noteEditor.isOpen = true;
      state.noteEditor.params = payload || initialState.noteEditor.params;
    },
    closeNoteEditor(state) {
      state.noteEditor = initialState.noteEditor;
    },
    openSupport(state) {
      state.isSupportOpen = true;
    },
    closeSupport(state) {
      state.isSupportOpen = false;
    },
    openAuth(state) {
      state.isAuthOpen = true;
    },
    closeAuth(state) {
      state.isAuthOpen = false;
    },
    closeCreateTask(state) {
      state.createTask = initialState.createTask;
    },
    openSendNotification(state, { payload }) {
      state.sendNotification.isOpen = true;
      state.sendNotification.data = payload;
    },
    closeSendNotification(state) {
      state.sendNotification.isOpen = false;
    },
    openCreateTask(state, { payload }) {
      state.createTask.isOpen = true;
      state.createTask.params = payload || initialState.createTask.params;
    },
    openBooking(state) {
      state.isBookingOpen = true;
    },
    closeBooking(state) {
      state.isBookingOpen = false;
    },
    openTech(state, { payload }) {
      state.isTechOpen = true;
      state.techType = payload;
    },
    closeTech(state) {
      state.isTechOpen = false;
    },
    openNonNativeShare(state, { payload }: PayloadAction<Maybe<string>>) {
      state.share.isOpen = true;
      state.share.link = payload;
    },
    closeShare(state) {
      state.share.isOpen = false;
      state.share.link = null;
    },
    openPremium(state) {
      state.isPremiumOpen = true;
    },
    closePremium(state) {
      state.isPremiumOpen = false;
    },
    globalReset() {
      return initialState;
    },
  },
});

const openShare = createAction(`${DOMAIN}/openShare`);
export const Actions = { ...slice.actions, openShare };
export default slice.reducer;
