import { CitiesAPI } from "@/api/cities";
import orderBy from "lodash/orderBy";
import { filterObject } from "@/utils";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { MASTER_SEARCH_RADIUS, IS_BROWSER } from "@/misc/constants";
import qs from "query-string";

export const extractLocMeta = async ({ query, params }) => {
  const defaultMeta = null;
  const hasCoords = query.lat && query.lng;
  if (hasCoords) {
    return {
      label: query.address_label,
      coords: {
        lat: +query.lat || 0,
        lng: +query.lng || 0,
      },
    };
  }
  if (params.loc) {
    const { data: city } = await CitiesAPI.findBySlug(params.loc);
    return {
      label: city.name,
      slug: city.slug,
      coords: {
        lat: city.loc.coordinates[1],
        lng: city.loc.coordinates[0],
      },
    };
  }
  return defaultMeta;
};

export const orderServicesByPrice = ({ data, sort }) => {
  return data.map((master) => {
    return {
      ...master,
      services: orderBy(
        master.services,
        "price.amount",
        sort.startsWith("-") ? "desc" : "asc"
      ),
    };
  });
};
export const maybeOrderServicesByPrice = ({ data, query }) => {
  if (query.sort?.includes("price")) {
    return orderServicesByPrice({ data, sort: query.sort });
  }
  return data;
};

const ALLOWED_QUERY_FIELDS = [
  "men",
  "women",
  "children",
  "service",
  "at_home",
  "at_saloon",
  "the_road",
  "sort",
  "date",
  "spec",
  "page",
  "discounts",
  "lat",
  "lng",
  "radius",
  "model_search",
  "price_from",
  "price_to",
  "works_online",
];
const filterQuery = (query) => {
  const filtered = filterObject(
    query,
    (key) => key !== undefined && key !== false && key !== "false"
  );
  return {
    ...pick(filtered, ALLOWED_QUERY_FIELDS),
    ...(filtered.date && { date: filtered.date }),
    radius: (Number(filtered.radius) || MASTER_SEARCH_RADIUS) * 1000,
  };
};

const cacheDomain = "search_cache:";
export const searchCache = {
  get(query) {
    if (IS_BROWSER) {
      try {
        const key = qs.stringify(omit(query, "page"));
        return JSON.parse(sessionStorage.getItem(cacheDomain + key));
      } catch (_e) {
        return null;
      }
    }
  },
  set(data, query) {
    if (IS_BROWSER) {
      const key = qs.stringify(omit(query, "page"));
      sessionStorage.setItem(cacheDomain + key, JSON.stringify(data));
    }
  },
};
export const buildFinalQuery = ({ query, locMeta, params }) => {
  const finalQuery = filterQuery(query);
  finalQuery.lng = locMeta.coords.lng;
  finalQuery.lat = locMeta.coords.lat;
  finalQuery.spec = query.spec || params.spec;
  return finalQuery;
};
