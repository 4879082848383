import { get, put, remove } from "./";
import { API_URL } from "@/misc/constants";
import { analyticsAction } from "@/utils/analytics";

const BASE = `${API_URL}/material`;
const getMaterials = (params) => {
  return get(BASE, {
    params,
    cache: false,
  });
};
const getBrandCompletion = (payload) => {
  return get(`${BASE}/brand/autocomplete`, {
    params: {
      title: payload,
    },
  });
};
const getUnitCompletion = (params) => {
  return get(`${BASE}/unit/autocomplete`, {
    params,
  });
};
const createMaterial = async (payload) => {
  const data = await put(BASE, payload);
  analyticsAction({
    category: "master",
    action: "add_material",
  });
  return data;
};
const removeMaterial = async (id) => {
  const data = await remove(`${BASE}/${id}`);
  return data;
};

export const MaterialsAPI = {
  createMaterial,
  getMaterials,
  getBrandCompletion,
  getUnitCompletion,
  removeMaterial,
};
