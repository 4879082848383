import { Actions as MasterProfileActions } from "@/store/masterProfile/slice";
import { Selectors as MasterProfileSelectors } from "@/store/masterProfile/selectors";
import { TaskEditorCacheService } from "../taskEditor/cache";
import { MasterServicesCacheService } from "./cache";
import { Selectors as SaloonProfileSelectors } from "@/store/saloonProfile/selectors";
import { Actions as SaloonProfileActions } from "@/store/saloonProfile/slice";

export const MasterServiceMutationHandler = {
  serviceCreated({ service }) {
    const store = window.__reduxStore;
    if (shouldUpdateMasterProfilePage(service)) {
      store.dispatch(MasterProfileActions.createService(service));
    }
    if (shouldUpdateSaloonProfilePage(service)) {
      store.dispatch(SaloonProfileActions.createService(service));
    }

    MasterServicesCacheService.addService({ service });
    TaskEditorCacheService.addService({ service });
  },
  serviceUpdated({ updated_service: service }) {
    const store = window.__reduxStore;
    if (shouldUpdateMasterProfilePage(service)) {
      store.dispatch(MasterProfileActions.updateService(service));
    }
    if (shouldUpdateSaloonProfilePage(service)) {
      store.dispatch(SaloonProfileActions.updateService(service));
    }

    MasterServicesCacheService.replaceService({ service });
    TaskEditorCacheService.replaceService({ service });
  },
  serviceRemoved({ service }) {
    const store = window.__reduxStore;
    if (shouldUpdateMasterProfilePage(service)) {
      store.dispatch(MasterProfileActions.deleteService(service._id));
    }
    if (shouldUpdateSaloonProfilePage(service)) {
      store.dispatch(SaloonProfileActions.deleteService(service._id));
    }

    MasterServicesCacheService.removeService({ service });
    TaskEditorCacheService.removeService({ service });
  },
};

const shouldUpdateMasterProfilePage = (service) => {
  const store = window.__reduxStore;
  return (
    MasterProfileSelectors.getMasterId(store.getState()) === service.master
  );
};

const shouldUpdateSaloonProfilePage = (service) => {
  const store = window.__reduxStore;
  const state = store.getState();
  return (
    SaloonProfileSelectors.getId(state) &&
    SaloonProfileSelectors.getMasters(state).find(
      ({ _id }) => _id === service.master
    )
  );
};
