import { WarehouseProductCategoriesCacheService } from "./cache";
import type { iWarehouseProductCategory } from "@/types";
import { WarehouseProductsCacheService } from "../products/cache";
import { WarehouseTxnsCacheService } from "../txns/cache";

export const WarehouseProductCategoryMutationHandler = {
  categoryCreated(data: { category: iWarehouseProductCategory }) {
    WarehouseProductCategoriesCacheService.addCategory(data.category);
  },
  categoryUpdated(data: { category: iWarehouseProductCategory }) {
    WarehouseProductCategoriesCacheService.replaceCategory(data.category);
  },
  categoryRemoved(data: { category: iWarehouseProductCategory }) {
    WarehouseProductCategoriesCacheService.removeCategory(data.category);
    WarehouseProductsCacheService.invalidateAll();
    WarehouseTxnsCacheService.invalidateAll();
  },
};
