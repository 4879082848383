import { DOMAIN } from "./slice";

const getMode = (state) => state[DOMAIN].mode;
const getAuthForm = state => state[DOMAIN].authForm;
const getStrategy = state => state[DOMAIN].strategy;

export const Selectors = {
  getStrategy,
  getAuthForm,
  getMode,
};
