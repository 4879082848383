import { createStructuredSelector } from "reselect";
import { createSelector } from "@reduxjs/toolkit";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { DOMAIN } from "./slice";
import moment from "moment";
import isempty from "lodash/isEmpty";

const $ = (s) => s[DOMAIN];
const getMode = (s) => $(s).mode;
const getSaloonsDict = (s) => $(s).saloons || {};
const getSaloonsList = createSelector(getSaloonsDict, (s) => Object.values(s));

const hasAnySaloons = (s) => {
  return !isempty(getSaloonsDict(s));
};
const getActiveSaloon = (s) => {
  const active = $(s).active;
  if (!active) return null;
  return getSaloonsDict(s)[active];
};

const getActiveSaloonId = (s) => {
  return getActiveSaloon(s)?._id;
};
const getSaloonOwner = createSelector(
  getActiveSaloon,
  (saloon) => saloon?.owner
);
const isSaloonOwner = createSelector(
  getSaloonOwner,
  UserSelectors.getUserId,
  (owner, authUserId) => {
    return owner?._id === authUserId;
  }
);
const isSaloonPremium = createSelector(
  [getActiveSaloon, UserSelectors.isPremium, isSaloonOwner],
  (saloon, isUserPremium, isOwner) => {
    if (isOwner) {
      return isUserPremium;
    }
    return saloon?.premium;
  }
);
const getTitle = createSelector(getActiveSaloon, (saloon) => saloon?.title);
const getLocationHr = createSelector(
  getActiveSaloon,
  (saloon) => saloon?.location_hr
);
const getStatsRange = (s) => $(s).statsRange;

// schedule
const getSchedule = createSelector(getActiveSaloon, (saloon) => {
  return saloon?.schedule;
});
const getWeekSchedule = createSelector(
  getSchedule,
  (schedule) => schedule?.for_week
);

// interior
const getInteriorPhotos = createSelector(
  getActiveSaloon,
  (saloon) => saloon?.interior_photos || []
);

// masters
const getSelectedSchedulerMaster = (s) => $(s).selectedSchedulerMaster;

const isMarketplaceRegistered = createSelector(getActiveSaloon, (saloon) => {
  return Boolean(saloon?.mp_id);
});
const getMarketplaceRegDefaultValues = createStructuredSelector({
  ceo_phone: UserSelectors.getPhone,
  ceo_name: UserSelectors.getFirstName,
  ceo_lastname: UserSelectors.getLastName,
  address_city: UserSelectors.getLocationHr,
  email: UserSelectors.getEmail,
  fullName: getTitle,
  name: getTitle,
  address_street: getLocationHr,
  ceo_birthdate: () => [moment().subtract(20, "years").toDate()],
});

const getSaloonPolicies = createSelector(
  [
    UserSelectors.getUserId,
    getActiveSaloon,
    UserSelectors.isSaloonOwner,
    isSaloonOwner,
  ],
  (userId, saloon, isUserOwnAnySaloons, isOwner) => {
    const isAdmin =
      !isOwner &&
      userId &&
      saloon &&
      saloon.admins.some(({ _id }) => _id === userId);
    return {
      removeInvites: isOwner,
      removeMasters: isOwner,
      inviteMasters: isOwner || isAdmin,
      inviteAdmins: isOwner,
      showAdmins: isOwner,
      removeAdmins: isOwner,
      changeWorkingHours: isOwner,
      changeTasks: isOwner || isAdmin,
      addInteriorPhotos: isOwner || isAdmin,
      updateSaloon: isOwner || isUserOwnAnySaloons,
      createSaloon: isUserOwnAnySaloons,
      premium: isOwner || isUserOwnAnySaloons,
      createMarketplaceShop: isOwner,
      smsAccount: isOwner,
    };
  }
);
const getClientsConfig = (s) => getActiveSaloon(s)?.clients_config;

const getEmployeesActiveTab = (s) => $(s).employeesActiveTab;
const getStatsActiveTab = (s) => $(s).statsActiveTab;
const getWarehouseActiveTab = (s) => $(s).warehouseActiveTab;

export const Selectors = {
  getEmployeesActiveTab,
  getStatsActiveTab,
  getWarehouseActiveTab,

  getSchedule,
  getWeekSchedule,

  getSaloonOwner,
  isSaloonOwner,
  getActiveSaloon,
  isSaloonPremium,
  getActiveSaloonId,
  getMode,
  getSaloonsList,
  hasAnySaloons,
  getInteriorPhotos,

  getStatsRange,

  getSelectedSchedulerMaster,

  getSaloonPolicies,

  isMarketplaceRegistered,
  getMarketplaceRegDefaultValues,

  getClientsConfig,
};
