import React from "react";

const DotsIcon = ({ color = "currentColor", ...props }) => (
  <svg
    width="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={color}
      d="M6.5 12A2.75 2.75 0 1 1 1 12a2.75 2.75 0 0 1 5.5 0M14.75 12a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0M23 12a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0"
    />
  </svg>
);

export default DotsIcon;
