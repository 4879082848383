import { API_URL, IS_SERVER } from "@/misc/constants";
import { get } from "./index";
import { specsCache } from "@/misc/httpCache";

const BASE = API_URL + "/query";

// mode - master | saloon
const scheduler = async (mode, { includeIntersected = true, ...params }) => {
  const url =
    mode === "saloon" ? BASE + "/saloonScheduler" : BASE + "/scheduler";
  const {
    data: { slots, ...rest },
  } = await get(url, {
    cache: false,
    params: { ...params, includeIntersected },
  });
  return {
    buckets: slots.reduce((acc, bucket) => {
      acc[bucket.date] = bucket;
      return acc;
    }, {}),
    ...rest,
  };
};

const professionSpecs = async (params) => {
  const { data } = await get(`${BASE}/professionSpecs`, {
    params,
    // react-query handles the cache on client
    cache: IS_SERVER ? specsCache : false,
  });
  return data.specs;
};
const taskEditor = async (params) => {
  const { data } = await get(`${BASE}/taskEditor`, {
    params,
    cache: false,
  });
  return data;
};

const aggrTasks1 = async (params) => {
  const { data } = await get(`${BASE}/aggr/businessTasks1`, {
    params,
    cache: false,
  });
  return data;
};

export const QueriesAPI = {
  scheduler,
  professionSpecs,
  taskEditor,
  aggrTasks1,
};
