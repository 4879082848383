import React from "react";
import s from "styled-components";
import type { CSSProperties } from "react";
const BASE = "/loaders/";

export const Hearts = s.img.attrs({
  src: BASE + "hearts.svg",
  role: "presentation",
})``;
export const HeartsLight = s.img.attrs({
  src: BASE + "hearts_light.svg",
  role: "presentation",
})``;

const styles: CSSProperties = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
  zIndex: 1000,
};
export const HeartsLoaderFullScreen = () => (
  <div style={styles}>
    <Hearts />
  </div>
);
export const OvalPreloader = s.img.attrs({
  role: "presentation",
  "aria-label": "идет загрузка",
  src: BASE + "oval.svg",
})``;
export const PuffPreloader = s.img.attrs({
  role: "presentation",
  "aria-label": "идет загрузка",
  src: BASE + "puff.svg",
})<{ centered?: boolean }>`
  display: ${({ centered }) => (centered ? "block" : "inline")};
  margin: ${({ centered }) => (centered ? "0 auto " : "")};
`;
export const PuffDarkPreloader = s.img.attrs({
  role: "presentation",
  "aria-label": "идет загрузка",
  src: BASE + "puff_dark.svg",
})``;

type tSpinSize = "xl" | "lg" | "md" | "sm" | "xs";
type tSpinProps = {
  size?: tSpinSize;
  centered?: boolean;
};
export const HeartsPreloader = s.img.attrs({
  role: "presentation",
  src: `${BASE}/ellipsis.svg`,
})<tSpinProps>`
  width: ${({ size = "md" }) => {
    if (size === "xl") {
      return "100px";
    } else if (size === "lg") {
      return "100px";
    } else if (size === "md") {
      return "100px";
    } else if (size === "sm") {
      return "50px";
    } else if (size === "xs") {
      return "25px";
    }
  }};
  pointer-events: none;
  display: ${({ centered = true }) => (centered ? "block" : "inline")};
  margin: ${({ centered = true }) => (centered ? "0 auto " : "")};
`;
export const Spin = HeartsPreloader;
export const FullScreenSpin = s(Spin)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
