import React from "react";
import PT from "prop-types";
import Modal from "_c/Modals";
import Main from "./main";
import { tWarehouseTxnEditorProps } from "./types";

type tProps = tWarehouseTxnEditorProps & {
  isOpen: boolean;
  onClose: tVoidFn;
};
const WarehouseTxnEditorModal = ({ isOpen, ...props }: tProps) => {
  return (
    <Modal
      css={`
        height: 100%;
      `}
      onClose={props.onClose}
      isOpen={isOpen}
    >
      <Main {...props} />
    </Modal>
  );
};
WarehouseTxnEditorModal.propTypes = {
  isOpen: PT.bool.isRequired,

  onClose: PT.func.isRequired,
};
export default WarehouseTxnEditorModal;
