export const DEFAULT_AUTH_STRATEGY = "phone";
export const DEFAULT_REG_STRATEGY = "phone";
export const DEFAULT_RESTORE_PASS_STRATEGY = "phone";

export const GEO_SERVICE_API_KEY = "e81710858ad3a68233d66dda819e2d6d433ebf90";

export const SERVER_FEATURES_TO_ACTIVATE = [];

export * from "./colors";
export * from "./todos";
export * from "./onboarding";
export * from "./initialScreen";
export * from "./i18n";
export * from "./money";
export * from "./clients";
export * from "./device";
