import { connect } from "react-redux";
import { Effects } from "@/store/clientProfile/effects";
import { Selectors } from "@/store/clientProfile/selectors";
import { Selectors as UserSelectors } from '@/store/user/selectors';
import ClientPage from './loadable';

const mSp = (state) => ({
  authUserId: UserSelectors.getUserId(state),
  loading: Selectors.isLoading(state),
  error: Selectors.getError(state),
  cityPhoto: Selectors.getCityPhoto(state),
});

const mDp = {
  loadProfile: Effects.loadProfile,
};

export default connect(mSp, mDp)(ClientPage);
