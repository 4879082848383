import styled from "styled-components";
import uiVars from "../../ui-vars";

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  > a,
  span {
    color: var(--font-color-accent);
    display: flex;
    align-items: center;
  }
  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    margin-inline-end: 7px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
  }
  button {
    transition: opacity 0.25s ease;
    margin-inline-start: 10px;
    padding-top: 3px;
    :focus,
    :hover {
      opacity: 0.6;
    }
  }
  @media (max-width: ${uiVars.mobileBp}px) {
    margin-left: 5px;
    img {
      margin-inline-end: 2px;
    }
    span {
      display: none;
    }
  }
`;
