import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextInput from "_c/form/TextInput";
import { i18nNs } from "./config";
import $ from "currency.js";

const ShortageAmountField = ({ id, t, name }) => {
  const methods = useFormContext();
  const value = methods.watch(name);
  const unit = methods.watch("unit");
  useEffect(() => {
    // some bs with react-hook-form, need to wait a little
    setTimeout(() => {
      // По возможности высчитывать сразу автоматически при включении тогглера - кол-во x 30%
      const amount = Number(methods.getValues().amount);
      if (amount) {
        methods.setValue(name, String($(amount * 0.3).value));
      }
    }, 100);
  }, [name]);

  return (
    <div
      css={`
        display: flex;
        align-items: center;
      `}
    >
      <TextInput
        type="number"
        onChange={(e) => methods.setValue(name, e.target.value)}
        value={value}
        placeholder={t(i18nNs + "shortage_amount_holder")}
        id={id}
      />
      {unit && (
        <span
          css={`
            margin-inline-start: 10px;
            flex: 1;
          `}
        >
          {unit}
        </span>
      )}
    </div>
  );
};

export default ShortageAmountField;
