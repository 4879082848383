export const DEFAULT_FEMALE_AVATAR = "/mockups/avatar_female_v2.svg";
export const DEFAULT_FEMALE_AVATAR_WHITE = "/mockups/avatar_female_white.svg";
export const DEFAULT_MALE_AVATAR = "/mockups/avatar_male_v2.svg";
export const DEFAULT_MALE_AVATAR_WHITE = "/mockups/avatar_male_white.svg";
export const DEFAULT_PROFILE_PHOTO = "/mockups/avatar_big.png";
export const DEFAULT_PROFILE_PHOTO_DARK = "/mockups/avatar_big_dark.png";
export const DEFAULT_PROFILE_PHOTO_MALE = "/mockups/avatar_big_male.png";
export const DEFAULT_PROFILE_PHOTO_MALE_DARK =
  "/mockups/avatar_big_male_dark.png";
export const DEFAULT_SALOON_LOGO = "/mockups/saloon_logo_big.png";
export const PLACEHOLDER_IMAGE_PATH = "/mockups/photo_placeholder.svg";
// 10mb
export const MAX_UPLOAD_PHOTO_SIZE = 10000000;

export const GOOGLE_MAPS_API_KEY = "AIzaSyDSRC5BvZgiIWUIWVa9StvuGoAKnS3aR-A";

export const MAX_ARTICLE_EXCERPT_LENGTH = 200;

export const TECH_USER_ID = "5d8c707b807a0900486dd44a";
export const IDEA_USER_ID = "5d8c707b807a0900486dd44c";

export const IS_SERVER = typeof window === "undefined";
export const IS_BROWSER = !IS_SERVER;

const DEFAULT_API_URL = "https://simpo.online/api";
const DEFAULT_WEBSOCKETS_URL = "wss://simpo.online/socket";
const DEFAULT_SITE_URL = "https://simpo.online";

export const WEBSOCKETS_URL = IS_SERVER
  ? null
  : window.__SOCKET_URL__ || DEFAULT_WEBSOCKETS_URL;

export const SITE_URL =
  (IS_SERVER ? process.env.SITE_URL : window.__SITE_URL__) || DEFAULT_SITE_URL;

let API_URL = "";
if (IS_SERVER) {
  API_URL = process.env.API_URL ? "http://api:4567/api" : DEFAULT_API_URL;
} else {
  API_URL = window.__API_URL__ || DEFAULT_API_URL;
}
export { API_URL };

export const COUCHDB_URL = "https://simpo.online/couchdb";

export const PROD = IS_SERVER
  ? process.env.NODE_ENV === "production"
  : !!window.__PROD__;

export const PUBLIC_VAPID_KEY =
  (IS_SERVER ? process.env.PUBLIC_VAPID_KEY : window.__PUBLIC_VAPID_KEY__) ||
  "";

export const CLOUDINARY_CLOUD_NAME = "simpo";
export const CLOUDINARY_UPLOAD_PRESET = "wumydocr";

export const MASTER_SEARCH_RADIUS = 25;

export const MAX_PHOTOS_PER_TASK = 3;

export const STATS_DATE_FORMAT = "YYYY-MM-DD";
export const COMMON_DATE_FORMAT = "YYYY-MM-DD";

// прогресс профиля
export const PROFILE_PROGRESS_SERVICES_COUNT = 5;
export const PROFILE_PROGRESS_PORTFOLIO_COUNT = 7;
export const PROFILE_INITIAL_PROGRESS = 10;

export const ACHIEVE_ICONS_ROOT = "/images/achieves/";

export const PREMIUM_ICON_URL = "/images/achieves/premium.png";
export const PREMIUM_MAP_ICON_URL = "/svg/premium_map.svg";

export const MESSAGES_PER_PAGE = 30;
export const DIALOGS_PER_PAGE = 20;
export const FAVORITES_PER_PAGE = 10;
export const SEARCH_PER_PAGE = 15;
//export const REVIEWS_PER_PAGE = 2;
//export const FEED_PER_PAGE = 2;
export const REVIEWS_PER_PAGE = 10;
export const FEED_PER_PAGE = 10;

// secs
export const PHONE_VERIFY_CODE_WAIT = 120;

// booking
export const MIN_TIME_BEFORE_BOOKING = { minutes: 30 };
export const BOOKING_INTERVAL_CHUNK = 15;

export const DEFAULT_TIMEZONE = "UTC";

export const TELEGRAM_BOT = "SimpoHelpBot";
export const WA_BOT = "79300097697";
export const VIBER_BOT = "simpoonline";

export const ALLOWED_FEED_OPTIONS = ["Review", "Portfolio"];

export const OPENING_HOURS_DAYS_SPECIFICATION = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].map((d) => `http://schema.org/${d}`);
export const LOCALE_QUERY_KEY = "hl";

export const MAPBOX_API_KEY =
  "pk.eyJ1Ijoic3RyYXg3NyIsImEiOiJjazRoNWoxM2YxMG9hM2xuNjQ4cW9mMDU4In0.72T8qIhYuFXzx0ZL_KuB2A";

export const TECH_SUPPORT_URL = "https://special.simpo.online/docs";

export const MAX_TASK_CUSTOM_FIELDS = 15;
