import * as React from "react";

function SvgMasseur({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96.06 100"
      {...props}
    >
      <path d="M66.93 60.43a11.86 11.86 0 1016.77 0 11.86 11.86 0 00-16.77 0zM16 11a11.22 11.22 0 1013-9.12A11.22 11.22 0 0016 11zM7.46 5.32c-1.52 3.63-1.27 7.2.57 8s4.57-1.54 6.09-5.17 1.27-7.2-.57-8S9 1.69 7.46 5.32z" />
      <path d="M95.92 90.22a3.9 3.9 0 00-4.78-2.76l-16.28 4.36-11.4-10.52c-21.84-19.59-35.1 4.12-63.46-6v14.24h54.85A38.39 38.39 0 0074.5 100l18.66-5a3.9 3.9 0 002.76-4.78zM42.78 61.43L29.3 48c-.92-3.31-3.84-13.31-6.46-16.87C11.67 15.84 2.47 51.5.3 68.78l1.8.64a37.13 37.13 0 0012.64 2.26 42.55 42.55 0 006.94-.61V51.38L37.25 67a3.91 3.91 0 005.53-5.52z" />
      <path d="M51.63 94.26H0V100h51.63a2.87 2.87 0 000-5.74z" />
    </svg>
  );
}

export default SvgMasseur;
