import { API_URL } from "@/misc/constants";
import { get, post, put } from "./index";
import { mastersCache } from "@/misc/httpCache";

const BASE = `${API_URL}/master`;
export const findById = (id) => {
  return get(`${BASE}/${id}`, { cache: false });
};
export const updateById = async (id, payload) => {
  const res = await post(`${BASE}/${id}`, payload);
  mastersCache.reset();
  return res;
};

export const getProfile = (id) => {
  return get(`${BASE}/${id}/profile`, { cache: false });
};
export const updateSchedule = (id, payload) => {
  mastersCache.reset();
  return post(`${BASE}/${id}/schedule`, payload);
};
export const getServices = (id) => {
  return get(`${BASE}/${id}/services`, { cache: false });
};
export const getDiscounts = (id) => {
  return get(`${BASE}/${id}/discounts`, { cache: false });
};
export const createMaster = async () => {
  const res = await put(BASE);
  return res;
};
const getReviews = (id, params) => {
  return get(`${BASE}/${id}/profile/reviews`, {
    params,
    cache: mastersCache,
  });
};

export const getClients = (id) => {
  return get(`${BASE}/${id}/clients`, {
    cache: false,
  });
};

export const MasterAPI = {
  getClients,
  getReviews,
  createMaster,
  getDiscounts,
  getServices,
  getProfile,
  findById,
  updateById,
  updateSchedule,
};
