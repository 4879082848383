import React from "react";
import { FullScreenSpin as Spin } from "_c/preloader";
import loadable from "@loadable/component";

const ArticlePage = loadable(
  () => import(/* webpackChunkName: "article-page" */ "."),
  {
    fallback: <Spin size="xl" />,
  }
);

export default ArticlePage;
