import React from "react";

function SvgComponent({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75.22 100"
      {...props}
    >
      <path d="M14.46 97.52l1-1-12-12-1 1a8.47 8.47 0 0012 12zm31.03-12.46V21L37 0l-8.45 21v64.1zM34.78 9.8h4.49l3.86 9.55H30.92zm-3.19 70V23.51h4.78v56.32zm13.9 11.73v-1.38H28.55v1.38a8.47 8.47 0 1016.94 0zm-21.65-3.39V75.23l-7.56 7.56-3.39-3.38 11-10.94v-4.29L7.06 81l12 12zm26.4-26.4L66.4 45.58l8.82-20.8-20.8 8.82-4.18 4.18v4.29l4.52-4.52 3.38 3.38-7.9 7.9zm7-27.6l9.48-4 3.17 3.17-4 9.48z" />
    </svg>
  );
}

export default SvgComponent;
