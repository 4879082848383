import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const ClientProfileLoadable = loadable(
  () => import(/* webpackChunkName: "custom-client-profile" */ "./main"),
  { ssr: false, fallback: <Spin size="xl" /> }
);

export default ClientProfileLoadable;
