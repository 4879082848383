import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const FavoritesPage = loadable(() => import("./"), {
  ssr: false,
  fallback: <Spin size="xl" />,
});

export default FavoritesPage;
