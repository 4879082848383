import { connect } from "react-redux";
import { Selectors } from "@/store/blog/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Effects } from "@/store/blog/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import BlogPage from "./loadable";

const mSp = (state) => ({
  articles: Selectors.getArticles(state),
  loading: Selectors.isArticlesLoading(state),
  count: Selectors.getArticlesCount(state),
  perPage: Selectors.getArticlesPerPage(state),
  favorites: FavSelectors.getFavoriteArticles(state),
  canCreateArticle: Selectors.canCreateArticle(state),
  isAuth: AuthSelectors.isAuth(state),
});
const mDp = {
  loadArticles: Effects.loadArticles,
  toggleLike: Effects.toggleLike,
  openShareModal: ModalActions.openShare,
};

export default connect(mSp, mDp)(BlogPage);
