import React, { ReactNode } from "react";
import { motion, AnimatePresence, Variants, Transition } from "framer-motion";
import { useToggle } from "@/hooks/useToggle";
import * as S from "./styled";
import AngleRightIcon from "_c/icon/AngleRight";

const variants: Variants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};
const transition: Transition = {
  duration: 0.3,
  ease: [0.04, 0.62, 0.23, 0.98],
};

type tProps = {
  isOpen: boolean;
  title: string;
  className?: string;
  bottomGap?: string;
  onChange: tVoidFn;
  children?: ReactNode;
};

export const ControlledAccordion = ({
  isOpen,
  onChange,
  className,
  title,
  bottomGap,
  ...props
}: tProps) => {
  return (
    <div
      className={className}
      css={`
        margin-bottom: 10px;
      `}
    >
      <S.Header type="button" initial={false} onClick={onChange} {...props}>
        <S.IconWrap
          initial={false}
          animate={{
            rotate: isOpen ? 90 : 0,
          }}
        >
          <AngleRightIcon color="white" />
        </S.IconWrap>
        <strong>{title}</strong>
      </S.Header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
            transition={transition}
          >
            <div
              css={`
                padding-top: 10px;
              `}
            >
              {props.children}
              {bottomGap && <div style={{ height: bottomGap }} />}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type tUncontrolledAccordionProps = Omit<tProps, "isOpen" | "onChange"> & {
  defaultOpen: boolean;
};
const Accordion = (props: tUncontrolledAccordionProps) => {
  const [isOpen, toggle] = useToggle(props.defaultOpen);
  return <ControlledAccordion {...props} isOpen={isOpen} onChange={toggle} />;
};
export default Accordion;
