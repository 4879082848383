import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import DiscountsPage from "./";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";

const mSp = (state) => ({
  userId: UserSelectors.getUserId(state),
  menu: LinksSelectors.getClientDiscountsChildren(state),
});

const connected = connect(mSp)(DiscountsPage);
export default connected;
