import React, { useState, useEffect, useRef, useMemo } from "react";
import * as S from "./index-styled";
import PT from "prop-types";
import { useTheme } from "@/hooks/useTheme";

const Popup = (props) => {
  const [active, setActive] = useState(false);
  const theme = useTheme();
  const ref = useRef();
  useEffect(() => {
    async function foo() {
      const mojs = await import("@mojs/core");
      const containerWidth = 300;
      const containerHeight = ref.current.offsetHeight;
      const center = containerWidth / 2;
      const radius = 30;
      const duration = 1200;
      const smallRadius = radius - 15;
      const xOffset = 50;
      const smallAngle = 50;
      const smallYOffset = { 0: -45 };
      class Heart extends mojs.CustomShape {
        getShape() {
          return '<path d="M94.967 15.972c-4.252-5.587-10.941-8.796-20.461-9.818a23.984 23.984 0 00-2.422-.126c-8.44 0-16.85 3.96-22.084 10.252C44.763 9.989 36.356 6.028 27.92 6.028c-.836 0-1.646.048-2.432.126C15.982 7.176 9.294 10.387 5.04 15.97.822 21.505-.553 29.009.956 38.271 3.16 51.71 20.563 68.075 34.544 81.227l.32.3c3.893 3.669 7.257 6.833 9.447 9.192.296.33 2.966 3.253 5.888 3.253 2.94 0 5.55-2.966 5.788-3.25 2.327-2.525 6.107-6.104 9.839-9.632 13.825-13.115 31.03-29.437 33.212-42.824 1.516-9.256.15-16.756-4.071-22.294z"/>';
        }
      }
      mojs.addShape("heart", Heart);
      const endAngle = 30;

      const HEART_OPTS = {
        left: 0,
        top: 0,
        parent: ref.current,
        shape: "heart",
        angle: { [-90]: endAngle, easing: "cubic.out" },
        scale: { 0: 1 },
        easing: "elastic.out",
        delay: "rand(50, 200)",
        radius,
        duration,
        x: containerWidth / 2,
        y: { 0: -55 },
      };
      const leftHeart = new mojs.Shape({
        ...HEART_OPTS,
        radius: smallRadius,
        fill: "deeppink",
        angle: { [-70]: -smallAngle },
        opacity: 0.85,
        y: smallYOffset,
        x: center - xOffset,
      });
      const rightHeart = new mojs.Shape({
        ...HEART_OPTS,
        opacity: 0.85,
        radius: smallRadius,
        fill: "deeppink",
        angle: { [-70]: smallAngle },
        y: smallYOffset,
        x: center + xOffset,
      });
      const centerHeart = new mojs.Shape({
        ...HEART_OPTS,
        angle: { [-90]: 0 },
        fill: "deeppink",
      }).then({
        angle: 0,
      });
      const burst = new mojs.Burst({
        left: 0,
        top: 0,
        parent: ref.current,
        x: containerWidth / 2,
        y: containerHeight / 2,
        radius: { 0: 175 },
        count: 20,
        timeline: { delay: 200 },
        angle: { 0: 45 },
        children: {
          radius: 5,
          fill: theme.color.primary,
          scale: { 1: 0, easing: "quad.in" },
          easing: "quint.out",
        },
      });
      const smallCircle = new mojs.Shape({
        left: 0,
        top: 0,
        scale: { 0: 1 },
        parent: ref.current,
        x: containerWidth / 2,
        y: containerHeight / 2,
        fill: theme.color.primary,
        opacity: { 0.7: 0 },
        radius: 70,
      });
      const tl2 = new mojs.Timeline({ speed: 1, repeat: 0 });
      const tl = new mojs.Timeline({
        speed: 1,
        repeat: 0,
        onComplete: () => {
          tl2.play();
        },
      });
      tl2.add(centerHeart);
      tl2.add(leftHeart);
      tl2.add(rightHeart);
      tl.add(smallCircle);
      tl.add(burst);
      tl.play();
      setTimeout(() => {
        setActive(true);
      }, 125);
    }
    foo();
  }, [theme]);
  const matchedRank = useMemo(() => {
    const rank = props.achieve.ranks.find(({ rank }) => rank === props.rank);
    return rank;
  }, [props.rank, props.achieve]);
  return (
    <S.Container onClick={props.onClose} ref={ref}>
      <S.ContainerInner isActive={active}>
        <S.Title>{matchedRank.title[props.locale]}</S.Title>
        <S.Desc>{matchedRank.desc[props.locale]}</S.Desc>
        <S.Count>
          <img alt="wreath" src="/svg/wreath.svg" />
          <span>{matchedRank.reward}</span>
        </S.Count>
      </S.ContainerInner>
    </S.Container>
  );
};
Popup.propTypes = {
  rank: PT.number,
  achieve: PT.object,
  locale: PT.string,
};
export default Popup;
