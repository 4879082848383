import { createAction, createSlice } from "@reduxjs/toolkit";

const DOMAIN = "masterSearch";
export const initialState = {
  loading: true,
  loadingGeo: false,
  results: [],
  hasMore: false,
  geoResults: [],
  error: null,
  geoError: null,
  locMeta: {
    label: "",
    coords: {
      lat: 0,
      lng: 0,
    },
    slug: null,
  },
  seoMeta: {
    no_index: true,
  },
  map: {
    popup: null,
    focus: null,
    open: false,
  },
  filters: {
    open: false,
  },
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
      state.map.popup = null;
      state.map.focus = null;
    },
    loadingSuccess(state, { payload }) {
      state.loading = false;
      state.hasMore = payload.hasMore;
      state.results = payload.data;
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    loadingGeoStart(state) {
      state.loadingGeo = true;
    },
    loadingGeoSuccess(state, { payload }) {
      state.loadingGeo = false;
      state.geoResults = payload;
    },
    loadingGeoError(state, { payload }) {
      state.loadingGeo = false;
      state.geoError = payload;
    },
    changeMapFocus(state, { payload }) {
      state.map.focus = payload;
    },
    openMapPopup(state, { payload }) {
      state.map.open = true;
      state.map.focus = payload.coords;
      state.map.popup = payload;
    },
    setLocMeta(state, { payload }) {
      state.locMeta = payload;
    },
    setSeoMeta(state, { payload }) {
      state.seoMeta = payload || initialState.seoMeta;
    },
    resetLocMeta(state) {
      state.locMeta = {
        label: "",
        coords: {
          lat: 0,
          lng: 0,
        },
        slug: null,
      };
    },
    closeMapPopup(state) {
      state.map.popup = null;
    },
    toggleMap(state) {
      state.map.open = !state.map.open;
    },
    toggleFilters(state) {
      state.filters.open = !state.filters.open;
    },
    reset() {
      return initialState;
    },
  },
});

const loadGeo = createAction(`${DOMAIN}/loadGeo`);
const loadMasters = createAction(`${DOMAIN}/loadMasters`);
const loadMoreMasters = createAction(`${DOMAIN}/loadMoreMasters`);

export const Actions = {
  ...slice.actions,
  loadGeo,
  loadMasters,
  loadMoreMasters,
};
export default slice.reducer;
