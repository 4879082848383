import React from "react";
import Layout from "@/layouts/Root";
import * as S from "./index-styled";
import loadable from "@loadable/component";
import { Helmet } from "_c/Helmet";
import { Container } from "reactstrap";

const Content = loadable(() => import("./content"));

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Helmet title="Политика конфиценциальности Simpo" />
      <Container style={{ position: "relative", zIndex: 21 }}>
        <S.Content>
          <Content />
        </S.Content>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;
