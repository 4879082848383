import { createSelector } from "@reduxjs/toolkit";
import last from "lodash/last";

const getReviews = state => state.reviews.items;
const isLoading = state => state.reviews.loading;
const isMore = state => state.reviews.isMore;
const getLastReview = createSelector(getReviews, reviews => {
  return last(reviews);
});
const getReview = state => state.reviews.review;
const getReviewMaster = state => state.reviews.reviewMaster;
const getReviewSaloon = state => state.reviews.reviewSaloon;
const getError = state => state.reviews.error;
export const Selectors = {
  getReview,
  isMore,
  getLastReview,
  getReviewMaster,
  getReviewSaloon,
  getReviews,
  isLoading,
  getError
};
