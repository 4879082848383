import React from "react";
import Info from "_c/atoms/Info";
import { CircleButton } from "_c/button";
import RefreshIcon from "_c/icon/Refresh";
import { Translate } from "@/i18n/utils";
import styled from "styled-components";
import type { TFunction } from "i18next";

const StyledInfo = styled(Info)`
  position: relative;
  padding-top: 30px;
`;

type tProps = {
  t: TFunction;
  error: any;
  className?: string;
  refetch: () => any;
};
const QueryError = ({ refetch, t, error, className }: tProps) => {
  return (
    <StyledInfo className={className} isError>
      <CircleButton
        type="button"
        small
        light
        css={`
          --size: 24px;
          color: black !important;
          position: absolute;
          right: 6px;
          top: 6px;
        `}
        onClick={() => refetch()}
      >
        <RefreshIcon width="18" />
      </CircleButton>
      <span>{Translate.serverError(t, error?.message ?? error)}</span>
    </StyledInfo>
  );
};

export default QueryError;
