import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const MasterClients = loadable(
  () =>
    import(
      /* webpackChunkName: "master-clients-page" */
      "./"
    ),
  {
    ssr: false,
    fallback: <Spin size="xl" />,
  }
);

export default MasterClients;
