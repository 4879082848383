export const SUPPORTED_CURRENCIES = [
  "RUB",
  "USD",
  "EUR",
  "UAH",
  "AUD",
  "GBP",
  "KZT",
  "TJS",
  "TMT",
  "BYR",
  "GEL",
  "DKK",
  "NOK",
  "NZD",
  "RON",
  "CHF",
  "CNY",
  "HKD",
  "VND",
  "BRL",
  "AMD",
  "AED",
  "PLN",
  "MDL",
  "BGN",
  "UZS",
  "INR",
  "MXN",
  "CAD",
  "KGS",
];
export const DEFAULT_CURRENCY = "USD";
