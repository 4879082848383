import loadable from "@loadable/component";

const MasterInitialSetupModalLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: "master-initial-setup-modal-global" */
      "./Global"
    ),
  {
    ssr: false,
    fallback: null,
  }
);

export default MasterInitialSetupModalLoadable;
