import React from "react";
import { Container, ContentContainer } from "./styled";
import { useLocation } from "react-router-dom";
import { scheduleBase } from "@/store/profileLinks/selectors";

const ProfileLayout = (props) => {
  const loc = useLocation();
  // this is just for one f shadow in the mobile scheduler
  const isSchedulePage = loc.pathname === scheduleBase;
  return (
    <Container fluid>
      <ContentContainer removedPadOnMobile={isSchedulePage}>
        {props.children}
      </ContentContainer>
    </Container>
  );
};
export default ProfileLayout;
