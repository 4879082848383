import React, { useMemo } from "react";
import { ConfigAPI } from "@/api/config";
import { QueryKeys } from "@/network/queryKeys";
import { AppConfigService } from "@/services/appConfig";
import { useQuery } from "react-query";

export const AppConfigCtx = React.createContext(
  AppConfigService.normalizeConfig()
);
const AppConfigProvider = (props: { children: any }) => {
  const { data } = useQuery(QueryKeys.appCfg, ConfigAPI.getAppConfig, {
    cacheTime: Infinity,
  });
  const cfg = useMemo(() => {
    return AppConfigService.normalizeConfig(data);
  }, [data]);
  return (
    <AppConfigCtx.Provider value={cfg}>{props.children}</AppConfigCtx.Provider>
  );
};

export default AppConfigProvider;
