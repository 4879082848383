import { PremiumAPI } from "@/api/premium";
import { Actions } from "./slice";
import { analyticsAction } from "@/utils/analytics";

const activateTrial = (role) => async (dispatch) => {
  const { data } = await PremiumAPI.activateTrial();
  analyticsAction({
    category: "Premium",
    action: role === "saloon" ? "user_premium" : "premium_test",
  });
  dispatch(Actions.setPremium(data));
};
const cancel = () => async (dispatch) => {
  await PremiumAPI.cancel();
  dispatch(Actions.globalReset());
};

export const Effects = {
  activateTrial,
  cancel,
};
