import React from "react";
import PT from "prop-types";
import Avatar from "_c/Avatar";
import * as S from "./styled";

const UserCard = (props) => {
  return (
    <S.Wrap style={props.style} onClick={props.onClick} role={props.role} as={props.as}>
      <Avatar
        size="md"
        online={props.online}
        css={`
          margin-inline-end: 10px;
        `}
        avatar={props.avatar}
        sex={props.sex}
      />{" "}
      <div
        css={`
          > * {
            margin-bottom: 3px;
          }
        `}
      >
        <div
          css={`
            font-weight: 700;
          `}
        >
          {props.header}
        </div>
        {props.subheader && (
          <div
            css={`
              font-size: 14px;
            `}
          >
            {props.subheader}
          </div>
        )}
        {props.extra}
      </div>
    </S.Wrap>
  );
};
UserCard.propTypes = {
  avatar: PT.string,
  sex: PT.bool,
  online: PT.bool,
  header: PT.string.isRequired,
  subheader: PT.any,
  extra: PT.any,
  role: PT.string,
  as: PT.string,
  style: PT.object,
  onClick: PT.func,
};

export default UserCard;
