import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "_p/blog/container";
import Editor from "_p/blog/editor/container";
import Article from "_p/blog/article/container";
import Layout from "@/layouts/Blog";
import NotFound from "_p/404";

const BlogContainer = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/blog/editor/:slug?" component={Editor} />
        <Route exact path="/blog/article/:slug" component={Article} />
        <Route exact path="/blog" component={Home} />
        <Route
          render={({ staticContext }) => {
            if (staticContext) {
              staticContext.notFound = true;
            }
            return <NotFound />;
          }}
        />
      </Switch>
    </Layout>
  );
};

export default BlogContainer;
