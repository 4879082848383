import React from "react";
import { createSelector } from "@reduxjs/toolkit";
import { Selectors as SaloonSelectors } from "@/store/saloonDashboard/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as SettingsSelectors } from "@/store/settings/selectors";
import { Selectors as MessengerSelectors } from "@/store/messenger/selectors";
import { Selectors as ScheduleSelectors } from "@/store/scheduler/selectors";
import OrdersPageIcon from "_c/icon/OrdersPage";
import SettingsPageIcon from "_c/icon/SettingsPage";
import ScheduleIcon from "_c/icon/SchedulePage";
import ChartIcon from "_c/icon/Chart";
import FavoritesPageIcon from "_c/icon/FavoritesPage";
import ShoppingIcon from "_c/icon/Shopping";
import ClientsPageIcon from "_c/icon/ClientsPage";
import MessengerPageIcon from "_c/icon/MessengerPage";
import SearchIcon from "_c/icon/Search";
import AchieveIcon from "_c/icon/Achieves";
import SaloonDashboardIcon from "_c/icon/SaloonDashboard";
import MasterPageIcon from "_c/icon/MasterPage";
import BonusPageIcon from "_c/icon/BonusPage";
import BlogIcon from "_c/icon/Blog";
import SmsMessageIcon from "_c/icon/SmsPage";
import NotesPageIcon from "_c/icon/NotesPage";
import qs from "query-string";
import { LinkGenerator } from "@/utils/LinkGenerator";
import CheckmarkIcon from "_c/icon/TodosPage";
import DashboardIcon from "_c/icon/Dashboard";
import WalletIcon from "_c/icon/Wallet";
import WarehousePageIcon from "_c/icon/WarehousePage";
import PremiumPageIcon from "_c/icon/PremiumPageIcon";
import EmployeesPageIcon from "_c/icon/EmployeesPageIcon";

const isMasterSelector = UserSelectors.isBusinessMode;

// const getRoutePrefix = createSelector(isMasterSelector, (isMaster) => {
//   return isMaster ? "/master" : "/client";
// });

const APP_BASE = "/app";
const getRoutePrefix = () => APP_BASE;

// warehouse
const getWarehouseChildren = createSelector([getRoutePrefix], (routePrefix) => {
  const base = routePrefix + "/warehouse";
  const baseKey = "warehouse";
  return [
    {
      route: `${base}/products`,
      key: `${baseKey}.products`,
    },
    {
      route: `${base}/transactions`,
      key: `${baseKey}.transactions`,
    },
  ];
});
const getWarehouse = createSelector(getRoutePrefix, (routePrefix) => {
  return {
    route: routePrefix + "/warehouse",
    key: "warehouse",
    Icon: WarehousePageIcon,
  };
});
const getWarehouseWithChildren = createSelector(
  [getWarehouse, getWarehouseChildren],
  (link, children) => {
    return {
      ...link,
      children,
    };
  }
);

// orders
const getOrdersChildren = createSelector(
  [getRoutePrefix, isMasterSelector],
  (routePrefix, isMaster) => {
    const base = routePrefix + "/orders";
    const baseKey = "orders";
    return [
      {
        route: `${base}/all`,
        key: `${baseKey}.all`,
      },
      {
        route: `${base}/confirm`,
        key: `${baseKey}.confirm`,
      },
      {
        route: `${base}/active`,
        key: `${baseKey}.active`,
      },
      {
        route: `${base}/completed`,
        key: `${baseKey}.completed`,
      },
      {
        route: `${base}/canceled`,
        key: `${baseKey}.canceled`,
      },
      {
        route: `${base}/personal`,
        key: `${baseKey}.personal`,
      },
      //{
      //  route: `${base}/need_to_pay`,
      //  key: `${baseKey}.need_to_pay`,
      //},
      //{
      //  route: `${base}/dispute`,
      //  key: `${baseKey}.dispute`,
      //},
      isMaster && {
        route: `${base}/my_orders`,
        key: `${baseKey}.my_orders`,
      },
    ].filter(Boolean);
  }
);
const getOrders = createSelector(
  [getRoutePrefix, isMasterSelector],
  (routePrefix, isMaster) => {
    return {
      route: routePrefix + "/orders",
      key: "orders",
      Icon: (props) => <OrdersPageIcon {...props} isFilled={!isMaster} />,
    };
  }
);
const getOrdersWithChildren = createSelector(
  [getOrders, getOrdersChildren],
  (link, children) => {
    return {
      ...link,
      children,
    };
  }
);

// settings
const getSettingsChildren = createSelector(
  [
    getRoutePrefix,
    UserSelectors.isSaloonOwner,
    UserSelectors.isPremium,
    SettingsSelectors.isCareerVisited,
    SettingsSelectors.isNotificationsVisited,
    UserSelectors.isBusinessMode,
  ],
  (
    routePrefix,
    isSaloonOwner,
    isPremium,
    isCareerVisited,
    isNotificationsVisited,
    isBusinessMode
  ) => {
    const base = routePrefix + "/settings";
    const baseKey = "settings";
    return [
      {
        route: `${base}/common`,
        key: `${baseKey}.common`,
        children: [
          {
            route: `${base}/notifications`,
            key: `${baseKey}.notifications`,
            cta: !isNotificationsVisited,
          },
          {
            route: `${base}/finance`,
            key: `${baseKey}.finance`,
          },
          {
            route: `${base}/locale`,
            key: `${baseKey}.locale`,
          },
          {
            route: `${base}/premium`,
            key: `${baseKey}.premium`,
            cta: !isPremium,
          },
        ].filter(Boolean),
      },
      isBusinessMode && {
        route: `${base}/career`,
        key: `${baseKey}.career`,
        cta: !isCareerVisited,
        children: [
          {
            route: `${base}/business_hours`,
            key: `${baseKey}.business_hours`,
          },
          {
            route: `${base}/templates`,
            key: `${baseKey}.templates`,
          },
        ],
      },
      {
        route: `${base}/create_master`,
        key: `${baseKey}.create_master`,
      },
      !isSaloonOwner && {
        route: `${base}/salon`,
        key: `${baseKey}.salon`,
      },
      isBusinessMode && {
        route: `${base}/marketplace`,
        key: `${baseKey}.marketplace`,
      },

      // {
      //   route: `${base}/rest`,
      //   key: `${baseKey}.rest`,
      // },
      // !isMaster && {
      //   route: `${base}/feed`,
      //   key: `${baseKey}.feed`,
      // },
      //{
      //  route: `${base}/appearance`,
      //  key: `${baseKey}.appearance`,
      //},
    ].filter(Boolean);
  }
);
const getSettings = createSelector([getRoutePrefix], (routePrefix) => {
  return {
    route: routePrefix + "/settings",
    key: "settings",
    Icon: SettingsPageIcon,
  };
});
const getSettingsWithChildren = createSelector(
  [getSettings, getSettingsChildren],
  (link, children) => {
    return {
      ...link,
      children,
    };
  }
);

// salon dashboard
const getSaloonDashboardChildren = createSelector(
  [SaloonSelectors.getSaloonPolicies, SaloonSelectors.isSaloonPremium],
  (policies, isPremium) => {
    const base = "/salon/dashboard";
    const baseKey = "salon";
    return [
      {
        route: `${base}/schedule`,
        key: `${baseKey}.schedule`,
        Icon: ScheduleIcon,
      },
      {
        route: `${base}/clients`,
        key: `${baseKey}.clients`,
        Icon: ClientsPageIcon,
      },
      {
        route: `${base}/warehouse`,
        key: `${baseKey}.warehouse`,
        Icon: WarehousePageIcon,
      },
      {
        route: `${base}/stats`,
        key: `${baseKey}.stats`,
        Icon: ChartIcon,
      },
      {
        route: `${base}/employees`,
        key: `${baseKey}.employees`,
        Icon: EmployeesPageIcon,
      },
      {
        route: `${base}/finance`,
        key: `${baseKey}.finance`,
        Icon: WalletIcon,
      },
      policies.premium && {
        route: `${base}/premium`,
        key: `${baseKey}.premium`,
        Icon: PremiumPageIcon,
        cta: !isPremium,
      },
      {
        route: `${base}/bonus`,
        key: "bonuses",
        Icon: BonusPageIcon,
        i18nFullPath: "common:profile_links.bonuses",
      },
      policies.smsAccount && {
        route: `${base}/sms-notifications`,
        key: "sms-notifications",
        Icon: SmsMessageIcon,
        i18nFullPath: "common:profile_links.sms_notifications",
      },
      {
        route: `${base}/settings`,
        key: `${baseKey}.settings`,
        Icon: SettingsPageIcon,
      },
    ].filter(Boolean);
  }
);
const getSaloonDashboard = createSelector(
  UserSelectors.isSaloonOwnerOrAdmin,
  (isSaloonRelated) => {
    if (!isSaloonRelated) return null;
    return {
      route: "/salon/dashboard",
      key: "salon",
      Icon: SaloonDashboardIcon,
    };
  }
);
const getSaloonDashboardWithChildren = createSelector(
  [getSaloonDashboard, getSaloonDashboardChildren],
  (link, children) => {
    if (!link) return null;
    return {
      ...link,
      children,
    };
  }
);

// schedule
export const scheduleBase = `${APP_BASE}/schedule`;
const scheduleBaseKey = "schedule";
const getSchedule = createSelector(
  [ScheduleSelectors.isCalendarVisited],
  (isCalendarVisited) => {
    return {
      route: scheduleBase,
      key: scheduleBaseKey,
      Icon: ScheduleIcon,
      cta: !isCalendarVisited,
    };
  }
);

// finance
const financeBase = `${APP_BASE}finance`;
const financeBaseKey = "finance";
const financeChildren = [
  {
    route: `${financeBase}/stats`,
    key: `${financeBaseKey}.stats`,
  },
  //{
  //  route: `${financeBase}/clients`,
  //  key: `${financeBaseKey}.clients`,
  //},
];
const getFinanceChildren = () => {
  return financeChildren;
};

const getFinance = createSelector(
  getRoutePrefix,
  isMasterSelector,
  (routePrefix, isMaster) => {
    return {
      route: routePrefix + "/finance",
      key: "finance",
      Icon: isMaster ? ChartIcon : WalletIcon,
    };
  }
);
const getFinanceWithChildren = getFinance;
//const getFinanceWithChildren = createSelector(
//  [getFinance, getFinanceChildren],
//  (link, children) => {
//    if (!link) return null;
//    return {
//      ...link,
//      children,
//    };
//  }
//);

// favorites
const getFavoritesChildren = createSelector(getRoutePrefix, (routePrefix) => {
  const base = routePrefix + "/favorites";
  const baseKey = "favorites";
  return [
    {
      route: `${base}/users`,
      key: `${baseKey}.users`,
    },
    {
      route: `${base}/portfolio`,
      key: `${baseKey}.portfolio`,
    },
    {
      route: `${base}/articles`,
      key: `${baseKey}.articles`,
    },
    {
      route: `${base}/saloons`,
      key: `${baseKey}.saloons`,
    },
  ].filter(Boolean);
});
const getFavorites = createSelector(getRoutePrefix, (routePrefix) => {
  return {
    route: routePrefix + "/favorites",
    key: "favorites",
    Icon: FavoritesPageIcon,
  };
});
const getFavoritesWithChildren = createSelector(
  [getFavorites, getFavoritesChildren],
  (link, children) => {
    return {
      ...link,
      children,
    };
  }
);

// bonus
const getBonusesChildren = createSelector(getRoutePrefix, (routePrefix) => {
  const base = routePrefix + "/bonus";
  const baseKey = "bonus";
  return [
    {
      route: `${base}/partners`,
      key: `${baseKey}.partners`,
    },
    {
      route: `${base}/account`,
      key: `${baseKey}.account`,
    },
  ];
});
const getBonuses = createSelector(getRoutePrefix, (routePrefix) => {
  return {
    route: routePrefix + "/bonus",
    key: "bonuses",
    Icon: BonusPageIcon,
  };
});
const getBonusesWithChildren = createSelector(
  [getBonuses, getBonusesChildren],
  (link, children) => {
    return {
      ...link,
      children,
    };
  }
);

// clients
const clientsBase = APP_BASE + "/clients";
const clientsBaseKey = "clients";
const clientsChildren = [
  {
    route: `${clientsBase}/list`,
    key: `${clientsBaseKey}.clients`,
  },
  {
    route: `${clientsBase}/invites`,
    key: `${clientsBaseKey}.invites`,
  },
];
const clientsList = {
  ...clientsChildren[0],
  key: "clients",
  Icon: ClientsPageIcon,
};
const getClientsList = () => clientsList;
const getClientsChildren = () => {
  return clientsChildren;
};
const getClients = createSelector(
  getRoutePrefix,
  isMasterSelector,
  (routePrefix, isMaster) => {
    if (!isMaster) return null;
    return {
      route: routePrefix + "/clients",
      key: "clients",
      Icon: ClientsPageIcon,
    };
  }
);
const getClientsWithChildren = createSelector(
  [getClients, getClientsChildren],
  (link, children) => {
    if (!link) return null;
    return {
      ...link,
      children,
    };
  }
);

// messenger
const getMessenger = createSelector(
  [getRoutePrefix, MessengerSelectors.hasFreshMessages],
  (routePrefix, hasFreshMessages) => {
    return {
      route: routePrefix + "/messages",
      key: "messages",
      Icon: MessengerPageIcon,
      cta: hasFreshMessages,
    };
  }
);

// achieves
const getAchieves = createSelector(getRoutePrefix, (routePrefix) => {
  return {
    route: routePrefix + "/achievements",
    key: "achievements",
    Icon: AchieveIcon,
  };
});

// profile
const getProfile = createSelector(
  [
    UserSelectors.getUserId,
    UserSelectors.getMasterId,
    UserSelectors.isBusinessMode,
  ],
  (userId, masterId, isBusinessMode) => {
    return {
      route:
        masterId && isBusinessMode
          ? LinkGenerator.masterProfile(masterId)
          : LinkGenerator.clientProfile(userId),
      key: "profile",
      Icon: MasterPageIcon,
    };
  }
);

// client discounts
const clientDiscountsChildren = [
  {
    route: "/client/discounts/all",
    key: "discounts.all",
  },
  {
    route: "/client/discounts/single_service",
    key: "discounts.single_service",
  },
  {
    route: "/client/discounts/all_services",
    key: "discounts.all_services",
  },
];
const getClientDiscountsChildren = () => {
  return clientDiscountsChildren;
};
const getClientDiscounts = createSelector(isMasterSelector, (isMaster) => {
  if (isMaster) return null;
  return {
    route: "/client/discounts",
    key: "discounts",
    Icon: ShoppingIcon,
  };
});
const getClientDiscountsWithChildren = createSelector(
  [getClientDiscounts, getClientDiscountsChildren],
  (link, children) => {
    if (!link) return null;
    return {
      ...link,
      children,
    };
  }
);

// blog
const blog = {
  route: "/blog",
  key: "blog",
  Icon: BlogIcon,
};
const getBlog = () => {
  return blog;
};

// search
const getSearch = createSelector(
  [
    UserSelectors.isMaster,
    UserSelectors.getCityLocation,
    UserSelectors.getLocationHr,
  ],
  (isMaster, loc, locHr) => {
    if (isMaster) return null;
    return {
      Icon: SearchIcon,
      key: "search",
      route: qs.stringifyUrl({
        url: "/search",
        query: {
          lat: loc?.coordinates?.[1],
          lng: loc?.coordinates?.[0],
          address_label: locHr,
        },
      }),
    };
  }
);

// sms notifications
const smsNotificationsBase = `${APP_BASE}/sms-notifications`;
const smsNotificationsBaseKey = "sms_notifications";
const smsNotificationsChildren = [
  {
    route: `${smsNotificationsBase}/account`,
    key: `${smsNotificationsBaseKey}.account`,
  },
  {
    route: `${smsNotificationsBase}/history`,
    key: `${smsNotificationsBaseKey}.history`,
  },
  {
    route: `${smsNotificationsBase}/templates`,
    key: `${smsNotificationsBaseKey}.templates`,
  },
];
const getSmsNotificationsChildren = () => {
  return smsNotificationsChildren;
};

const getSmsNotifications = createSelector([isMasterSelector], (isMaster) => {
  if (!isMaster) return null;
  return {
    route: smsNotificationsBase,
    key: smsNotificationsBaseKey,
    Icon: SmsMessageIcon,
  };
});
const getSmsNotificationsWithChildren = createSelector(
  [getSmsNotifications, getSmsNotificationsChildren],
  (link, children) => {
    if (!link) return null;
    return {
      ...link,
      children,
    };
  }
);

const getTodos = createSelector(getRoutePrefix, (prefix) => {
  return {
    route: prefix + "/todos",
    key: "todos",
    Icon: CheckmarkIcon,
  };
});

const getNotes = createSelector(getRoutePrefix, (prefix) => {
  return {
    route: prefix + "/notes",
    key: "notes",
    Icon: NotesPageIcon,
  };
});

const getDashboard = createSelector(getRoutePrefix, (prefix) => ({
  route: prefix + "/dashboard",
  key: "dashboard",
  Icon: DashboardIcon,
}));

export const Selectors = {
  getRoutePrefix,

  getOrders,
  getOrdersChildren,
  getOrdersWithChildren,

  getSettingsChildren,
  getSettings,
  getSettingsWithChildren,

  getSaloonDashboard,
  getSaloonDashboardChildren,
  getSaloonDashboardWithChildren,

  getSchedule,

  getFinance,
  getFinanceChildren,
  getFinanceWithChildren,

  getFavorites,
  getFavoritesChildren,
  getFavoritesWithChildren,

  getBonuses,
  getBonusesChildren,
  getBonusesWithChildren,

  getClients,
  getClientsList,
  getClientsChildren,
  getClientsWithChildren,

  getMessenger,

  getAchieves,

  getProfile,

  getClientDiscounts,
  getClientDiscountsChildren,
  getClientDiscountsWithChildren,

  getSmsNotifications,
  getSmsNotificationsChildren,
  getSmsNotificationsWithChildren,

  getBlog,

  getSearch,

  getTodos,

  getNotes,

  getDashboard,

  getWarehouse,
  getWarehouseChildren,
  getWarehouseWithChildren,
};
