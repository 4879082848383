import { useCallback } from "react";
import { MaterialsAPI } from "@/api/materials";

export const useLoadBrandOptions = () => {
  return useCallback(async (brand) => {
    const { data } = await MaterialsAPI.getBrandCompletion(brand);
    return data.map(({ title }) => ({
      value: title,
      label: title,
    }));
  }, []);
};
