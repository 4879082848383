import { API_URL } from "@/misc/constants";
import { get, post } from "./index";

const BASE = `${API_URL}/externalMessenger`;

const getMySub = () => get(`${BASE}/mySubscription`, { cache: false });
const getSubPageMeta = (id) => get(`${BASE}/${id}/page`, { cache: false });
const unsub = (id, messenger) => post(`${BASE}/${id}/unsubscribe/${messenger}`);
export const ExtMessengerAPI = {
  getMySub,
  getSubPageMeta,
  unsub,
};
