import React, { useRef, useState } from "react";
import * as S from "./styled";
import Lock from "_c/ScrollLock";
import {
  useMotionValue,
  useTransform,
  AnimatePresence,
  motion,
  LayoutGroup,
} from "framer-motion";
import DenyIcon from "_c/icon/Deny";
import { createPortal } from "react-dom";
import PT from "prop-types";

const rootVariants = {
  initial: { y: "100%", opacity: 0 },
  animate: { y: "0%", opacity: 1 },
  exit: { opacity: 0, y: "100%" },
};
const contentVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};
const rootTransition = {
  duration: 0.2,
};
const contentTransition = {
  duration: 0.1,
};

const Main = (props) => {
  const y = useMotionValue(-props.defaultHeight);

  const height = useTransform(y, (y) => {
    return -y + 1;
  });
  const onDragEnd = (_e, { velocity }) => {
    if (velocity.y > 100) {
      props.onClose();
    }
  };

  return (
    <S.Wrap>
      <S.Header
        dragConstraints={props.constraintsRef}
        dragMomentum={true}
        onDragEnd={onDragEnd}
        style={{ y }}
        drag="y"
      >
        {props.header}
        <button onClick={props.onClose}>
          <DenyIcon color="currentColor" />
        </button>
      </S.Header>
      <S.Content data-scroll-lock-scrollable style={{ height }}>
        {props.showChildren && (
          <motion.div
            key="bottom-sheet-content"
            transition={contentTransition}
            variants={contentVariants}
            style={{ height: "100%" }}
          >
            {props.children}
          </motion.div>
        )}
      </S.Content>
    </S.Wrap>
  );
};
const BottomSheet = (props) => {
  const constraintsRef = useRef(null);
  const [showChildren, setShowChildren] = useState(false);
  const onAnimationComplete = () => {
    setTimeout(() => {
      setShowChildren((show) => !show);
    }, 1);
  };
  const portalFn = props.disablePortal ? (el) => el : createPortal;
  return portalFn(
    <AnimatePresence>
      {props.isOpen && (
        <>
          <div
            onClick={props.onClose}
            ref={constraintsRef}
            style={{
              pointerEvents: "none",
              zIndex: props.zIndex - 1,
            }}
            css={`
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            `}
          />
          <LayoutGroup id="bottom-sheet">
            <S.MotionWrap
              style={{ zIndex: props.zIndex }}
              exit="exit"
              initial="initial"
              animate="animate"
              variants={rootVariants}
              transition={rootTransition}
              onAnimationComplete={onAnimationComplete}
              key="bottom-sheet-wrap"
              defaultWidth={props.defaultWidth}
              mobileBp={props.mobileBp}
            >
              <Lock />
              <Main
                constraintsRef={constraintsRef}
                defaultHeight={props.defaultHeight}
                header={props.header}
                showChildren={showChildren}
                onClose={props.onClose}
              >
                {props.children}
              </Main>
            </S.MotionWrap>
          </LayoutGroup>
        </>
      )}
    </AnimatePresence>,
    document.getElementById("root")
  );
};
BottomSheet.propTypes = {
  isOpen: PT.bool,
  defaultHeight: PT.number,
  zIndex: PT.number,
  disablePortal: PT.bool,

  onClose: PT.func.isRequired,
};
BottomSheet.defaultProps = {
  defaultWidth: 600,
  mobileBp: 650,
  zIndex: 1002,
  defaultHeight: 300,
};
export default BottomSheet;
