import React from "react";
import Tooltip from "_c/Tooltip";
import PT from "prop-types";
import { StyledRoot } from "./styled";

const ns = "common:footer.apps";
const AppStoreLink = ({ t, className }) => {
  return (
    <StyledRoot className={className}>
      <Tooltip title={t(`${ns}.tooltip_appstore`)}>
        <img src="/images/apple-store.svg" alt="app store" />
      </Tooltip>
    </StyledRoot>
  );
};
AppStoreLink.propTypes = {
  t: PT.func.isRequired,
};

export default AppStoreLink;
