import React from "react";
import { useFormContext } from "react-hook-form";
import TextInput from "_c/form/TextInput";
import { i18nNs } from "./config";

const AmountField = ({ id, t, name }) => {
  const methods = useFormContext();
  const value = methods.watch(name);
  const unit = methods.watch("product")?.unit;
  return (
    <div
      css={`
        display: flex;
        align-items: center;
      `}
    >
      <TextInput
        type="number"
        onChange={(e) => methods.setValue(name, e.target.value)}
        required
        value={value}
        placeholder={t(i18nNs + "qty_holder")}
        id={id}
        min={0}
      />
      {unit && (
        <span
          css={`
            margin-inline-start: 10px;
            flex: 1;
            color: var(--font-color-main);
          `}
        >
          {unit}
        </span>
      )}
    </div>
  );
};

export default AmountField;
