import React, { useEffect, useState, useRef, useCallback } from "react";
import ee from "@/utils/ee";
import Popup from "./index";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";

const HIDE_TIMEOUT = 4500;
const AchievePopupContainer = (props) => {
  const [payload, setPayload] = useState(null);
  const timeoutRef = useRef();
  useEffect(() => {
    ee.on("new_achieve", (payload) => {
      setPayload(payload);
      timeoutRef.current = setTimeout(() => {
        setPayload(null);
      }, [HIDE_TIMEOUT]);
    });
  }, []);
  const onClose = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setPayload(null);
  }, []);
  return (
    <AnimatePresence>
      {payload ? (
        <motion.div
          style={{ zIndex: 100 }}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Popup
            locale={props.locale}
            rank={payload.rank}
            onClose={onClose}
            achieve={payload.achieve}
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
const mSp = (state) => ({
  locale: UserSelectors.getLocale(state),
});

export default connect(mSp)(AchievePopupContainer);
