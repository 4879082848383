import React from "react";
import PT from "prop-types";
import Modal from "_c/Modals";
import SimpleTodoCreatorLoadable from "./loadable";

const SimpleTodoCreatorModal = ({ isOpen, ...props }) => {
  return (
    <Modal
      css={`
        height: 100%;
      `}
      onClose={props.onClose}
      isOpen={isOpen}
    >
      <SimpleTodoCreatorLoadable {...props} />
    </Modal>
  );
};
SimpleTodoCreatorModal.propTypes = {
  isOpen: PT.bool.isRequired,

  onClose: PT.func.isRequired,
};
export default SimpleTodoCreatorModal;
