import React, { useMemo } from "react";
import { useAppConfig } from "@/hooks/useAppConfig";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { Selectors } from "@/store/prefs/selectors";
import { useSelector } from "react-redux";
import { royalViolet } from "@/styles/theme";
import { useLocale } from "@/hooks/useLocale";
import { RTL_LOCALES } from "@/config";

const ThemeProvider = (props: { children: any }) => {
  const appCfg = useAppConfig();
  const { theme: themePref, color_scheme: schemePref } = useSelector(
    Selectors.getAllPrefs
  );
  const locale = useLocale();
  const theme = useMemo(() => {
    const theme = themePref || appCfg.default_theme;
    const scheme = schemePref || appCfg.default_color_scheme;
    let themeToReturn;
    if (scheme === "dark") {
      themeToReturn = appCfg.themes.find(({ id }) => id === "dark");
    } else {
      themeToReturn =
        appCfg.themes.find(({ id }) => id === theme) ||
        appCfg.themes.find((theme) => theme.__name__ === scheme);
    }
    themeToReturn = themeToReturn || appCfg.themes[0] || royalViolet;
    return {
      ...themeToReturn,
      rtl: RTL_LOCALES.has(locale),
    };
  }, [appCfg, themePref, schemePref, locale]);
  return (
    <div dir={theme.rtl ? "rtl" : "ltr"}>
      <SCThemeProvider theme={theme}>{props.children}</SCThemeProvider>
    </div>
  );
};

export default ThemeProvider;
