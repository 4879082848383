import s from "styled-components";

export const Wrap = s.div`
  overflow: hidden;
`;
export const Content = s.div`
  min-height: 100vh;
  margin-top: 35px;
  background: ${({ theme }) => {
    if (theme.__name__ === "light") {
      return "url(/blobs/blog/bg_3.svg) no-repeat 105% 40px, url(/blobs/blog/bg_2.svg) no-repeat -40% 60px";
    }
    return "none";
  }};
  background-size: 50%;
  margin-bottom: 120px;
  @media(max-width: 991px) {
    background: none;
  }
  @media(max-width: 767px) {
    margin-bottom: 15px;
  }
  @media(max-width: 475px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
