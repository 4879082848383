import React from "react";
import Modal from "_c/Modals";
import Main from "./main";
import { tWarehouseProductEditorProps } from "./types";

type tProps = tWarehouseProductEditorProps & {
  isOpen: boolean;
  onClose: tVoidFn;
};
const WarehouseProductEditorModal = ({ isOpen, ...props }: tProps) => {
  return (
    <Modal
      css={`
        height: 100%;
      `}
      onClose={props.onClose}
      isOpen={isOpen}
    >
      <Main {...props} />
    </Modal>
  );
};
export default WarehouseProductEditorModal;
