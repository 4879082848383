import React from "react";
import * as S from "./styled";
import { Container, Col, Row } from "reactstrap";
import { Button } from "_c/button";
import { Link } from "react-router-dom";
import { Helmet } from "_c/Helmet";
import { useTranslation } from "react-i18next";
import SpecsList from "_c/SpecsList";

const NotFoundPage = () => {
  const { t, ready } = useTranslation(["errors", "common"], {
    useSuspense: false,
  });
  const err = t("errors:page_not_found");
  if (!ready) return null;
  return (
    <S.Wrap>
      <Helmet title={err} />
      <SpecsList />
      <S.Main>
        <Container>
          <Row>
            <Col lg={{ offset: 3, size: 6 }}>
              <S.Hero>
                <h2>{err}</h2>
                <div>
                  <img src="/images/zigzag.png" role="presentation" />
                </div>
                <Button as={Link} to="/" large>
                  {t("common:go_home")}
                </Button>
              </S.Hero>
            </Col>
          </Row>
        </Container>
      </S.Main>
      <S.SearchWrap>
        <S.Search />
      </S.SearchWrap>
    </S.Wrap>
  );
};

export default NotFoundPage;
