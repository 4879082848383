import { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { MasterAPI } from "@/api/master";
import { ClientsCacheService } from "@/services/clients/cache";
import { queryClient } from "../network/queryClient";
import { QueriesAPI } from "@/api/queries";
import { TaskEditorCacheService } from "@/services/taskEditor/cache";

export const usePrefetchOftenUsedData = () => {
  const store = useStore();
  const isAuth = useSelector(AuthSelectors.isAuth);
  useEffect(() => {
    if (isAuth) {
      const state = store.getState();
      const masterId = UserSelectors.getMasterId(state);
      const isBiz = UserSelectors.isBusinessMode(state);
      if (masterId && isBiz) {
        queryClient.prefetchQuery(
          TaskEditorCacheService.buildQueryKey(masterId),
          () => QueriesAPI.taskEditor({ master: masterId })
        );
        queryClient.prefetchQuery(
          ClientsCacheService.buildListQueryKey({
            domain: "master",
            masterId,
          }),
          () => MasterAPI.getClients(masterId).then(({ data }) => data)
        );
      }
    }
  }, [isAuth]);
};
