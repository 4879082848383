import { ConfigAPI } from "@/api/config";
import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";
import { dark, royalViolet } from "@/styles/theme";
import { createTheme } from "@/styles/theme";
import type { tAppConfig } from "@/types/appConfig";

const defaultConfig: tAppConfig = {
  premium_only__tasks_with_clients: false,
  premium_only__accounting: false,
  premium_only__todo_attachments: false,
  premium_only__calendar_event_photos: false,
  premium_only__manual_client_notification: false,
  premium_only__todo_progress_indicator: false,
  premium_only__org: false,
  premium_only__scheduler_day_stats: false,
  premium_only__copy_calendar_event: false,
  premium_only__copy_task_with_client: false,
  nopremium__todos_per_day_limit: false,
  nopremium__subtodos_per_todo_limit: 7,
  premium_only__biz_tasks_per_day_limit: false,
  premium_only__events_per_day_limit: false,
  global_entity_fab_actions: ["todo", "expense"],
  global_entity_fab_actions_biz: [
    "task_business",
    "todo",
    "task_private",
    "client",
    "service",
    "expense",
  ],
  color_picker_palette: ["#FF6900", "#FCB900", "#00D084"],
  enable_app_rate_prompt: true,
  enable_app_survey_prompt: true,
  todo_editor_nav_order: [
    "list",
    "date",
    "reminder",
    "priority",
    "recurrence",
    "location",
    "color",
  ],
  todo_max_attachments: 10,
  calendar_event_max_photos: 10,
  default_color_scheme: "light",
  default_theme: "royal_violet",
  themes: [royalViolet, dark],
  biz_client_sources: [],
};

const normalizeConfig = (cfg?: tAppConfig): tAppConfig => {
  if (!cfg) return defaultConfig;
  return {
    ...defaultConfig,
    ...cfg,
    ...(cfg?.themes ? { themes: cfg.themes.map(createTheme) } : {}),
  };
};
export const AppConfigService = {
  async getConfig(): Promise<tAppConfig> {
    const cfg = await queryClient.fetchQuery(
      QueryKeys.appCfg,
      ConfigAPI.getAppConfig,
      {
        cacheTime: Infinity,
      }
    );
    return normalizeConfig(cfg);
  },
  normalizeConfig,
};
