import React from "react";

const ChartIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M8.73 5.1a.8.8 0 0 1 .215 1.581c-3.119.659-5.346 3.398-5.338 6.566-.008.247.007.495.043.74.385 3.163 2.964 5.615 6.161 5.859 3.198.243 6.123-1.79 6.988-4.858l.037-.1a.808.808 0 0 1 .945-.448c.208.049.386.18.495.362.11.182.14.4.084.604a8.265 8.265 0 0 1-4.024 5.06 8.35 8.35 0 0 1-7.825.119 8.248 8.248 0 0 1-4.447-6.398 5.122 5.122 0 0 1-.061-.966C1.998 9.299 4.766 5.914 8.63 5.113Zm4.712-2.6c4.275.125 7.86 3.244 8.547 7.437h.008v.14l.003.146c-.011.34-.141.669-.37.926a1.485 1.485 0 0 1-1.026.495l-6.575.436h-.105a1.6 1.6 0 0 1-1.595-1.463l-.439-6.54a1.466 1.466 0 0 1 .412-1.115 1.548 1.548 0 0 1 1.14-.462Zm.044 1.585.438 6.427 6.487-.426c-.606-3.367-3.486-5.862-6.925-6Z"
    />
  </svg>
);
export default ChartIcon;
