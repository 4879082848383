import React from "react";
import * as S from "./styled";
import PT from "prop-types";
import { generateAvatar } from "@/utils";

const Avatar = (props) => {
  const src = generateAvatar({
    sex: props.sex,
    width: 200,
    height: 200,
    url: props.avatar,
    reverseColors: props.reverseColors,
  });
  return (
    <S.Wrap
      onClick={props.onClick}
      style={props.style}
      className={props.className}
    >
      <S.Avatar
        online={props.online}
        size={props.size}
        src={src}
        alt="avatar"
      />
      {props.online !== undefined && (
        <S.Online size={props.size} online={props.online} />
      )}
    </S.Wrap>
  );
};
Avatar.propTypes = {
  online: PT.bool,
  avatar: PT.string,
  style: PT.object,
  sex: PT.bool,
  size: PT.oneOf(["xs", "sm", "md", "lg"]),
  className: PT.string,
  reverseColors: PT.bool,
  onClick: PT.func,
};
Avatar.defaultProps = {
  size: "md",
};

export default Avatar;
