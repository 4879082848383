import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const PartnersPage = loadable(
  () => import(/* webpackChunkName: "partners-page" */ "_p/partners"),
  {
    fallback: <Spin size="xl" />,
  }
);

export default PartnersPage;
