import { get } from "./";
import { API_URL } from "@/misc/constants";

const findBySlug = (slug) => {
  return get(`${API_URL}/city/slug/${slug}`);
};

export const CitiesAPI = {
  findBySlug,
};
