import React from "react";
import Layout from "@/layouts/Profile";
import Favorites from "_p/favorites/container";
import Orders from "_p/orders/container";
import Messages from "_p/messages/container";
import Schedule from "_p/schedule/container";
import Profile from "_p/master/container";
import Bonus from "_p/bonus/container";
import Settings from "_p/settings/container";
import Finance from "_p/finance/container";
import SmsNotifications from "_p/sms-notifications/container";
import { Route } from "react-router-dom";
import Achieves from "_p/achieves/container";
import Clients from "_p/masterClients/container";
import Dashboard from "_p/dashboard/container";
import Todos from "_p/todos/container";
import Notes from "_p/notes/container";
import Warehouse from "_p/warehouse/container";

const MasterContainer = (props) => {
  const base = props.match.url;
  return (
    <Layout>
      <Route key="profile" exact path={`${base}`} component={Profile} />
      <Route key="orders" path={`${base}/orders/:mode?`} component={Orders} />
      <Route
        key="sms-notifications"
        path={`${base}/sms-notifications/:mode?`}
        component={SmsNotifications}
      />
      <Route key="messages" path={`${base}/messages`} component={Messages} />
      <Route
        key="favorites"
        path={`${base}/favorites/:type?`}
        component={Favorites}
      />
      <Route
        key="clients"
        path={`${base}/clients/:mode?`}
        component={Clients}
      />
      <Route
        key="schedule"
        path={`${base}/schedule/:mode?`}
        component={Schedule}
      />
      <Route key="bonus" path={`${base}/bonus/:mode?`} component={Bonus} />
      <Route
        key="settings"
        path={`${base}/settings/:mode?`}
        component={Settings}
      />
      <Route
        key="finance"
        path={`${base}/finance/:mode?`}
        component={Finance}
      />
      <Route
        key="achievements"
        path={`${base}/achievements`}
        component={Achieves}
      />
      <Route key="dashboard" path={`${base}/dashboard`} component={Dashboard} />
      <Route key="todos" path={`${base}/todos`} component={Todos} />
      <Route key="notes" path={`${base}/notes`} component={Notes} />
      <Route
        key="warehouse"
        path={`${base}/warehouse/:child?`}
        component={Warehouse}
      />
    </Layout>
  );
};
export default MasterContainer;
