import { useQuery } from "react-query";
import { SaloonAPI } from "@/api/saloon";
import { SaloonsCacheService } from "@/services/saloons/cache";
import { useDispatch } from "react-redux";
import { Actions } from "@/store/saloonDashboard/slice";
import { useEffect } from "react";

export const useMySaloonsQuery = () => {
  const dispatch = useDispatch();
  const query = useQuery(
    SaloonsCacheService.buildMySaloonsQueryKey(),
    () => SaloonAPI.getMySaloons().then((d) => d.data),
    {
      cacheTime: Infinity,
    }
  );
  const data = query.data;
  useEffect(() => {
    if (data) {
      dispatch(Actions.setSaloons(data));
    }
  }, [data]);
  return query;
};
