import { connect } from "react-redux";
//import { sessionLogin, afterLogin, addAffiliateData } from "_a/auth";
import { Selectors } from "@/store/auth/selectors";
import { Effects as AuthEffects } from "@/store/auth/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as PrefsSelectors } from "@/store/prefs/selectors";
import HomePage from "./loadable";

const mSp = (state) => ({
  isAuth: Selectors.isAuth(state),
  profileLink: UserSelectors.getProfileLink(state),
  initialScreen: PrefsSelectors.getInitialScreen(state),
});
const mDp = {
  sessionLogin: AuthEffects.sessionLogin,
  openAuthModal: ModalActions.openAuth,
  //addAffiliateData,
};

export default connect(mSp, mDp)(HomePage);
