import React from "react";
export const ShoppingSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    stroke={props.color || "currentColor"}
    strokeWidth="1.5"
    fill="none"
    {...props}
  >
    <path d="M21 21H3L5 3h14l2 18z" strokeLinecap="round"></path>
    <path
      d="M16 7v2a3.9996 3.9996 0 01-1.1716 2.8284 3.9996 3.9996 0 01-5.6568 0A3.9998 3.9998 0 018 9V7"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
export default ShoppingSvg;
