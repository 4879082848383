import { TasksCacheService } from "./cache";
import { SchedulerCacheService } from "../scheduler/cache";
import { FreeSlotsCacheService } from "../freeSlots/cache";

export const TaskMutationHandler = {
  // this doesn't fire on the server, client only event
  multipleTasksCreated({ tasks }) {
    SchedulerCacheService.addTasks(tasks);
    // TODO:: there should be a better strategy
    TasksCacheService.invalidateAll();
    FreeSlotsCacheService.invalidateAll();
  },
  taskCreated({ task }) {
    SchedulerCacheService.addTasks([task]);
    TasksCacheService.addTask(task);
    FreeSlotsCacheService.invalidateAll();
  },
  taskUpdated({ updated_task: task, task: oldTask }) {
    TasksCacheService.replaceTask(task, oldTask);
    SchedulerCacheService.replaceTask(task, oldTask);
    FreeSlotsCacheService.invalidateAll();

    if (!oldTask) return;

    // TODO:: complex case, let's just invalidate everything by now
    if ((oldTask.client || task.client) && oldTask.client !== task.client) {
      TasksCacheService.invalidateClientHistory();
    }
  },
  taskCancelled({ task }) {
    const updates = {
      status: "CANCELLED",
      cancel_reason: task.cancel_reason,
    };

    TasksCacheService.updateTask(task._id, updates);
    SchedulerCacheService.removeTask(task);
    FreeSlotsCacheService.invalidateAll();
  },
  taskAccepted({ task }) {
    TasksCacheService.replaceTask(task);
    SchedulerCacheService.updateTask(task._id, task);
    FreeSlotsCacheService.invalidateAll();
  },
  taskRemoved({ task }) {
    TasksCacheService.removeTask(task);
    SchedulerCacheService.removeTask(task);
    FreeSlotsCacheService.invalidateAll();
  },
  taskCompleted({ task }) {
    const updates = { status: "DONE" };
    SchedulerCacheService.updateTask(task, updates);
    TasksCacheService.updateTask(task._id, updates);
  },
  taskNoteUpdated({ task_id, updates }) {
    TasksCacheService.updateTask(task_id, updates);
    SchedulerCacheService.updateTask(task_id, updates);
  },
  taskPhotoRequested({ task_id, updates }) {
    TasksCacheService.updateTask(task_id, updates);
    SchedulerCacheService.updateTask(task_id, updates);
  },
};
