import React from "react";
const RefreshSvg = (props) => (
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C8.68994 6 6 8.69 6 12H9L5 16L1 12H4C4 7.57999 7.57996 4 12 4C13.5699 4 15.03 4.45999 16.26 5.23999L14.8 6.70001C13.97 6.25 13.01 6 12 6ZM15 12L19 8L23 12H20C20 16.42 16.42 20 12 20C10.4299 20 8.96997 19.54 7.73999 18.76L9.19995 17.3C10.03 17.75 10.99 18 12 18C15.3099 18 18 15.31 18 12H15Z"
      fill={props.color || "currentColor"}
    />
  </svg>
);
export default RefreshSvg;
