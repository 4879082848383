import { WarehouseTxnsCacheService } from "./cache";
import { WarehouseProductMutationHandler } from "../products/mutationHandler";
import type { iWarehouseProduct, iWarehouseTxn } from "@/types";

type tCommonData = { txn: iWarehouseTxn; product?: iWarehouseProduct };
export const WarehouseTxnMutationHandler = {
  txnCreated(data: tCommonData) {
    if (data.txn) {
      WarehouseTxnsCacheService.addTxn(data.txn);
    }
    if (data.product) {
      WarehouseProductMutationHandler.productUpdated({ product: data.product });
    }
  },
  txnUpdated(data: tCommonData) {
    if (data.txn) {
      WarehouseTxnsCacheService.replaceTxn(data.txn);
    }
    if (data.product) {
      WarehouseProductMutationHandler.productUpdated({ product: data.product });
    }
  },
  txnRemoved(data: tCommonData) {
    if (data.txn) {
      WarehouseTxnsCacheService.removeTxn(data.txn);
    }
    if (data.product) {
      WarehouseProductMutationHandler.productUpdated({ product: data.product });
    }
  },
  txnCancelled(data: tCommonData) {
    if (data.txn) {
      WarehouseTxnsCacheService.replaceTxn(data.txn);
    }
    if (data.product) {
      WarehouseProductMutationHandler.productUpdated({ product: data.product });
    }
  },
};
