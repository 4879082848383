import React from "react";

const SchedulePageIcon = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 24 24" width="24" {...props}>
    <path
      fill={color}
      d="M3.007 8.772a5.307 5.307 0 0 1 .837-3.144l.155-.227.013-.012.108-.127.113-.13.013-.013c.216-.222.456-.417.714-.583l.073-.046.073-.043.171-.099c.07-.033.145-.072.215-.105.05-.02.101-.045.152-.065.095-.04.196-.079.297-.112a.518.518 0 0 1 .108-.04l.04-.016.045-.016a7.237 7.237 0 0 1 1.416-.277l-.05.006v-.995a.72.72 0 1 1 1.44 0v3.144a.72.72 0 1 1-1.44 0v-.727a4.741 4.741 0 0 0-1.376.351c-1.135.51-1.677 1.483-1.728 3.025l-.004.248v.387H19.61v-.397a4.412 4.412 0 0 0-.418-2.066 2.633 2.633 0 0 0-1.322-1.207 4.584 4.584 0 0 0-1.119-.32l-.25-.031v.733a.72.72 0 0 1-1.44 0v-.787H8.947v-1.43h6.113v-.927a.72.72 0 0 1 1.44-.01v1.003c.35.035.697.096 1.038.183l.339.096a.564.564 0 0 1 .085.026l.108.039c.1.033.194.072.29.112.056.02.108.045.151.065.076.033.153.072.215.105.063.033.124.07.177.099.054.029.095.062.14.089.194.125.378.267.55.422l.17.16.007.014.117.125.111.131.006.013c.647.894.995 1.98.996 3.094l-.007.279v.387H21v7.754c0 3.382-1.888 5.09-5.63 5.09H8.621C4.888 22 3 20.292 3 16.91V9.156h.007v-.384Zm13.109 7.474a.84.84 0 0 0-.833.85c.009.47.378.85.833.85.455 0 .824-.38.824-.85s-.37-.85-.824-.85Zm-4.112-.009a.852.852 0 0 0-.832.859c0 .47.377.85.832.85.455 0 .824-.38.824-.859a.855.855 0 0 0-.824-.85Zm-4.12 0a.834.834 0 0 0-.824.859c0 .47.37.85.824.85.455 0 .824-.39.824-.859s-.369-.85-.824-.85Zm8.232-3.62c-.464 0-.824.38-.824.85 0 .469.369.85.824.85.455 0 .824-.381.824-.85 0-.47-.37-.85-.824-.85Zm-4.112-.009a.852.852 0 0 0-.832.859c0 .469.377.85.832.85.455 0 .824-.381.824-.86 0-.46-.369-.84-.824-.849Zm-4.111 0c-.464 0-.833.39-.833.859s.37.85.824.85c.464 0 .833-.39.824-.86 0-.468-.369-.849-.815-.849Z"
    />
  </svg>
);
export default SchedulePageIcon;
