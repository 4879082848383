import React, { createRef } from "react";
import { connect } from "react-redux";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import PT from "prop-types";
import qs from "query-string";
import { withTranslation } from "react-i18next";
import { scrollToTop } from "@/utils";
import { withRouter } from "react-router-dom";
import { analyticsChangePage } from "@/utils/analytics";

const BANNED_SCROLL = /search/;
const ONLY_INITIAL_SCROLL = /orders|warehouse/;
class RouteWatcher extends React.Component {
  prevPathRef = createRef();
  handleScroll = (path) => {
    const prevPath = this.prevPathRef.current;
    if (BANNED_SCROLL.test(path)) {
      return;
    }
    if (
      ONLY_INITIAL_SCROLL.test(path) &&
      prevPath &&
      ONLY_INITIAL_SCROLL.test(prevPath)
    ) {
      return;
    }
    scrollToTop();
  };
  listener = (loc) => {
    analyticsChangePage();
    const path = loc.pathname;
    this.handleScroll(path);
    const parsedQuery = qs.parse(loc.search);
    const { hl: locale } = parsedQuery;
    if (locale && this.props.locale !== locale) {
      this.props.i18n.changeLanguage(locale);
    }
    this.prevPathRef.current = loc.pathname;
  };
  componentDidMount() {
    this.prevPathRef.current = window.location.pathname;
    this.props.history.listen(this.listener);
  }
  render() {
    return null;
  }
}
RouteWatcher.propTypes = {
  locale: PT.string,
};

const mSp = (state) => ({
  locale: LocaleSelectors.getLocale(state),
});
export default withRouter(connect(mSp)(withTranslation([])(RouteWatcher)));
