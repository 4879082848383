import React, { useMemo } from "react";
import Label from "_c/form/Label";
import FormSect from "_c/form/Sect";
import Paper from "_c/Paper";
import { render } from "micromustache";
import type { TFunction } from "i18next";

type tProps = {
  t: TFunction;
  ns: string;
  placeholders: tStringDict;
  message: string;
};

const MessagePreview = ({ t, ns, message, placeholders }: tProps) => {
  const preview = useMemo(() => {
    try {
      return render(message, placeholders);
    } catch (e) {
      return message;
    }
  }, [message, placeholders]);
  return (
    <FormSect>
      <Label as="div">{t(`${ns}.msg_preview`)}</Label>
      <Paper
        css={`
          white-space: pre-wrap;
          margin: 0;
          font-weight: 400;
          background-color: var(--bg-elevate);
        `}
      >
        {preview ? preview : t(`${ns}.msg_empty`)}
      </Paper>
    </FormSect>
  );
};

export default MessagePreview;
