import React from "react";
import * as S from "./index-styled";
import { Link } from "react-router-dom";
import PT from "prop-types";
import TelegramIcon from "_c/icon/Telegram";
import LocaleSwitch from "_c/LocaleSwitch";

const FooterMiddle = ({ t }) => (
  <S.MiddleWrap>
    <Link to="/" title={t("common:go_home")}>
      <S.Logo />
    </Link>
    <S.Socials>
      <S.Social>
        <a href="https://t.me/SimpoApp" target="_blank" title="Telegram">
          <TelegramIcon width="30" />
        </a>
      </S.Social>
    </S.Socials>
    <LocaleSwitch
      showFullNameOfActiveLanguage
      css={`
        margin-top: 15px;
      `}
    />
  </S.MiddleWrap>
);
FooterMiddle.propTypes = {
  t: PT.func.isRequired,
};

export default FooterMiddle;
