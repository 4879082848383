import "@/momentLocales";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "../common/store";
import App from "../common/containers/app";
import { useSSR } from "react-i18next";
import { loadableReady } from "@loadable/component";
import rootReducer from "../common/store/rootReducer";
import { HelmetProvider } from "react-helmet-async";
import { PROD } from "@/misc/constants";
import { QueryClientProvider, Hydrate } from "react-query";
import { queryClient } from "@/network/queryClient";
import { setupSw } from "./setupSw";
import { changeMomentLocale } from "@/utils/time";
import { initI18n } from "./initI18n";

const lang = window.__lang;
const i18n = initI18n(lang);
changeMomentLocale(lang);
const store = createStore(window.__PRELOADED_STATE__);
window.__reduxStore = store;
if (PROD) {
  console.log("^-^ Production ^-^");
}
const BaseApp = () => {
  useSSR(window.__i18Store, lang, { i18n });
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={window.__REACT_QUERY_STATE__}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </Hydrate>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
loadableReady().then(() => {
  hydrate(<BaseApp />, document.getElementById("root"));
});
if (module.hot) {
  module.hot.accept("../common/store/rootReducer", () =>
    store.replaceReducer(rootReducer)
  );
  module.hot.accept();
}

if (process.env.NODE_ENV === "production") {
  setupSw();
}

(async () => {
  if ("serviceWorker" in navigator) {
    window.__pushWorker__ = await navigator.serviceWorker.register(
      "/sw-push.js",
      {
        scope: "/scope",
      }
    );
  }
})();
