import React from "react";

function SvgComponent({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 99.99"
      {...props}
    >
      <path d="M29 51.27l2.74-2.74A18.78 18.78 0 005.16 75.08l2.74-2.74A14.91 14.91 0 0129 51.27zm22.48 17.02L48.73 71a14.91 14.91 0 01-21.07 21.1l-2.74 2.74a18.78 18.78 0 0026.56-26.55zm7.3-20.11a4.63 4.63 0 01-.6.74 4.94 4.94 0 01-2.75 1.39 8.93 8.93 0 01-2.17 9.07l-31.91 31.9L24.09 94 56 62.12a12.84 12.84 0 002.78-13.94zm-11.84-4.05a9 9 0 012.75.43 4.94 4.94 0 011.39-2.74 4.63 4.63 0 01.74-.6A12.86 12.86 0 0037.88 44L6 75.91l2.74 2.73 31.9-31.9a8.9 8.9 0 016.3-2.61z" />
      <path d="M51.08 41.82a5.07 5.07 0 012.23-1.27A18.36 18.36 0 0156 18.05L70.74 3.31a1.93 1.93 0 00.57-1.37A1.94 1.94 0 0068 .57L53.26 15.31a22.24 22.24 0 00-3 27.66 4.8 4.8 0 01.82-1.15zm48.35-12.56a2 2 0 00-2.74 0L82 44a18.31 18.31 0 01-22.5 2.7 4.94 4.94 0 01-1.27 2.22 5.13 5.13 0 01-1.15.84A22.07 22.07 0 0069 53.25a22.07 22.07 0 0015.71-6.51L99.43 32a1.93 1.93 0 000-2.74z" />
      <path d="M59.2 40.8a6.46 6.46 0 00-9.15 0 6.47 6.47 0 004.58 11 6.46 6.46 0 004.57-11zm-2.05 7.09a3.56 3.56 0 11-5-5 3.57 3.57 0 016.09 2.52 3.54 3.54 0 01-1.09 2.48zm-5.74-29.32v3.64c4.17.27 10.57 4.17 16.39 10s9.71 12.22 10 16.39h3.64c-.26-5.09-4.44-12.3-11.12-18.92S56.48 18.82 51.41 18.57zm24.22 5.8c5.39 5.39 9.31 11 11.45 16.23l8.11-4.81c0-11.29-19.69-31-31-31L59.66 13c5.16 2.18 10.67 6.07 15.97 11.37z" />
    </svg>
  );
}

export default SvgComponent;
