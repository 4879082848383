import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarLink = styled(Link)`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.35s ease;
  :hover {
    opacity: 0.7;
  }
`;

export const Navbar = styled.nav`
  color: white;
  margin: 0 auto;
  padding-top: 20px;
`;
export const NavbarList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  a {
    color: ${({ color }) => color};
  }
`;
export const NavBarLi = styled.li`
  margin: 10px;
  margin-top: 0;
  margin-bottom: 5px;
`;
