import { createSelector } from "@reduxjs/toolkit";

const isLoading = (state) => state.eventNotifications.loading;
const isMore = (state) => state.eventNotifications.more;
const getError = (state) => state.eventNotifications.error;
const getNotifications = (state) => state.eventNotifications.items;
const getFresh = (state) => state.eventNotifications.fresh;
const getFreshCount = createSelector(getFresh, (fresh) => fresh.length);
const isFreshEmpty = createSelector(getFreshCount, (count) => count === 0);
const getNotificationsCount = createSelector(getNotifications, (n) => n.length);
const isNotificationsEmpty = createSelector(
  getNotificationsCount,
  (l) => l === 0
);
const getNotificationsWithFreshParam = createSelector(
  [getNotifications, getFresh],
  (notifications, fresh) => {
    return notifications.map((notif) => ({
      ...notif,
      fresh: fresh.includes(notif._id),
    }));
  }
);

export const Selectors = {
  getError,
  getNotifications,
  getNotificationsWithFreshParam,
  getNotificationsCount,
  getFresh,
  getFreshCount,
  isFreshEmpty,
  isLoading,
  isMore,
  isNotificationsEmpty,
};
