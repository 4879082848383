import React from "react";
import QueryRequest from "./";
import { Header as ModalHeader, Content as ModalContent } from "_c/Modals";
import { useTranslation } from "react-i18next";
import type { tProps as tQueryRequestProps } from "./index";

type tProps = {
  query: tQueryRequestProps["query"];
  children: any;
  onClose: tVoidFn;
};

const QueryRequestWithModal = ({ query, onClose, children }: tProps) => {
  const { t, ready } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  if (!ready) return null;
  const queries = Array.isArray(query) ? query : [query];
  if (queries.every((query) => query.status === "success")) {
    return children;
  }
  return (
    <>
      <ModalHeader onClick={() => onClose()}>{t("common:close")}</ModalHeader>
      <ModalContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <QueryRequest query={query}>{null}</QueryRequest>
      </ModalContent>
    </>
  );
};

export default QueryRequestWithModal;
