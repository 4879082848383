import { Effects as FavEffects } from "@/store/favorites/effects";
import { Actions } from "./slice";
import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { PortfolioAPI } from "@/api/portfolio";
import { CommentsAPI } from "@/api/comments";
import pick from "lodash/pick";
import { analyticsAction } from "@/utils/analytics";

const toggleLike = (id) => (dispatch, getState) => {
  const isFav = FavSelectors.isFavorite(getState(), "portfolio", id);
  if (isFav) {
    dispatch(FavEffects.removeFavorite("portfolio", id));
    dispatch(Actions.removeLike(id));
  } else {
    dispatch(Actions.addLike(id));
    dispatch(FavEffects.addFavorite("portfolio", id));
  }
};
const addPortfolio = (payload) => async (dispatch) => {
  const { data } = await PortfolioAPI.createPortfolio(payload);
  if (payload.as === "master") {
    analyticsAction({
      category: "master",
      action: "master_add_photo",
    });
  }
  dispatch(Actions.addPortfolio(data));
  return Promise.resolve(data);
};
const removePortfolio = (item) => async (dispatch) => {
  if (item.isCreator) {
    await PortfolioAPI.deleteById(item._id);
  } else {
    await PortfolioAPI.deleteMe(item._id);
  }
  dispatch(Actions.removePortfolio(item._id));
};
const addComment = (id, payload) => async (dispatch, getState) => {
  const user = UserSelectors.getProfile(getState());
  const { data } = await CommentsAPI.createComment("portfolio", id, payload);
  dispatch(Actions.addComment(id));
  return Promise.resolve({
    ...data,
    created_at: new Date().toISOString(),
    author: {
      ...pick(user, [
        "first_name",
        "last_name",
        "_id",
        "sex",
        "avatar",
        "online",
      ]),
      master: user?.master?._id,
    },
  });
};
const removeComment = (id, commentId) => async (dispatch) => {
  await CommentsAPI.deleteComment("portfolio", id, commentId);
  dispatch(Actions.removeComment(id));
};
const loadSinglePortfolio = (id) => async (dispatch) => {
  try {
    dispatch(Actions.loadSingleStart());
    const { data } = await PortfolioAPI.findById(id);
    dispatch(Actions.loadSingleSuccess(data));
  } catch (e) {
    dispatch(Actions.loadSingleError(e));
  }
};

export const Effects = {
  loadSinglePortfolio,
  addComment,
  removeComment,
  addPortfolio,
  removePortfolio,

  toggleLike,
};
