import type { iWarehouseProduct, IWarehouseTxnEmbeddedProduct } from "@/types";
import { WarehouseTxnsCacheService } from "../txns/cache";
import { WarehouseProductsCacheService } from "./cache";
import pick from "lodash/pick";

export const WarehouseProductMutationHandler = {
  productCreated(data: { product: iWarehouseProduct } | iWarehouseProduct) {
    // @ts-ignore
    const product: iWarehouseProduct = data.product || data;
    WarehouseProductsCacheService.addProduct(product);
  },
  productUpdated(data: {
    product: iWarehouseProduct;
    txns_been_updated?: boolean;
    txns_embedded_product_fields?: (keyof iWarehouseProduct)[];
  }) {
    if (data.txns_been_updated) {
      const embeddedProduct = {
        ...pick(data.product, data.txns_embedded_product_fields as any),
        _id: data.product._id,
      } as IWarehouseTxnEmbeddedProduct;
      WarehouseTxnsCacheService.updateEmbeddedProduct(embeddedProduct);
    }
    WarehouseProductsCacheService.replaceProduct(data.product);
  },
  productRemoved(data: { product: iWarehouseProduct }) {
    WarehouseProductsCacheService.removeProduct(data.product);
    WarehouseTxnsCacheService.invalidateAll();
  },
};
