import React, { useCallback } from "react";
import { Selectors } from "@/store/modals/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "@/store/modals/slice";
import WarehouseTxnEditorModal from "./";

const WarehouseTxnEditorGlobalModal = () => {
  const isOpen = useSelector(Selectors.isWarehouseTxnEditorOpen);
  const props = useSelector(Selectors.getWarehouseTxnEditorParams);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(Actions.closeWarehouseTxnEditor());
  }, []);
  return (
    <WarehouseTxnEditorModal onClose={onClose} isOpen={isOpen} {...props} />
  );
};

export default WarehouseTxnEditorGlobalModal;
