import { connect } from "react-redux";
import MasterClients from "./loadable";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";

const mSp = (state) => ({
  menu: LinksSelectors.getClientsChildren(state),
});

const connected = connect(mSp)(MasterClients);
export default connected;
