import React, { useRef } from "react";
import * as S from "./styled";
import { useTranslation } from "react-i18next";
import { useNotify } from "@/hooks/useNotify";
import { useDispatch } from "react-redux";
import TextInput from "_c/form/TextInput";
import Label from "_c/form/Label";
import ButtonGroup from "_c/form/ButtonGroup";
import { Button } from "_c/button";
import { Effects as MessengerEffects } from "@/store/messenger/effects";
import { TECH_USER_ID } from "@/misc/constants";

const SupportModal = (props) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { t, ready } = useTranslation(["common", "errors"]);
  const { notify, notifyError } = useNotify();
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        MessengerEffects.createMessageOutsideMessenger(
          TECH_USER_ID,
          inputRef.current.value
        )
      );
      notify(t("common:message_submit_success"));
      props.onClose();
    } catch (e) {
      notifyError(`errors:${e}`);
    }
  };
  if (!ready) return null;
  return (
    <S.Wrap>
      <form onSubmit={onSubmit}>
        <Label htmlFor="support-msg">{t("common:support_form.label")}</Label>
        <TextInput
          rows="7"
          required
          placeholder={t("common:support_form.placeholder")}
          ref={inputRef}
          id="support-msg"
          as="textarea"
        />
        <ButtonGroup>
          <Button greenish small>
            {t("common:support_form.submit_btn")}
          </Button>
          <Button type="button" small negative onClick={() => props.onClose()}>
            {t("common:support_form.close_btn")}
          </Button>
        </ButtonGroup>
      </form>
    </S.Wrap>
  );
};

export default SupportModal;
