import React from "react";
import PT from "prop-types";
import Modal from "_c/Modals";
import AccountingTxnEditorLoadable from "./loadable";
import { usePreloadModules } from "@/hooks/usePreloadModules";
import type { tAbstractModalProps } from "@/types/ui";
import type { tRootProps } from "./types";

type tProps = tAbstractModalProps<tRootProps>;
const modules = [AccountingTxnEditorLoadable];
const AccountingTxnEditorModal = (props: tProps) => {
  usePreloadModules({
    modules,
    delay: 250,
  });
  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen}>
      <AccountingTxnEditorLoadable {...props} />
    </Modal>
  );
};
AccountingTxnEditorModal.propTypes = {
  isOpen: PT.bool.isRequired,

  onClose: PT.func.isRequired,
};
export default AccountingTxnEditorModal;
