import * as React from "react";

function SvgHairdresser({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 97.29 100"
      {...props}
    >
      <path d="M81.53 45.73C88.19 29.13 95.35 2.63 87.32 0l-14.9 28.38zM58.59 72.67a44.56 44.56 0 015-7.63c-2.2-.08-4.34-.9-7.3-5.88l-7.11 13.51zM64 59.07c7.18-.66 13.13 9.06 15.5 13.6h9.4L50.78 0C39 3.87 60 59.44 64 59.07zm5-13.7a5 5 0 11-5 5 5 5 0 015.05-5z" />
      <path d="M52.58 100a10.58 10.58 0 01-2.85-.38c-6.67-1.78-10.41-9.49-8.35-17.18 1.75-6.53 7.08-11.09 13-11.09a10.94 10.94 0 012.85.38c6.66 1.78 10.41 9.49 8.35 17.18-1.78 6.53-7.11 11.09-13 11.09zm1.77-23.65c-3.57 0-7 3.1-8.14 7.38-1.35 5 .81 10 4.81 11.07a6.3 6.3 0 001.56.2c3.57 0 7-3.1 8.14-7.38 1.35-5-.81-10-4.81-11.06a5.87 5.87 0 00-1.56-.21zM85.52 100c-5.89 0-11.22-4.56-13-11.09-2.06-7.69 1.68-15.4 8.35-17.18a10.94 10.94 0 012.85-.38c5.89 0 11.22 4.56 13 11.09a15.64 15.64 0 01-.8 10.61 11.64 11.64 0 01-7.55 6.57 11 11 0 01-2.85.38zm-1.77-23.65a5.87 5.87 0 00-1.56.21c-4 1.07-6.16 6-4.81 11.06C78.52 91.9 82 95 85.52 95a6.3 6.3 0 001.56-.2 6.77 6.77 0 004.31-3.8 10.63 10.63 0 00.5-7.22c-1.15-4.33-4.57-7.43-8.14-7.43zM25 17.21h11.88a2.39 2.39 0 000-4.77H25a3.84 3.84 0 010-7.67h11.88a2.39 2.39 0 000-4.77h-16.4C3.32.39-5 25 3.12 45.44c10.49 26.46-.93 31.19-.93 42.64a11.42 11.42 0 0022.83 0c0-11.45-11.41-16.18-4.15-33.55h16a2.39 2.39 0 000-4.77H25a3.83 3.83 0 110-7.66h11.88a2.39 2.39 0 000-4.77H25a3.84 3.84 0 010-7.67h11.88a2.39 2.39 0 000-4.77H25a3.83 3.83 0 110-7.66z" />
    </svg>
  );
}

export default SvgHairdresser;
