import { connect } from "react-redux";
import { Effects } from "@/store/masterProfile/effects";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors } from "@/store/masterProfile/selectors";
import MasterPage from "./loadable";

const mSp = (state) => ({
  isAuth: AuthSelectors.isAuth(state),
  profile: Selectors.getProfile(state),
  isOwner: Selectors.isOwner(state),
});

const mDp = {
  addToClientHistory: Effects.addToClientHistory,
};

export default connect(mSp, mDp)(MasterPage);
