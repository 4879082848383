import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Effects as AuthEffects } from "@/store/auth/effects";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { PROD } from "@/misc/constants";
export const useDevOnlyAutoAuth = () => {
  const isAuth = useSelector(AuthSelectors.isAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!PROD && !isAuth) {
      dispatch(AuthEffects.sessionLogin());
    }
  }, [isAuth]);
};
