import React from "react";
import { useTranslation } from "react-i18next";
import { Header, FooterV2, Content } from "_c/Modals";
import { useForm, FormContext } from "react-hook-form";
import {
  TextField,
  AbstractField,
  AbstractControlledField,
} from "_c/form/Field";
import { useMut } from "@/hooks/useMut";
import { WarehouseProductsService } from "@/services/warehouse";
import { useAvailableUnits } from "./hooks/useAvailableUnits";
import { AsyncCreatableSelect } from "_c/Select";
import { useLoadBrandOptions } from "./hooks/useLoadBrandOptions";
import Switch from "_c/Switch";
import TextareaAutosize from "react-textarea-autosize";
import ProductPhotoField from "./PhotoField";
import { inputMixin } from "_c/form/TextInput";
import { i18nNs } from "./config";
import CategoryField from "./CategoryField";
import ShortageAmountField from "./ShortageAmountField";
import AmountField from "./AmountField";
import { analyticsAction } from "@/utils/analytics";

const WarehouseProductEditor = (props) => {
  const { t, ready, i18n } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  const loadBrandOptions = useLoadBrandOptions();
  const saloon = props.product?.saloon || props.saloon;
  const master = props.product?.master || props.master;
  const isNew = !props.product;
  const mut = useMut(
    (data) => {
      if (props.product) {
        return WarehouseProductsService.updateProduct({
          product: props.product._id,
          data,
        });
      } else {
        return WarehouseProductsService.createProduct({ data });
      }
    },
    {
      t,
      onSuccess(data) {
        if (isNew && props.onCreate) {
          props.onCreate(data);
        }
        props.onClose();
      },
      notifyOnError: true,
    }
  );
  const methods = useForm({
    defaultValues: {
      title: props.product?.title || "",
      sku: props.product?.sku || "",
      photo: props.product?.photo || "",
      category: props.product?.category || null,
      amount: props.product?.amount,
      notify_on_shortage: props.product?.notify_on_shortage || false,
      shortage_amount: props.product?.shortage_amount || 0,
      brand: props.product?.brand
        ? { value: props.product.brand, label: props.product.brand }
        : "",
      note: props.product?.note || "",
      unit: props.product?.unit || "",
    },
  });
  const onSubmit = (form) => {
    mut.mutate({
      ...form,
      ...(isNew ? (saloon ? { saloon } : { master }) : {}),
      brand: form.brand?.value || "",
      category: form.category?._id || null,
      ...(isNew && { full_stock_amount: form.amount }),
    });
  };
  const availableUnits = useAvailableUnits({ i18n });
  const notifyOnShortage = methods.watch("notify_on_shortage");
  if (!ready) return null;
  const formId = "warehouse-product-form";
  return (
    <>
      <Header onClick={props.onClose}>{t("common:close")}</Header>
      <FormContext {...methods}>
        <Content
          onSubmit={methods.handleSubmit(onSubmit)}
          id={formId}
          as="form"
        >
          <ProductPhotoField isSaloon={!!saloon} />
          <AbstractControlledField
            id={formId + "-category"}
            name="category"
            t={t}
            label={t(i18nNs + "category")}
            Component={CategoryField}
            master={master}
            saloon={saloon}
          />
          <TextField
            t={t}
            id={formId + "-title"}
            name="title"
            label={t(i18nNs + "title")}
            autoComplete="off"
            maxLength="128"
            required
            isRequired
            placeholder={t(i18nNs + "title_holder")}
          />
          <AbstractField
            t={t}
            id={formId + "-unit"}
            name="unit"
            label={t(i18nNs + "unit")}
            required
            Component="select"
            className="simpo-native-select"
            isRequired
          >
            {availableUnits.map((u) => (
              <option key={u} value={u}>
                {u}
              </option>
            ))}
          </AbstractField>
          {isNew && (
            <AbstractControlledField
              Component={AmountField}
              t={t}
              id={formId + "-amount"}
              name="amount"
              label={t(i18nNs + "qty")}
            />
          )}
          <TextField
            t={t}
            id={formId + "-sku"}
            name="sku"
            label={t(i18nNs + "sku")}
            autoComplete="off"
            maxLength="128"
            placeholder={t(i18nNs + "sku_holder")}
          />
          <AbstractControlledField
            id={formId + "-brand"}
            name="brand"
            isClearable
            onChange={([brand]) => {
              if (brand) {
                analyticsAction({
                  category: "warehouse",
                  action: saloon
                    ? "good_brand_added_salon"
                    : "good_brand_added",
                });
              }
              return brand;
            }}
            formatCreateLabel={(name) => t("common:create_some", { name })}
            noOptionsMessage={() => t(i18nNs + "empty_brands")}
            t={t}
            label={t("common:brand")}
            placeholder=""
            cacheOptions
            Component={AsyncCreatableSelect}
            loadOptions={loadBrandOptions}
          />
          <AbstractControlledField
            id={formId + "-notify-on-shortage"}
            name="notify_on_shortage"
            t={t}
            label={t(i18nNs + "notify_on_shortage")}
            Component={Switch}
          />
          {notifyOnShortage && (
            <AbstractControlledField
              Component={ShortageAmountField}
              t={t}
              id={formId + "-shortage-amount"}
              name="shortage_amount"
              label={t(i18nNs + "shortage_amount")}
            />
          )}
          <AbstractField
            t={t}
            id={formId + "-note"}
            name="note"
            maxLength={1024}
            label={t(i18nNs + "note")}
            Component={TextareaAutosize}
            placeholder={t(i18nNs + "note_holder")}
            maxRows={10}
            css={`
              ${inputMixin}
            `}
            data-scroll-lock-scrollable
          />
        </Content>
      </FormContext>
      <FooterV2
        disabled={mut.isLoading}
        buttonType="submit"
        buttonForm={formId}
      >
        {props.product ? t("common:change") : t("common:add")}
      </FooterV2>
    </>
  );
};

export default WarehouseProductEditor;
