import React from "react";
import AuthModal from "_c/Modals/auth-modal";
import BasketModal from "_c/Modals/basket-modal/container";
import BookingModal from "_c/Modals/booking-modal/modal";
import ShareModal from "_c/Modals/share-modal";
import PremiumModal from "_c/Modals/premium-modal";
import CreateTaskModal from "_c/Modals/task-editor/GlobalModal";
import SupportModal from "_c/Modals/support-modal";
import SendNotificationModal from "_c/Modals/send-notification-modal";
import { connect } from "react-redux";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Selectors } from "@/store/modals/selectors";
import { FEAT_FLAG_MULTI_ITEM_BASKET } from "@/misc/featureFlags";
import MasterInitialSetupGlobalModal from "_c/Modals/master-user-initial-setup/Global.loadable";
import TodoEditor from "_c/Modals/simple-todo-creator/GlobalModal";
import NoteEditor from "_c/Modals/note-editor/GlobalModal";
import AccountingTxnEditor from "_c/Modals/accounting-txn-editor/GlobalModal";
import WarehouseProductEditor from "_c/Modals/warehouse-product-editor/GlobalModal";
import WarehouseTxnEditor from "_c/Modals/warehouse-txn-editor/GlobalModal";
import PhotosGallery from "@/components/Modals/photos-gallery/GlobalModal";
import ClientEditor from "@/components/Modals/client-editor-modal/GlobalModal";
import ExternalNotificationTmplEditor from "_c/Modals/exteral-notification-tmpl-editor/GlobalModal";

const ModalsContainer = (props) => (
  <>
    <AuthModal />
    {FEAT_FLAG_MULTI_ITEM_BASKET && <BasketModal />}
    <BookingModal
      isOpen={props.isBookingOpen}
      onClose={props.closeBookingModal}
    />
    <ShareModal
      isOpen={props.isShareOpen}
      onClose={props.closeShareModal}
      shareLink={props.shareLink}
    />
    <CreateTaskModal />
    <PremiumModal
      isOpen={props.isPremiumOpen}
      onClose={props.closePremiumModal}
    />
    <SupportModal
      isOpen={props.isSupportOpen}
      onClose={props.closeSupportModal}
    />
    <SendNotificationModal
      isOpen={props.isSendNotificationOpen}
      onClose={props.closeSendNotification}
    />
    <MasterInitialSetupGlobalModal />
    <TodoEditor />
    <NoteEditor />
    <AccountingTxnEditor />
    <WarehouseProductEditor />
    <WarehouseTxnEditor />
    <ClientEditor />
    <PhotosGallery />
    <ExternalNotificationTmplEditor />
  </>
);

const mSp = (state) => ({
  isBookingOpen: Selectors.isBookingOpen(state),
  isShareOpen: Selectors.isShareOpen(state),
  isPremiumOpen: Selectors.isPremiumOpen(state),
  isSupportOpen: Selectors.isSupportOpen(state),
  shareLink: Selectors.getShareLink(state),
  isSendNotificationOpen: Selectors.isSendNotificationOpen(state),
});
const mDp = {
  closeBookingModal: ModalActions.closeBooking,
  closePremiumModal: ModalActions.closePremium,
  closeShareModal: ModalActions.closeShare,
  closeSupportModal: ModalActions.closeSupport,
  closeSendNotification: ModalActions.closeSendNotification,
};

export default connect(mSp, mDp)(ModalsContainer);
