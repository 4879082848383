import { QueryKeys } from "@/network/queryKeys";
import { queryClient } from "@/network/queryClient";
import { iWarehouseProductCategory } from "@/types";

const buildAllCategoriesQueryKey = (args?: {
  saloon?: string;
  master?: string;
}) => {
  const base = QueryKeys.warehouseAllProductCategories;
  if (!args) return base;
  return [
    base,
    args.saloon ? { master: args.saloon } : { saloon: args.master },
  ];
};

const invalidateAll = () => {
  queryClient.invalidateQueries(buildAllCategoriesQueryKey());
};
export const WarehouseProductCategoriesCacheService = {
  buildAllCategoriesQueryKey,
  invalidateAll,
  addCategory(category: iWarehouseProductCategory) {
    queryClient.setQueryDataIfCached(
      buildAllCategoriesQueryKey({
        master: category.master,
        saloon: category.saloon,
      }),
      (list: iWarehouseProductCategory[]) => {
        return list.concat(category);
      }
    );
  },
  replaceCategory(category: iWarehouseProductCategory) {
    queryClient.setQueryDataIfCached(
      buildAllCategoriesQueryKey({
        master: category.master,
        saloon: category.saloon,
      }),
      (list: iWarehouseProductCategory[]) => {
        return list.map((old) => (old._id === category._id ? category : old));
      }
    );
  },
  removeCategory(category: iWarehouseProductCategory) {
    queryClient.setQueryDataIfCached(
      buildAllCategoriesQueryKey({
        master: category.master,
        saloon: category.saloon,
      }),
      (list: iWarehouseProductCategory[]) => {
        return list.filter((c) => c._id !== category._id);
      }
    );
  },
};
