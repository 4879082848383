import { DOMAIN } from "./slice";
import { COMMON_INITIAL_SCREENS, MASTER_INITIAL_SCREENS } from "@/config";
import { Selectors as UserSelectors } from "@/store/user/selectors";

const $ = (state) => state[DOMAIN];

const getAvailableInitialScreens = (state) => {
  const isBusinessMode = UserSelectors.isBusinessMode(state);
  const available = isBusinessMode
    ? MASTER_INITIAL_SCREENS
    : COMMON_INITIAL_SCREENS;
  if (UserSelectors.isSaloonOwnerOrAdmin(state)) {
    return available;
  }
  return available.filter((s) => s !== "salon");
};
const getInitialScreen = (state) => {
  const userId = UserSelectors.getUserId(state);
  if (!userId) return null;
  const pref = $(state).initial_screen;
  if (!pref) return null;
  const available = getAvailableInitialScreens(state);
  return available.find((screen) => screen === pref) || null;
};
const getOverallStatsNotification = (state) =>
  $(state).day_overall_stats_notification;
const getTodoCompletionProbTipVisibility = (state) =>
  $(state).todo_completion_prob_tip;
const getDailySleepMinutes = (state) => $(state).daily_sleep;

export const Selectors = {
  getAllPrefs: $,
  getInitialScreen,
  getAvailableInitialScreens,
  getOverallStatsNotification,
  getTodoCompletionProbTipVisibility,
  getDailySleepMinutes,
};
