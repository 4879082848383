import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Effects } from "@/store/locale/effects";
import { useDispatch } from "react-redux";

const LocaleChangeListener = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation([], {
    useSuspense: false,
  });
  useEffect(() => {
    const listener = (locale) => {
      dispatch(Effects.changeLocale(locale));
    };
    i18n.on("languageChanged", listener);
    return () => {
      i18n.off("languageChanged", listener);
    };
  }, []);
  return null;
};
export default LocaleChangeListener;
