import React from "react";
import { Button } from "_c/button";
import { useTranslation } from "react-i18next";
import { Helmet } from "_c/Helmet";
import { Selectors } from "@/store/msgrSubPage/selectors";
import { connect } from "react-redux";
import { FullScreenSpin as Spin } from "_c/preloader";

const Messenger = ({ link, icon, title }) => {
  return (
    <a
      css={`
        display: block;
        margin: 10px;
        img {
          display: inline-block;
          width: 50px;
        }
      `}
      href={link}
      title={title}
    >
      <img src={icon} />
    </a>
  );
};
const ns = "common:msgr_sub_page";
const connector = connect((state) => ({
  isLoading: Selectors.isLoading(state),
  data: Selectors.getData(state),
  ownerPhoto: Selectors.getOwnerPhoto(state),
  ownerName: Selectors.getOwnerName(state),
  ownerType: Selectors.getOwnerType(state),
  viberLink: Selectors.getViberLink(state),
  tgLink: Selectors.getTgLink(state),
  waLink: Selectors.getWaLink(state),
  isWaAvailable: Selectors.isWaAvailable(state),
  isTgAvailable: Selectors.isTgAvailable(state),
  isViberAvailable: Selectors.isViberAvailable(state),
  isAnyMessengerAvailable: Selectors.isAnyMessengerAvailable(state),
  error: Selectors.getError(state),
}));
const MessengerSubPage = (props) => {
  const { t, ready } = useTranslation("common", {
    useSuspense: false,
  });
  if (!ready || props.isLoading || props.error) {
    return <Spin />;
  }
  const title = t(`${ns}.title_${props.ownerType}`, { name: props.ownerName });
  const desc = t(`${ns}.desc`);
  return (
    <>
      <Helmet
        title={title}
        meta={[
          {
            property: "og:image",
            content: props.ownerPhoto,
          },
          {
            name: "description",
            content: desc,
          },
          {
            name: "robots",
            content: "nofollow",
          },
        ]}
        link={[
          {
            rel: "preload",
            href: "/icons/whatsapp.svg",
            as: "image",
          },
          {
            rel: "preload",
            href: "/icons/viber.svg",
            as: "image",
          },
          {
            rel: "preload",
            href: "/icons/telegram.svg",
            as: "image",
          },
        ]}
      />
      <div
        css={`
          padding: 10px;
          display: flex;
          flex-direction: column;
          margin: auto;
          color: var(--font-color-main);
        `}
      >
        {props.isAnyMessengerAvailable ? (
          <>
            <div
              css={`
                text-align: center;
                strong {
                  font-weight: 700;
                }
                p {
                  max-width: 500px;
                  margin-top: 10px;
                }
              `}
            >
              <strong>{title}</strong>
              <p>{desc}</p>
            </div>
            <div
              css={`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              `}
            >
              {props.isWaAvailable && (
                <Messenger
                  title="Whatsapp"
                  link={props.waLink}
                  icon="/icons/whatsapp.svg"
                />
              )}
              {props.isViberAvailable && (
                <Messenger
                  title="Viber"
                  link={props.viberLink}
                  icon="/icons/viber.svg"
                />
              )}
              {props.isTgAvailable && (
                <Messenger
                  title="Telegram"
                  link={props.tgLink}
                  icon="/icons/telegram.svg"
                />
              )}
            </div>
          </>
        ) : (
          <p>{t(`${ns}.no_opts`)}</p>
        )}
      </div>
    </>
  );
};
export default connector(MessengerSubPage);
