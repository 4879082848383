import { connect } from "react-redux";
import { Selectors } from "@/store/blog/selectors";
import { Effects } from "@/store/blog/effects";

const mSp = (state) => ({
  authorsLoading: Selectors.isBestAuthorsLoading(state),
  articlesLoading: Selectors.isPopularArticlesLoading(state),
  articles: Selectors.getPopularArticles(state),
  authors: Selectors.getBestAuthorsView(state),
});
const mDp = {
  loadPopularArticles: Effects.loadPopularArticles,
  loadBestAuthors: Effects.loadBestAuthors,
};

export default connect(mSp, mDp);
