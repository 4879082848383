import { keyframes, css } from "styled-components";
import appShellUiVars from "_c/AppShell/ui-vars";

const rippleKeyframes = keyframes`
 to {
    transform: scale3d(4,4,4);
    opacity: 0;
  }
`;
export const rippleMixin = css`
  backface-visibility: hidden;
  perspective: 1000;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  opacity: 1;
  transform: scale3d(0, 0, 0);
  will-change: transform;
  animation: ${rippleKeyframes} 650ms linear;
`;
export const viewportHeightMixin = css`
  height: 100vh;
  height: calc(var(--initial-vh, 1vh) * 100);
`;
export const minViewportHeightMixin = css`
  min-height: 100vh;
  min-height: calc(var(--initial-vh, 1vh) * 100);
`;

const circSize = "6px";
export const requiredMixin = css`
  ::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -9px;
    margin-left: 2px;
    border-radius: 50%;
    width: ${circSize};
    height: ${circSize};
    min-width: ${circSize};
    min-height: ${circSize};
    background: var(--color-danger);
  }
`;

export const positiveRequiredMixin = css`
  ::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -9px;
    margin-left: 2px;
    border-radius: 50%;
    width: ${circSize};
    height: ${circSize};
    min-width: ${circSize};
    min-height: ${circSize};
    background: ${({ theme }) => theme.color.positive};
  }
`;

export const fabMixin = css`
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 12;
  @media (max-width: ${appShellUiVars.mobileBp}px) {
    right: 10px;
    bottom: 70px;
  }
`;
