import { API_URL } from "@/misc/constants";
import { get, post, put, remove } from "./index";
import { mastersCache } from "@/misc/httpCache";
import { analyticsAction } from "@/utils/analytics";

const BASE = `${API_URL}/discount`;

const getMyDiscounts = () => {
  return get(`${BASE}/myDiscounts`, { cache: false });
};
const create = (payload) => {
  mastersCache.reset();
  if (!payload.service) {
    analyticsAction({
      category: "discount",
      action: "all_services",
    });
  }
  if (payload.model_search) {
    analyticsAction({
      category: "discount",
      action: "models",
    });
  }
  if (payload.client) {
    analyticsAction({
      category: "discount",
      action: "client_discount",
    });
  }
  if (payload.expires) {
    analyticsAction({
      category: "discount",
      action: "limit_discount",
    });
  }
  return put(BASE, payload);
};
const updateById = (id, payload) => {
  mastersCache.reset();
  return post(`${BASE}/${id}`, payload);
};
const deleteById = (id) => {
  mastersCache.reset();
  return remove(`${BASE}/${id}`);
};
export const DiscountAPI = {
  getMyDiscounts,
  updateById,
  create,
  deleteById,
};
