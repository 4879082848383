import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import deepExtend from "@/utils/deepExtend";

export const DOMAIN = "premium";

const initialState = {
  allow_trial: false,
  trial: false,
  expires: null as Maybe<string>,
};
export type tState = typeof initialState;

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setPremium(state, { payload }: PayloadAction<Partial<tState>>) {
      if (payload) {
        deepExtend(state, payload);
      }
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
