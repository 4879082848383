import { createSlice } from "@reduxjs/toolkit";
import deepExtend from "@/utils/deepExtend";
import { DEFAULT_REG_STRATEGY } from "@/config";

export const DOMAIN = "userRegister";
const initialState = {
  step: 1,
  strategy: DEFAULT_REG_STRATEGY,
  form: {
    show_client_role: false,
    is_master: false,
    is_client: false,
    is_todo: false,
    is_saloon_owner: false,
    first_name: "",
    email: "",
    phone: "",
    password: "",
    repeat_password: "",
  },
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    changeStrategy(state, { payload }) {
      state.strategy = payload;
    },
    changeStep(state, { payload }) {
      state.step = payload;
    },
    nextStep(state) {
      state.step += 1;
    },
    prevStep(state) {
      state.step -= 1;
    },
    updateForm(state, { payload }) {
      deepExtend(state.form, payload);
    },
    reset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
