import { string, object } from "./";
import { isValidPhoneNumber } from "react-phone-number-input";

export const phoneSchema = string().test("phone-test", "BAD_PHONE", (phone) => {
  if (!phone) return true;
  return isValidPhoneNumber(phone);
});
export const objectPhoneRequiredSchema = object().shape({
  phone: phoneSchema.required(),
});
