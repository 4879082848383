import { createSelector } from "@reduxjs/toolkit";
import isempty from "lodash/isEmpty";
import { Money } from "@/utils/money";

const getBasket = (state) => state.basket.items;
const getBasketAmount = (state) => state.basket.items?.length || 0;
const isBasketEmpty = createSelector(getBasket, (basket) => isempty(basket));
const getFinalPrice = createSelector(getBasket, (basket) => {
  if (isempty(basket)) return null;
  const initial = basket.map(({ service, discount, amount }) => {
    const price = amount
      ? Money.mulByScalar(service.price, amount)
      : service.price;
    if (discount) {
      return Money.subtractPercent(price, discount.amount);
    } else {
      return price;
    }
  });

  const final = Money.sumArray(initial);
  return final;
});
const getMasterId = createSelector(getBasket, (basket) => {
  if (isempty(basket)) return null;
  return basket[0]?.service?.master;
});
const getSessionDuration = createSelector(getBasket, (basket) => {
  if (isempty(basket)) return 0;
  return basket.reduce((acc, { service }) => {
    return acc + service.duration;
  }, 0);
});

export const Selectors = {
  getBasket,
  getBasketAmount,
  getFinalPrice,
  getMasterId,
  getSessionDuration,
  isBasketEmpty,
};

const isInBasket = (basket, serviceId) => {
  const service = basket.find(({ service }) => service._id === serviceId);
  return Boolean(service);
};
const canAddToBasket = (basket, masterId) => {
  if (isempty(basket)) return true;
  const master = basket[0].service.master;
  return master === masterId;
};
export const ComponentSelectors = {
  isInBasket,
  canAddToBasket,
};
