import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import { Effects as FavEffects } from "@/store/favorites/effects";
import { CommentsAPI } from "@/api/comments";
import { ReviewsAPI } from "@/api/reviews";
import { MasterAPI } from "@/api/master";
import { SaloonAPI } from "@/api/saloon";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors } from "./selectors";
import { Actions } from "./slice";
import pick from "lodash/pick";

const toggleLike = id => (dispatch, getState) => {
  const state = getState();
  const isFav = FavSelectors.isFavorite(state, "reviews", id);
  if (isFav) {
    dispatch(FavEffects.removeFavorite("reviews", id));
    dispatch(Actions.removeLike(id));
  } else {
    dispatch(Actions.addLike(id));
    dispatch(FavEffects.addFavorite("reviews", id));
  }
};
const loadComments = id => async dispatch => {
  const { data } = await CommentsAPI.getComments("review", id);
  dispatch(Actions.setComments({ payload: data, id }));
};
const addComment = (id, payload) => async (dispatch, getState) => {
  const user = UserSelectors.getProfile(getState());
  const { data } = await CommentsAPI.createComment("review", id, payload);
  const normComment = {
    ...data,
    author: {
      ...pick(user, [
        "first_name",
        "last_name",
        "_id",
        "sex",
        "avatar",
        "online"
      ]),
      master: user?.master?._id
    }
  };
  dispatch(
    Actions.addComment({
      id,
      created_at: new Date().toISOString(),
      payload: normComment
    })
  );
};
const loadReviews = (id, type) => async (dispatch, getState) => {
  const lastReview = Selectors.getLastReview(getState());
  if (lastReview) {
    const loader =
      type === "master" ? MasterAPI.getReviews : SaloonAPI.getReviews;
    try {
      dispatch(Actions.loadReviewsStart());
      const { data } = await loader(id, {
        last: lastReview.created_at
      });
      dispatch(Actions.loadReviewsSuccess(data));
    } catch (e) {
      dispatch(Actions.loadReviewsError(e));
    }
  }
};
const loadReview = id => async (dispatch, getState) => {
  try {
    dispatch(Actions.loadReviewStart());
    const { data } = await ReviewsAPI.findById(id);
    let master = null,
      saloon = null;
    if (data.master) {
      master = await MasterAPI.findById(data.master).then(({ data }) => data);
    }
    if (data.saloon) {
      saloon = await SaloonAPI.findById(data.saloon).then(({ data }) => data);
    }
    dispatch(
      Actions.loadReviewSuccess({
        review: data,
        master,
        saloon
      })
    );
  } catch (e) {
    dispatch(Actions.loadReviewError(e));
  }
};
const removeComment = (id, commentId) => async dispatch => {
  await CommentsAPI.deleteComment("review", id, commentId);
  dispatch(Actions.removeComment({ reviewId: id, commentId }));
};
const updateReview = (id, payload) => async dispatch => {
  await ReviewsAPI.updateById(id, payload);
  dispatch(Actions.updateReview({ id, updates: payload }));
};

export const Effects = {
  addComment,
  loadReview,
  loadReviews,
  loadComments,
  removeComment,
  toggleLike,
  updateReview
};
