import React from "react";

const SvgPlusRound = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke={color || "currentColor"}
    strokeWidth="1.5"
    fill="none"
    width="24"
    {...props}
  >
    <path
      d="M12 7.757v8.486M16.243 12H7.757M12 21c4.9706 0 9-4.0294 9-9s-4.0294-9-9-9-9 4.0294-9 9 4.0294 9 9 9z"
      strokeLinecap="round"
    ></path>
  </svg>
);

export default SvgPlusRound;
