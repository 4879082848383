import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import * as S from "./styled";
import { useSelector } from "react-redux";
import { Selectors as AppShellSelectors } from "@/store/appShell/selectors";
import { useIsMobile } from "./hooks";
import DesktopAside from "./DesktopAside/container";
import MobileBottomNav from "./MobileBottomNav/container";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import ErrorBoundary from "_c/ErrorBoundary";

const AppShell = (props) => {
  const collapsed = useSelector(AppShellSelectors.isLinksCollapsed);
  const isAuth = useSelector(AuthSelectors.isAuth);
  const isMobile = useIsMobile();
  const isDesktop = !isMobile;
  return (
    <>
      <S.Wrap>
        <Header />
        <S.ChildrenAndSidebarContainer isAuth={isAuth} isCollapsed={collapsed}>
          {isAuth && isDesktop && <DesktopAside />}
          <S.Children>
            <ErrorBoundary>{props.children}</ErrorBoundary>
          </S.Children>
        </S.ChildrenAndSidebarContainer>
        <Footer />
      </S.Wrap>
      {isAuth && isMobile && <MobileBottomNav />}
    </>
  );
};

export default AppShell;
