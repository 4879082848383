import { UserAPI } from "@/api/user";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Actions as IdentityModalActions } from "@/store/identityModal/slice";
import { batch } from "react-redux";

const acceptInviteByPhone = (code) => async (dispatch) => {
  const { data: phone } = await UserAPI.verifyPhone(code);
  setTimeout(() => {
    batch(() => {
      dispatch(ModalActions.openAuth());
      dispatch(
        IdentityModalActions.updateAuthForm({
          phone,
          password: "",
        })
      );
      dispatch(IdentityModalActions.changeStrategy("phone"));
      dispatch(IdentityModalActions.changeMode("auth"));
    });
  }, 500);
};

export const Effects = {
  acceptInviteByPhone,
};
