import React, { forwardRef } from "react";
import Tooltip from "_c/Tooltip";

const Popover = forwardRef(({ showArrow = true, ...props }, ref) => {
  return (
    <Tooltip
      disabled={props.disabled}
      destroyOnHide
      ref={ref}
      size="regular"
      interactive
      onShow={props.onShow}
      onHide={props.onHide}
      position={props.position}
      showArrow={showArrow}
      titleComponent={props.content}
      trigger={props.trigger || "click"}
      animation={props.animation}
    >
      {props.children}
    </Tooltip>
  );
});
Popover.displayName = "Popover";
export default Popover;
