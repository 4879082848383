import React from "react";

const FavoritesPageIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M17.501 21.919a.682.682 0 0 1-.352-.09l-5.14-2.706-4.523 2.435a1.521 1.521 0 0 1-2.077-.64 1.64 1.64 0 0 1-.158-1.001l.88-5.194-3.678-3.724A1.589 1.589 0 0 1 2 9.885c0-.418.163-.819.453-1.113.235-.248.545-.406.88-.451l5.042-.767 2.244-4.67c.256-.541.791-.884 1.377-.884.587 0 1.121.343 1.378.883l2.253 4.69 5.06.775c.406.06.772.284 1.016.624.243.339.343.764.277 1.18a1.61 1.61 0 0 1-.44.9l-3.66 3.68.51 2.795a.783.783 0 0 1-.625.902.758.758 0 0 1-.88-.613l-.52-2.795a1.621 1.621 0 0 1 .44-1.407l3.67-3.706-5.06-.785a1.52 1.52 0 0 1-1.152-.901L12.01 3.55 9.73 8.294a1.52 1.52 0 0 1-1.152.901l-5.043.767 3.643 3.697c.361.36.526.88.44 1.389l-.88 5.194 4.524-2.426c.45-.252.993-.252 1.443 0l5.13 2.705c.311.168.471.53.388.88a.77.77 0 0 1-.74.599l.018-.081Z"
    />
  </svg>
);

export default FavoritesPageIcon;
