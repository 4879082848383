import React from 'react';
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const SaloonPage = loadable(
  () => import(/* webpackChunkName: "saloon-page" */ "_p/saloon"),
  {
    fallback: <Spin size="xl" />,
  }
);

export default SaloonPage;
