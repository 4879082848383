import React from "react";
import PT from "prop-types";
import { AddressSuggestions } from "react-dadata";
import { GEO_SERVICE_API_KEY } from "@/config";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import { useSelector } from "react-redux";

const PlacesAutocomplete = (props) => {
  const locale = useSelector(LocaleSelectors.getLocale);
  const { InputComponent } = props;
  const onSelect = (d) => {
    if (props.onSelect) {
      props.onSelect({
        name: d.value,
        fullName: d.unrestricted_value,
        lat: parseFloat(d.data.geo_lat) || 0,
        lng: parseFloat(d.data.geo_lon) || 0,
        country: d.data.country,
        city: d.data.city,
        flat: d.data.flat,
        house: d.data.house,
        street: d.data.street,
      });
    }
  };
  return (
    <AddressSuggestions
      value={{ value: props.value || "" }}
      count={10}
      uid={props.uid}
      minChars={2}
      customInput={InputComponent}
      onChange={onSelect}
      selectOnBlur
      delay={200}
      filterLanguage={locale}
      token={GEO_SERVICE_API_KEY}
      containerClassName={props.className}
      inputProps={{
        onChange: (e) => {
          if (props.onChange) {
            props.onChange(e.target.value);
          }
        },
        id: props.id,
        onFocus: props.onFocus,
        onBlur: props.onBlur,
        name: props.name,
        autoComplete: "off",
        autoCorrect: "off",
        placeholder: props.placeholder,
        error: props.error,
        "aria-invalid": props["aria-invalid"],
        "aria-describedby": props["aria-describedby"],
        onBlur: props.onBlur,
      }}
    />
  );
};
PlacesAutocomplete.propTypes = {
  value: PT.string,
  InputComponent: PT.any,
  className: PT.string,

  onFocus: PT.func,
  onSelect: PT.func,
  onChange: PT.func,
};

export default PlacesAutocomplete;
