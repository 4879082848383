import React, {
  useState,
  memo,
  useRef,
  useLayoutEffect,
} from "react";
import PT from "prop-types";
import * as S from "./styled";
import DenyIcon from "_c/icon/Deny";

const CloseableMessage = ({ name, message, ...props }) => {
  const normName = "vsbl_msg:" + name;
  const firstRender = useRef(true);
  const [visible, setVisible] = useState(() => {
    return !Boolean(localStorage.getItem(normName));
  });
  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setVisible(!Boolean(localStorage.getItem(normName)));
    }
  }, [normName]);
  const onClose = () => {
    localStorage.setItem(normName, 1);
    setVisible(false);
  };
  if (!visible) return null;
  return (
    <S.Wrap cta {...props}>
      <div
        css={`
          margin-right: 15px;
        `}
      >
        {props.header && (
          <strong
            css={`
              display: block;
              margin-bottom: 5px;
            `}
          >
            {props.header}
          </strong>
        )}
        <p
          css={`
            margin: 0;
          `}
        >
          {message}
        </p>
      </div>
      <S.CloseButtonWrap>
        <button onClick={onClose}>
          <DenyIcon color="white" />
        </button>
      </S.CloseButtonWrap>
    </S.Wrap>
  );
};
CloseableMessage.displayName = "CloseableMessage";
CloseableMessage.propTypes = {
  message: PT.any.isRequired,
  header: PT.string,
  name: PT.string.isRequired,
};
export default memo(CloseableMessage);
