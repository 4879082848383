import { createAction, createSlice } from "@reduxjs/toolkit";

export const DOMAIN = "scheduler";

const initialState = {
  isCalendarVisited: false,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setIsCalendarVisited(state, { payload }) {
      state.isCalendarVisited = payload;
    },
  },
});

export const Actions = slice.actions;

export default slice.reducer;
