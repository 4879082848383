import { Actions } from "../slice";
import { call, takeLatest, put, select, debounce } from "redux-saga/effects";
import { FEAT_FLAG_MULTI_ITEM_BASKET } from "@/misc/featureFlags";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import qs from "query-string";
import { Actions as BasketActions } from "../../basket/slice";
import { Selectors as BasketSelectors } from "../../basket/selectors";

function* normalizeShareLink(link) {
  const locale = yield select(LocaleSelectors.getLocale);
  const parsed = qs.parseUrl(
    link && typeof link === "string" ? link : window.location.href
  );
  const normalized = qs.stringifyUrl({
    url: parsed.url,
    query: {
      ...parsed.query,
      hl: parsed.query.hl || locale,
    },
  });
  return normalized;
}
function* shareModalSaga({ payload: rawUrl }) {
  try {
    if (navigator.share) {
      const url = yield call(normalizeShareLink, rawUrl);
      yield navigator.share({
        url,
      });
    } else {
      yield put(Actions.openNonNativeShare(rawUrl));
    }
  } catch (_e) {}
}

function* watchCloseBookingSaga() {
  if (
    !FEAT_FLAG_MULTI_ITEM_BASKET &&
    !(yield select(BasketSelectors.isBasketEmpty))
  ) {
    yield put(BasketActions.clearBasket());
  }
}
export default function* () {
  yield debounce(50, Actions.openShare.type, shareModalSaga);
  yield takeLatest(Actions.closeBooking.type, watchCloseBookingSaga);
}
