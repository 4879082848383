import { useQuery } from "react-query";
import { QueryKeys } from "../../network/queryKeys";
import { minute } from "../../network/constants";
import { SpecAPI } from "../../api/specs";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { useSelector } from "react-redux";

export const useSearchableSpecsQuery = () => {
  const locale = useSelector(UserSelectors.getLocale);
  return useQuery(
    [QueryKeys.searchableSpecs, { locale }],
    () => SpecAPI.getSearchable({ locale }),
    {
      staleTime: minute * 20,
      cacheTime: Infinity,
    }
  );
};
