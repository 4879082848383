import React from "react";

const SettingsPageIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12.714 2c.55-.01 1.071.19 1.46.57.391.37.6.88.6 1.42 0 1.1.91 1.991 2.041 1.991.35 0 .7-.1 1.01-.27.97-.522 2.18-.19 2.73.74l.68 1.17c.17.3.27.64.27.99 0 .71-.39 1.37-1.02 1.72-.31.17-.57.43-.75.73a1.96 1.96 0 0 0 .75 2.67 1.978 1.978 0 0 1 .75 2.7l-.68 1.13c-.36.62-1.03 1-1.75 1-.36 0-.72-.09-1.02-.27-.32-.17-.67-.27-1.02-.27-.54 0-1.06.21-1.44.59-.39.37-.6.88-.6 1.41 0 1.09-.91 1.98-2.04 1.98h-1.37c-.54 0-1.05-.22-1.42-.59-.38-.38-.58-.88-.58-1.4 0-1.1-.9-1.99-2.03-1.99-.37 0-.73.1-1.04.29a2.15 2.15 0 0 1-1.55.2c-.52-.14-.97-.48-1.24-.93l-.64-1.13a1.949 1.949 0 0 1-.25-1.56c.14-.53.5-.98.99-1.25.31-.17.57-.42.75-.73a1.98 1.98 0 0 0-.75-2.67 1.947 1.947 0 0 1-.74-2.67l.64-1.12c.27-.47.72-.81 1.25-.95a2.066 2.066 0 0 1 1.56.21c.32.17.67.26 1.02.26.54 0 1.06-.21 1.44-.58.38-.37.59-.88.59-1.4 0-1.1.91-1.99 2.04-1.99Zm.401 7.481a2.853 2.853 0 0 0-3.07.59c-.8.78-1.05 1.97-.61 2.99a2.794 2.794 0 0 0 2.59 1.7h.01c.75.01 1.45-.28 1.98-.8.53-.51.83-1.21.83-1.94.01-1.11-.68-2.12-1.73-2.54Z"
    />
  </svg>
);

export default SettingsPageIcon;
