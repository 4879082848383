import { createSlice } from "@reduxjs/toolkit";
import isempty from "lodash/isEmpty";
import last from "lodash/last";
import deepExtend from "@/utils/deepExtend";

const DOMAIN = "messenger";

const initialState = {
  fresh: [],
  dialogs: [],
  dialogsLoading: false,
  dialogsError: null,
  activeDialog: null,
  messagesLoading: false,
  messagesError: null,
  input: {},
  photoInput: {},
  loadedDialogs: {}
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setFreshMessages(state, { payload }) {
      state.fresh = payload;
    },
    changeActiveDialog(state, { payload }) {
      state.activeDialog = payload;
    },
    resetActiveDialog(state) {
      state.activeDialog = null;
    },
    loadDialogsStart(state) {
      state.dialogsError = null;
      state.dialogsLoading = true;
    },
    loadDialogsSuccess(state, { payload }) {
      state.dialogsLoading = false;
      state.dialogs = state.dialogs.concat(payload);
      payload.forEach(({ _id }) => {
        state.loadedDialogs[_id] = 1;
      });
    },
    loadDialogsError(state, { payload }) {
      state.dialogsLoading = false;
      state.dialogsError = payload;
    },
    loadMessagesStart(state) {
      state.messagesError = null;
      state.messagesLoading = true;
    },
    loadMessagesSuccess(state, { payload: messages }) {
      state.messagesLoading = false;
      if (!isempty(messages)) {
        const dialog = state.dialogs.find(
          ({ _id }) => _id === messages[0].dialog
        );
        if (dialog) {
          dialog.messages = messages.concat(dialog.messages || []);
        }
      }
    },
    loadMessagesError(state, { payload }) {
      state.messagesLoading = false;
      state.messagesError = payload;
    },
    updateTextInput(state, { payload }) {
      state.input[state.activeDialog] = payload;
    },
    updatePhotoInput(state, { payload }) {
      state.photoInput[state.activeDialog] = payload;
    },
    resetInput(state) {
      state.input[state.activeDialog] = "";
      state.photoInput[state.activeDialog] = undefined;
    },
    addDialog(state, { payload }) {
      state.dialogs.unshift(payload);
      state.loadedDialogs[payload._id] = 1;
    },
    addMessage(state, { payload }) {
      const dialog = state.dialogs.find(({ _id }) => _id === payload.dialog);
      if (dialog) {
        if (!dialog.messages) {
          dialog.messages = [];
        }
        dialog.messages.push(payload);
        dialog.last_message = payload;
      }
      if (payload.fresh) {
        state.fresh.push({
          _id: payload._id,
          dialog: payload.dialog
        });
      }
    },
    removeMessage(state, { payload }) {
      const dialogIdx = state.dialogs.findIndex(
        ({ _id }) => _id === payload.dialog
      );
      if (dialogIdx !== -1) {
        const dialog = state.dialogs[dialogIdx];
        const msgIdx = dialog.messages.findIndex(
          ({ _id }) => _id === payload._id
        );
        if (msgIdx !== -1) {
          dialog.messages.splice(msgIdx, 1);
        }
        if (isempty(dialog.messages)) {
          state.dialogs.splice(dialogIdx, 1);
          state.loadedDialogs[dialog._id] = 0;
        } else if (dialog.last_message?._id === payload._id) {
          dialog.last_message = last(dialog.messages);
        }
      }
    },
    addFresh(state, { payload }) {
      state.fresh.push({
        _id: payload._id,
        dialog: payload.dialog
      });
    },
    removeFresh(state, { payload: id }) {
      const idx = state.fresh.findIndex(({ _id }) => _id === id);
      if (idx !== -1) {
        state.fresh.splice(idx, 1);
      }
    },
    updateMessage(state, { payload }) {
      const dialog = state.dialogs.find(({ _id }) => _id === payload.dialog);
      if (dialog) {
        const message = dialog.messages.find(
          ({ _id }) => _id === payload.message
        );
        if (message) {
          deepExtend(message, payload.updates);
        }
      }
    },
    globalReset() {
      return initialState;
    }
  }
});

export const Actions = slice.actions;
export default slice.reducer;
