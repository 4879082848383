import { get, post, put, remove } from "./";
import { API_URL } from "@/misc/constants";
import { analyticsAction } from "@/utils/analytics";

const BASE = `${API_URL}/task`;
const getTasks = (params) => {
  return get(`${BASE}/search`, { params, cache: false });
};
const getPayLink = (id) => {
  return get(`${BASE}/${id}/payLink`, { cache: false });
};
const getPhotos = (id) => {
  return get(`${BASE}/${id}/photos`, { cache: false });
};
const findById = (id) => {
  return get(`${BASE}/${id}`, { cache: false });
};
const createPersonalTask = async (payload) => {
  const res = await put(`${BASE}/personal`, payload);
  analyticsAction({
    category: "Add",
    action: "add_task",
  });
  return res;
};
const createTask = async (payload) => {
  if (payload.personal) {
    return createPersonalTask(payload);
  }
  const res = await put(BASE, payload);
  if (payload.saloon) {
    analyticsAction({
      category: "salon_dashboard",
      action: "salon_new_task",
    });
  } else {
    analyticsAction({
      category: "Add",
      action: "add_task",
    });
  }
  return res;
};
const updatePersonalTask = async (id, payload) => {
  const res = await post(`${BASE}/personal/${id}`, payload);
  return res;
};
const updateTask = async (id, payload) => {
  if (payload.personal) {
    return updatePersonalTask(id, payload);
  }
  const res = await post(`${BASE}/${id}`, payload);
  return res;
};
const getMaterials = (id) => {
  return get(`${BASE}/${id}/materials`, {
    cache: false,
  });
};
const acceptTask = async (id) => {
  const res = await post(`${BASE}/${id}/accept`);
  return res;
};
const finishTask = async (id) => {
  const res = await post(`${BASE}/${id}/finish`);
  return res;
};
const removeTask = async (id) => {
  const res = await remove(`${BASE}/${id}`);
  return res;
};
const cancelTask = async (id, payload) => {
  const res = await post(`${BASE}/${id}/cancel`, payload);
  return res;
};
const updateById = async (id, payload) => {
  if (payload.personal) {
    return updatePersonalTask(id, payload);
  }
  const res = await post(`${BASE}/${id}`, payload);
  return res;
};
const requestPhoto = async (id) => {
  const res = await post(`${BASE}/${id}/requestPhoto`);
  return res;
};
const createNote = async (taskId, payload) => {
  const res = await put(`${BASE}/${taskId}/note`, payload);
  return res;
};
const removeNote = async (taskId) => {
  const res = await remove(`${BASE}/${taskId}/note`);
  return res;
};

const startTaskEducation = async () => {
  await post(`${BASE}/education/start`);
};
const createDispute = async (taskId, data) => {
  const res = await post(`${BASE}/${taskId}/dispute`, data);
  return res;
};
const updateDispute = async (claimId, data) => {
  const res = await post(`${BASE}/claim/${claimId}`, data);
  return res;
};
const getCreateCustomTaskPerm = async (masterId) => {
  const res = await get(`${BASE}/createCustomTaskPerm/${masterId}`, {
    cache: false,
  });
  return res;
};

export const TasksAPI = {
  getCreateCustomTaskPerm,
  updateDispute,
  createDispute,
  createNote,
  findById,
  getMaterials,
  getPayLink,
  acceptTask,
  updateTask,
  cancelTask,
  finishTask,
  getTasks,
  requestPhoto,
  startTaskEducation,
  createTask,
  removeTask,
  updateById,
  removeNote,
  getPhotos,
};
