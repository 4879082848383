import { UserAPI } from "@/api/user";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as PrefsSelectros } from "@/store/prefs/selectors";
import { Actions as PrefsActions } from "@/store/prefs/slice";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { getReduxState, getReduxStore } from "@/store";

const getAllPrefs = () => {
  return PrefsSelectros.getAllPrefs(getReduxStore().getState());
};
const updatePrefs = async (partial) => {
  const reduxStore = getReduxStore();
  const id = UserSelectors.getUserId(getReduxState());
  const oldPrefs = getAllPrefs();
  const newPrefs = merge(cloneDeep(oldPrefs), partial);
  // optimistic update
  reduxStore.dispatch(PrefsActions.replacePrefs(newPrefs));
  if (id) {
    try {
      await UserAPI.updateById(id, { prefs: newPrefs });
    } catch (e) {
      // rollback
      reduxStore.dispatch(PrefsActions.replacePrefs(oldPrefs));
      throw e;
    }
  }
};
export const UserPrefsService = {
  getDailySleepMinutes() {
    return PrefsSelectros.getDailySleepMinutes(getReduxState());
  },
  getAllPrefs,
  getTodoCompletionProbTipVisibility() {
    return PrefsSelectros.getTodoCompletionProbTipVisibility(getReduxState());
  },
  updatePrefs,
  async changeInitialScreen(initial_screen) {
    await updatePrefs({ initial_screen });
  },
  async changeDayOverallStatsNotification(day_overall_stats_notification) {
    await updatePrefs({ day_overall_stats_notification });
  },
  async changeTodoCompletionProbTipVisibility(todo_completion_prob_tip) {
    await updatePrefs({ todo_completion_prob_tip });
  },
  async changeDailySleep(daily_sleep) {
    await updatePrefs({ daily_sleep });
  },
};
