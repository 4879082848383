import ms from "ms";
import type { tLocale } from "@/types";

export const DEFAULT_LOCALE: tLocale = "en";
export const SUPPORTED_LOCALES: tLocale[] = [
  "ar",
  "bn",
  "da",
  "de",
  "el",
  "en",
  "es",
  "et",
  "fa",
  "fi",
  "fr",
  "ga",
  "he",
  "hi",
  "id",
  "it",
  "ja",
  "jv",
  "kn",
  "ko",
  "mr",
  "ms",
  "nl",
  "pa",
  "pl",
  "pt",
  "ru",
  "sw",
  "ta",
  "te",
  "th",
  "tl",
  "tr",
  "uk",
  "ur",
  "vi",
  "zh",
];
export const SUPPORTED_LOCALES_HR: { value: tLocale; label: string }[] =
  Object.entries({
    ar: "العربية",
    bn: "বাংলা",
    da: "Dansk",
    de: "Deutsch",
    el: "Ελληνικά",
    en: "English",
    es: "Español",
    et: "Eesti keel",
    fa: "فارْسِى",
    fi: "Suomi",
    fr: "Français",
    ga: "Gaeilge",
    he: "עִבְרִית",
    hi: "हिन्दी",
    id: "Bahasa Indonesia",
    it: "Italiano",
    ja: "日本語",
    jv: "ꦧꦱ ꦗꦮ",
    kn: "ಕನ್ನಡ",
    ko: "한국어",
    mr: "मराठी",
    ms: "بهاس ملايو",
    nl: "Nederlands",
    pa: "ਪੰਜਾਬੀ",
    pl: "Polszczyzna",
    pt: "Português",
    ru: "Русский",
    sw: "Kiswahili",
    ta: "தமிழ்",
    te: "తెలుగు",
    th: "ภาษาไทย",
    tl: "Tagalog",
    tr: "Türkçe",
    uk: "Українська",
    ur: "لشکری",
    vi: "Tiếng Việt",
    zh: "中文",
  }).map(([value, label]) => ({ value, label }));

export const TRANSLATIONS_BASE_URL = "https://simpo.online/i18n/front";
export const I18NEXT_TRANSLATIONS_URL = `${TRANSLATIONS_BASE_URL}/{{ns}}/{{lng}}.json`;

export const I18NEXT_RELOAD_TRANSLATIONS_INTERVAL = {
  client: ms("10 minutes"),
  server: ms("3 minutes"),
};

export const RTL_LOCALES: Set<tLocale> = new Set([
  "he",
  "ar",
  "ur",
  "fa",
  "pa",
  "jv",
]);
