export const SEARCH_NS = "search_page";
export const SALOON_NS = "saloon_profile";
export const ORDERS_NS = "bookings";
export const MESSENGER_NS = "messenger";
export const SCHEDULE_NS = "schedule";
export const SALOON_DASH_NS = "salon_dashboard";
export const BONUS_NS = "bonus_page";
export const FAVORITES_NS = "favorites";
export const SEARCH_SEO_NS = "search_seo";
export const CLIENT_DISC_NS = "client_discounts";
export const SETTINGS_NS = "settings";
export const ACHIEVES_NS = "achievements";
export const FINANCE_NS = "finance";
export const PARTNERS_NS = "partners_page";
export const BLOG_NS = "blog";
export const CLIENT_PROFILE_NS = "client_profile";
export const SALOON_PROFILE_NS = "saloon_profile";
export const MASTER_CLIENTS_NS = "master_clients";
export const MP_REG_FORM_NS = "mp_reg_form";
export const HOME_NS = "home_page";
export const BOOKING_NS = "booking_modal";
export const THANKYOU_NS = "thankyou";
export const SMS_NOTIF_NS = "sms_notifications";
export const ONBOARDING_NS = "onboarding";
export const DASHBOARD_NS = "dashboard";
export const APP_STORIES_NS = "app_stories";
