import { get, put, post, remove } from "./";
import { API_URL } from "@/misc/constants";
import {
  tBizClient,
  tCreateBizClientArgs,
  tExtMessengerSub,
  tGetClientTasksHistoryArgs,
  tHttpClientRes,
  tRefreshClientBucketsArgs,
  tUpdateBizClientArgs,
  tBusinessTask,
} from "@/types";

const BASE = API_URL + "/client";
const findById = (id: string): tHttpClientRes<tBizClient> => {
  return get(`${BASE}/${id}`, {
    cache: false,
  });
};
const getExtMessengerSub = (id: string): tHttpClientRes<tExtMessengerSub> => {
  return get(`${BASE}/${id}/externalMessengerSub`, {
    cache: false,
  });
};
const getTasksHistory = (
  id: string,
  params: tGetClientTasksHistoryArgs
): tHttpClientRes<tBusinessTask[]> => {
  return get(`${BASE}/${id}/tasksHistory`, {
    params,
    cache: false,
  });
};
const createClient = async (
  payload: tCreateBizClientArgs
): tHttpClientRes<tBizClient> => {
  const res = await put(BASE, payload);
  return res;
};
const updateClient = async (id: string, payload: tUpdateBizClientArgs) => {
  const res = await post(`${BASE}/${id}`, payload);
  return res;
};
const removeClient = async (id: string): tHttpClientRes<void> => {
  const res = await remove(`${BASE}/${id}`);
  return res;
};
const refreshBuckets = async (
  payload: tRefreshClientBucketsArgs
): tHttpClientRes<void> => {
  const res = await post(`${BASE}/refreshBuckets`, payload);
  return res;
};

export const ClientsAPI = {
  getExtMessengerSub,
  findById,
  createClient,
  updateClient,
  removeClient,
  getTasksHistory,
  refreshBuckets,
};
