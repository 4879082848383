import * as React from "react";

function SvgPedicure({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 97.71"
      {...props}
    >
      <path d="M4.93 0c3.24 7.2 10.45 33.68 5.78 36.29C-1.25 43-3.71 52 6 60c13.18 11 10.28 35.15 18.07 36.94 8.44 1.93 20.86.14 20.86-4.37 0-6.76-10.62-1.46-11.23-9.57-1.43-18.94 1.36-23.16 1.9-33 .61-11.22-3.56-24.44-1.73-39.83 2.93 7.3 13.81 18.91 12.25 32.18-1.44 12.37-6.41 11.9-5.47 20.65C41.78 73.43 62 71.47 71 90.23c5.93 12.38 27.44 6.17 28.38 3.63 3.25-8.85-7.39-6.7-9.74-9.14-8.19-8.47-9.12-25.1-19.58-42.45C61.5 28.05 62.25 0 62.25 0z" />
    </svg>
  );
}

export default SvgPedicure;
