export default {
  collapsedWidth: "80px",
  uncollapsedWidth: "270px",
  mobileBp: 991,
  headDesktopHeight: "60px",
  headMobileHeight: "50px",
  bottomMobileNavHeight: "62px",
  iconSize: 24,

  desktopAnimDuration: 300,
};
