import s from "styled-components";

export const Wrap = s.div`
  position: relative;
`;
export const Avatar = s.img`
  border-radius: 50%;
  object-fit: cover;
  ${({ size }) => {
    let value;
    switch (size) {
      case "lg":
        value = 64;
        break;
      case "md":
        value = 40;
        break;
      case "sm":
        value = 32;
        break;
      case "xs":
        value = 24;
        break;
      default:
        value = 64;
    }
    return `
      width: ${value}px;
      min-width: ${value}px;
      height: ${value}px;
      min-height: ${value}px;
    `;
  }};
`;
export const Online = s.div`
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${({ online, theme }) =>
    online ? theme.color.positive : theme.color.negative};
  ${({ size }) => {
    let right, bottom;
    switch (size) {
      case "lg":
        right = -2;
        bottom = 12;
        break;
      case "md":
        right = -4;
        bottom = 7;
        break;
      case "sm":
        right = -5;
        bottom = 5;
        break;
      default:
        right = -2;
        bottom = 12;
    }
    return `
      right: ${right}px;
      bottom: ${bottom}px;
    `;
  }}
`;
