import React, { useRef, useCallback, useContext } from "react";
import Popover from "_c/Popover";
import TextInput from "_c/form/TextInput";
import Label from "_c/form/Label";
import Sect from "_c/form/Sect";
import { Button } from "_c/button";
import s from "styled-components";
import PT from "prop-types";
import ButtonGroup from "_c/form/ButtonGroup";
import { useFocusRestore } from "@/hooks/useFocusRestore";
import { ThemeContext } from "styled-components";

const StyledForm = s.form`
 text-align: left;
 padding: 5px; 
 width: 310px;
`;

const Form = ({ t, ...props }) => {
  const inputRef = useRef();
  useFocusRestore(inputRef);
  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(inputRef.current.value);
    props.onClose();
  };
  return (
    <StyledForm style={{ textAlign: "left" }} onSubmit={onSubmit}>
      <Sect>
        <Label theme={props.theme} htmlFor="message-input">
          {t("common:message")}
        </Label>
        <TextInput
          theme={props.theme}
          rows="4"
          as="textarea"
          id="message-input"
          ref={inputRef}
          required
        />
      </Sect>
      <ButtonGroup theme={props.theme}>
        <Button theme={props.theme} small greenish type="submit">
          {t("common:send")}
        </Button>
        <Button
          theme={props.theme}
          onClick={props.onClose}
          small
          negative
          type="button"
        >
          {t("common:cancel")}
        </Button>
      </ButtonGroup>
    </StyledForm>
  );
};
const MessagePopover = (props) => {
  const ref = useRef();
  const theme = useContext(ThemeContext);
  const onClose = useCallback(() => {
    if (ref.current) {
      ref.current.hideTooltip();
    }
  }, []);
  return (
    <Popover
      ref={ref}
      content={
        <Form
          t={props.t}
          theme={theme}
          onClose={onClose}
          onSubmit={props.onSubmit}
        />
      }
    >
      {props.children}
    </Popover>
  );
};
MessagePopover.propTypes = {
  onSubmit: PT.func.isRequired,
  t: PT.func.isRequired,
};

export default MessagePopover;
