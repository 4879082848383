import {combineReducers} from "redux";
import modals from "./modals/slice";
import auth from "./auth/slice";
import user from "./user/slice";
import favorites from "./favorites/slice";
import masterSearch from "./masterSearch/slice";
import masterProfile from "./masterProfile/slice";
import basket from "./basket/slice";
import settings from "./settings/slice";
import saloonProfile from "./saloonProfile/slice";
import saloonDashboard from "./saloonDashboard/slice";
import messenger from "./messenger/slice";
import eventNotifications from "./eventNotifications/slice";
import premium from "./premium/slice";
import portfolio from "./portfolio/slice";
import clientProfile from "./clientProfile/slice";
import reviews from "./reviews/slice";
import clientFeed from "./clientFeed/slice";
import blog from "./blog/slice";
import achieves from "./achieves/slice";
import locale from "./locale/slice";
import userRegister from "./userRegister/slice";
import identityModal from "./identityModal/slice";
import canary from "./canary/slice";
import paymentResult from "./paymentResult/slice";
import appShell from "./appShell/slice";
import scheduler from "./scheduler/slice";
import extMessengerSub from "./extMessengerSub/slice";
import msgrSubPage from "./msgrSubPage/slice";
import prefs from "./prefs/slice";

const rootReducer = combineReducers({
  achieves,
  appShell,
  auth,
  basket,
  blog,
  canary,
  clientFeed,
  clientProfile,
  eventNotifications,
  extMessengerSub,
  favorites,
  identityModal,
  locale,
  masterProfile,
  masterSearch,
  messenger,
  msgrSubPage,
  modals,
  portfolio,
  paymentResult,
  prefs,
  premium,
  reviews,
  saloonDashboard,
  saloonProfile,
  scheduler,
  settings,
  user,
  userRegister,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
