import React from "react";
import * as S from "./styled";
import PlacesAutocomplete from "./PlacesAutocomplete";
import ServiceAutocomplete from "./ServiceAutocomplete";
import PT from "prop-types";
import PinIc from "_c/icon/Pin";

const ServiceSearchFormView = ({
  onServiceChange,
  service,
  onLocationSelect,
  onLocationChange,
  location,
  onSubmit,
  onGeoClick,
  t,
  preloadSearchPage,
}) => (
  <S.Wrap onSubmit={onSubmit}>
    <S.TextInputWrap>
      <ServiceAutocomplete
        t={t}
        service={service}
        onChange={onServiceChange}
        onFocus={preloadSearchPage}
      />
      <PlacesAutocomplete
        t={t}
        onSelect={onLocationSelect}
        value={location.label}
        onChange={onLocationChange}
        onFocus={preloadSearchPage}
      />
    </S.TextInputWrap>
    <S.SubmitAndGeoWrap>
      <S.SubmitButton
        onMouseEnter={preloadSearchPage}
        onTouchStart={preloadSearchPage}
        type="submit"
        id="src_main"
      >
        {t("common:find")}
      </S.SubmitButton>
      <S.GeoButton
        type="button"
        onClick={onGeoClick}
        title={t("common:geo_find_me")}
        large
      >
        <PinIc color="white" />
      </S.GeoButton>
    </S.SubmitAndGeoWrap>
  </S.Wrap>
);
ServiceSearchFormView.propTypes = {
  service: PT.string,
  location: PT.object,

  onServiceChange: PT.func.isRequired,
  onLocationSelect: PT.func.isRequired,
  onLocationChange: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  preloadSearchPage: PT.func.isRequired,
  onGeoClick: PT.func.isRequired,
};

export default ServiceSearchFormView;
