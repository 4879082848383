import styled, { css } from "styled-components";

export const inputMixin = css`
  width: 100%;
  border-radius: var(--root-br);
  border: ${({ error }) =>
    `1px solid ${error ? "var(--color-danger)" : "transparent"}`};
  color: var(--font-color-main);
  padding: 15px 10px;
  outline: none;
  transition: border-color .25s ease;
  :disabled {
    opacity: .7;
  }
  :focus {
    border-color: ${({ error }) =>
      error ? "var(--color-danger)" : "var(--color-primary)"}};
  }
  background: var(--bg-touchable);
  ::placeholder {
    color: var(--font-color-accent);
  }
`;
const StyledInput = styled.input`
  ${inputMixin};
`;

export default StyledInput;
