import styled from "styled-components";
import BaseModal from "react-modal";

export const Header = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: var(--font-color-accent);
  border-bottom: ${({ theme }) => theme.border.light};
  padding: 15px 0;
`;
export const Footer = styled.button.attrs({
  className: "ripple-btn",
})`
  flex: 0 0 auto;
  padding: 15px 0;
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  background-color: var(--color-cta);
  color: white;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  :disabled {
    opacity: 0.5;
  }
`;
export const Content = styled.div.attrs({
  "data-scroll-lock-scrollable": true,
})`
  padding: 20px;
  height: 100%;
  overflow-y: auto;
`;

type tModalProps = {
  mobileBp: number;
};
export const Modal = styled(BaseModal)<tModalProps>`
  background-color: var(--bg-paper);
  padding: 0;
  width: 100%;
  max-width: ${({ mobileBp }) => mobileBp}px;
  max-height: 100%;
  outline: none;
  display: flex;
  direction: ${({ theme }) => (theme.rtl ? "rtl" : "ltr")};
  border-radius: var(--root-br);
  flex-direction: column;
  @media (max-width: ${({ mobileBp }) => mobileBp}px) {
    max-width: 100%;
    border-radius: 0;
    width: 100%;
    height: 100%;
    ${Footer} {
      border-radius: 0;
    }
    ${Content} {
      padding: 10px;
    }
  }
`;
