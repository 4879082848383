import { NotificationsAPI } from "@/api/notifications";
import { Selectors } from "./selectors";
import { Actions } from "./slice";

const loadNotifications = () => async (dispatch, getState) => {
  const offset = Selectors.getNotificationsCount(getState());
  dispatch(Actions.loadingStart());
  try {
    const { data } = await NotificationsAPI.getNotifications({ offset });
    dispatch(Actions.loadingSuccess(data));
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};
const clearFresh = () => async (dispatch, getState) => {
  if (!Selectors.isFreshEmpty(getState())) {
    await NotificationsAPI.clearFresh();
    dispatch(Actions.clearFresh());
  }
};

export const Effects = {
  clearFresh,
  loadNotifications
};
