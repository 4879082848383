export const TODO_POS_BUFF = "0|hzzzzz";

export const TODOS_DEFAULT_LIST_ID = "__DEFAULT_TODO_LIST__";

export const TODOS_DEFAULT_TITLE = "🤫";

export const TODO_LIST_TITLE_MAX_LEN = 32;

export const TODO_PRIORITIES = ["a", "b", "c", "d", "e"];

export const MAX_TODO_CHILDREN_DEPTH = 3;
