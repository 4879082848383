import * as React from "react";

function SvgVisagist({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 94.65 100"
      {...props}
    >
      <path d="M76.14 59.3v34.37a6.33 6.33 0 1012.65 0V59.3zm0-19.7h12.65v12.65H76.14zM82.47 0h-.77v30L73.62 3.59a10.41 10.41 0 00-3.29 8.55c.73 7.44 5.81 20.42 5.81 20.42h12.65s5.08-13 5.81-20.42C95.26 5.46 89.17 0 82.47 0zm-79 90.08a9.92 9.92 0 009.92 9.92h36.17a9.92 9.92 0 009.92-9.92zm0-12.34h56.01v6.47H3.47z" />
      <path d="M25.57 69.05h11.81v14.78H25.57zm-11.89-16.4a21.47 21.47 0 003 3.68l29.49-29.52a21.56 21.56 0 00-3.68-3zM31.35 20.6a20.83 20.83 0 00-3.47.31L10.76 38a20.83 20.83 0 00-.31 3.47 21.44 21.44 0 00.26 3.23l23.88-23.84a21.68 21.68 0 00-3.24-.26z" />
      <path d="M31.47 10.11A31.47 31.47 0 1063 41.58a31.47 31.47 0 00-31.53-31.47zm0 58.69A27.22 27.22 0 1158.7 41.58 27.22 27.22 0 0131.47 68.8z" />
    </svg>
  );
}

export default SvgVisagist;
