import { createSlice } from "@reduxjs/toolkit";
import deepExtend from "@/utils/deepExtend";

const DOMAIN = "user";

const initialState = {
  profile: null,
  updating: false,
  updateError: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    addRole(state, { payload }) {
      if (state.profile) {
        state.profile.roles.push(payload);
      }
    },
    updateUser(state, { payload }) {
      state.updating = false;
      state.updateError = null;
      if (state.profile) {
        deepExtend(state.profile, payload);
      }
    },
    setUser(state, { payload }) {
      state.profile = payload;
    },
    updateMaster(state, { payload }) {
      const master = state.profile?.master;
      if (master) {
        deepExtend(master, payload);
      }
    },
    createMaster(state, { payload: master }) {
      state.profile.master = master;
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
