import React from "react";
import type { CSSProperties } from "react";
import { useLocale } from "@/hooks/useLocale";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Menu, MenuItem } from "_c/Menu";
import { SUPPORTED_LOCALES_HR } from "@/config";
import { analyticsAction } from "@/utils/analytics";
import type { tLocale } from "@/types";

const Wrap = styled.div<{ upperText: boolean }>`
  font-weight: 700;
  cursor: pointer;
  color: var(--font-color-emp);
  text-transform: ${({ upperText }) => (upperText ? "uppercase" : "none")};
  font-size: 14px;
`;

type tProps = {
  className?: string;
  style?: CSSProperties;
  showFullNameOfActiveLanguage?: boolean;
};
const LocaleSwitch = (props: tProps) => {
  const currentLocale = useLocale();
  const { i18n, ready } = useTranslation([], {
    useSuspense: false,
  });
  const onClick = (newLocale: tLocale) => {
    i18n.changeLanguage(newLocale);
    analyticsAction({
      category: "locale",
      action: `appbar_${newLocale}`,
    });
  };
  if (!ready) return null;
  return (
    <Menu
      overflow="auto"
      portal
      menuButton={
        <Wrap
          upperText={!props.showFullNameOfActiveLanguage}
          role="button"
          style={props.style}
          className={props.className}
        >
          {props.showFullNameOfActiveLanguage
            ? SUPPORTED_LOCALES_HR.find((l) => l.value === currentLocale)?.label
            : currentLocale}
        </Wrap>
      }
    >
      {SUPPORTED_LOCALES_HR.map(({ value, label }) => (
        <MenuItem key={value} onClick={() => onClick(value as tLocale)}>
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LocaleSwitch;
