import "@/polyfills";
import "@/styles/App.css";
import React from "react";
import { GlobalStyle } from "@/styles/GlobalStyle";
import ThemeProvider from "@/providers/Theme";
import ModalsContainer from "../modals";
import Router from "../router";
import RouteWatcher from "_c/RouteWatcher";
import AchieveContainer from "_c/modules/achieves/popup/container";
import LocaleChangeListener from "_c/LocaleChangeListener";
import GlobalProgress from "_c/GlobalProgress";
import Canary from "_c/Canary";
import AppShell from "_c/AppShell";
import RippleButtonClicker from "_c/RippleButtonClicker";
import { useCalcViewportHeight } from "@/hooks/useCalcViewportHeight";
import { useRehydrateBasket } from "@/hooks/useRehydrateBasket";
import ToastContainer from "_c/Toast/container";
import { useAfterLoginFlow } from "@/hooks/useAfterLoginFlow";
import { usePreloadOftenUsedModules } from "@/hooks/usePreloadOftenUsedModules";
import { usePrefetchOftenUsedData } from "@/hooks/usePrefetchOftenUsedData";
import { usePreloadOftenUsedLocales } from "@/hooks/usePreloadOftenUsedLocales";
import { useDevOnlyAutoAuth } from "../../hooks/useDevOnlyAutoAuth";
import AppConfigProvider from "@/providers/AppConfig";
import ErrorBoundary from "@/components/ErrorBoundary";

const App = () => {
  useDevOnlyAutoAuth();
  useCalcViewportHeight();
  useRehydrateBasket();
  useAfterLoginFlow();
  usePreloadOftenUsedModules();
  usePreloadOftenUsedLocales();
  usePrefetchOftenUsedData();
  return (
    <ErrorBoundary renderFallback={renderErrorFallback}>
      <LocaleChangeListener />
      <AppConfigProvider>
        <ThemeProvider>
          <RippleButtonClicker />
          <GlobalStyle />
          <GlobalProgress />
          <Canary />
          <ModalsContainer />
          <RouteWatcher />
          <ToastContainer />
          <AppShell>
            <Router />
          </AppShell>
          <AchieveContainer />
        </ThemeProvider>
      </AppConfigProvider>
    </ErrorBoundary>
  );
};
function renderErrorFallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={() => resetErrorBoundary()}>Reload</button>
    </div>
  );
}

export default App;
