import styled from "styled-components";
import { Container as BaseContainer } from "reactstrap";

export const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.section`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 20;
  &:after {
    content: "";
    background: ${({ theme }) => {
      if (theme.__name__ === "light") return "url(/img/contentBg2_light.png)";
      if (theme.__name__ === "dark") return "url(/img/contentBg2_dark.png)";
    }};
    background-repeat: no-repeat;
    background-position: top center;
    height: 1305px;
    position: absolute;
    top: -22vw;
    left: 0px;
    right: 0;
    z-index: 20;
    pointer-events: none;
    background-size: 100%;
  }
`;
