import { createSelector } from "@reduxjs/toolkit";
import { createStructuredSelector } from "reselect";
import { Selectors as ProfileSelectors } from "./profile";
import { Selectors as ServiceSelectors } from "./services";
import { Selectors as ReviewSelectors } from "@/store/reviews/selectors";
import { formatMinutes } from "@/utils/time";
import { OPENING_HOURS_DAYS_SPECIFICATION } from "@/misc/constants";

const getOpeningHoursSpecification = createSelector(
  ProfileSelectors.getWeekSchedule,
  (schedule) => {
    return schedule
      .map(({ opening_hours }, idx) => ({
        "@type": "OpeningHoursSpecification",
        opens: formatMinutes(opening_hours[0]),
        closes: formatMinutes(opening_hours[1]),
        dayOfWeek: OPENING_HOURS_DAYS_SPECIFICATION[idx],
      }))
      .filter(({ closes }) => closes !== "00:00");
  }
);
const getSeoMeta = createStructuredSelector({
  name: ProfileSelectors.getTitle,
  reviews: ReviewSelectors.getReviews,
  services: ServiceSelectors.getServicesForSEO,
  saloonId: ProfileSelectors.getId,
  coords: ProfileSelectors.getCoords,
  address: ProfileSelectors.getHrAddress,
  rate: ProfileSelectors.getRate,
  reviewsCount: ProfileSelectors.getReviewsCount,
  workingHours: getOpeningHoursSpecification,
});
export const Selectors = {
  getSeoMeta,
};
