import React from "react";

const ClientsPageIcon = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    {...props}
  >
    <path
      fill={color}
      d="M5.904 11.232a17.563 17.563 0 0 1 2.809 0c.509.03 1.016.093 1.517.191 1.084.22 1.792.578 2.095 1.155.228.451.228.988 0 1.438-.303.578-.983.959-2.107 1.155-.501.101-1.007.167-1.517.196-.472.053-.943.053-1.421.053h-.859a4.122 4.122 0 0 0-.522-.035 10.347 10.347 0 0 1-1.517-.191c-1.084-.208-1.792-.578-2.095-1.155a1.587 1.587 0 0 1-.006-1.462c.297-.577 1.005-.952 2.1-1.155a11.159 11.159 0 0 1 1.523-.19zm1.398-8.798c1.883 0 3.41 1.57 3.41 3.507s-1.527 3.507-3.41 3.507-3.41-1.57-3.41-3.507 1.527-3.507 3.41-3.507zm8.119 12.824a17.563 17.563 0 0 1 2.809 0c.509.03 1.016.093 1.517.191 1.084.22 1.792.578 2.095 1.155.228.451.228.988 0 1.438-.303.578-.983.959-2.107 1.155-.501.101-1.007.167-1.517.196-.472.052-.944.052-1.421.052h-.859a4.122 4.122 0 0 0-.522-.035 10.347 10.347 0 0 1-1.517-.191c-1.084-.208-1.792-.578-2.095-1.155a1.587 1.587 0 0 1-.006-1.462c.298-.578 1.006-.953 2.101-1.155.502-.097 1.011-.16 1.522-.189zm1.398-8.798c1.883 0 3.41 1.57 3.41 3.507s-1.527 3.507-3.41 3.507-3.41-1.57-3.41-3.507 1.527-3.507 3.41-3.507z"
    />
  </svg>
);

export default ClientsPageIcon;
