import React from "react";
import PT from "prop-types";
import { StyledRoot } from "./styled";

const ns = "common:footer.apps";
const PlayMarketLink = ({ t, className }) => {
  return (
    <StyledRoot
      className={className}
      as="a"
      title={t(`${ns}.tooltip_google`)}
      href="https://play.google.com/store/apps/details?id=com.moss.simpo&hl=ru"
    >
      <img src="/images/google-play.svg" alt="google play" />
    </StyledRoot>
  );
};
PlayMarketLink.propTypes = {
  t: PT.func.isRequired,
};

export default PlayMarketLink;
