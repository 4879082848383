import { ExternalNotificationsAPI } from "@/api/externalNotifications";
import { QueryKeys } from "@/network/queryKeys";
import { useQuery } from "react-query";
import { useLocale } from "../useLocale";
import ms from "ms";
import { useUserId } from "../useUserId";

export const useSupportedExternalNotificationTmplsQuery = () => {
  const locale = useLocale();
  const query = useQuery(
    [QueryKeys.externalNotificationSupportedTmpls, { locale }],
    () =>
      ExternalNotificationsAPI.getSupportedTemplates({ locale }).then(
        (d) => d.data
      ),
    {
      cacheTime: Infinity,
      staleTime: ms("1 hour"),
    }
  );
  return query;
};

export const useExternalNotificationTmplsQuery = () => {
  const userId = useUserId();
  const query = useQuery(
    [QueryKeys.externalNotificationTmpls, userId],
    () => ExternalNotificationsAPI.getMyTemplates().then((d) => d.data),
    {
      cacheTime: Infinity,
    }
  );
  return query;
};
