import styled from "styled-components";

type tProps = {
  cta?: boolean;
  isError?: boolean;
};
const Wrap = styled.div<tProps>`
  background: ${({ theme, cta, isError }) =>
    cta
      ? theme.color.positive
      : isError
      ? theme.color.negative
      : theme.color.primary_2};
  color: white;
  border-radius: var(--root-br);
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 500;
  margin: 14px 0;
  padding: 15px;
  a,
  button {
    box-shadow: none;
    color: white;
    border-bottom: 2px solid white;
    transition: opacity 0.3s ease;
    &:hover,
    &:focus {
      border-bottom-color: transparent;
      opacity: 0.7;
    }
  }
`;

export default Wrap;
