import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selectors } from "@/store/auth/selectors";
import { usePrevious } from "@/hooks/usePrevious";
import { useHistory } from "react-router-dom";
import { Actions } from "@/store/modals/slice";

const AuthOnly = (props) => {
  const isAuth = useSelector(Selectors.isAuth);
  const history = useHistory();
  const prevIsAuth = usePrevious(isAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth && prevIsAuth) {
      history.push("/");
    } else if (!isAuth && !prevIsAuth) {
      dispatch(Actions.openAuth());
    }
  }, [isAuth, prevIsAuth, dispatch]);
  if (isAuth) {
    return props.children;
  }
  return null;
};

export default AuthOnly;
