import React from "react";
import { StyledModal, CloseOverlay } from "./index-styled";
import PT from "prop-types";
import provide from "./provider";
import Loadable from "./loadable";

const ModalAuth = (props) => {
  return (
    <StyledModal onClose={props.close} isOpen={props.isOpen}>
      <CloseOverlay onClick={() => props.close()} />
      <Loadable {...props} />
    </StyledModal>
  );
};
ModalAuth.propTypes = {
  isOpen: PT.bool,
  close: PT.func,
  register: PT.func,
  login: PT.func,
  affiliateData: PT.object,
  isLoadingRegister: PT.bool,
  isLoadingLogin: PT.bool,
  loginForm: PT.object,
  updateLoginForm: PT.func,
};

export default provide(ModalAuth);
