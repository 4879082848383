import { GeoApi } from "@/api/geo";
import type { tGeoCoordinates, tGeoLocation, tSavedGeoLocation } from "@/types";
import { UserSyncDb, ENTITIES } from "../../syncDb";
const db = UserSyncDb.instance;

const error = "geolocation_failed";
const detectCurrentPosition = (): Promise<tGeoCoordinates> => {
  return new Promise((res, rej) => {
    if (!("geolocation" in navigator)) return rej();
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        res({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      },
      () => rej(error)
    );
  });
};
const reverseGeocode = ({ lat, lng }: tGeoCoordinates) => {
  return GeoApi.reverseGeocode({
    lat,
    lng,
  });
};
export const GeoService = {
  detectCurrentPosition,
  async detectCurrentLocation(): Promise<tGeoLocation> {
    const data = await reverseGeocode(await detectCurrentPosition());
    if (!data) throw new Error(error);
    return data;
  },
  reverseGeocode,
  async isLocationSaved(loc: tGeoLocation): Promise<boolean> {
    const res: tSavedGeoLocation = db.searchEngine.findOne(
      ENTITIES.savedGeoLocation,
      {
        name: loc.name,
      }
    );
    return !!res;
  },
  async saveLocation(loc: tGeoLocation): Promise<void> {
    await db.addDoc({
      ...loc,
      type: ENTITIES.savedGeoLocation,
    });
  },
  async removeLocation(id: string) {
    await db.removeDoc(id);
  },
};
