import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const DOMAIN = "masterProfile";

const initialState = {
  loading: true,
  master: null,
  services: [],
  discounts: [],
  achieves: [],
  articles: [],
  associates: [],
  clients: [],
  error: null,
  servicesFilter: "",
  isFreeSlotsOpen: false,
  redirect: {
    isRedirected: false,
    redirectId: null,
  },
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadingStart(state) {
      state.error = null;
      state.loading = true;
      state.servicesFilter = "";
      state.redirect = initialState.redirect;
    },
    loadingSuccess(state, { payload }) {
      const {
        services,
        discounts,
        clients,
        associates,
        achieves,
        master_services,
        articles,
        ...master
      } = payload;
      state.services = master_services.map((service) => ({
        ...service,
        master: master._id,
      }));
      state.discounts = discounts;
      state.clients = clients;
      state.articles = articles;
      state.associates = associates;
      state.achieves = achieves?.achievements || [];
      state.master = master;
      state.loading = false;
    },
    loadingRedirect(state, { payload }) {
      state.redirect.isRedirected = true;
      state.redirect.redirectId = payload;
      state.loading = false;
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    updateServicesFilter(state, { payload }) {
      state.servicesFilter = payload;
    },
    createService(state, { payload }) {
      state.services.push(payload);
    },
    deleteService(state, { payload }) {
      const idx = state.services.findIndex(({ _id }) => _id === payload);
      if (idx !== -1) {
        state.services.splice(idx, 1);
      }
    },
    updateService(state, { payload }) {
      const service = state.services.find(({ _id }) => _id === payload._id);
      if (service) {
        merge(service, payload);
      }
    },
    openFreeSlots(state) {
      state.isFreeSlotsOpen = true;
    },
    closeFreeSlots(state) {
      state.isFreeSlotsOpen = false;
    },
    createDiscount(state, { payload }) {
      state.discounts.push(payload);
    },
    updateDiscount(state, { payload }) {
      const discount = state.discounts.find(({ _id }) => _id === payload._id);
      if (discount) {
        merge(discount, payload);
      }
    },
    deleteDiscount(state, { payload }) {
      const idx = state.discounts.findIndex(({ _id }) => _id === payload);
      if (idx !== -1) {
        state.discounts.splice(idx, 1);
      }
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
