import { getCookie } from "@/utils";
import { IS_SERVER } from "@/misc/constants";

const getLocale = (state, maybeCtx) => {
  if (state.locale) return state.locale;
  if (IS_SERVER && maybeCtx) {
    return maybeCtx.cookies.get("locale");
  } else {
    return getCookie("locale");
  }
};

export const Selectors = {
  getLocale
};
