import { connect } from "react-redux";
import BottomModal from "./";
import { Selectors as BasketSelectors } from "@/store/basket/selectors";
import { Effects as BasketEffects } from "@/store/basket/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Selectors } from "@/store/modals/selectors";

const mSp = (state) => ({
  isOpen: Selectors.isBottomOpen(state),
  basket: BasketSelectors.getBasket(state),
  finalPrice: BasketSelectors.getFinalPrice(state),
});
const mDp = {
  clearBasket: BasketEffects.clearBasket,
  openBookingModal: ModalActions.openBooking,
};

export default connect(mSp, mDp)(BottomModal);
