import { createSlice } from "@reduxjs/toolkit";
import deepExtend from "@/utils/deepExtend";
import { DEFAULT_AUTH_STRATEGY } from "@/config";

export const DOMAIN = "identityModal";

const initialState = {
  mode: "register",
  strategy: DEFAULT_AUTH_STRATEGY,
  authForm: {
    email: "",
    phone: "",
    password: "",
  },
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    changeMode(state, { payload }) {
      state.mode = payload;
    },
    changeStrategy(state, { payload }) {
      state.strategy = payload;
    },
    updateAuthForm(state, { payload }) {
      deepExtend(state.authForm, payload);
    },
    resetAuthForm(state) {
      state.authForm = initialState.authForm;
    },
  },
});
export const Actions = slice.actions;
export default slice.reducer;
