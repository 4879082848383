import { DOMAIN } from "./slice";
import { generateUrl } from "@/utils/cloudinary";
import { createSelector } from "reselect";
import { SITE_URL } from "@/misc/constants";
import {
  DEFAULT_PROFILE_PHOTO,
  DEFAULT_PROFILE_PHOTO_MALE,
  DEFAULT_SALOON_LOGO,
} from "@/misc/constants";
import { LinkGenerator } from "@/utils/LinkGenerator";
const root = (state) => state[DOMAIN];

const isLoading = (state) => root(state).isLoading;
const getData = (state) => root(state).data;
const getError = (state) => root(state).error;

const isWaAvailable = (state) => !getData(state)?.wa?.active;
const isTgAvailable = (state) => !getData(state)?.tg?.active;
const isViberAvailable = (state) => !getData(state)?.viber?.active;
const isAnyMessengerAvailable = createSelector(
  [isWaAvailable, isTgAvailable, isViberAvailable],
  (wa, tg, viber) => {
    return wa || viber || tg;
  }
);
const getOwnerName = createSelector(getData, (data) => {
  if (!data) return null;
  if (data.master) {
    return [data.master.first_name, data.master.last_name]
      .filter(Boolean)
      .join(" ");
  } else if (data.saloon) {
    return [data.saloon.type, data.saloon.title].filter(Boolean).join(" ");
  }
});
const getOwnerPhoto = createSelector(getData, (data) => {
  if (!data) return null;
  if (data.master) {
    if (data.master.avatar) {
      return generateUrl(data.master.avatar, {
        width: 720,
        height: 800,
        crop: "fill",
        gravity: "face",
      });
    } else {
      if (data.master.sex) {
        return SITE_URL + DEFAULT_PROFILE_PHOTO_MALE;
      } else {
        return SITE_URL + DEFAULT_PROFILE_PHOTO;
      }
    }
  } else if (data.saloon) {
    if (data.saloon.logo) {
      return generateUrl(data.saloon.logo, {
        width: 720,
        height: 800,
        crop: "fill",
      });
    } else {
      return SITE_URL + DEFAULT_SALOON_LOGO;
    }
  }
});
const getToken = (state) => getData(state)?._id;
const getWaLink = createSelector(getToken, (token) => {
  return LinkGenerator.whatsappBot(token);
});
const getViberLink = createSelector(getToken, (token) => {
  return LinkGenerator.viberBot(token);
});
const getTgLink = createSelector(getToken, (token) => {
  return LinkGenerator.telegramBot(token);
});
const getOwnerType = createSelector(getData, (data) => {
  if (data.master) return "master";
  return "saloon";
});

export const Selectors = {
  isLoading,
  getData,
  isTgAvailable,
  isViberAvailable,
  isWaAvailable,
  isAnyMessengerAvailable,
  getWaLink,
  getViberLink,
  getTgLink,
  getOwnerName,
  getOwnerPhoto,
  getOwnerType,
  getError,
};
