import { API_URL } from "@/misc/constants";
import { put, remove } from "./";

const BASE = `${API_URL}/favorite`;

const addFavorite = (type, id) => {
  return put(`${BASE}/${type}/${id}`);
};
const removeFavorite = (type, id) => {
  return remove(`${BASE}/${type}/${id}`);
};

export const FavoritesAPI = {
  addFavorite,
  removeFavorite,
};
