import { Actions } from "./slice";
import { IS_BROWSER } from "@/misc/constants";

const visitCalendar = () => async (dispatch) => {
  if (IS_BROWSER) {
    localStorage.setItem("calendar-visited", 1);
    dispatch(Actions.setIsCalendarVisited(true));
  }
};

export const Effects = {
  visitCalendar,
};
