import { API_URL } from "@/misc/constants";
import { get, put, remove, post } from "./index";

// TODO:: cache
const BASE = `${API_URL}/messenger`;
const DIALOGS_URL = `${BASE}/dialog`;
const MESSAGE_URL = `${BASE}/message`;
const getDialogs = (params) => {
  return get(DIALOGS_URL, { params, cache: false });
};
const getMessages = (params) => {
  return get(MESSAGE_URL, { params, cache: false });
};
const createMessage = (payload) => {
  return put(MESSAGE_URL, {
    ...payload,
    text: payload.text ? payload.text.trim() : payload.text,
  });
};
const removeMessage = (id) => {
  return remove(`${MESSAGE_URL}/${id}`);
};
const receiveMessage = (id) => {
  return post(`${MESSAGE_URL}/${id}/receive`);
};
const updateImportant = (id, payload) => {
  return post(`${MESSAGE_URL}/${id}/important`, payload);
};
const findDialogById = (id) => {
  return get(`${DIALOGS_URL}/${id}`, { cache: false });
};

export const MessengerAPI = {
  createMessage,
  updateImportant,
  findDialogById,
  getDialogs,
  getMessages,
  removeMessage,
  receiveMessage,
};
