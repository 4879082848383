import styled from "styled-components";

export const Wrap = styled.div`
  border-radius: var(--root-br);
  position: relative;
  color: var(--font-color-accent);
  margin-bottom: 7px;
  box-shadow: ${({ theme }) => theme.shadow.light};
  display: flex;
  background-color: var(--bg-paper);
  align-items: flex-start;
  padding: 10px;
`;
