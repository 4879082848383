import { useEffect } from "react";
import React from "react";
import { useTheme } from "@/hooks/useTheme";
import { IS_APPLE_DEVICE } from "@/config";

const RippleButtonClicker = () => {
  const theme = useTheme();
  const themeName = theme.__name__;
  useEffect(() => {
    const onClick = ({ target, clientX, clientY }) => {
      if (!target) {
        return;
      }
      const $btn = target.closest(".ripple-btn, .react-tabs__tab");
      if (!$btn) {
        return;
      }
      const $ripple = document.createElement("i");
      const diameter = Math.max($btn.clientWidth, $btn.clientHeight);
      const radius = diameter / 2;
      const rect = $btn.getBoundingClientRect();
      $ripple.style.width = $ripple.style.height = `${diameter}px`;
      $ripple.style.left = `${clientX - (rect.left + radius)}px`;
      $ripple.style.top = `${clientY - (rect.top + radius)}px`;
      $ripple.classList.add(`ripple-effect-${themeName}`);

      $btn.appendChild($ripple);
      $ripple.addEventListener("animationend", () => {
        if ($btn) {
          $btn.removeChild($ripple);
        }
      });
    };
    document.addEventListener("click", onClick, {
      capture: true,
    });
    return () => {
      document.removeEventListener("click", onClick, {
        capture: true,
      });
    };
  }, [themeName]);
  return null;
};
const RippleButtonClickerContainer = () => {
  if (IS_APPLE_DEVICE) return null;
  return <RippleButtonClicker />;
};
export default RippleButtonClickerContainer;
