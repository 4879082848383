import React from "react";
import PT from "prop-types";
import Info from "_c/atoms/Info";
import { useLoader } from "@/hooks/useLoader";
import { Translate } from "@/i18n/utils";
import { Spin } from "_c/preloader";

const NetworkRequest = (props) => {
  const { data, isLoading, error, updateData } = useLoader({
    loader: props.loader,
    defaultLoadingState: props.defaultLoadingState || "loading",
    isEnabled: props.isEnabled,
    select: props.select,
    defaultValue: props.defaultValue,
  });
  if (!props.isEnabled) return null;
  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Info isError>{Translate.serverError(props.t, error)}</Info>;
  }
  if (!data) {
    return null;
  }
  return props.children({ data, updateData });
};
NetworkRequest.propTypes = {
  isEnabled: PT.bool,
  select: PT.func,
  defaultValue: PT.any,
  defaultLoadingState: PT.string,

  loader: PT.func.isRequired,
  t: PT.func.isRequired,
};
NetworkRequest.defaultProps = {
  isEnabled: true,
}

export default NetworkRequest;
