import { QueryClient as BaseQueryClient } from "react-query";
import isempty from "lodash/isEmpty";
import {
  DEFAULT_STALE_TIME,
  DEFAULT_POLLING_INTERVAL,
  DEFAULT_CACHE_TIME,
} from "./constants";

export class QueryClient extends BaseQueryClient {
  setQueryDataIfCached(key, cb, keepStale = true) {
    const cache = this.getQueryData(key);
    if (cache) {
      if (keepStale) {
        this.setQueryDataButKeepStale(key, cb);
      } else {
        if (typeof cb === "function") {
          this.setQueryData(key, cb(cache));
        } else {
          this.setQueryData(key, cb);
        }
      }
    }
  }
  setQueryDataButKeepStale(key, updater) {
    const state = this.getQueryState(key);
    if (!state) {
      this.setQueryData(key, updater);
    } else {
      this.setQueryData(key, updater, {
        updatedAt: state.dataUpdatedAt,
      });
    }
  }
  setInfiniteQueriesDataIfCached(key, cb) {
    const queries = this.getQueriesData(key);
    if (!isempty(queries)) {
      this.setQueriesData(key, (v) => {
        if (!v) return v;
        return {
          pageParams: v.pageParams,
          pages: cb(v.pages),
        };
      });
    }
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      cacheTime: DEFAULT_CACHE_TIME,
      retry: false,
      refetchInterval: DEFAULT_POLLING_INTERVAL,
    },
  },
});
