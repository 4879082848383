import { SaloonAPI } from "@/api/saloon";
import { Actions } from "./slice";
import { Selectors } from "./selectors";
import { Actions as PortfolioActions } from "@/store/portfolio/slice";
import { Actions as ReviewActions } from "@/store/reviews/slice";
import { batch } from "react-redux";
import { SaloonsService } from "@/services/saloons";

const loadProfile = (id) => async (dispatch) => {
  dispatch(Actions.loadingStart());
  try {
    const { data } = await SaloonAPI.getProfile(id);
    const { portfolio, reviews_initial_chunk, ...saloon } = data;
    batch(() => {
      dispatch(ReviewActions.setReviews(reviews_initial_chunk));
      dispatch(PortfolioActions.setPortfolio(portfolio));
      dispatch(Actions.loadingSuccess(saloon));
    });
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};

const updateLogo = (logo) => async (_d, getState) => {
  await SaloonsService.updateSaloon({
    id: Selectors.getId(getState()),
    updates: { logo },
  });
};

export const Effects = {
  loadProfile,
  updateLogo,
};
