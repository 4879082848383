import s, { css } from "styled-components";
import { Button, CircleButton } from "_c/button";
import SearchIc from "_c/icon/Search";
import PinIc from "_c/icon/Pin";
import { HeartsPreloader } from "_c/preloader";

export const Wrap = s.form`
  display: flex;
  align-items: center;
  background-color: var(--bg-paper);
  border-radius: var(--root-br);
  padding: 30px;
  @media(max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
  }
  @media(max-width: 575px) {
    width: 100%;
    background: transparent;
    flex-direction: column;
  }
`;
export const Input = s.input`
  && {
    width: 100%;
    height: auto;
    border-radius: var(--root-br);
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, .1);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    background-color: var(--bg-touchable);
    color: var(--font-color-accent);
    font-size: 16px;
    font-weight: 400;
    padding: 13px 50px;
    line-height: 14px;
    padding-left: 29px;
    transition: border-color .35s ease-in-out;
    outline: none;
    ::-webkit-input-placeholder { 
      line-height: normal;
      color: var(--font-color-accent);
    }
    :focus {
      border-color: rgba(0, 0, 0, .4);
    }
    :focus + svg {
      opacity: 1;
    }
  }
`;
export const TextInputWrap = s.div`
  flex-grow: 1;
  display: flex;
  @media(max-width: 575px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const Label = s.label`
  height: 0;
  font-size: 0;
  opacity: 0;
`;
export const Preloader = s(HeartsPreloader)`
  width: 30px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
const iconMixin = css`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.35s ease-in-out;
`;
export const SearchIcon = s(SearchIc)`
  ${iconMixin}
  opacity: 0.3;
`;
export const PinIcon = s(PinIc)`
  ${iconMixin}
`;
export const SubmitAndGeoWrap = s.div`
  display: flex;
  align-items: center;
`;
export const SubmitButton = s(Button)`
  &[disabled] {
    opacity: .8;
    pointer-events: none;
  }
  @media(max-width: 767px) {
    margin-top: 15px;
  }
`;
export const GeoButton = s(CircleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  min-width: 43px;
  min-height: 43px;
  margin-inline-start: 10px;
  // margin-left: 10px;
  @media(max-width: 767px) {
    margin-top: 15px;
  }
`;
export const InputWrap = s.div`
  position: relative;
  flex-grow: 1;
  margin-inline-end: 25px;
  @media(max-width: 767px) {
    :last-child {
      margin-inline-end: 0;
    }
  }
  @media(max-width: 575px) {
    margin: 0;
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;
export const AutocompleteList = s.ul`
  position: absolute;
  width: 100%;
  left: 0;
  background-color: var(--bg-paper);
  margin-top: 5px;
  z-index: 21;
  box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.4);
  max-height: 250px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 45px;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
      border-radius: 45px;
    }
    ::-webkit-scrollbar-thumb {
	    background-image: var(--grad-primary-n);
      border-radius: 45px;
    }
`;
export const AutocompleteItem = s.li`
  width: 100%;
  color: var(--font-color-accent);
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  border-bottom: ${({ theme }) => theme.border.light};
  transition: opacity .35s ease-in-out;
  cursor: pointer;
  :hover {
    opacity: .6;
  }
`;
