import React from "react";

const SvgSearch = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    stroke={color || "currentColor"}
    strokeWidth="1.5"
    fill="none"
    {...props}
  >
    <path d="M10.5 18c4.1421 0 7.5-3.3579 7.5-7.5S14.6421 3 10.5 3 3 6.3579 3 10.5 6.3579 18 10.5 18z"></path>
    <path
      d="M21 21l-5-5M6.2561 12C6.8206 13.597 8.261 14.7803 10 14.9725"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default SvgSearch;
