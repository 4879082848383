import { QueryKeys } from "@/network/queryKeys";
import { queryClient } from "@/network/queryClient";
import { iGetWarehouseProductsArgs, iWarehouseProduct } from "@/types";
import isempty from "lodash/isEmpty";

const buildSearchQueryKey = ({
  master,
  saloon,
  ...rest
}: iGetWarehouseProductsArgs) => {
  return [
    QueryKeys.warehouseProductsSearch,
    {
      ...(saloon ? { saloon } : { master }),
      ...rest,
    },
  ];
};
const buildSingleProductQueryKey = (id?: string) => {
  const base = QueryKeys.warehouseSingleProduct;
  if (!id) return base;
  return [base, id];
};

const invalidateSingleProduct = (id?: string) => {
  queryClient.invalidateQueries(buildSingleProductQueryKey(id));
};

const invalidateSearch = () => {
  queryClient.invalidateQueries(QueryKeys.warehouseProductsSearch);
};
const invalidateAll = () => {
  invalidateSearch();
  invalidateSingleProduct();
};
export const WarehouseProductsCacheService = {
  buildSearchQueryKey,
  buildSingleProductQueryKey,
  invalidateSearch,
  invalidateAll,
  addProduct(product: iWarehouseProduct) {
    queryClient.invalidateQueries(
      buildSearchQueryKey({ master: product.master, saloon: product.saloon })
    );
    queryClient.setQueryData(buildSingleProductQueryKey(product._id), product);
  },
  replaceProduct(product: iWarehouseProduct) {
    queryClient.setInfiniteQueriesDataIfCached(
      {
        active: true,
        queryKey: buildSearchQueryKey({
          saloon: product.saloon,
          master: product.master,
        }),
      },

      (pages: tProductSearchPage[]): tProductSearchPage[] => {
        return pages.map((page) => {
          return {
            products: page.products.map((old) =>
              old._id === product._id ? product : old
            ),
            next: page.next,
          };
        });
      }
    );
    queryClient.setQueryDataIfCached(
      buildSingleProductQueryKey(product._id),
      product
    );
  },
  removeProduct(product: iWarehouseProduct) {
    invalidateSingleProduct(product._id);
    queryClient.setInfiniteQueriesDataIfCached(
      {
        active: true,
        queryKey: buildSearchQueryKey({
          saloon: product.saloon,
          master: product.master,
        }),
      },

      (pages: tProductSearchPage[]): tProductSearchPage[] => {
        return pages
          .map((page) => {
            return {
              products: page.products.filter((old) => old._id !== product._id),
              next: page.next,
            };
          })
          .filter((page) => !isempty(page));
      }
    );
  },
};

type tProductSearchPage = { products: iWarehouseProduct[]; next: any };
