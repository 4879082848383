import { AchievesAPI } from "@/api/achieves";
import { Actions } from "./slice";

const loadAchieves = () => async (dispatch) => {
  dispatch(Actions.loadingStart());
  try {
    const { data } = await AchievesAPI.getAchieves();
    dispatch(Actions.loadingSuccess(data));
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};
export const Effects = {
  loadAchieves,
};
