import React from "react";
import * as S from "./index-styled";
import DenyIcon from "_c/icon/Deny";
import { analyticsAction } from "@/utils/analytics";
import { motion } from "framer-motion";
import { CurrencyFormatter } from "@/i18n/currency";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { useToggle } from "@/hooks/useToggle";

const ns = "common:pre_book_modal";
const MotionModal = motion(S.Modal);
const variants = {
  exit: {
    y: "100%",
    opacity: 0,
  },
  initial: {
    y: "100%",
    opacity: 0,
  },
  minimized: {
    y: "calc(100% - 55px)",
    opacity: 1,
  },
  opened: {
    y: "0%",
    opacity: 1,
  },
};

const Modal = (props) => {
  const { t, ready } = useTranslation("common", {
    useSuspense: false,
  });
  const [minimized, toggleMinimized] = useToggle(false);
  const onBookClick = () => {
    props.openBookingModal();
    analyticsAction({ category: "Booking", action: "booktime" });
  };
  if (!ready) return null;
  return (
    <AnimatePresence initial={false}>
      {props.isOpen && (
        <MotionModal
          exit="exit"
          initial="initial"
          animate={minimized ? "minimized" : "opened"}
          variants={variants}
        >
          <Content
            minimized={minimized}
            toggleMinimized={toggleMinimized}
            t={t}
            onBookClick={onBookClick}
            basket={props.basket}
            clearBasket={props.clearBasket}
            finalPrice={props.finalPrice}
          />
        </MotionModal>
      )}
    </AnimatePresence>
  );
};

const Content = ({
  minimized,
  toggleMinimized,
  t,
  onBookClick,
  basket,
  clearBasket,
  finalPrice,
}) => {
  return (
    <>
      <S.Top>
        <S.Line />
        <S.Circ />
        <S.CloseButton minimized={minimized} onClick={toggleMinimized}>
          <DenyIcon color="white" />
        </S.CloseButton>
      </S.Top>
      <S.Main>
        <div>
          <S.InfoLine>
            <S.InfoInfo>{t(`${ns}.count`)}:</S.InfoInfo>
            <S.InfoValue>{basket.length}</S.InfoValue>
          </S.InfoLine>
          <S.InfoLine>
            <S.InfoInfo>{t(`${ns}.sum`)}:</S.InfoInfo>
            <S.InfoValue>{CurrencyFormatter.format(finalPrice)}</S.InfoValue>
          </S.InfoLine>
        </div>
        <S.ButtonsWrap>
          <S.BookButton id="choosetime" onClick={onBookClick} greenish>
            {t(`${ns}.book_cta`)}
          </S.BookButton>
          <S.BaseButton negative onClick={() => clearBasket()}>
            {t(`${ns}.flush`)}
          </S.BaseButton>
        </S.ButtonsWrap>
      </S.Main>
    </>
  );
};

export default Modal;
