import loadable from "@loadable/component";

const GlobalProgress = loadable(
  () => import(/* webpackChunkName: "global-progress" */ "./main"),
  {
    ssr: false,
  }
);

export default GlobalProgress;
