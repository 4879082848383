import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { requiredMixin } from "@/styles/mixins";

export const Wrap = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  ${({ theme }) => `
    @media(max-width: ${theme.mobileBp}) {
      -webkit-overflow-scrolling: touch;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
    } 
  `}
`;
export const Item = styled.li`
  margin-inline-end: 8px;
  margin-bottom: 8px;
  :last-child {
    margin-inline-end: 0;
  }
  ${({ theme }) => `
    @media(max-width: ${theme.mobileBp}) {
      margin-bottom: 0;
    } 
  `}
`;

export const Link = styled(NavLink).attrs({
  className: "ripple-btn",
})`
  border-radius: var(--root-br);
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  background-color: var(--bg-touchable);
  color: var(--font-color-touchable);
  display: block;
  padding: 12px 15px;
  ${({ $cta }) => $cta && requiredMixin};
`;
