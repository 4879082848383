import React from "react";

const PremiumPageIcon = ({ color = "currentColor", ...props }) => (
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6154 2.1005L19.2579 4.36744C20.0009 4.62143 20.5 5.30542 20.5 6.0714V12.7122C20.5 14.7252 19.7519 16.6741 18.3946 18.1951C17.773 18.8921 16.9755 19.4911 15.9567 20.0271L12.3663 21.912C12.2542 21.97 12.1307 22 12.0062 22C11.8827 22 11.7592 21.97 11.647 21.912L8.04946 20.026C7.02863 19.4891 6.23008 18.8901 5.60956 18.1961C4.25018 16.6731 3.5 14.7232 3.5 12.7082C3.5 12.3022 3.83959 11.9723 4.25739 11.9723C4.67518 11.9723 5.01477 12.3022 5.01477 12.7082C5.01477 14.3702 5.6322 15.9762 6.75285 17.2321C7.25194 17.7911 7.91156 18.2811 8.76876 18.7311L12.0062 20.428L15.2364 18.7321C16.0915 18.2831 16.7512 17.7931 17.2513 17.2321C18.3699 15.9772 18.9863 14.3732 18.9863 12.7122V6.0714C18.9863 5.9294 18.8936 5.8034 18.7568 5.75641L12.1142 3.48946C12.0412 3.46446 11.9599 3.46446 11.8858 3.48946L5.24425 5.75641C5.10738 5.8034 5.01477 5.9294 5.01477 6.0714V7.97135C5.01477 8.37834 4.67518 8.70833 4.25739 8.70833C3.83959 8.70833 3.5 8.37834 3.5 7.97135V6.0714C3.5 5.30542 4.00012 4.62143 4.7431 4.36744L11.3846 2.1005C11.7818 1.9665 12.2192 1.9665 12.6154 2.1005ZM11.2862 14.3175C11.0846 14.3175 10.8931 14.2395 10.7511 14.1015L8.84121 12.2455C8.54587 11.9576 8.54587 11.4936 8.84121 11.2056C9.13758 10.9176 9.61609 10.9176 9.91143 11.2056L11.2862 12.5405L14.6842 9.23962C14.9795 8.95263 15.4591 8.95263 15.7544 9.23962C16.0497 9.52661 16.0497 9.9926 15.7544 10.2796L11.8214 14.1015C11.6793 14.2395 11.4869 14.3175 11.2862 14.3175Z"
      fill={color}
    />
  </svg>
);

export default PremiumPageIcon;
