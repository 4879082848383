import { connect } from "react-redux";
import { Selectors } from "@/store/scheduler/selectors";
import { Selectors as PrefsSelectors } from "@/store/prefs/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Effects } from "@/store/scheduler/effects";
import loadable from "@loadable/component";

const Scheduler = loadable(
  () => import(/* webpackChunkName: "scheduler" */ "./"),
  {
    ssr: false,
  }
);
const mSp = (state) => ({
  currency: UserSelectors.getCurrency(state),
  isVisited: Selectors.isCalendarVisited(state),
  masterId: UserSelectors.getMasterId(state),
  disableBusiness: UserSelectors.isStandardMode(state),
  isStandardMode: UserSelectors.isStandardMode(state),
  weekBusinessHours: UserSelectors.getMasterWeekBusinessHours(state),
  dailySleep: UserSelectors.isStandardMode(state)
    ? PrefsSelectors.getDailySleepMinutes(state)
    : 0,
});
const mDp = {
  visit: Effects.visitCalendar,
};

export default connect(mSp, mDp)(Scheduler);
