import { get, put, post, remove } from ".";
import { API_URL } from "@/misc/constants";

const BASE = `${API_URL}/saloon`;

const create = async (payload) => {
  const res = put(BASE, payload);
  return res;
};
const findById = (id) => {
  return get(`${BASE}/${id}`, { cache: false });
};
const getProfile = (id) => {
  return get(`${BASE}/${id}/profile`, { cache: false });
};
const updateById = async (id, payload) => {
  const res = post(`${BASE}/${id}`, payload);
  return res;
};
const deleteById = async (id) => remove(`${BASE}/${id}`);
const getMySaloons = () => {
  return get(`${BASE}/mySaloons`, { cache: false });
};
const updateSchedule = async (id, payload) => {
  const res = await post(`${BASE}/${id}/schedule`, payload);
  return res;
};

const leaveSaloon = async (saloonId, masterId) => {
  const res = await post(`${BASE}/${saloonId}/master/${masterId}/leave`);
  return res;
};
const getReviews = (id, params) => {
  return get(`${BASE}/${id}/profile/reviews`, {
    params,
    cache: false,
  });
};

// clients
const getClients = (id, params) => {
  return get(`${BASE}/${id}/clients`, {
    params,
    cache: false,
  });
};

// invites
const createInvite = async (saloonId, payload) => {
  const res = await put(`${BASE}/${saloonId}/invite`, payload, {
    params: {
      auto_accept: true,
    },
  });
  return res;
};
const removeInvite = async (id) => {
  const res = await remove(`${BASE}/invite/${id}`);
  return res;
};
const acceptInvite = async (id) => {
  const res = await post(`${BASE}/invite/${id}/accept`);
  return res;
};
const rejectInvite = (id) => {
  return post(`${BASE}/invite/${id}/reject`);
};
const removeMaster = async (saloonId, masterId) => {
  const res = await remove(`${BASE}/${saloonId}/master/${masterId}`);
  return res;
};
const removeAdmin = async (saloonId, adminId) => {
  const res = await remove(`${BASE}/${saloonId}/admin/${adminId}`);
  return res;
};

const dashboard = {
  getInvites(id) {
    return get(`${BASE}/${id}/dashboard/invites`, { cache: false });
  },
  getMasters(id) {
    return get(`${BASE}/${id}/dashboard/masters`, { cache: false });
  },
  getAdmins(id) {
    return get(`${BASE}/${id}/dashboard/admins`, { cache: false });
  },
  getStats(id, params) {
    return get(`${BASE}/${id}/dashboard/stats/overall`, {
      cache: false,
      params,
    });
  },
  getMaterialStats(id, params) {
    return get(`${BASE}/${id}/dashboard/stats/materials`, {
      cache: false,
      params,
    });
  },
  getClientStats(id, params) {
    return get(`${BASE}/${id}/dashboard/stats/clients`, {
      cache: false,
      params,
    });
  },
};

export const SaloonAPI = {
  create,
  getReviews,
  createInvite,
  findById,
  deleteById,
  removeInvite,
  removeMaster,
  leaveSaloon,
  acceptInvite,
  rejectInvite,
  removeAdmin,
  dashboard,
  getMySaloons,
  getProfile,
  updateById,
  updateSchedule,
  getClients,
};
