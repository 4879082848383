import { createSlice } from "@reduxjs/toolkit";
import { REVIEWS_PER_PAGE } from "@/misc/constants";
import deepExtend from "@/utils/deepExtend";

const DOMAIN = "reviews";
const initialState = {
  loading: false,
  error: null,
  items: [],
  isMore: true,
  review: null,
  reviewMaster: null,
  reviewSaloon: null
};

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setReviews(state, { payload = [] }) {
      state.items = payload;
      state.isMore = payload.length >= REVIEWS_PER_PAGE;
    },
    setComments(state, { payload }) {
      const items = [
        state.items.find(({ _id }) => _id === payload.id),
        state.review?._id === payload.id && state.review
      ].filter(Boolean);
      items.forEach(review => {
        review.comments = payload.payload;
      });
    },
    addLike(state, { payload: id }) {
      const items = [
        state.items.find(({ _id }) => _id === id),
        state.review?._id === id && state.review
      ].filter(Boolean);
      items.forEach(item => {
        item.likes_count += 1;
      });
    },
    removeLike(state, { payload: id }) {
      const items = [
        state.items.find(({ _id }) => _id === id),
        state.review?._id === id && state.review
      ].filter(Boolean);
      items.forEach(item => {
        item.likes_count = Math.max(0, item.likes_count - 1);
      });
    },
    addComment(state, { payload }) {
      const items = [
        state.items.find(({ _id }) => _id === payload.id),
        state.review?._id === payload.id && state.review
      ].filter(Boolean);
      items.forEach(item => {
        item.comments_count += 1;
        if (Array.isArray(item.comments)) {
          item.comments.push(payload.payload);
        } else {
          item.comments = [payload.payload];
        }
      });
    },
    removeComment(state, { payload }) {
      const items = [
        state.items.find(({ _id }) => _id === payload.reviewId),
        state.review?._id === payload.reviewId && state.review
      ].filter(Boolean);
      items.forEach(review => {
        review.comments_count = Math.max(0, review.comments_count - 1);
        const commentIdx = review.comments.findIndex(
          ({ _id }) => _id === payload.commentId
        );
        if (commentIdx !== -1) {
          review.comments.splice(commentIdx, 1);
        }
      });
    },
    updateReview(state, { payload }) {
      const review = state.items.find(({ _id }) => _id === payload.id);
      if (review) {
        deepExtend(review, payload.updates);
      }
      if (state.review?._id === payload.id) {
        deepExtend(state.review, payload.updates);
      }
    },
    loadReviewsStart(state) {
      state.error = null;
      state.loading = true;
    },
    loadReviewsSuccess(state, { payload }) {
      state.loading = false;
      state.items = state.items.concat(payload);
      state.isMore = payload.length >= REVIEWS_PER_PAGE;
    },
    loadReviewsError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    loadReviewStart(state) {
      state.error = null;
      state.loading = true;
    },
    loadReviewSuccess(state, { payload }) {
      state.loading = false;
      state.review = payload.review;
      state.reviewMaster = payload.master;
      state.reviewSaloon = payload.saloon;
    },
    loadReviewError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const Actions = slice.actions;
export default slice.reducer;
