import React from "react";
import * as S from "./styled";
import { useDispatch, useStore } from "react-redux";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Actions as UserRegisterActions } from "@/store/userRegister/slice";
import { Selectors as UserRegisterSelectors } from "@/store/userRegister/selectors";
import { Link } from "react-router-dom";
import PT from "prop-types";
import ThemeToggler from "../ThemeToggler";
import LocaleSwitch from "../LocaleSwitch";
import { SITE_URL } from "@/misc/constants";

const AnonHeader = ({ t, i18n }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const openAuthModal = () => {
    const registerForm = {
      show_client_role: true,
    };
    if (!UserRegisterSelectors.isRoleSelected(store.getState())) {
      registerForm.is_client = true;
    }
    dispatch(UserRegisterActions.updateForm(registerForm));
    dispatch(ModalActions.openAuth());
  };
  return (
    <S.Wrap>
      <S.LeftCol>
        <Link to="/">
          <S.Logo />
        </Link>
        <LocaleSwitch
          i18n={i18n}
          css={`
            margin-left: 15px;
          `}
        />
        <ThemeToggler
          css={`
            display: inline-block;
            margin-left: 15px;
            @media (max-width: 500px) {
              display: none;
            }
          `}
        />
      </S.LeftCol>
      <S.RightCol>
        <S.Button as="a" href={SITE_URL + "/partners"} target="_blank">
          {t("common:profile_upper_menu.for_masters")}
        </S.Button>
        <S.Button onClick={openAuthModal}>
          {t("profile_upper_menu.login")}
        </S.Button>
      </S.RightCol>
    </S.Wrap>
  );
};
AnonHeader.propTypes = {
  t: PT.func.isRequired,
};

export default AnonHeader;
