import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selectors as ModalSelectors } from "@/store/modals/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import NoteEditor from "./";
import { useTranslation } from "react-i18next";

const GlobalNoteEditorModal = () => {
  const { t, ready } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  const isOpen = useSelector(ModalSelectors.isNoteEditorOpen);
  const params = useSelector(ModalSelectors.getNoteEditorParams);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(ModalActions.closeNoteEditor());
  }, []);
  if (!ready) return null;
  return <NoteEditor onClose={onClose} isOpen={isOpen} {...params} t={t} />;
};

export default GlobalNoteEditorModal;
