import React from "react";
import * as S from "./styled";
import PT from "prop-types";
import { generateUrl } from "@/utils/cloudinary";
import { useTranslation } from "react-i18next";
import SpecsList from "_c/SpecsList";
import { useTheme } from "@/hooks/useTheme";

const { StaticHeader, ArticleHeader, ArticleHeaderText, StaticHeaderText } = S;

const BlogHeader = (props) => {
  const theme = useTheme();
  const { t, ready } = useTranslation(["blog"], {
    useSuspense: false,
  });
  if (!ready) return null;
  const isDark = theme.__name__ === "dark";
  const Header = props.isStatic ? StaticHeader : ArticleHeader;
  const HeaderText = props.isStatic ? StaticHeaderText : ArticleHeaderText;
  const title = props.isStatic ? t("blog:title_h1") : props.title;
  const content = props.isStatic ? t("blog:description") : props.desc;
  return (
    <Header
      url={generateUrl(props.thumb, {
        background: "black",
        opacity: 70,
        secure: true,
      })}
    >
      <SpecsList color="white" />
      <S.HeaderDecor
        src={isDark ? "/images/blog_top_dark.svg" : "/images/blog_top.svg"}
      />
      <HeaderText>
        <h1>{props.isStatic ? title : title || ""}</h1>
        <p>{content}</p>
      </HeaderText>
    </Header>
  );
};
BlogHeader.propTypes = {
  isAuth: PT.bool,
  isMaster: PT.bool,
  title: PT.string,
  desc: PT.string,
  content: PT.string,
  thumb: PT.string,
};

export default BlogHeader;
