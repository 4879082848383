// @ts-nocheck
import React from "react";
import TextInput from "./TextInput";
import { useFormContext, Controller } from "react-hook-form";
import Message from "./Message";
import Label from "./Label";
import Sect from "./Sect";
import PlacesAutocomplete from "_c/PlacesAutocomplete";

type TAbstractProps = any;
const ErrorMessage = ({ error, errorId, t }) => (
  <Message
    id={errorId}
    error
    message={t([
      `errors:${error.message}`,
      `errors:${
        error.type === "serverError" ? "unspecified_server" : "unspecified"
      }`,
    ])}
  />
);

export const AbstractControlledField = ({
  Component,
  name,
  id,
  sectStyle,
  label,
  warn,
  isRequired,
  ...rest
}: TAbstractProps) => {
  const { errors, control } = useFormContext();
  const { t } = rest;
  const error = errors[name];
  const errorId = "error-" + id;
  return (
    <Sect style={sectStyle}>
      {label && (
        <Label isRequired={isRequired} htmlFor={id}>
          {label}
        </Label>
      )}
      <Controller
        error={Boolean(error)}
        control={control}
        as={Component}
        name={name}
        aria-describedby={errorId}
        aria-invalid={error ? "true" : "false"}
        id={id}
        {...rest}
      />
      {error && <ErrorMessage t={t} error={error} errorId={errorId} />}
      {warn && <Message warn message={warn} />}
    </Sect>
  );
};
export const PlacesAutocompleteField = (props) => {
  return (
    <AbstractControlledField
      Component={PlacesAutocomplete}
      InputComponent={TextInput}
      {...props}
    />
  );
};
export const AbstractField = ({
  Component,
  name,
  id,
  label,
  t,
  warn,
  isRequired,
  sectStyle,
  ...rest
}: TAbstractProps) => {
  const { register, errors } = useFormContext();
  const error = errors[name];
  const errorId = "error-" + id;
  return (
    <Sect style={sectStyle}>
      {label && (
        <Label isRequired={isRequired} htmlFor={id}>
          {label}
        </Label>
      )}
      <Component
        name={name}
        aria-describedby={errorId}
        aria-invalid={error ? "true" : "false"}
        error={Boolean(error)}
        id={id}
        ref={register}
        {...rest}
      />
      {error && <ErrorMessage t={t} error={error} errorId={errorId} />}
      {warn && <Message warn message={warn} />}
    </Sect>
  );
};

export const TextField = (props: TAbstractProps) => {
  return <AbstractField {...props} Component={TextInput} />;
};
