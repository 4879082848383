import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selectors as ModalSelectors } from "@/store/modals/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import TaskEditor from "./modal";
import { useMasterId } from "@/hooks/useMasterId";

const GlobalCreateTaskModal = () => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(ModalActions.closeCreateTask());
  }, []);
  const authMasterId = useMasterId();
  const params = useSelector(ModalSelectors.getCreateTaskParams);
  const isOpen = useSelector(ModalSelectors.isCreateTaskOpen);
  const masterId = params.masterId || authMasterId;
  if (!masterId) {
    return null;
  }
  return (
    <TaskEditor
      mode="custom"
      params={params}
      onClose={onClose}
      saloonOnly={params?.saloonOnly}
      isOpen={isOpen}
      masterId={masterId}
      {...params}
    />
  );
};

export default GlobalCreateTaskModal;
