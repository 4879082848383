import React from "react";

const BonusPageIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M9.885 14.767a25.263 25.263 0 0 1 4.317 0 14.93 14.93 0 0 1 2.334.343c1.66.335 2.744.995 3.198 1.963a3.001 3.001 0 0 1-.026 2.525c-.463.968-1.547 1.628-3.234 1.97-.765.173-1.542.285-2.324.335-.866.097-1.6.097-2.282.097h-.27a.764.764 0 0 1-.68-.761c0-.391.293-.72.68-.761h.598A23.92 23.92 0 0 0 14 20.39a14.235 14.235 0 0 0 2.115-.3c1.197-.263 1.95-.642 2.185-1.143.184-.384.184-.83 0-1.214-.236-.51-.988-.907-2.159-1.144a13.61 13.61 0 0 0-2.15-.308 22.39 22.39 0 0 0-4.064 0c-.714.046-1.424.146-2.124.299-1.197.264-1.94.642-2.185 1.144a1.496 1.496 0 0 0 0 1.223 2.54 2.54 0 0 0 1.748 1.03l.102.027a.769.769 0 0 1 .281 1.273.757.757 0 0 1-.767.177 3.871 3.871 0 0 1-2.7-1.847 2.966 2.966 0 0 1 0-2.526c.462-.994 1.546-1.636 3.216-1.98a16.032 16.032 0 0 1 2.386-.334Zm.08-12.36a5.297 5.297 0 0 1 5.801 1.166 5.385 5.385 0 0 1 1.149 5.842 5.322 5.322 0 0 1-4.924 3.302c-2.936-.005-5.314-2.402-5.314-5.358l.005-.232a5.357 5.357 0 0 1 3.283-4.72Zm2.026 1.124c-1.01-.002-1.98.4-2.694 1.118A3.84 3.84 0 0 0 8.18 7.36a3.837 3.837 0 0 0 2.347 3.55 3.792 3.792 0 0 0 4.155-.826 3.855 3.855 0 0 0 .83-4.182 3.81 3.81 0 0 0-3.521-2.37Z"
    />
  </svg>
);

export default BonusPageIcon;
