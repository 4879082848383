import { connect } from "react-redux";
import { Selectors } from "@/store/blog/selectors";
import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Effects } from "@/store/blog/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import Article from "./loadable";

const mSp = (state) => ({
  article: Selectors.getArticle(state),
  canCreateArticle: Selectors.canCreateArticle(state),
  favorites: FavSelectors.getFavoriteArticles(state),
  isAuth: AuthSelectors.isAuth(state),
  isOwner: Selectors.isOwner(state),
  isAdmin: UserSelectors.isAdmin(state),
  authUserId: UserSelectors.getUserId(state),
});
const mDp = {
  addComment: Effects.addComment,
  removeComment: Effects.removeComment,
  loadArticle: Effects.loadArticle,
  removeArticle: Effects.removeArticle,
  toggleLike: Effects.toggleLike,
  openShareModal: ModalActions.openShare,
};

export default connect(mSp, mDp)(Article);
