import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend/cjs";
import {
  I18NEXT_TRANSLATIONS_URL,
  I18NEXT_RELOAD_TRANSLATIONS_INTERVAL,
} from "@/config";
const RELOAD_INTERVAL = I18NEXT_RELOAD_TRANSLATIONS_INTERVAL.client;
const RERENDER_REACT_I18_NEXT_EVENT = "__rerender_react__";

export function initI18n(lang) {
  const i18n = i18next.createInstance({
    // fallbackLng: [DEFAULT_LOCALE],
    fallbackLng: false,
    load: "languageOnly",
    defaultNS: "common",
    lng: lang,
    ns: [],
    saveMissing: false,
    debug: false,
    react: {
      useSuspense: false,
      bindI18n: `languageChanged ${RERENDER_REACT_I18_NEXT_EVENT}`,
    },
    backend: {
      loadPath: I18NEXT_TRANSLATIONS_URL,
      requestOptions: {
        cache: "no-cache",
      },
    },

    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    wait: true,
    initImmediate: false,
  });
  i18n.use(Backend).use(initReactI18next).init();
  if (typeof RELOAD_INTERVAL === "number" && RELOAD_INTERVAL > 0) {
    const rerender = () => i18n.emit(RERENDER_REACT_I18_NEXT_EVENT);
    setInterval(async () => {
      await i18n.reloadResources();
      rerender();
    }, RELOAD_INTERVAL);
  }
  window.__i18n = i18n;
  return i18n;
}
