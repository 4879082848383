import React, { useCallback, useState } from "react";
import Modal, { Content, Header } from "_c/Modals";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { Selectors } from "@/store/modals/selectors";
import { useSelector, useStore } from "react-redux";
import { ExternalNotificationsAPI as API } from "@/api/externalNotifications";
import NetworkRequest from "_c/NetworkRequest";
import TextInput from "_c/form/TextInput";
import UserCard from "_c/UserCard";
import MessageIcon from "_c/icon/Message";
import ClipboardIcon from "_c/icon/Clipboard";
import { CircleButton } from "_c/button";
import { copyToClipboard } from "@/utils";
import { useNotify } from "@/hooks/useNotify";
import ClientProfileModal from "_c/Modals/client-profile-modal";
import { stripPhone } from "@/utils";
import { SITE_URL } from "@/misc/constants";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import PremiumModal from "_c/Modals/premium-modal";
import { useAppConfig } from "@/hooks/useAppConfig";
import { LinkGenerator } from "@/utils/LinkGenerator";

const MainContent = ({
  client,
  message,
  openClientProfile,
  updateMessage,
  t,
}) => {
  const { notify } = useNotify();
  const encodedMessage = encodeURIComponent(message);
  const phone = stripPhone(client.phone);
  const masterId = useSelector(UserSelectors.getMasterId);
  const locale = useSelector(UserSelectors.getLocale);
  return (
    <div>
      <UserCard
        style={{ cursor: "pointer" }}
        role="button"
        avatar={client.avatar}
        sex={client.sex}
        header={client.full_name}
        subheader={client.phone}
        onClick={openClientProfile}
      />
      <TextInput
        data-scroll-lock-scrollable
        rows={10}
        as="textarea"
        onChange={({ target }) => updateMessage(target.value)}
        style={{
          width: "100%",
        }}
        value={message}
      />
      <div
        css={`
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          > * {
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
            margin-right: 10px;
          }
          img {
            display: block;
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
          }
        `}
      >
        {phone && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://wa.me/${phone}?text=${encodedMessage}`}
          >
            <img src="/icons/whatsapp.svg" />
          </a>
        )}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`viber://forward?text=${encodedMessage}`}
        >
          <img src="/icons/viber.svg" />
        </a>
        {client.tg_id && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={LinkGenerator.tg(client.tg_id)}
          >
            <img src="/icons/telegram.svg" />
          </a>
        )}
        {phone && (
          <CircleButton
            as="a"
            href={`sms://${phone}?&body=${encodedMessage}`}
            large
          >
            <MessageIcon
              style={{
                position: "relative",
                left: "1px",
              }}
              width="26"
              color="white"
            />
          </CircleButton>
        )}
        <CircleButton
          large
          onClick={() => {
            copyToClipboard(message);
            notify(t("common:clipboard_copy_success"));
          }}
        >
          <ClipboardIcon
            style={{
              position: "relative",
              left: "4px",
            }}
            width="24"
            color="white"
          />
        </CircleButton>
      </div>
    </div>
  );
};
const Main = (props) => {
  const data = useSelector(Selectors.getSendNotificationData);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const loader = useCallback(() => {
    return API.getMetadata(data);
  }, [data]);
  const { t } = useTranslation("common", { useSuspense: false });
  return (
    <>
      <Header onClick={props.onClose}>{t("common:close")}</Header>
      <Content
        css={`
          color: var(--font-color-main);
        `}
      >
        <NetworkRequest t={t} loader={loader} isEnabled={Boolean(data)}>
          {({ data: { client, task, message }, updateData }) => (
            <>
              <MainContent
                client={client}
                message={message}
                t={t}
                openClientProfile={() => setIsProfileOpen(true)}
                updateMessage={(message) =>
                  updateData({ task, client, message })
                }
              />
              <ClientProfileModal
                client={client}
                disableActions
                isOpen={isProfileOpen}
                onClose={() => setIsProfileOpen(false)}
                t={t}
              />
            </>
          )}
        </NetworkRequest>
      </Content>
    </>
  );
};
const WithPremium = (props) => {
  const isPremiumRequired =
    useAppConfig().premium_only__manual_client_notification;
  const store = useStore();
  const onPremiumClose = useCallback(() => {
    if (!UserSelectors.isPremium(store.getState())) {
      props.onClose();
    }
  }, [props.onClose]);
  const isPremium = useSelector(UserSelectors.isPremium);
  if (isPremiumRequired && !isPremium) {
    return <PremiumModal isOpen onClose={onPremiumClose} />;
  }
  return props.children;
};

const SendNotificationModal = (props) => {
  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen}>
      <WithPremium onClose={props.onClose}>
        <Main onClose={props.onClose} />
      </WithPremium>
    </Modal>
  );
};
SendNotificationModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
};

export default SendNotificationModal;
