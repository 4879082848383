import { useLayoutEffect } from "react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import uiVars from "./ui-vars";

// fixes library bug
export const useHidePopperOnOpen = ({ isCollapsed }) => {
  useLayoutEffect(() => {
    if (!isCollapsed) {
      const dropdowns = document.querySelectorAll(
        ".pro-inner-list-item.popper-element"
      );
      dropdowns.forEach((d) => {
        d.style.display = "none";
      });
    }
  }, [isCollapsed]);
};
export const useIsMobile = () => {
  const isMobile = useMediaQuery({ maxWidth: uiVars.mobileBp });
  return isMobile;
};
