import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const FinancePage = loadable(
  () => import(/* webpackChunkName: "finance-page" */ "_p/finance"),
  {
    ssr: false,
    fallback: <Spin size="xl" />,
  }
);

export default FinancePage;
