import { connect } from "react-redux";
import { Selectors } from "@/store/blog/selectors";
import { Effects } from "@/store/blog/effects";
import { Actions } from "@/store/blog/slice";
import Editor from "./loadable";

const mSp = (state) => ({
  form: Selectors.getEditorForm(state),
});
const mDp = {
  initEditor: Effects.initEditor,
  onSubmit: Effects.createOrUpdateArticle,
  updateForm: Actions.updateEditorForm,
  reset: Actions.resetEditorForm,
};

export default connect(mSp, mDp)(Editor);
