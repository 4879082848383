import { Actions } from "./slice";
import { Selectors } from "./selectors";
import { FEAT_FLAG_MULTI_ITEM_BASKET } from "@/misc/featureFlags";
import { Actions as ModalActions } from "../modals/slice";

const addToBasket = (payload) => (dispatch, getState) => {
  if (FEAT_FLAG_MULTI_ITEM_BASKET) {
    dispatch(Actions.addToBasket(payload));
    const basket = Selectors.getBasket(getState());
    localStorage.setItem("basket", JSON.stringify(basket));
  } else {
    dispatch(Actions.restoreBasket([payload]));
    dispatch(ModalActions.openBooking());
  }
};
const clearBasket = () => (dispatch) => {
  dispatch(Actions.clearBasket());
  if (FEAT_FLAG_MULTI_ITEM_BASKET) {
    localStorage.removeItem("basket");
  }
};
const removeFromBasket = (payload) => (dispatch, getState) => {
  dispatch(Actions.removeFromBasket(payload));
  const basket = Selectors.getBasket(getState());
  if (FEAT_FLAG_MULTI_ITEM_BASKET) {
    localStorage.setItem("basket", JSON.stringify(basket));
  }
  if (!basket.length) {
    dispatch(ModalActions.closeBooking());
  }
};

export const Effects = {
  addToBasket,
  clearBasket,
  removeFromBasket,
};
