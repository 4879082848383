import { useEffect } from "react";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { useSelector, useDispatch } from "react-redux";
import { Effects as AuthEffects } from "@/store/auth/effects";

export const useAfterLoginFlow = () => {
  const isAuth = useSelector(AuthSelectors.isAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth) {
      setTimeout(() => {
        dispatch(AuthEffects.afterLogin());
      }, [200]);
    }
  }, [isAuth]);
};
