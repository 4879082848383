import { createSelector } from "@reduxjs/toolkit";
import { DOMAIN } from "./slice";
import moment from "moment";

const $ = (s: tReduxRootState) => s[DOMAIN];
const isTrial = (s: tReduxRootState) => $(s).trial;
const getAllowTrial = (s: tReduxRootState) => $(s).allow_trial;
const getExpires = (s: tReduxRootState) => $(s).expires;
const isActive = createSelector(getExpires, (expires) => {
  if (expires) {
    return moment(expires).isAfter(moment());
  }
  return false;
});

export const Selectors = {
  getAllowTrial,
  getExpires,
  isActive,
  isTrial,
};
