import React from 'react';

const SvgBell = ({ color, ...props})=> (
  <svg width="12" viewBox="0 0 12 16" {...props}>
    <path
      fillRule="evenodd"
      fill={color || "currentColor"}
      d="M11.248 11.195h-.75V6.396c0-2.843-1.778-4.419-3.753-4.737V-.004H5.244v1.663c-1.975.318-3.753 1.894-3.753 4.737v4.799h-.75c-.415 0-.751.358-.751.8 0 .441.336.799.751.799h3.002v.8c0 1.325 1.008 2.4 2.252 2.4 1.243 0 2.251-1.075 2.251-2.4v-.8h3.002c.415 0 .751-.358.751-.799 0-.442-.336-.8-.751-.8zm-4.503 2.399c0 .442-.336.8-.75.8-.415 0-.751-.358-.751-.8v-.8h1.501v.8z"
    />
  </svg>
);

export default SvgBell;
