import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";

const buildByMasterQueryKey = (master) => {
  return [QueryKeys.discountsByMaster, master];
};
export const MasterDiscountsCacheService = {
  buildByMasterQueryKey,
  addDiscount({ discount }) {
    queryClient.setQueryDataIfCached(
      buildByMasterQueryKey(discount.master),
      (data) => {
        return data.concat(discount);
      }
    );
  },
  replaceDiscount({ discount: updated }) {
    queryClient.setQueryDataIfCached(
      buildByMasterQueryKey(updated.master),
      (data) => {
        return data.map((discount) =>
          discount._id === updated._id ? updated : discount
        );
      }
    );
  },
  removeDiscount({ discount }) {
    queryClient.setQueryDataIfCached(
      buildByMasterQueryKey(discount.master),
      (data) => {
        return data.filter((s) => s._id !== discount._id);
      }
    );
  },
};
