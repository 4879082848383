import { IS_BROWSER } from "@/misc/constants";

export const Translate = {
  serverError: (t, e) => t([`errors:${e}`, "errors:unspecified_server"]),
};

export const preloadNS = (ns) => {
  if (IS_BROWSER && ns && window.__i18n) {
    window.__i18n.loadNamespaces(ns);
  }
};
