import React, { useEffect, useRef } from "react";
import { Menu as BaseMenu } from "@szhsin/react-menu";
import { addScrollableTarget } from "scroll-lock";
import { useColorScheme } from "@/hooks/useColorScheme";

export const Menu = (props) => {
  const checkElTimeoutRef = useRef();
  useEffect(() => {
    return () => clearInterval(checkElTimeoutRef.current);
  }, []);
  const onMenuVisibilityChange = ({ open }) => {
    if (props.onMenuChange) props.onMenuChange({ open });
    const stopPolling = () => clearInterval(checkElTimeoutRef.current);
    stopPolling();
    if (open) {
      checkElTimeoutRef.current = setInterval(() => {
        const $el = document.querySelector(".szh-menu");
        if ($el) {
          stopPolling();
          addScrollableTarget($el);
        }
      }, 50);
    }
  };
  const colorScheme = useColorScheme();
  return (
    <BaseMenu
      theming={colorScheme === "dark" ? colorScheme : undefined}
      {...props}
      onMenuChange={onMenuVisibilityChange}
    />
  );
};
export { MenuButton, MenuItem, MenuRadioGroup } from "@szhsin/react-menu";
