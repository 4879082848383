import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { PHONE_VERIFY_CODE_WAIT } from "@/misc/constants";

export const DOMAIN = "settings";

const initialState = {
  mode: "common",
  isCareerVisited: false,
  isNotificationsVisited: false,
  phone: {
    phone: null,
    step: "input",
    wait: null,
    confirmed: false,
  },
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setIsCareerVisited(state, { payload }) {
      state.isCareerVisited = payload;
    },
    setIsNotificationsVisited(state, { payload }) {
      state.isNotificationsVisited = payload;
    },
    changeMode(state, { payload }) {
      state.mode = payload;
    },
    phoneStepConfirm(state, { payload }) {
      state.phone.step = "confirm";
      state.phone.phone = payload;
      state.phone.wait = moment()
        .add(PHONE_VERIFY_CODE_WAIT, "seconds")
        .toISOString();
    },
    phoneResetStep(state) {
      state.phone = initialState.phone;
    },
    phoneConfirmed(state) {
      state.phone.wait = null;
      state.phone.confirmed = true;
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
