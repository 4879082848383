import { QueriesAPI } from "./queries";
import isempty from "lodash/isEmpty";

export const getAll = QueriesAPI.professionSpecs;

export const getSearchable = async ({ locale }) => {
  const data = await QueriesAPI.professionSpecs({
    locale,
    searchable_only: true,
  });
  if (isempty(data)) return [];
  return Object.entries(data).map(([_id, data]) => ({ _id, ...data }));
};

export const SpecAPI = {
  getAll,
  getSearchable,
};
