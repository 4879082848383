import React from "react";
import BaseSwitch, { ReactSwitchProps as BaseSwitchProps } from "react-switch";
import { useTheme } from "@/hooks/useTheme";
import * as S from "./styled";

type tProps = {
  checked?: boolean;
  onChange: BaseSwitchProps["onChange"];
  activeColor?: string;
  inactiveColor?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  className?: string;
};
const Switch = (props: tProps) => {
  const theme = useTheme();
  const id = props.id || "switch";
  const onColor = props.activeColor || theme.color.primary;
  return (
    <S.Wrap className={props.className}>
      <BaseSwitch
        onColor={onColor}
        checked={props.checked as boolean}
        offColor={props.inactiveColor}
        onChange={props.onChange}
        id={id}
        activeBoxShadow={`0 0 2px 3px ${
          props.checked ? onColor : props.inactiveColor || onColor
        }`}
        disabled={props.disabled}
        checkedIcon={null as any}
        uncheckedIcon={null as any}
      />
      <S.Label htmlFor={id}>{props.label}</S.Label>
    </S.Wrap>
  );
};

export default Switch;
