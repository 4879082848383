import { Selectors as ProfileSelectors } from "./profile";
import { Selectors as ServicesSelectors } from "./services";
import { Selectors as SEOSelectors } from "./seo";
const isLoading = (state) => state.saloonProfile.loading;
const getError = (state) => state.saloonProfile.error;

export const Selectors = {
  ...SEOSelectors,
  ...ServicesSelectors,
  ...ProfileSelectors,
  isLoading,
  getError,
};
