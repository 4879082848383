import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import isempty from "lodash/isEmpty";
import first from "lodash/first";

export const DOMAIN = "saloonDashboard";

const initialState = {
  mode: "stats",
  statsRange: null,
  saloons: {},
  active: null,
  warehouseActiveTab: "products",
  employeesActiveTab: "staff",
  statsActiveTab: "overall",
  selectedSchedulerMaster: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState: {
    ...initialState,
    statsRange: [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
  },
  reducers: {
    setSaloons(state, { payload }) {
      if (isempty(payload)) {
        state.saloons = {};
      } else {
        if (!state.active) {
          state.active = first(payload)._id;
        }

        state.saloons = payload.reduce((acc, s) => {
          acc[s._id] = s;
          return acc;
        }, {});
      }
    },
    changeWarehouseActiveTab(state, { payload }) {
      state.warehouseActiveTab = payload;
    },
    changeMode(state, { payload }) {
      state.mode = payload;
    },
    changeStatsActiveTab(state, { payload }) {
      state.statsActiveTab = payload;
    },
    changeEmployeesActiveTab(state, { payload }) {
      state.employeesActiveTab = payload;
    },
    changeActiveSaloon(state, { payload }) {
      state.active = payload;
    },
    selectSchedulerMaster(state, { payload }) {
      state.selectedSchedulerMaster = payload;
    },
    updateStatsRange(state, { payload }) {
      state.statsRange = payload;
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
