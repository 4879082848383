import { createSelector } from "reselect";
import { FAVORITES_PER_PAGE } from "@/misc/constants";
import isempty from "lodash/isEmpty";

const getFavorites = (state) => state.favorites;
const getFavoriteArticles = createSelector(getFavorites, (fav) => fav.articles);
const getFavoriteUsers = createSelector(getFavorites, (fav) => fav.users);
const getFavoriteSaloons = createSelector(getFavorites, (fav) => fav.saloons);
const getFavoritePortfolio = createSelector(
  getFavorites,
  (fav) => fav.portfolio
);
const getFavoriteReviews = createSelector(getFavorites, (fav) => fav.reviews);
const getFavoritesByType = (state, type) => state.favorites[type];
const isFavoritesEmpty = createSelector(getFavoritesByType, (fav) =>
  isempty(fav)
);
const isFavorite = createSelector(
  [getFavoritesByType, (_state, _type, id) => id],
  (fav, id) => {
    return fav.includes(id);
  }
);

// view
const getError = (state, type) => state.favorites.view[type].error;
const getItems = (state, type) => state.favorites.view[type].items;
const isLoading = (state) => state.favorites.view.loading;
const getItemsToLoad = createSelector(
  [getFavoritesByType, getItems],
  (ids, loaded) => {
    const start = loaded.length;
    const slice = ids.slice(start, start + FAVORITES_PER_PAGE);
    return slice;
  }
);
const hasMoreItemsToLoad = createSelector(
  [getFavoritesByType, getItems],
  (ids, loaded) => {
    return ids.length > loaded.length;
  }
);

export const Selectors = {
  getFavoritesByType,
  getFavorites,
  getFavoriteArticles,
  getFavoriteUsers,
  getFavoriteSaloons,
  getFavoritePortfolio,
  getFavoriteReviews,
  isFavorite,
  isFavoritesEmpty,
  view: {
    getError,
    getItems,
    getItemsToLoad,
    hasMoreItemsToLoad,
    isLoading,
  },
};
