import ee from "@/utils/ee";

export const ProgressEmitter = {
  start() {
    ee.emit("global_progress/start");
  },
  end() {
    ee.emit("global_progress/end");
  },
};
