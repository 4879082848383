import { API_URL } from "@/misc/constants";
import { post } from "./index";
import isempty from "lodash/isEmpty";

const login = (data) => {
  return post(`${API_URL}/auth`, data);
};
const logout = () => {
  return post(`${API_URL}/auth/logout`);
};

const sessionLogin = ({ cookies } = {}) => {
  const headers = {
    ...(isempty(cookies) ? {} : { Cookie: cookies }),
  };
  return post(`${API_URL}/auth/session`, {}, { headers });
};

export const AuthAPI = {
  login,
  logout,
  sessionLogin,
};
