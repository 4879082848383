import React from "react";
import Modal, { Content } from "_c/Modals";
import { ClientsAPI } from "@/api/clients";
import Loadable from "./loadable";
import { usePreloadModules } from "@/hooks/usePreloadModules";
import { useQuery } from "react-query";
import { ClientsCacheService } from "@/services/clients/cache";
import QueryRequest from "_c/QueryRequest";
import Footer from "./Footer";
import type { tClientProfileViewerProps } from "./types";
import type { tBizClient } from "@/types";

const ClientLoaderProxy = (
  props: Omit<tClientProfileViewerProps, "isOpen">
) => {
  const shouldLoadClient =
    Boolean(props.client) && typeof props.client === "string";
  const query = useQuery(
    ClientsCacheService.buildSingleClientQueryKey(props.client as string),
    () => ClientsAPI.findById(props.client as string).then(({ data }) => data),
    {
      enabled: shouldLoadClient,
    }
  );
  const client = shouldLoadClient ? query.data : (props.client as tBizClient);
  if (shouldLoadClient && query.status !== "success") {
    return (
      <>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QueryRequest query={query}>{null}</QueryRequest>
        </Content>
        <Footer onClose={props.onClose} />
      </>
    );
  } else if (client) {
    return <Loadable {...props} client={client} />;
  } else {
    return (
      <>
        <Content></Content>
        <Footer onClose={props.onClose} />
      </>
    );
  }
};

const preloadModules = [Loadable];
const ClientProfileModal = ({
  isOpen,
  ...props
}: tClientProfileViewerProps) => {
  usePreloadModules({ modules: preloadModules, delay: 500 });
  return (
    <Modal mobileBp={500} onClose={props.onClose} isOpen={isOpen}>
      <ClientLoaderProxy {...props} />
    </Modal>
  );
};
export default ClientProfileModal;
