import { get } from "./";
import { API_URL } from "@/misc/constants";

const getUserAchieves = (id) => {
  return get(`${API_URL}/user/${id}/achievements`);
};
const getAchieves = () => {
  return get(`${API_URL}/achievement`);
};

export const AchievesAPI = {
  getAchieves,
  getUserAchieves,
};
