import { string } from "yup";
import {
  nameRegex,
  lastnameRegex,
  middlenameRegex,
  passRegex,
} from "@/misc/regex";
export const firstNameSchema = string().matches(nameRegex, {
  excludeEmptyString: true,
  message: "first_name",
});
export const lastNameSchema = string().matches(lastnameRegex, {
  excludeEmptyString: true,
  message: "last_name",
});
export const middleNameSchema = string().matches(middlenameRegex, {
  excludeEmptyString: true,
  message: "middle_name",
});
export const passwordSchema = string().matches(passRegex, {
  excludeEmptyString: true,
  message: "password_regex",
});
