import { useEffect } from "react";
import { preloadModules } from "@/utils/loadable";

export const usePreloadModules = ({ modules, delay = 1 }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      preloadModules(modules);
    }, delay);
    return () => clearTimeout(timeout);
  }, [modules]);
};
