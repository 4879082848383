import isempty from "lodash/isEmpty";
import c from "currency.js";

export function reduceServiceDiscounts({ discounts, userId, clientId }) {
  if (isempty(discounts)) return null;
  const highest = discounts.reduce((acc, d) => {
    if (d.consumer || d.client) {
      if (
        (d.consumer && d.consumer === userId) ||
        (d.client && d.client === clientId)
      ) {
        if (acc === null || d.amount > acc.amount) {
          return d;
        }
      } else {
        return acc;
      }
    }
    if (acc === null || d.amount > acc.amount) {
      return d;
    }
    return acc;
  }, null);
  return highest;
}
export const checkIfDiscountPersonal = (discount) => {
  return Boolean(discount) && Boolean(discount.consumer);
};

export function sum(n1, n2) {
  return c(n1).add(c(n2)).value;
}
export function mul(n1, n2) {
  return c(n1).multiply(c(n2)).value;
}
export function sub(n1, n2) {
  return c(n1).subtract(c(n2)).value;
}

// currency js
export const div = (n1, n2) => c(n1).divide(n2).value;
export const subtractPercent = (n, perc) => {
  return c(n).subtract(c(n).multiply(c(perc))).value;
};

export const Money = {
  isEqual(n1, n2) {
    return n1.amount === n2.amount;
  },
  sumArray(arr) {
    if (isempty(arr)) return null;
    const final = arr.reduce((acc, { amount }) => {
      acc = c(amount).add(acc);
      return acc;
    }, c(0));
    return {
      currency: arr[0].currency,
      amount: final.value,
    };
  },
  add(n1, n2) {
    return {
      currency: n1.currency,
      amount: c(n1.amount).add(n2.amount).value,
    };
  },
  sub(n1, n2) {
    return {
      currency: n1.currency,
      amount: c(n1.amount).subtract(c(n2.amount)).value,
    };
  },
  addScalar(n1, scalar) {
    return {
      currency: n1.currency,
      amount: c(n1.amount).add(c(scalar)).value,
    };
  },
  subScalar(n1, scalar) {
    return {
      currency: n1.currency,
      amount: c(n1.amount).subtract(c(scalar)).value,
    };
  },
  divByScalar(n1, scalar) {
    return {
      currency: n1.currency,
      amount: c(n1.amount).divide(c(scalar)).value,
    };
  },
  mulByScalar(n1, scalar) {
    return {
      currency: n1.currency,
      amount: c(n1.amount).multiply(c(scalar)).value,
    };
  },
  subtractPercent(money, perc) {
    return {
      currency: money.currency,
      amount: c(money.amount).subtract(c(money.amount).multiply(c(perc))).value,
    };
  },
};
