import { API_URL } from "@/misc/constants";
import { get, post, put, remove } from "./index";
import {
  iCreateWarehouseProductCategoryDto,
  iCreateWarehouseProductDto,
  iCreateWarehouseTxnDto,
  iGetWarehouseProductCategoriesArgs,
  iGetWarehouseProductsArgs,
  iGetWarehouseTxnsArgs,
  iUpdateWarehouseProductCategoryDto,
  iUpdateWarehouseProductDto,
  iUpdateWarehouseTxnDto,
  iWarehouseProduct,
  iWarehouseProductCategory,
  iWarehouseTxn,
} from "@/types";
import type { tHttpClientRes } from "@/types/http";

const BASE = `${API_URL}/warehouse`;
const TXN_BASE = `${BASE}/txn`;
const PRODUCT_BASE = `${BASE}/product`;
const CATEGORY_BASE = `${BASE}/productCategory`;

// txns

export const createTxn = (
  payload: iCreateWarehouseTxnDto
): tHttpClientRes<{
  txn: iWarehouseTxn;
  category?: iWarehouseProductCategory;
}> => put(TXN_BASE, payload);
export const updateTxn = (
  id: string,
  payload: iUpdateWarehouseTxnDto
): tHttpClientRes<{
  txn: iWarehouseTxn;
  category?: iWarehouseProductCategory;
}> => post(`${TXN_BASE}/${id}`, payload);
export const removeTxn = (
  id: string
): tHttpClientRes<{
  txn: iWarehouseTxn;
  category?: iWarehouseProductCategory;
}> => remove(`${TXN_BASE}/${id}`);
export const getTxnById = (id: string): Promise<iWarehouseTxn> =>
  get(`${TXN_BASE}/${id}`, { cache: false }).then((d) => d.data);
export const getTxns = (
  params: iGetWarehouseTxnsArgs
): Promise<{ txns: iWarehouseTxn[] }> =>
  get(TXN_BASE, {
    cache: false,
    params,
  }).then((d) => d.data);
export const cancelTxn = (
  id: string
): tHttpClientRes<{
  txn: iWarehouseTxn;
  category?: iWarehouseProductCategory;
}> => post(`${TXN_BASE}/${id}/cancel`);

// products

export const createProduct = (
  payload: iCreateWarehouseProductDto
): tHttpClientRes<{
  product: iWarehouseProduct;
}> => put(PRODUCT_BASE, payload);
export const updateProduct = (
  id: string,
  payload: iUpdateWarehouseProductDto
): tHttpClientRes<{
  product: iWarehouseProduct;
}> => post(`${PRODUCT_BASE}/${id}`, payload);
export const removeProduct = (
  id: string
): tHttpClientRes<{
  product: iWarehouseProduct;
}> => remove(`${PRODUCT_BASE}/${id}`);
export const getProductById = (id: string): Promise<iWarehouseProduct> =>
  get(`${PRODUCT_BASE}/${id}`, { cache: false }).then((d) => d.data);
export const getProducts = (
  params: iGetWarehouseProductsArgs
): Promise<{ products: iWarehouseProduct[] }> =>
  get(PRODUCT_BASE, {
    cache: false,
    params,
  }).then((d) => d.data);

// product categories

export const createProductCategory = (
  payload: iCreateWarehouseProductCategoryDto
): tHttpClientRes<{
  category: iWarehouseProductCategory;
}> => put(CATEGORY_BASE, payload);
export const updateProductCategory = (
  id: string,
  payload: iUpdateWarehouseProductCategoryDto
): tHttpClientRes<{
  category: iWarehouseProductCategory;
}> => post(`${CATEGORY_BASE}/${id}`, payload);
export const removeProductCategory = (
  id: string
): tHttpClientRes<{
  category: iWarehouseProductCategory;
}> => remove(`${CATEGORY_BASE}/${id}`);
export const getProductCategoryById = async (
  id: string
): Promise<iWarehouseProductCategory> => {
  const { data } = await get(`${CATEGORY_BASE}/${id}`, { cache: false });
  return data;
};
export const getProductCategories = async (
  params: iGetWarehouseProductCategoriesArgs
): Promise<iWarehouseProductCategory[]> => {
  const { data } = await get(CATEGORY_BASE, {
    cache: false,
    params,
  });
  return data;
};
export const WarehouseAPI = {
  createProductCategory,
  updateProductCategory,
  removeProductCategory,
  getProductCategoryById,
  getProductCategories,

  getTxns,
  getTxnById,
  createTxn,
  updateTxn,
  removeTxn,
  cancelTxn,

  createProduct,
  updateProduct,
  removeProduct,
  getProductById,
  getProducts,
};
