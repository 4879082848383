import React from "react";
import * as S from "./styled";
import Main from "./main";

const SupportModal = (props) => {
  return (
    <S.Modal {...props}>
      <S.CloseOverlay onClick={() => props.onClose()} />
      <Main onClose={props.onClose} />
    </S.Modal>
  );
};

export default SupportModal;
