import s from "styled-components";

const Paper = s.div`
  background: white;                  
  padding: 15px;                      
  border-radius: var(--root-br);
  box-shadow: ${({ theme }) => theme.shadow[0]};   
  background: ${({ cta }) => (cta ? "var(--color-cta)" : "var(--bg-paper)")};
  display: ${({ fullHeight }) => (fullHeight ? "flex" : "block")};
  flex-direction: ${({ fullHeight }) => (fullHeight ? "column" : "")};
  min-height: ${({ fullHeight }) => (fullHeight ? "100%" : "auto")};
`;
export default Paper;
