import React, { memo } from "react";
import * as S from "./styled";
import PT from "prop-types";
import { useSpecIcon } from "./hooks";
import { useSearchUrlGenerator } from "@/hooks/useSearchUrlGenerator";
import { useToggle } from "@/hooks/useToggle";

const hiddenSpecs = new Set("edu", "fitness");
const Spec = (props) => {
  const { desc, title, _id } = props.spec;
  const generateUrl = useSearchUrlGenerator();
  const icon = useSpecIcon(_id);
  const match = props.matchedPath;
  const activeSpec = props.query.spec || match?.params?.spec;
  if (!icon || hiddenSpecs.has(_id)) {
    return null;
  }
  return (
    <S.Slide
      $isActive={activeSpec === _id}
      to={generateUrl({
        query: {
          ...props.query,
          spec: _id,
          ...(props.manualLocation && {
            address_label: props.manualLocation.label,
            ...props.manualLocation.coords,
          }),
        },
        params: match && { ...match.params, spec: _id },
      })}
      title={desc}
    >
      <S.IconWrap>{icon}</S.IconWrap>
      <span>{title}</span>
    </S.Slide>
  );
};
const Specs = (props) => {
  const [isOpen, toggleIsOpen] = useToggle();
  return (
    <S.Wrap isOpen={isOpen}>
      <S.Head>{props.t("common:spec_many")}</S.Head>
      <S.Slider>
        {props.specs.map((spec) => (
          <Spec
            query={props.query}
            key={spec._id}
            spec={spec}
            matchedPath={props.matchedPath}
            manualLocation={props.manualLocation}
          />
        ))}
      </S.Slider>
      <S.ShowMoreBtn onClick={toggleIsOpen}>
        {props.t(`common:search_form.${isOpen ? "less" : "more"}`)}
      </S.ShowMoreBtn>
    </S.Wrap>
  );
};
Specs.displayName = "Specs";
Specs.propTypes = {
  query: PT.object.isRequired,
  specs: PT.array.isRequired,
  matchedPath: PT.object,
  manualLocation: PT.object,

  t: PT.func,
};

export default memo(Specs);
