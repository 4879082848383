import styled from 'styled-components';
import uiVars from '../../ui-vars';

export const Wrap = styled.button`
  margin-right: 10px;  
  line-height: 0;
  display: block;
  @media(max-width: ${uiVars.mobileBp}px) {
    display: none;
  }
`