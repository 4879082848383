import { IS_BROWSER } from "@/misc/constants";

export const IS_ANDROID =
  IS_BROWSER && /(android)/i.test(window.navigator.userAgent);

export const IS_MACOS =
  IS_BROWSER &&
  ["Macintosh", "MacIntel", "MacPPC", "Mac68K"].indexOf(
    window.navigator.platform
  ) !== -1;

export const IS_IOS =
  IS_BROWSER &&
  ["iPhone", "iPad", "iPod"].indexOf(window.navigator.platform) !== -1;

export const IS_APPLE_DEVICE = IS_MACOS || IS_IOS;

export const IS_STANDALONE: boolean =
  IS_BROWSER &&
  (window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator as any).standalone ||
    document.referrer.includes("android-app://"));
