import { toast } from "react-toastify";

const text = {
  ru: "Доступна новая версия. Нажмите, чтобы перезагрузить.",
  en: "New version is available. Tap to reload.",
};

const log = (msg) => console.log("[SW]:: " + msg);

function invokeServiceWorkerUpdateFlow(registration) {
  log("run update flow");
  const invoke = () => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  };
  const i18n = window.__i18n;
  toast.info(text[i18n?.language || "en"], {
    autoClose: false,
    position: "bottom-center",
    theme: "colored",
    draggable: false,
    pauseOnHover: false,
    hideProgressBar: true,
    closeOnClick: true,
    onClose: invoke,
  });
}

export function setupSw() {
  if ("serviceWorker" in navigator) {
    // wait for the page to load
    window.addEventListener("load", async () => {
      // register the service worker from the file specified
      const registration = await navigator.serviceWorker.register("/sw.js");

      // ensure the case when the updatefound event was missed is also handled
      // by re-invoking the prompt when there's a waiting Service Worker
      if (registration.waiting) {
        invokeServiceWorkerUpdateFlow(registration);
      }

      // detect Service Worker update available and wait for it to become installed
      registration.addEventListener("updatefound", () => {
        log("update found");
        if (registration.installing) {
          // wait until the new Service worker is actually installed (ready to take over)
          registration.installing.addEventListener("statechange", () => {
            log("state change");
            if (registration.waiting) {
              // if there's an existing controller (previous Service Worker), show the prompt
              if (navigator.serviceWorker.controller) {
                invokeServiceWorkerUpdateFlow(registration);
              } else {
                // otherwise it's the first install, nothing to do
                log("first install");
              }
            }
          });
        }
      });

      let refreshing = false;

      // detect controller change and refresh the page
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        log("controller change");
        if (!refreshing) {
          refreshing = true;
          setTimeout(() => {
            window.location.reload();
          });
        }
      });
    });
  }
}
