import { createSelector } from "@reduxjs/toolkit";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as FavoriteSelectors } from "@/store/favorites/selectors";
import { Selectors as PremiumSelectors } from "@/store/premium/selectors";

const getProfile = (state) => state.masterProfile.master;
const getMaster = getProfile;
const getUser = (state) => getMaster(state)?.user;
const getUserId = (state) => getUser(state)?._id;
const getMasterId = (state) => getMaster(state)?._id;

const isOwner = createSelector(
  getMasterId,
  UserSelectors.getMasterId,
  (masterId, authMasterId) => {
    return masterId === authMasterId;
  }
);
const isImported = (state) => getMaster(state)?.is_imported;
const getLastOnline = (state) => getUser(state)?.online_at;
const getAvatar = createSelector(
  [isOwner, getUser, UserSelectors.getAvatar],
  (isOwner, masterUser, authUserAvatar) => {
    if (isOwner) return authUserAvatar;
    return masterUser?.avatar;
  }
);
const getSex = (state) => getUser(state)?.sex;
const getFullName = (state) =>
  [getUser(state)?.first_name, getUser(state)?.last_name]
    .filter(Boolean)
    .join(" ");
const getBio = (state) => getUser(state)?.bio || "";
const getRate = (state) => getMaster(state)?.rate || 0;
const getReviewsCount = (state) => getMaster(state)?.reviews_count || 0;
const getCoords = (state) => getMaster(state)?.location?.coordinates;
const getHrAddress = (state) => getMaster(state)?.location_hr || "";
const getCity = (state) => getUser(state)?.location_hr || "";
const isPremium = createSelector(
  [getMaster, isOwner, PremiumSelectors.isActive],
  (master, isOwner, isActive) => {
    if (isOwner) return isActive;
    return master?.premium;
  }
);
const getPhone = (state) =>
  getUser(state)?.phone || getMaster(state)?.imported_phone;
const showPhone = (state) =>
  isImported(state) || (getMaster(state)?.show_phone && isPremium(state));
const isOnline = createSelector([getUser, isOwner], (user, isOwner) => {
  if (isOwner) return true;
  return user?.online;
});
const isFavorite = createSelector(
  getUserId,
  FavoriteSelectors.getFavoriteUsers,
  (userId, favorites) => {
    return favorites.includes(userId);
  }
);
const getFavoriteId = getUserId;
const getWeekSchedule = (state) => getMaster(state)?.schedule?.for_week || [];
const isSearchHidden = (state) => getMaster(state)?.search_hidden;

const getPublicFb = (state) => getMaster(state)?.public_fb;
const getPublicInsta = (state) => getMaster(state)?.public_insta;
const getPublicViber = (state) => getMaster(state)?.public_viber;
const getPublicVk = (state) => getMaster(state)?.public_vk;
const getPublicWa = (state) => getMaster(state)?.public_wa;
const getPublicTg = (state) => getMaster(state)?.public_tg;

export const Selectors = {
  getWeekSchedule,
  getCoords,
  getMaster,
  isPremium,
  isImported,
  isOnline,
  isSearchHidden,
  getLastOnline,
  getAvatar,
  getFavoriteId,
  getSex,
  getUserId,
  getMasterId,
  getFullName,
  getBio,
  getRate,
  getCity,
  getReviewsCount,
  getHrAddress,
  isOwner,
  isFavorite,
  getProfile,
  getPhone,
  showPhone,
  getPublicFb,
  getPublicInsta,
  getPublicViber,
  getPublicVk,
  getPublicWa,
  getPublicTg,
};
