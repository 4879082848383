import { IS_BROWSER } from "@/misc/constants";

const YM_COUNTER_ID = 55484272;
export const sendGoogleAction = ({ category, action }) => {
  if (IS_BROWSER && window.ga) {
    window.ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
    });
  }
};
export const sendYandexAction = (action) => {
  if (IS_BROWSER && window.ym) {
    window.ym(YM_COUNTER_ID, "reachGoal", action);
  }
};

export const getYandexMetrikaClientId = () => new Promise((res) => {
  try {
    window.ym(YM_COUNTER_ID, "getClientID", (clientId) => {
      if (clientId) {
        res(clientId);
      } else {
        res(undefined);
      }
    });
  } catch (e) {
    console.error(e);
    res(undefined);
  }
});
export const analyticsAction = ({ category, action }) => {
  sendGoogleAction({ category, action });
  sendYandexAction(action);
};
export const analyticsChangePage = () => {
  if (IS_BROWSER && window.ga) {
    window.ga("set", "page", window.location.pathname);
    window.ga("send", "pageview");
  }
};

export const analyticsSetUser = (userId) => {
  if (IS_BROWSER && window.ga) {
    window.ga("set", "&uid", userId);
    window.ga("send", "pageview");
  }
};
