import AuthHeader from "./";
import { connect } from "react-redux";
import { Effects as AuthEffects } from "@/store/auth/effects";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as NotificationSelectors } from "@/store/eventNotifications/selectors";
import { Selectors as MessengerSelectors } from "@/store/messenger/selectors";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";
import { Selectors as AppShellSelectors } from "@/store/appShell/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";

const mSp = (state) => ({
  name: UserSelectors.getFirstName(state),
  avatar: UserSelectors.getAvatar(state),
  sex: UserSelectors.getSex(state),
  freshNotificationsCount: NotificationSelectors.getFreshCount(state),
  freshMessagesCount: MessengerSelectors.getFreshMessagesCount(state),
  roleSpecificLinks: AppShellSelectors.getDesktopHeaderLinks(state),
  profileLink: UserSelectors.isBusinessMode(state)
    ? LinksSelectors.getProfile(state)?.route
    : null,
  messengerLink: LinksSelectors.getMessenger(state),
});
const mDp = {
  logout: AuthEffects.logout,
  openCreateTask: ModalActions.openCreateTask,
};

export default connect(mSp, mDp)(AuthHeader);
