import React from "react";
const AchievesSvg = ({ color, ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M9.084 11.477c.42 0 .762.338.762.756v.993h1.01a.763.763 0 0 1 .727.35.751.751 0 0 1 0 .803.763.763 0 0 1-.728.35h-1.01v1.003a.76.76 0 0 1-1.523 0V14.73H7.314a.763.763 0 0 1-.727-.35.751.751 0 0 1 0-.803.763.763 0 0 1 .727-.35h1.01v-.994l.007-.103a.76.76 0 0 1 .754-.653Zm7.085 3.71a.766.766 0 0 1 .823-.167.743.743 0 0 1 .243 1.221.757.757 0 0 1-1.062 0 .743.743 0 0 1-.004-1.054Zm-1.453-3.517a.749.749 0 0 1 .567 0c.095.04.182.097.257.167a.753.753 0 0 1 .168.818.615.615 0 0 1-.168.246.775.775 0 0 1-1.063 0 .727.727 0 0 1 0-1.064.682.682 0 0 1 .24-.167Zm7.28-.154c-.135-3.079-2.743-5.436-5.886-5.414a.698.698 0 0 0-.688.707c.005.384.32.7.706.688 2.447-.049 4.37 1.721 4.472 4.078.005.092.006.185.003.295v4.339a4.234 4.234 0 0 1-1.183 3.045 4.236 4.236 0 0 1-3.313 1.313c-2.775.047-5.538.047-8.256.001a4.262 4.262 0 0 1-4.455-4.374v-4.34a4.273 4.273 0 0 1 4.178-4.36c.092-.003.186 0 .308.003h4.687a.698.698 0 0 0 .698-.698v-.935a2.657 2.657 0 0 0-2.646-2.617H9.605a.556.556 0 0 1-.392-.161.55.55 0 0 1-.169-.395A.698.698 0 0 0 8.347 2H8.34a.698.698 0 0 0-.69.705c.006.522.214 1.012.587 1.377.374.368.866.539 1.383.561h.997c.684 0 1.247.554 1.255 1.229v.23l-3.959.001c-.125-.005-.249-.007-.37-.005-3.123.068-5.609 2.664-5.54 5.772v4.31c-.003.123-.002.246.003.37.137 3.12 2.78 5.565 5.865 5.412 1.341.024 2.706.035 4.077.035a228.7 228.7 0 0 0 4.142-.036 5.62 5.62 0 0 0 4.339-1.742 5.612 5.612 0 0 0 1.57-4.025v-4.307a5.743 5.743 0 0 0-.003-.371"
    />
  </svg>
);
export default AchievesSvg;
