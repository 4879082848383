import styled from "styled-components";
import uiVars from "../../ui-vars";
export { Wrap, Logo } from "../styled";

const colPad = "30px";
const mobileColPad = "10px";
export const Button = styled.button`
  margin-bottom: 2px;
  color: var(--font-color-emp);
  font-size: 16px;
  text-align: right;
  font-weight: 500;
  transition: opacity 0.35s ease;
  :hover {
    opacity: 0.7;
  }
`;
export const LeftCol = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${colPad};
  @media (max-width: ${uiVars.mobileBp}px) {
    padding-left: ${mobileColPad};
  }
`;
export const RightCol = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-right: ${colPad};
  @media (max-width: ${uiVars.mobileBp}px) {
    padding-right: ${mobileColPad};
  }
`;
export const Links = styled.div`
  display: flex;
`;
