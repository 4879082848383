import { createTheme } from "./utils";

const primary = "#464668";
const darkTheme = createTheme({
  id: "dark",
  name: "Dark",
  bg: {
    main: "#121212",
    paper: "#1E1E1E",
    elevate: "#272727",
  },
  color: {
    primary,
    primary_2: primary,
    primary_3: primary,
    primary_4: primary,
    touchable: "#383838",
    positive: "#31D0B0",
    negative: "#F453DB",
    progressBar: "#FFFFFF",
  },
  fontColor: {
    main: "#FFFFFF",
    secondary: "rgba(255,255,255, .9)",
    header: "#FFFFFF",
  },
  gradient: {
    primary: {
      normal: `linear-gradient(to bottom right,#0d324dff 0%,#7f5a83ff 100%)`,
    },
  },
  border: {
    light: "1px solid rgba(255,255,255,.075)",
    mediumColor: "rgba(255,255,255, 0.3)",
    lightColor: "rgba(255,255,255,.075)",
    schedulerColor: "rgba(255,255,255,.1)",
  },
  shadow: {
    mobileBottomBar: "none",
    mobileBottomBarFab: "none",
  },
  __name__: "dark",
});

export default darkTheme;
