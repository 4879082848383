import { post } from "./";
import { API_URL } from "@/misc/constants";

const enableFeature = (feature) => {
  return post(`${API_URL}/feature/enable`, {
    feature,
  });
};

export const FeaturesAPI = {
  enableFeature,
};
