import s from "styled-components";

export const Container = s.div`
  position: fixed;
  left: 50%;
  bottom: 75px;
  width: 300px;
  transform: translateX(-50%);
  z-index: 100;
`;
export const ContainerInner = s.div`
  @keyframes shine {
    0% {
      background-position: right;    
      opacity: 1;
          }
    100% {
      opacity: 0;
    }
  }
  background: var(--grad-primary-n);
  border-radius: 7px;
  text-align: center;
  color: white;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity .5s ease;
  padding: 20px 10px;
  padding-bottom: 15px;
  position: relative;
  ${({ isActive }) =>
    isActive &&
    `
    &::after {
	  	content:'';
      left: 0;
      top:0;
	    width:100%;
	    height:100%;
	    position: absolute;
      background-size:300% 100%;
	    z-index:1;
	    animation: shine 1.25s forwards;
      background-image: linear-gradient(
           130deg,
               transparent 33%,
                   rgba(255,255,255,0.7) 50%,
                       transparent 66%
                         );
    }
  `}

`;
export const Count = s.div`
  position: relative;
  img {
    width: 38px;
    height: 38px;
    z-index: 2;
  }
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-48%, -66%);
    font-weight: 600;
    font-size: 14px;
  }
`;
export const Title = s.h4`
   margin-top: 0;
   margin-bottom: 3px;
   font-size: 20px;
   font-family: ${({ theme }) => theme.font.accent};
   font-weight: 400;
   color: #ffd700;
`;
export const Desc = s.p`
  font-size: 14px;
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 10px;
`;
