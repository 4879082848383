import s from "styled-components";
import { CircleButton, Button } from "../../button";

export const Modal = s.div`
  width: 700px;
  position: fixed;
  bottom: 0;
  left: calc(50% - 350px);
  z-index: 1000;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background: var(--bg-paper);
  transform: translateY(100%);
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0 7px 15px rgba(72,69,50,0.15);
  @media(max-width: 730px) {
    width: 94vw;
    left: 3vw;
  }
`;

export const CloseButton = s(CircleButton).attrs({
  primaryNoGrad: true,
})`
  position: relative;
  z-index: 2;
  margin-left: auto;
  transition: transform .3s ease-in-out;
  outline: none;
  transform: rotate(${({ minimized }) => (minimized ? "-45deg" : "0deg")});
`;
export const Top = s.div`
  display: flex;
  position: relative;
  margin: 0 20px;
`;
export const Line = s.span`
  width: 100%;
  position: absolute;
  height: 1px;
  top: 50%;
  background-color: #d9d9d9;
  &::before, &::after {
    content: '';
    width: 7px;
    height: 7px;
    background-color: #aaa6aa;
    position: absolute;
    border-radius: 50%;
    top: -3px;
  }
  &::before {
    left: 45px;
  }
  &::after {
    left: 65px;
  }
`;
export const Circ = s.span`
  width: 7px;
  height: 7px;
  background-color: #aaa6aa;
  position: absolute;
  border-radius: 50%;
  top: calc(50% - 3px);
  left: 25px;
`;
export const Main = s.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  padding-top: 6px;
  > div {
    width: 50%;
  }
  > div:last-child {
    display: flex;
    justify-content: center;
  }
  @media(max-width: 730px) {
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
    > div:first-child {
      margin-bottom: 10px;
    }
  }
`;
export const ServicesAmount = s.div`
  font-size: 18px;
  color: #db1890;
`;
export const InfoLine = s.div`
  display: flex;
  margin-top: 10px;
  padding-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  :nth-last-child(2) {
    border: none;
  }
  :last-child {
    margin-top: 15px;
    border: none;
    padding-top: 4px;
    border-top: 1px solid #e0369f;
    font-size: 20px;
  }
`;
export const InfoInfo = s.span`
  color: var(--font-color-accent);
  margin-right: 10px;
`;
export const InfoValue = s.span`
  color: var(--color-cta);
`;

export const AddServicesHeader = s.h3`
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #89818a;
  color: var(--color-primary);
`;
export const AddServices = s.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px 5px;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #89818a;
  background-color: #b4f3e6;
  @media(max-width: 400px) {
    padding-bottom: 40px;
  }
`;
export const AddService = s.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; 
  padding-top: 0;
  transition: opacity .35s ease;
  :hover {
    opacity: .7;
  }
  span:last-child {
    margin-top: 3px;
  }
`;

export const ButtonsWrap = s.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const BaseButton = s(Button)`
  @media(max-width: 400px) {
    padding: 10px 30px;
  }
`;
export const BookButton = s(Button)`
  margin-bottom: 10px;
  @media(max-width: 400px) {
    padding: 10px 30px;
  }
`;
