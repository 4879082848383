import { Container as BaseContainer } from "reactstrap";
import styled from "styled-components";

// ^-^ holly molly ^-^
export const Container = styled(BaseContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible !important;
  transition: background-color 0.3s ease;
  background-color: var(--bg-root);
`;
export const ContentContainer = styled.div`
  padding: 0 25px;
  margin-top: 10px;
  @media (max-width: ${({ theme }) => theme.mobileBp}) {
    padding: ${({ removedPadOnMobile }) =>
      removedPadOnMobile ? "0" : "0 5px"};
  }
`;

export const AngleBlob = styled.div`
  position: absolute;
  width: 375px;
  height: 184.66px;
  right: 0;
  top: 0;
  background-image: ${({ theme }) => {
    return `url(/blobs/top_${theme.__name__}.svg)`;
  }};
  pointer-events: none;
  @media (max-width: 1250px) {
    width: 300px;
    height: 147.72px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
