import { Selectors } from "./selectors";
import { UserAPI } from "@/api/user";
import { BlogAPI } from "@/api/blog";
import { PortfolioAPI } from "@/api/portfolio";
import { FavoritesAPI } from "@/api/favorites";
import { SaloonAPI } from "@/api/saloon";
import { Actions } from "./slice";
import isempty from "lodash/isEmpty";

const toggleFavorite = (type, id) => (dispatch, getState) => {
  const isFav = Selectors.isFavorite(getState(), type, id);
  if (isFav) {
    dispatch(removeFavorite(type, id));
  } else {
    dispatch(addFavorite(type, id));
  }
};
const addFavorite = (type, id) => async (dispatch) => {
  dispatch(Actions.addFavorite({ type, id }));
  await FavoritesAPI.addFavorite(type, id);
};
const removeFavorite = (type, id) => async (dispatch) => {
  dispatch(Actions.removeFavorite({ type, id }));
  await FavoritesAPI.removeFavorite(type, id);
};

// view
const getLoader = (type) => {
  switch (type) {
    case "users":
      return UserAPI.findById;
    case "articles":
      return BlogAPI.getExcerpt;
    case "portfolio":
      return PortfolioAPI.findById;
    case "saloons":
      return SaloonAPI.findById;
    default:
      throw Error(`cannot find loader for the type "${type}"`);
  }
};
const loadItems = (type) => async (dispatch, getState) => {
  const state = getState();
  const toLoad = Selectors.view.getItemsToLoad(state, type);
  if (isempty(toLoad)) return;
  const loader = getLoader(type);
  try {
    dispatch(Actions.loadViewStart());
    const items = await Promise.all(
      toLoad.map((id) => loader(id).then(({ data }) => data))
    );
    dispatch(Actions.loadViewSuccess({ type, items }));
  } catch (e) {
    console.error(e);
    dispatch(Actions.loadViewError(e));
  }
};
const maybeLoad = (type) => (dispatch, getState) => {
  const state = getState();
  const empty = Selectors.isFavoritesEmpty(state, type);
  if (empty) {
    return;
  }
  const items = Selectors.view.getItems(state, type);
  if (isempty(items)) {
    dispatch(loadItems(type));
  }
};

export const Effects = {
  loadItems,
  maybeLoad,
  addFavorite,
  removeFavorite,
  toggleFavorite,
};
