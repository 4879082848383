import { SaloonInvitesCacheService as Cache } from "./cache";
import { SaloonStaffCacheService } from "../saloonStaffService/cache";

export const SaloonInviteMutationHandler = {
  removed({ invite }) {
    Cache.removeInvite({ id: invite._id, saloon: invite.saloon });
  },
  statusChanged({ invite }) {
    const saloonId = invite.saloon;
    const status = invite.status;
    const type = invite.type;
    Cache.updateInvite({
      id: invite._id,
      saloonId,
      updates: {
        status,
      },
    });
    if (status === "ACCEPT") {
      if (type === "master") {
        SaloonStaffCacheService.invalidateMasters(saloonId);
      } else if (type === "admin") {
        SaloonStaffCacheService.invalidateAdmins(saloonId);
      } else {
        // shouldn't be here
        SaloonStaffCacheService.invalidateAll(saloonId);
      }
    }
  },
};
