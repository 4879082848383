import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";

const connector = connect((state) => ({
  isAuth: AuthSelectors.isAuth(state),
  isMaster: UserSelectors.isMaster(state),
  isSaloonOwnerOrAdmin: UserSelectors.isSaloonOwnerOrAdmin(state),
}));
const EntrypointPage = ({ isAuth, isMaster, isSaloonOwnerOrAdmin }) => {
  if (!isAuth) {
    return <Redirect to="/partners" />;
  } else if (isSaloonOwnerOrAdmin) {
    return <Redirect to="/salon/dashboard/schedule" />;
  } else if (isMaster) {
    return <Redirect to="/master/schedule" />;
  } else {
    return <Redirect to="/client" />;
  }
};

export default connector(EntrypointPage);
