import { connect } from "react-redux";
import { Selectors as AppShellSelectors } from "@/store/appShell/selectors";
import { Actions } from "@/store/appShell/slice";
import loadable from "@loadable/component";
const DesktopAside = loadable(
  () =>
    import(
      /*webpackChunkName: "app-shell-desktop-aside" */
      "./"
    ),
  {
    ssr: false,
  }
);

const mSp = (state) => ({
  links: [AppShellSelectors.getSupportLink(state)].concat(
    AppShellSelectors.getDesktopNavigation(state)
  ),
  isCollapsed: AppShellSelectors.isLinksCollapsed(state),
});
const mDp = {
  toggleCollapse: Actions.toggleLinksCollapsed,
};
export default connect(mSp, mDp)(DesktopAside);
