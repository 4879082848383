import { Cache as BaseCache } from "axios-extensions";
import { IS_SERVER, IS_BROWSER } from "@/misc/constants";

const minute = 60000;
const DEFAULT_TTL = minute * 4;

class Cache extends BaseCache {
  constructor(ttl = DEFAULT_TTL) {
    super({
      maxAge: ttl,
    });
  }
}

const createCache = (ttl) => {
  if (IS_SERVER) return false;
  return new Cache(ttl);
};

export const servicesCache = createCache();
export const usersCache = createCache();
export const mastersCache = createCache();
export const searchCache = createCache();
export const articlesCache = createCache();
export const articleCommentsCache = createCache();
export const messagesCache = createCache();
export const portfolioCache = createCache();
export const portfolioCommentsCache = createCache();
export const reviewCache = createCache();
export const commentsCache = createCache();
export const notificationsCache = createCache();
export const smsTemplatesCache = createCache();
export const mastersSearchSeoMetaCache = createCache(minute);

// let react-query to deal with these caches on the client side
export const specsCache = IS_BROWSER ? false : new Cache(minute * 2);
export const appConfigCache = IS_BROWSER ? false : new Cache(minute * 2);
