import React from "react";
import AuthHeader from "./Auth/container";
import AnonHeader from "./Anon";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AppShellHeader = () => {
  const isAuth = useSelector(AuthSelectors.isAuth);
  const { t, ready, i18n } = useTranslation(["common"], { useSuspense: false });
  if (!ready) {
    return null;
  }
  if (isAuth) {
    return <AuthHeader t={t} />;
  } else {
    return <AnonHeader i18n={i18n} t={t} />;
  }
};
export default AppShellHeader;
