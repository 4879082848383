import { Actions } from "./slice";
import { MasterAPI } from "@/api/master";
import { Actions as PortfolioActions } from "@/store/portfolio/slice";
import { Actions as ReviewActions } from "@/store/reviews/slice";
import { addToClientHistory as addToHistory } from "@/utils/clientHistory";
import { Selectors } from "./selectors";
import { batch } from "react-redux";

const loadProfile = (id) => async (dispatch) => {
  dispatch(Actions.loadingStart());
  try {
    const { data, status } = await MasterAPI.getProfile(id);
    if (status === 301) {
      dispatch(Actions.loadingRedirect(data));
    } else {
      const { portfolio, reviews_initial_chunk, ...rest } = data;
      batch(() => {
        dispatch(PortfolioActions.setPortfolio(portfolio));
        dispatch(ReviewActions.setReviews(reviews_initial_chunk));
        dispatch(Actions.loadingSuccess(rest));
      });
    }
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};
const addToClientHistory = () => async (_dispatch, getState) => {
  const profile = Selectors.getMaster(getState());
  if (profile) {
    addToHistory({
      _id: profile._id,
      avatar: profile.user.avatar,
      first_name: profile.user.first_name,
      last_name: profile.user.last_name,
      sex: profile.user.sex,
    });
  }
};

export const Effects = {
  addToClientHistory,
  loadProfile,
};
