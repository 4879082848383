import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "eventNotifications";

const initialState = {
  loading: false,
  fresh: [],
  items: [],
  more: true,
  error: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    add(state, { payload }) {
      state.items.unshift(payload);
      state.fresh.push(payload._id);
    },
    clearFresh(state) {
      state.fresh = [];
    },
    addFromSocket(state, { payload }) {
      state.fresh.push(payload._id);
      state.items.unshift(payload);
    },
    loadingStart(state) {
      state.error = null;
      state.loading = true;
    },
    loadingSuccess(state, { payload }) {
      state.loading = false;
      state.more = payload.length >= 15;
      state.items = state.items.concat(payload);
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    setFresh(state, { payload }) {
      state.fresh = payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
