import styled from "styled-components";
import { requiredMixin } from "@/styles/mixins";

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 7px;
  color: var(--font-color-accent);
  ${({ isRequired }) => isRequired && requiredMixin};
`;
export default Label;
