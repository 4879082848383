import { createSelector } from "@reduxjs/toolkit";
import { generateAvatar } from "@/utils";
import { LinkGenerator } from "@/utils/LinkGenerator";
import { Selectors as UserSelectors } from "@/store/user/selectors";

const getArticles = (state) => state.blog.articles.items;
const isArticlesLoading = (state) => state.blog.articles.loading;
const getArticlesError = (state) => state.blog.articles.error;
const getArticlesCount = (state) => state.blog.articles.count;
const getArticlesPerPage = (state) => state.blog.articles.perPage;

const getPopularArticles = (state) => state.blog.popularArticles.items;
const isPopularArticlesLoading = (state) => state.blog.popularArticles.loading;
const getPopularArticlesError = (state) => state.popularArticles.error;

const getBestAuthors = (state) =>
  state.blog.bestAuthors.items.map(({ author }) => author);
const getBestAuthorsView = createSelector(getBestAuthors, (authors) => {
  return authors.map(({ sex, avatar, first_name, last_name, master, _id }) => ({
    url: master
      ? LinkGenerator.masterProfile(master)
      : LinkGenerator.clientProfile(_id),
    title: [first_name, last_name].filter(Boolean).join(" "),
    id: _id,
    avatar: generateAvatar({ sex, url: avatar }),
  }));
});
const getBestAuthorsError = (state) => state.blog.bestAuthors.error;
const isBestAuthorsLoading = (state) => state.blog.bestAuthors.loading;

const getArticle = (state) => state.blog.article.item;
const isArticleLoading = (state) => state.blog.article.loading;
const getArticleRedirectMeta = (state) => state.blog.article.redirect;
const getArticleError = (state) => state.blog.article.error;
const isOwner = createSelector(
  [getArticle, UserSelectors.getUserId],
  (article, userId) => {
    return article?.author?._id === userId;
  }
);

const getEditorForm = (state) => state.blog.editor;

const getHeaderTitle = (state, path) => {
  return path.startsWith("/blog/editor")
    ? state.blog.editor.title
    : state.blog.article.item?.title;
};
const getHeaderDesc = (state, path) => {
  return path.startsWith("/blog/editor")
    ? state.blog.editor.desc
    : state.blog.article.item?.desc;
};
const getHeaderThumb = (state, path) => {
  return path.startsWith("/blog/editor")
    ? state.blog.editor.thumb
    : state.blog.article.item?.thumb;
};

const canCreateArticle = createSelector(
  [UserSelectors.isMaster, UserSelectors.isAdmin],
  (isMaster, isAdmin) => isMaster || isAdmin
);

export const Selectors = {
  getArticles,
  isArticlesLoading,
  getArticlesError,
  getArticlesCount,
  getArticlesPerPage,

  getPopularArticlesError,
  getPopularArticles,
  isPopularArticlesLoading,

  getBestAuthorsError,
  getBestAuthors,
  getBestAuthorsView,
  isBestAuthorsLoading,

  canCreateArticle,

  getArticle,
  isArticleLoading,
  getArticleError,
  getArticleRedirectMeta,
  isOwner,

  getEditorForm,

  getHeaderTitle,
  getHeaderDesc,
  getHeaderThumb,
};
