import React, { useRef } from "react";
import * as S from "./styled";
import PT from "prop-types";
import { useScrollButtons } from "@/hooks/useScrollButtons";
import { CircleButton } from "_c/button";
import AngleRightIcon from "_c/icon/AngleRight";

const ProfileMenu = (props) => {
  const ref = useRef();
  const { visibleButtons, goNext } = useScrollButtons({ ref });
  return (
    <div
      css={`
        position: relative;
      `}
    >
      {visibleButtons.right && (
        <CircleButton
          light
          css={`
            position: absolute;
            z-index: 2;
            top: 5px;
            right: 0;
          `}
          onClick={goNext}
        >
          <AngleRightIcon color="black" />
        </CircleButton>
      )}
      <S.Wrap id={props.id} className={props.className} ref={ref}>
        {props.items.map((item) => (
          <S.Item key={item.key}>
            <S.Link
              $cta={item.cta}
              activeClassName="profile-menu-active"
              to={item.route}
            >
              {props.t(item.i18nFullPath || `common:menus.${item.key}`)}
            </S.Link>
          </S.Item>
        ))}
      </S.Wrap>
    </div>
  );
};
ProfileMenu.propTypes = {
  className: PT.string,
  id: PT.string,
  items: PT.array.isRequired,

  t: PT.func.isRequired,
};
export default ProfileMenu;
