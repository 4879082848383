import React, { useState } from "react";
import { Header, Content, FooterV2 } from "_c/Modals";
import { NotesService } from "@/services/notes";
import { useMut } from "@/hooks/useMut";
import { inputMixin } from "_c/form/TextInput";
import TextareaAutosize from "react-textarea-autosize";
import TrashIcon from "_c/icon/Trash";
import { CircleButton } from "_c/button";
import moment from "moment";

const ns = "common:note_editor.";
const FORM_ID = "note-editor-form";
const NoteEditor = (props) => {
  const { note, t } = props;
  const [text, setText] = useState(note?.text ?? "");
  const finalText = text.trim();
  const removeMut = useMut(
    () => {
      return NotesService.removeNote(note);
    },
    {
      notifyOnError: true,
      confirm: true,
      t,
      onSuccess() {
        props.onClose();
      },
    }
  );
  const mut = useMut(
    (form) => {
      if (note) {
        return NotesService.updateNote(note, form);
      } else {
        return NotesService.createNote(form);
      }
    },
    {
      notifyOnError: true,
      t,
      onSuccess() {
        props.onClose();
      },
    }
  );
  const onSubmit = (e) => {
    e.preventDefault();
    mut.mutate({
      text: finalText,
    });
  };
  return (
    <>
      <Header onClick={props.onClose}>{t("common:close")}</Header>
      <Content
        onSubmit={onSubmit}
        as="form"
        id={FORM_ID}
        css={`
          color: var(--font-color-main);
        `}
      >
        <TextareaAutosize
          placeholder={t(ns + "text")}
          autoFocus={!note}
          css={`
            ${inputMixin}
          `}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Content>
      {note && (
        <div
          css={`
            color: var(--font-color-main);
            flex: 0 0 auto;
            display: flex;
            margin: 5px 10px;
            justify-content: space-between;
            align-items: center;
            time {
              font-size: 14px;
            }
          `}
        >
          <time dateTime={note.created_at}>
            {moment(note.created_at).format("lll")}
          </time>
          <CircleButton
            onClick={removeMut.mutate}
            disabled={removeMut.isLoading || mut.isLoading}
            medium
            transparent
          >
            <TrashIcon color="var(--color-danger)" />
          </CircleButton>
        </div>
      )}
      <FooterV2
        buttonForm={FORM_ID}
        buttonType="submit"
        disabled={mut.isLoading || !finalText}
      >
        {t("common:save")}
      </FooterV2>
    </>
  );
};

export default NoteEditor;
