import Paper from "_c/Paper";
import s from "styled-components";
import { Button } from "_c/button";

const gutter = 5;
export const Item = s(Paper)`
  display: inline-flex;
  margin: ${gutter}px;
  flex-direction: column;
  width: calc(50% - ${gutter * 2}px);
  @media(max-width: 1199px) {
    width: 100%;
  }
  @media(max-width: 991px) {
    width: calc(50% - ${gutter * 2}px);
  }
  @media(max-width: 575px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;
export const Name = s.div`
  color: var(--font-color-emp);
  font-weight: 500;
  margin-left: 10px;
`;
export const AvatarAndName = s.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;
export const Status = s.div`
  color: var(--font-color-accent);
`;
export const GoProfile = s(Button)`
  margin-top: 25px;
  margin-left: auto;
  display: inline-block;
`;
export const OnlineStatus = s.div`
  margin-left: 10px;
  color: ${({ online, theme }) =>
    online ? theme.color.positive : theme.color.negative};
`;
export const ServiceName = s.span`
  color: var(--color-cta);
  font-weight: 500;
`;
export const Amount = s.div`
  color: ${({ theme }) => theme.color.positive};
  font-weight: 700;
  margin-left: 10px;
`;
export const ActualPrice = s.span`
  font-weight: 500;
  color: ${({ theme }) => theme.color.positive};
`;
export const OldPrice = s.span`
  font-weight: 500;
  color: var(--color-danger);
  opacity: .6;
`;
