import React, { useState, useEffect, useRef, useCallback } from "react";
import s from "styled-components";
import ClipboardIcon from "_c/icon/Clipboard";
import { copyToClipboard } from "@/utils";
import DenyIcon from "_c/icon/Deny";
import { useScrollLock } from "@/hooks/useScrollLock";
import { useNormalizedLink } from "./hooks";
import { useTranslation } from "react-i18next";

const config = {
  pageDots: false,
  lazyLoad: false,
  wrapAround: false,
  contain: true,
  cellAlign: "left",
  freeScroll: true,
  prevNextButtons: false,
  adaptiveHeight: false,
};

const iconSize = 64;
const CloseButton = s.button`
  border: none;
  box-shadow: none;
  position: absolute;
  right: 0;
  top: -35px;
`;
const BaseButton = s.button`
  width: 62px;
  min-width: 62px;
  height: 62px;
  min-height: 62px;
  background: var(--grad-primary-n);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  :hover, :focus {
    background: var(--grad-primary-h);
  }
`;
const Wrap = s.div`
  width: 450px;
  pointer-events: ${({ isDrag }) => (isDrag ? "none" : "auto")};
  @media(max-width: 530px) {
    width: 95vw;
  }
`;
const Item = s.div`
  margin: 0 10px;
`;

export default ({ shareLink, ...props }) => {
  useScrollLock(document.querySelector(".modal"));
  const { t, ready } = useTranslation("common", {
    useSuspense: false,
  });
  const [Flickity, setFlickity] = useState(null);
  const [dragging, setDragging] = useState(false);
  const ref = useRef();
  const [Share, setShare] = useState(null);
  useEffect(() => {
    import("react-flickity-component").then(setFlickity);
    import("react-share").then(setShare);
  }, []);
  const onDragStart = useCallback(() => {
    setDragging(true);
  }, []);
  const onDragEnd = useCallback(() => {
    setDragging(false);
  }, []);
  const setRef = useCallback((flRef) => {
    if (flRef) {
      const { flkty } = flRef;
      flkty.on("dragStart", onDragStart);
      flkty.on("dragEnd", onDragEnd);
      ref.current = flRef;
    }
  }, []);
  useEffect(() => {
    return () => {
      if (ref.current) {
        const { flkty } = ref.current;
        flkty.off("dragStart", onDragStart);
        flkty.off("dragEnd", onDragEnd);
      }
    };
  }, []);
  const linkProps = { size: iconSize, round: true };
  const url = useNormalizedLink(shareLink);
  const shareProps = {
    url,
  };
  const onPinterestOpen = () => {
    var win = window.open(
      "http://pinterest.com/pin/create/button/?url=" + shareProps.url,
      "_blank"
    );
    win.focus();
  };
  const copy = () => {
    copyToClipboard(shareProps.url);
    alert(t("common:share_copy_link_success"));
  };
  if (!ready || !Flickity || !Share) return null;
  return (
    <Wrap isDrag={dragging}>
      <CloseButton onClick={() => props.onClose()}>
        <DenyIcon color="white" width="15" />
      </CloseButton>
      <Flickity.default ref={setRef} options={config}>
        <Item>
          <BaseButton onClick={copy} style={{ paddingLeft: "8px" }}>
            <ClipboardIcon width="30" color="white" />
          </BaseButton>
        </Item>
        <Item>
          <Share.WhatsappShareButton {...shareProps}>
            <Share.WhatsappIcon {...linkProps} />
          </Share.WhatsappShareButton>
        </Item>
        <Item>
          <Share.ViberShareButton {...shareProps}>
            <Share.ViberIcon {...linkProps} />
          </Share.ViberShareButton>
        </Item>
        <Item>
          <Share.TelegramShareButton {...shareProps}>
            <Share.TelegramIcon {...linkProps} />
          </Share.TelegramShareButton>
        </Item>
        <Item>
          <Share.VKShareButton {...shareProps}>
            <Share.VKIcon {...linkProps} />
          </Share.VKShareButton>
        </Item>
        <Item>
          <Share.FacebookShareButton {...shareProps}>
            <Share.FacebookIcon {...linkProps} />
          </Share.FacebookShareButton>
        </Item>
        <Item>
          <Share.OKShareButton {...shareProps}>
            <Share.OKIcon {...linkProps} />
          </Share.OKShareButton>
        </Item>
        <Item>
          <button onClick={onPinterestOpen}>
            <Share.PinterestIcon {...linkProps} />
          </button>
        </Item>
        <Item>
          <Share.EmailShareButton {...shareProps}>
            <Share.EmailIcon {...linkProps} />
          </Share.EmailShareButton>
        </Item>
      </Flickity.default>
    </Wrap>
  );
};
