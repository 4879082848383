import { useEffect } from "react";
import { preloadNS } from "@/i18n/utils";
import { PARTNERS_NS } from "@/i18n/ns";
import { useSelector } from "react-redux";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";

const authNamespaces = ["notifications"];
const nonAuthNamespaces = [PARTNERS_NS];

const delay = 1500;
export const usePreloadOftenUsedLocales = () => {
  const isAuth = useSelector(AuthSelectors.isAuth);
  const namespaces = isAuth ? authNamespaces : nonAuthNamespaces;
  useEffect(() => {
    const timeout = setTimeout(() => {
      preloadNS(namespaces);
    }, delay);
    return () => clearTimeout(timeout);
  }, [namespaces]);
};

