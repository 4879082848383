import { createStructuredSelector, createSelector } from "reselect";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { DOMAIN } from "./slice";
import moment from "moment";
import { IS_SERVER } from "@/misc/constants";

const getMode = (state) => state[DOMAIN].mode;
const getFormSex = createSelector(UserSelectors.getSex, (sex) =>
  sex ? "m" : "f"
);
const timezoneSelectSelector = createSelector(
  UserSelectors.getTimezone,
  (tz) => {
    return tz ? { value: tz, label: tz } : null;
  }
);
const getInitialCommonSettings = createStructuredSelector({
  first_name: UserSelectors.getFirstName,
  last_name: UserSelectors.getLastName,
  middle_name: UserSelectors.getMiddleName,
  sex: getFormSex,
  avatar: UserSelectors.getAvatar,
  timezone: timezoneSelectSelector,
  //location_hr: UserSelectors.getLocationHr,
  bio: UserSelectors.getBio,
});
const getMarketplaceRegDefaultValues = createStructuredSelector({
  ceo_phone: UserSelectors.getPhone,
  ceo_name: UserSelectors.getFirstName,
  ceo_lastname: UserSelectors.getLastName,
  address_city: UserSelectors.getLocationHr,
  email: UserSelectors.getEmail,
  address_street: UserSelectors.getMasterLocationHr,
  ceo_birthdate: () => [moment().subtract(20, "years").toDate()],
});
const getMenu = createSelector(
  [UserSelectors.isMaster, UserSelectors.isSaloonOwner],
  (isMaster, isSaloonOwner) => {
    const menu = [
      "common",
      isMaster && "career",
      isMaster && "marketplace",
      isMaster && "premium",
      "notifications",
      "contacts",
      "appearance",
      !isMaster && "create_master",
      !isSaloonOwner && "salon",
      "finance",
      "feed",
    ];
    return menu.filter(Boolean);
  }
);
const isCareerVisited = (state) => state[DOMAIN].isCareerVisited;
const isCareerReallyVisited = createSelector(isCareerVisited, (isVisited) => {
  if (IS_SERVER || isVisited) return isVisited;
  const data = localStorage.getItem("career-visited");
  return Boolean(data);
});
const isNotificationsVisited = (state) => state[DOMAIN].isNotificationsVisited;
const isNotificationsReallyVisited = createSelector(
  isNotificationsVisited,
  (isVisited) => {
    if (IS_SERVER || isVisited) return isVisited;
    const data = localStorage.getItem("notifications-visited");
    return Boolean(data);
  }
);
const getPhoneMeta = (state) => state[DOMAIN].phone;
const shouldHighlightSettingsMenu = createSelector(
  [
    UserSelectors.isMaster,
    UserSelectors.isFirstLogin,
    isCareerReallyVisited,
    isNotificationsReallyVisited,
  ],
  (isMaster, isFirstLogin, isCareerVisited, isNotificationsVisited) => {
    return (
      isFirstLogin || (isMaster && !isCareerVisited) || !isNotificationsVisited
    );
  }
);

const isMasterPhonePublic = createSelector(
  [UserSelectors.getMaster, UserSelectors.isPremium],
  (master, isPremium) => Boolean(master?.show_phone && isPremium)
);

export const Selectors = {
  shouldHighlightSettingsMenu,
  isCareerVisited: isCareerReallyVisited,
  isNotificationsVisited: isNotificationsReallyVisited,
  getMenu,
  getMode,
  getInitialCommonSettings,
  getMarketplaceRegDefaultValues,
  getPhoneMeta,
  isMasterPhonePublic,
};
