import { createSlice } from '@reduxjs/toolkit';

export const DOMAIN = 'canary';

const initialState = {
  enabled: false,
}

const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    enable(state) {
      state.enabled = true;
    },
    disable(state) {
      state.enabled = false;
    },
    toggle(state) {
      state.enabled = !state.enabled;
    }
  }
})
export default slice.reducer;
export const Actions = slice.actions;