import { createStructuredSelector } from "reselect";
import { createSelector } from "@reduxjs/toolkit";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as FavoriteSelectors } from "@/store/favorites/selectors";

const getProfile = (state) => state.clientProfile.client;
const getClient = createSelector(
  [getProfile, UserSelectors.getProfile],
  (client, authUser) => {
    if (client && authUser?._id === client?._id) {
      return authUser;
    } else {
      return client;
    }
  }
);

const getFirstName = createSelector(getClient, (client) => {
  return client?.first_name;
});
const getLastName = createSelector(getClient, (client) => {
  return client?.last_name;
});
const getFullName = createSelector(
  [getFirstName, getLastName],
  (first, last) => {
    return [first, last].filter(Boolean).join(" ");
  }
);
const getAvatar = createSelector(getClient, (profile) => profile?.avatar);
const getSex = createSelector(getClient, (client) => client?.sex);
const getBio = createSelector(getClient, (client) => client?.bio);
const getId = createSelector(getClient, (client) => client?._id);
const getMasterId = createSelector(getClient, (client) => client?.master?._id);
const isOwner = createSelector(
  [getId, UserSelectors.getUserId],
  (clientId, authUserId) => {
    return clientId === authUserId;
  }
);
const getCoords = createSelector(getClient, (client) => {
  return client?.location?.coordinates;
});
const isOnline = createSelector([getClient, isOwner], (client, isOwner) => {
  if (isOwner) return true;
  return client?.online;
});
const isFavorite = createSelector(
  getId,
  FavoriteSelectors.getFavoriteUsers,
  (id, favorites) => {
    return favorites.includes(id);
  }
);
const getMasters = createSelector(getProfile, (client) => client?.masters);
const getContacts = createSelector(getProfile, (client) => client?.contacts);
const getAchieves = (state) => state.clientProfile.achieves?.achievements;
const getExcerpt = createStructuredSelector({
  _id: getId,
  sex: getSex,
  avatar: getAvatar,
  first_name: getFirstName,
  last_name: getLastName,
  online: isOnline,
  master: getMasterId,
});

export const ProfileSelectors = {
  getFullName,
  getAvatar,
  getAchieves,
  getId,
  getCoords,
  getContacts,
  getExcerpt,
  getMasterId,
  getMasters,
  getSex,
  getBio,
  isOnline,
  isOwner,
  isFavorite,
};
