import React from "react";
import { Spin } from "_c/preloader";
import { useTranslation } from "react-i18next";
import QueryError from "_c/QueryError";
import type { UseQueryResult } from "react-query";

type tQuery = UseQueryResult<any, any>;
export type tProps = {
  query: tQuery | tQuery[];
  children: any;
  spin?: (props?: any) => JSX.Element;
};
const QueryRequest = ({ query, children, spin }: tProps) => {
  const { t, ready } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  const queries = Array.isArray(query) ? query : [query];
  const checkStatus = (match: tQuery["status"]) =>
    queries.some((query) => query.status === match);
  if (!ready || checkStatus("idle")) return null;
  else if (checkStatus("loading")) {
    return spin || <Spin />;
  } else if (checkStatus("error")) {
    return (
      <>
        {queries
          .filter((query) => query.status === "error")
          .map((query) => (
            <QueryError error={query.error} t={t} refetch={query.refetch} />
          ))}
      </>
    );
  } else if (queries.every((query) => query.status === "success")) {
    return children || null;
  }
  return null;
};

export default QueryRequest;
