import { useState, useCallback } from "react";

type tReturnValue = [
  value: boolean,
  toggleFn: tVoidFn,
  setterFn: (val: boolean) => void
];
export const useToggle = (defaultState = false): tReturnValue => {
  const [value, setValue] = useState(defaultState);
  const toggle = useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle, setValue];
};
