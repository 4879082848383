// import { LinkGenerator } from "@/utils/LinkGenerator";
export default [
  /*{
    route: LinkGenerator.blogHome(),
    key: "blog",
  },
  */
  {
    __key: "support",
    key: "question",
  },
  {
    __key: "idea",
    key: "idea",
  },
];
