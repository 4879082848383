import React from "react";
import PT from "prop-types";
import Modal from "_c/Modals";
import TaskEditor from "./loadable";

const TaskEditorModal = (props) => {
  return (
    <Modal
      onClose={props.onClose}
      isOpen={props.isOpen}
      css={`
        height: 100%;
      `}
    >
      <TaskEditor {...props} />
    </Modal>
  );
};
TaskEditorModal.propTypes = {
  isOpen: PT.bool.isRequired,

  onClose: PT.func.isRequired,
};

export default TaskEditorModal;
