import { Actions as MasterSearchActions } from "@/store/masterSearch/slice";
import { Effects as MasterProfileEffects } from "@/store/masterProfile/effects";
import { Effects as ClientProfileEffects } from "@/store/clientProfile/effects";
import { Effects as SaloonProfileEffects } from "@/store/saloonProfile/effects";
import { Effects as ReviewEffects } from "@/store/reviews/effects";
import { Effects as PaymentResultEffects } from "@/store/paymentResult/effects";
import { Effects as PortfolioEffects } from "@/store/portfolio/effects";
import { Selectors as MasterProfileSelectors } from "@/store/masterProfile/selectors";
import { Selectors as ClientProfileSelectors } from "@/store/clientProfile/selectors";
import { Selectors as SaloonProfileSelectors } from "@/store/saloonProfile/selectors";
import { Selectors as BlogSelectors } from "@/store/blog/selectors";
import { Selectors as ReviewSelectors } from "@/store/reviews/selectors";
import { Selectors as PaymentResultSelectors } from "@/store/paymentResult/selectors";
import { Selectors as PortfolioSelectors } from "@/store/portfolio/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as MsgrSubPageSelectors } from "@/store/msgrSubPage/selectors";
import { Effects as MsgrSubPageEffects } from "@/store/msgrSubPage/effects";
import { LinkGenerator } from "@/utils/LinkGenerator";
import { Effects as BlogEffects } from "@/store/blog/effects";
import { matchPath } from "react-router-dom";
import { QueryKeys } from "./network/queryKeys";
import { IS_SERVER } from "./misc/constants";
import { SpecAPI } from "./api/specs";

export const routes = [
  {
    path: "/master",
    exact: true,
    prefetch: async ({ store, ctx, queryClient, locale }) => {
      let id = ctx.query.id;
      if (!id) {
        const state = store.getState();
        if (UserSelectors.isMaster(state)) {
          id = UserSelectors.getMasterId(state);
        } else {
          return ctx.throw(400, "NOT_FOUND");
        }
      }
      await Promise.all([
        store.dispatch(MasterProfileEffects.loadProfile(id)),
        IS_SERVER &&
          queryClient.prefetchQuery([QueryKeys.allSpecs, { locale }], () =>
            SpecAPI.getAll({ locale })
          ),
      ]);
      const state = store.getState();
      const error = MasterProfileSelectors.getError(state);
      if (error) {
        return ctx.throw(400, error);
      }
      const redirect = MasterProfileSelectors.getRedirectMeta(state);
      if (redirect.isRedirected) {
        ctx.status = 301;
        ctx.redirect(LinkGenerator.masterProfile(redirect.redirectId));
      }
    },
  },
  {
    path: "/client",
    exact: true,
    prefetch: async ({ store, ctx }) => {
      let id = ctx.query.id;
      if (!id) {
        const userId = UserSelectors.getUserId(store.getState());
        if (userId) {
          id = userId;
        } else {
          return ctx.throw(400, "NOT_FOUND");
        }
      }
      await store.dispatch(ClientProfileEffects.loadProfile(id));
      const state = store.getState();
      const error = ClientProfileSelectors.getError(state);
      if (error) {
        return ctx.throw(400, error);
      }
      const redirect = ClientProfileSelectors.getRedirectMeta(state);
      if (redirect.isRedirected) {
        ctx.status = 301;
        ctx.redirect(LinkGenerator.clientProfile(redirect.redirectId));
      }
    },
  },
  {
    path: "/salon",
    exact: true,
    prefetch: async ({ store, ctx, queryClient, locale }) => {
      const { id } = ctx.query;
      if (!id) return ctx.throw(400, "NOT_FOUND");
      await Promise.all([
        store.dispatch(SaloonProfileEffects.loadProfile(id)),
        IS_SERVER &&
          queryClient.prefetchQuery([QueryKeys.allSpecs, { locale }], () =>
            SpecAPI.getAll({ locale })
          ),
      ]);
      const error = SaloonProfileSelectors.getError(store.getState());
      if (error) {
        return ctx.throw(400, error);
      }
    },
  },
  ...["/blog/editor/:slug", "/blog/editor"].map((path) => ({
    path,
    prefetch: async ({ params, store }) => {
      await store.dispatch(BlogEffects.initEditor(params.params.slug));
    },
  })),
  {
    path: "/blog",
    exact: true,
    prefetch: async ({ store, ctx }) => {
      await Promise.all([
        store.dispatch(BlogEffects.loadArticles(ctx.query)),
        store.dispatch(BlogEffects.loadPopularArticles()),
        store.dispatch(BlogEffects.loadBestAuthors()),
      ]);
      const error = BlogSelectors.getArticlesError(store.getState());
      if (error) {
        return ctx.throw(400, error);
      }
    },
  },
  {
    path: "/blog/article/:slug",
    prefetch: async ({ store, params, ctx }) => {
      await Promise.all([
        store.dispatch(BlogEffects.loadArticle(params.params.slug)),
        store.dispatch(BlogEffects.loadPopularArticles()),
        store.dispatch(BlogEffects.loadBestAuthors()),
      ]);
      const state = store.getState();
      const error = BlogSelectors.getArticleError(state);
      if (error) {
        return ctx.throw(400, error);
      }
      const redirect = BlogSelectors.getArticleRedirectMeta(state);
      if (redirect.isRedirected) {
        ctx.status = 301;
        ctx.redirect(LinkGenerator.blogArticle(redirect.redirectId));
      }
    },
  },
  {
    path: "/review/:id",
    prefetch: async ({ store, params, ctx }) => {
      const { id } = params.params;
      await store.dispatch(ReviewEffects.loadReview(id));
      const error = ReviewSelectors.getError(store.getState());
      if (error) {
        return ctx.throw(400, error);
      }
    },
  },
  {
    path: "/portfolio/:id",
    prefetch: async ({ store, params, ctx }) => {
      const { id } = params.params;
      await store.dispatch(PortfolioEffects.loadSinglePortfolio(id));
      const error = PortfolioSelectors.getSingleError(store.getState());
      if (error) {
        return ctx.throw(400, error);
      }
    },
  },
  {
    path: "/messenger/sub/:token",
    prefetch: async ({ store, params, ctx }) => {
      const { token } = params.params;
      await store.dispatch(MsgrSubPageEffects.loadData(token));
      const error = MsgrSubPageSelectors.getError(store.getState());
      if (error) {
        return ctx.throw(400, error);
      }
    },
  },
  {
    path: "/thankyou",
    prefetch: async ({ store, ctx }) => {
      const { query } = ctx;
      const isSuccess =
        typeof query.Success === "string"
          ? query.Success === "true"
          : Boolean(query.Success);
      if (!query.OrderId) {
        throw new Error("TASK_NOT_FOUND");
      }
      await store.dispatch(
        PaymentResultEffects.loadTask(query.OrderId, isSuccess)
      );
      const state = store.getState();
      const error = PaymentResultSelectors.getError(state);
      if (error) {
        return ctx.throw(400, error);
      }
    },
  },
  ...["/search", "/search/:loc", "/search/:loc/:spec"].map((path) => ({
    path,
    exact: true,
    prefetch: async ({ store, ctx, params }) => {
      store.dispatch(
        MasterSearchActions.loadMasters({
          query: ctx.query,
          params: params.params,
        })
      );
      store.dispatch(
        MasterSearchActions.loadGeo({
          query: ctx.query,
          params: params.params,
        })
      );
      return Promise.resolve();
    },
  })),
];

export const matchRoute = (path) => {
  let matched, matchedParams;
  for (let i = 0; i < routes.length; i++) {
    matchedParams = matchPath(path, routes[i]);
    if (matchedParams) {
      matched = routes[i];
      break;
    }
  }
  return [matched, matchedParams];
};
