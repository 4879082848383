import { createSlice } from "@reduxjs/toolkit";
import deepExtend from "@/utils/deepExtend";

const DOMAIN = "blog";

const initialState = {
  articles: {
    items: [],
    loading: false,
    count: 0,
    perPage: 6,
    error: null,
  },
  article: {
    loading: false,
    error: null,
    item: null,
    redirect: {
      isRedirected: false,
      redirectId: null,
    },
  },
  popularArticles: {
    items: [],
    loading: false,
    error: null,
  },
  bestAuthors: {
    items: [],
    loading: false,
    error: null,
  },
  editor: {
    _id: null,
    title: "",
    content: "",
    thumb: undefined,
    tags: [],
    desc: "",
  },
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadArticlesStart(state) {
      state.articles.error = null;
      state.articles.loading = true;
    },
    loadArticlesSuccess(state, { payload }) {
      state.articles.loading = false;
      state.articles.perPage = payload.perPage;
      state.articles.count = payload.count;
      state.articles.items = payload.items;
    },
    loadArticlesError(state, { payload }) {
      state.articles.loading = false;
      state.articles.error = payload;
    },
    loadBestAuthorsStart(state) {
      state.bestAuthors.error = null;
      state.bestAuthors.loading = true;
    },
    loadBestAuthorsSuccess(state, { payload }) {
      state.bestAuthors.items = payload;
      state.bestAuthors.loading = false;
    },
    loadBestAuthorsError(state, { payload }) {
      state.bestAuthors.loading = false;
      state.bestAuthors.error = payload;
    },
    loadPopularArticlesStart(state) {
      state.popularArticles.error = null;
      state.popularArticles.loading = true;
    },
    loadPopularArticlesSuccess(state, { payload }) {
      state.popularArticles.loading = false;
      state.popularArticles.items = payload;
    },
    loadPopularArticlesError(state, { payload }) {
      state.popularArticles.error = payload;
      state.popularArticles.loading = false;
    },
    loadArticleStart(state) {
      state.article.loading = false;
      state.article.error = null;
      state.article.redirect = initialState.article.redirect;
    },
    loadArticleSuccess(state, { payload }) {
      state.article.loading = false;
      state.article.item = payload;
    },
    loadArticleRedirect(state, { payload }) {
      state.article.loading = false;
      state.article.redirect = {
        isRedirected: true,
        redirectId: payload,
      };
    },
    loadArticleError(state, { payload }) {
      state.article.loading = false;
      state.article.error = payload;
    },
    addLike(state, { payload: id }) {
      const article = state.articles.items.find(({ _id }) => _id === id);
      if (article) {
        article.likes_count += 1;
      }
      const singleArticle = state.article.item;
      if (singleArticle?._id === id) {
        singleArticle.likes_count += 1;
      }
    },
    removeLike(state, { payload: id }) {
      const article = state.articles.items.find(({ _id }) => _id === id);
      if (article) {
        article.likes_count = Math.max(0, article.likes_count - 1);
      }
      const singleArticle = state.article.item;
      if (singleArticle?._id === id) {
        singleArticle.likes_count = Math.max(0, singleArticle.likes_count - 1);
      }
    },
    addComment(state, { payload }) {
      const article = state.article.item;
      article.comments_count += 1;
      if (Array.isArray(article.comments)) {
        article.comments.push(payload);
      } else {
        article.comments = [payload];
      }
    },
    removeComment(state, { payload: id }) {
      const article = state.article.item;
      article.comments_count = Math.max(0, article.comments_count - 1);
      const commentIdx = article.comments.findIndex(({ _id }) => _id === id);
      if (commentIdx !== -1) {
        article.comments.splice(commentIdx, 1);
      }
    },
    removeArticle(state) {
      state.article.item = null;
    },

    initEditor(state, { payload }) {
      deepExtend(state.editor, payload);
    },
    resetEditor(state) {
      state.editor = initialState.editor;
    },
    resetEditorForm(state) {
      state.editor = initialState.editor;
    },
    updateEditorForm(state, { payload }) {
      for (const field in payload) {
        state.editor[field] = payload[field];
      }
    },
  },
});
export const Actions = slice.actions;
export default slice.reducer;
