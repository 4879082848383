import { connect } from "react-redux";
import { Selectors as ModalSelectors } from "@/store/modals/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Selectors as IdentitySelectors } from "@/store/identityModal/selectors";
import { Actions as IdentityActions } from "@/store/identityModal/slice";

const mSp = (state) => ({
  isOpen: ModalSelectors.isAuthOpen(state),
  mode: IdentitySelectors.getMode(state),
});
const mDp = {
  close: ModalActions.closeAuth,
  changeMode: IdentityActions.changeMode,
};

export default connect(mSp, mDp);
