import * as React from "react";

function SvgStylistImagemaker({ color = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80.64 97.11"
      {...props}
    >
      <path
        d="M2.29 89.56a22 22 0 015.05.86c4.76 1.41 5.5 3.47 9.41 4.41 4.61 1.1 6.36-1.09 11.8-1.31 6.57-.27 7.41 3.9 13.34 3.57 4.08-.23 3.75-1.89 8.93-2.62 6.32-.89 7.63 1.47 12.63.12 3.79-1 4-2.63 8.22-4a21.5 21.5 0 016.58-1.1 2.41 2.41 0 002.26-3.18 126.51 126.51 0 00-12.29-26 125.74 125.74 0 00-12.7-17 2.59 2.59 0 01-.33-1.27v-1.47a.74.74 0 00-.78-.74l-14.07.72-14.07-.72a.74.74 0 00-.78.74v1.36a2.59 2.59 0 01-.33 1.27 125.77 125.77 0 00-25 43.18 2.41 2.41 0 002.13 3.18zm23.86-52.05a1.43 1.43 0 001.3.85c2.1.09 4.21.26 6.31.44a80.91 80.91 0 0013.3 0c2.06-.17 4.12-.34 6.17-.43a1.43 1.43 0 001.3-.85l1.79-4.15a31.14 31.14 0 002.54-12.3 15.38 15.38 0 00-2.18-7.9L54.79 10c-.18-1.1-.4-2.21-.65-3.3A46.5 46.5 0 0052.06.17a.27.27 0 00-.51.2 44.26 44.26 0 012.06 6.44c.26 1.12.48 2.27.66 3.41a85.58 85.58 0 00-7.4 7.25 87 87 0 00-6.43 8 .12.12 0 01-.2 0 87 87 0 00-6.43-8 85.58 85.58 0 00-7.4-7.25c.18-1.14.4-2.29.66-3.41A44.26 44.26 0 0129.13.37a.27.27 0 00-.51-.2 46.5 46.5 0 00-2.08 6.51c-.25 1.09-.47 2.2-.64 3.3-.64 1.02-1.27 2.12-1.9 3.18a15.38 15.38 0 00-2.18 7.9 31.14 31.14 0 002.54 12.3z"
        fill={color}
      />
    </svg>
  );
}

export default SvgStylistImagemaker;
