import { QueryKeys } from "../../network/queryKeys";
import { queryClient } from "../../network/queryClient";

const buildQueryKey = (saloonId) => {
  if (!saloonId) return QueryKeys.saloonInvites;
  return [QueryKeys.saloonInvites, saloonId];
};
export const SaloonInvitesCacheService = {
  buildQueryKey,
  invalidateBySaloonId(saloonId) {
    queryClient.invalidateQueries(buildQueryKey(saloonId));
  },
  invalidateAll() {
    queryClient.invalidateQueries(buildQueryKey());
  },
  updateInvite({ id, updates, saloonId }) {
    const update = (key) => {
      queryClient.setQueryDataIfCached(key, (invites) => {
        return invites.map((invite) =>
          invite._id === id ? { ...invite, ...updates } : invite
        );
      });
    };
    const key = buildQueryKey(saloonId);
    if (saloonId) {
      update(key);
    } else {
      queryClient.getQueriesData(key).forEach(([key]) => update(key));
    }
  },
  removeInvite({ id, saloonId }) {
    const update = (key) => {
      queryClient.setQueryDataIfCached(key, (invites) => {
        return invites.filter((invite) => invite._id !== id);
      });
    };
    const key = buildQueryKey(saloonId);
    if (saloonId) {
      update(key);
    } else {
      queryClient.getQueriesData(key).forEach(([key]) => update(key));
    }
  },
};
