import React from "react";
import Layout from "@/layouts/Profile";
import Profile from "_p/saloon/container";
import Dashboard from "_p/saloon-dashboard/container";
import { Route } from "react-router-dom";

const SaloonContainer = ({ match }) => {
  return (
    <Layout>
      <Route key="profile" exact path={`${match.url}`} component={Profile} />
      <Route
        key="saloon-dashboard"
        path={`${match.url}/dashboard/:mode?`}
        component={Dashboard}
      />
    </Layout>
  );
};

export default SaloonContainer;
