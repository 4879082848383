import { useTheme } from "./useTheme";
import { UserPrefsService } from "@/services/userPrefs";

export const useColorScheme = () => {
  return useTheme().__name__;
};

export const useChangeColorScheme = () => {
  return (scheme) => {
    return UserPrefsService.updatePrefs({
      color_scheme: scheme,
    });
  };
};

export const useToggleColorScheme = () => {
  const scheme = useColorScheme();
  return () => {
    return UserPrefsService.updatePrefs({
      color_scheme: scheme === "dark" ? "light" : "dark",
    });
  };
};
