import React, { useCallback, useRef } from "react";
import * as S from "./styled";
import PT from "prop-types";
import { AnimatedList } from "_c/Animated";
import { Actions as CanaryActions } from "@/store/canary/slice";
import { useDispatch } from "react-redux";

const Advice = (props) => {
  const clickedRef = useRef(0);
  const dispatch = useDispatch();
  const onInfoClick = useCallback(() => {
    clickedRef.current += 1;
    if (clickedRef.current >= 10) {
      dispatch(CanaryActions.enable());
      alert("Канарейка включена ;)");
    }
  }, []);
  return (
    <S.Wrap>
      <S.HeadWrap>
        <S.Head>{props.title}</S.Head>
        <S.InfoButton onClick={onInfoClick}>i</S.InfoButton>
      </S.HeadWrap>
      <AnimatedList.Container>
        <AnimatedList.Item itemKey={props.advice}>
          <S.Content>{props.advice}</S.Content>
        </AnimatedList.Item>
      </AnimatedList.Container>
    </S.Wrap>
  );
};
Advice.propTypes = {
  advice: PT.string,
  title: PT.string,
};

export default Advice;
