import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selectors as ModalSelectors } from "@/store/modals/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import EditorModal from "./";

const GlobalExternalNotificationTmplEditorModal = () => {
  const isOpen = useSelector(
    ModalSelectors.isExternalNotificationTmplEditorOpen
  );
  const params = useSelector(
    ModalSelectors.getExternalNotificationTmplEditorParams
  );
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(ModalActions.closeExternalNotificationTmplEditor());
  }, []);
  return <EditorModal onClose={onClose} isOpen={isOpen} {...params} />;
};

export default GlobalExternalNotificationTmplEditorModal;
