import { useMemo } from "react";
import { toast } from "react-toastify";

export const useNotify = () => {
  return useMemo(
    () => ({
      notify: (text) => toast.success(text),
      notifyError: (error) => toast.error(error),
    }),
    []
  );
};
