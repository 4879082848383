import moment from "moment";
import { queryClient } from "../../network/queryClient";
import { QueryKeys } from "../../network/queryKeys";
import uniqBy from "lodash/uniqBy";
import isempty from "lodash/isEmpty";
import { COMMON_DATE_FORMAT } from "@/misc/constants";
import { notifyManager } from "react-query";

const buildBaseQueryKey = ({ masterId, saloonId }) => [
  QueryKeys.scheduler,
  {
    masterId,
    ...(saloonId && { saloonId }),
  },
];
const buildQueryKey = ({ masterId, saloonId, dateRange }) => {
  const [key, params] = buildBaseQueryKey({ masterId, saloonId });
  return [
    key,
    {
      ...params,
      start: moment(dateRange.start).locale("en").format(COMMON_DATE_FORMAT),
      end: moment(dateRange.end).locale("en").format(COMMON_DATE_FORMAT),
    },
  ];
};
const mutateTasksCache = (cb, queryKey = QueryKeys.scheduler) => {
  const updates = [];
  queryClient.getQueriesData(queryKey).forEach(([key]) => {
    updates.push(() =>
      queryClient.setQueryDataIfCached(key, (data) => ({
        ...data,
        tasks: cb(data.tasks || [], key),
      }))
    );
  });
  if (!isempty(updates)) {
    notifyManager.batch(() => {
      updates.forEach((update) => update());
    });
  }
};
const removeTask = (taskOrTaskId) => {
  const id = taskOrTaskId?._id ?? taskOrTaskId;
  mutateTasksCache((tasks) => tasks.filter((task) => task._id !== id));
};

const addTasks = (tasks) => {
  if (isempty(tasks)) return;
  const tasksWithMeta = tasks.map((task) => ({
    task,
    start: moment(task.start_date).locale("en").format(COMMON_DATE_FORMAT),
    end: moment(task.end_date).locale("en").format(COMMON_DATE_FORMAT),
  }));
  const task = tasks[0];
  const partialQueryKeysToUpdate = [
    buildBaseQueryKey({ masterId: task.master }),
    task.saloon &&
      buildBaseQueryKey({ masterId: task.master, saloonId: task.saloon }),
  ].filter(Boolean);

  partialQueryKeysToUpdate.forEach((queryKey) => {
    mutateTasksCache((cachedTasks, [_, cacheKey]) => {
      const updates = tasksWithMeta.reduce((acc, { task, start, end }) => {
        if (cacheKey.start <= end && cacheKey.end >= start) {
          acc.push(task);
        }
        return acc;
      }, []);
      if (!isempty(updates)) {
        return uniqBy([...cachedTasks, ...updates], "_id");
      } else {
        return cachedTasks;
      }
    }, queryKey);
  });
};
const invalidateAll = () => {
  queryClient.invalidateQueries(QueryKeys.scheduler);
};
export const SchedulerCacheService = {
  invalidateAll,
  buildQueryKey,
  addTasks,
  removeTask,
  updateTask(taskOrTaskId, updates) {
    const id = taskOrTaskId?._id ?? taskOrTaskId;
    mutateTasksCache((tasks) =>
      tasks.map((task) =>
        task._id === id
          ? {
              ...task,
              ...updates,
            }
          : task
      )
    );
  },
  replaceTask(task, oldTask) {
    if (!oldTask) {
      // just in case
      removeTask(task._id);
      addTasks([task]);
    }
    // complex case, days are different, so we need remove the old task then insert the new one (if there is appropriate cache for one)
    else if (
      !moment(task.start_date).isSame(oldTask.start_date) ||
      !moment(task.end_date).isSame(oldTask.end_date)
    ) {
      // TODO:: there must be some optimizations
      removeTask(oldTask);
      addTasks([task]);
    } else {
      mutateTasksCache((tasks) =>
        tasks.map((t) => (t._id === task._id ? task : t))
      );
    }
  },
  replaceBuckets(buckets) {
    if (isempty(buckets)) return;
    // TODO:: pass the saloon? or it doesn't matter?
    const pairs = queryClient
      .getQueriesData(buildBaseQueryKey({ masterId: buckets[0].master }))
      .map(([key]) => {
        const [_, { start, end }] = key;
        const applied = buckets.filter((bucket) => {
          const bucketDate = bucket.date;
          return bucketDate >= start && bucketDate <= end;
        });
        if (isempty(applied)) return null;
        return [key, applied];
      })
      .filter(Boolean);
    notifyManager.batch(() => {
      for (const [key, buckets] of pairs) {
        queryClient.setQueryDataButKeepStale(key, (data) => ({
          ...data,
          buckets: {
            ...(data.buckets || {}),
            ...buckets.reduce((acc, bucket) => {
              acc[bucket.date] = bucket;
              return acc;
            }, {}),
          },
        }));
      }
    });
  },
};
