import { createSlice } from "@reduxjs/toolkit";

export const DOMAIN = "portfolio";
const initialState = {
  items: [],
  gallery: {
    open: false,
    idx: null,
  },
  single: {
    loading: false,
    error: null,
    item: null,
  },
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setPortfolio(state, { payload }) {
      state.items = payload;
      state.gallery.open = false;
      state.gallery.idx = null;
    },
    addLike(state, { payload: id }) {
      const items = [
        state.items.find(({ _id }) => _id === id),
        state.single.item?._id === id && state.single.item,
      ].filter(Boolean);
      items.forEach((item) => {
        item.likes_count += 1;
      });
    },
    addComment(state, { payload: id }) {
      const items = [
        state.items.find(({ _id }) => _id === id),
        state.single.item?._id === id && state.single.item,
      ].filter(Boolean);
      items.forEach((item) => {
        item.comments_count += 1;
      });
    },
    removeComment(state, { payload: id }) {
      const items = [
        state.items.find(({ _id }) => _id === id),
        state.single.item?._id === id && state.single.item,
      ].filter(Boolean);
      items.forEach((item) => {
        item.comments_count = Math.max(0, item.comments_count - 1);
      });
    },
    removeLike(state, { payload: id }) {
      const items = [
        state.items.find(({ _id }) => _id === id),
        state.single.item?._id === id && state.single.item,
      ].filter(Boolean);
      items.forEach((item) => {
        item.likes_count = Math.max(0, item.likes_count - 1);
      });
    },
    addPortfolio(state, { payload }) {
      state.items = state.items.concat(payload);
    },
    removePortfolio(state, { payload: id }) {
      const idx = state.items.findIndex(({ _id }) => _id === id);
      if (idx !== -1) {
        state.items.splice(idx, 1);
      }
    },
    openGallery(state, { payload: idx }) {
      state.gallery.open = true;
      state.gallery.idx = idx;
    },
    closeGallery(state) {
      state.gallery.open = false;
      state.gallery.idx = null;
    },
    loadSingleStart(state) {
      state.single.error = null;
      state.single.loading = true;
    },
    loadSingleSuccess(state, { payload }) {
      state.single.loading = false;
      state.single.item = payload;
    },
    loadSingleError(state, { payload }) {
      state.single.loading = false;
      state.single.error = payload;
    },
  },
});
export const Actions = slice.actions;
export default slice.reducer;
