import { DOMAIN } from "./slice";
const isLoading = (state) => state[DOMAIN].loading;
const getError = (state) => state[DOMAIN].error;
const isSuccess = (state) => state[DOMAIN].success;
const getTask = (state) => state[DOMAIN].task;

export const Selectors = {
  isLoading,
  getError,
  isSuccess,
  getTask,
};
