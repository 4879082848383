export const DEFAULT_EVENT_COLOR = "#B9AAE4";
export const DEFAULT_TODO_COLOR = "#856CC9";
export const DEFAULT_BIZ_TASK_COLOR = "#59D29A";

export const EVENT_COLORS = {
  todo: "#0277BD",
  personal: "#8233C5",
  business: "var(--color-cta)",
  expense: "#FFB300",
};
