import { API_URL } from "@/misc/constants";
import { get } from "./index";
import { searchCache, mastersSearchSeoMetaCache } from "@/misc/httpCache";

const BASE = `${API_URL}/search`;
export const searchMaster = (params) => {
  return get(BASE, { cache: false, params });
};
export const searchMasterGeo = (params) => {
  const url = `${BASE}/geo`;
  return get(url, { cache: searchCache, params });
};
export const autocomplete = (params) => {
  return get(`${BASE}/autocomplete`, { params });
};
export const getMasterSearchSeoMeta = (citySlug, specSlug) => {
  return get(`${BASE}/seo`, {
    cache: mastersSearchSeoMetaCache,
    params: {
      city: citySlug,
      spec: specSlug,
    },
  });
};

export const SearchAPI = {
  autocomplete,
  searchMaster,
  searchMasterGeo,
  getMasterSearchSeoMeta,
};
