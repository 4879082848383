import $ from "currency.js";
//import React from "react";
import s from "styled-components";

//const Price = s.span`
//  display: inline-block;
//  i::before {
//    margin-right: 0;
//    margin-left: 0;
//  }
//`;
const getSymbol = (currency) => {
  return currency;
  //return <i className={`icon-${currency}`} />;
  //return currency in symbols ? symbols[currency] : symbols.RUB;
};
export const CurrencyFormatter = {
  format(money, opts) {
    if (!money) return null;
    return $(money.amount, opts).value + " " + getSymbol(money.currency);
    //return (
    //  return
    //  <Price>
    //    {getSymbol(money.currency)}
    //    <span>{$(money.amount).value}</span>
    //  </Price>
    //);
    //return $(money.amount, { symbol: symbols[money.currency] }).format(true);
  },
  getSymbol,
};
