import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PT from "prop-types";
import { FullScreenSpin } from "_c/preloader";
import { useHistory, useParams } from "react-router-dom";
import { useNotify } from "@/hooks/useNotify";

const VerifyEmailPage = (props) => {
  const { t, ready } = useTranslation(["settings", "errors"], {
    useSuspense: false,
  });
  const { code } = useParams();
  const { notify, notifyError } = useNotify();
  const history = useHistory();

  useEffect(() => {
    async function foo() {
      try {
        await props.verifyEmail(code);
        notify(t("settings:contacts.verification.confirmed_email"));
        history.replace("/?profile-redirect=true");
      } catch (e) {
        console.error(e);
        notifyError(t([`errors:${e}`, "errors:unspecified_server"]));
        history.push("/");
      }
    }
    if (ready) {
      foo();
    }
  }, [t, ready, code]);
  return <FullScreenSpin size="xl" />;
};
VerifyEmailPage.propTypes = {
  verifyEmail: PT.func.isRequired,
};

export default VerifyEmailPage;
