import { createTheme } from "./utils";

const theme = createTheme({
  id: "royal_violet",
  name: "Royal Violet",
  color: {
    primary: "#856cc9",
    primary_2: "#AA95E4",
    primary_3: "#B9AAE4",
    primary_4: "#706297",
    touchable: "#F4F2FD",
    positive: "#59d29a",
    negative: "#f484a1",
    warn: "#FFD6C5",
  },
  gradient: {
    primary: {
      normal: "linear-gradient(123.82deg, #4b3094 11.86%, #856cc9 71.71%)",
    },
  },
});

export default theme;
