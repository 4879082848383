import React, { memo, useEffect } from "react";
import { ControlledAccordion as Accordion } from "_c/Accordion";
import { useToggle } from "@/hooks/useToggle";
import type { TFunction } from "i18next";
import type { tExternalNotificationTmplPlaceholderGroup } from "@/types";

type tProps = {
  t: TFunction;
  appendVariable: (val: string) => void;
  varsGroup: tExternalNotificationTmplPlaceholderGroup;
  ns: string;
  availableVars: string[];
};

const bottomId = "ext-notif-tmpl-vars-bottom";
const AvailableVars = ({
  t,
  ns,
  appendVariable,
  availableVars: vars,
  varsGroup,
}: tProps) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const onVarClick = (variable: string) => {
    appendVariable(variable);
  };
  useEffect(() => {
    if (isOpen) {
      const $bottom = document.querySelector("#" + bottomId);
      if ($bottom) {
        setTimeout(() => {
          $bottom.scrollIntoView({
            behavior: "smooth",
          });
        }, 1);
      }
    }
  }, [isOpen]);
  return (
    <>
      <Accordion
        isOpen={isOpen}
        onChange={toggleIsOpen}
        title={t(`${ns}.available_vars`)}
      >
        {vars.map((variable) => (
          <div
            css={`
              margin-bottom: 7px;
              :last-child {
                margin-bottom: 0;
              }
            `}
            key={variable}
          >
            <span
              onClick={() => onVarClick(variable)}
              role="button"
              css={`
                cursor: pointer;
                color: var(--color-cta);
                font-weight: 700;
              `}
            >
              {"{{" + variable + "}}"}
            </span>
            <span> - </span>
            <span>
              {t(
                `${ns}.vars_desc.${
                  varsGroup === "tasks"
                    ? variable
                    : `_groups.${varsGroup}.${variable}`
                }`
              )}
            </span>
          </div>
        ))}
      </Accordion>
      <div
        id={bottomId}
        css={`
          height: 10px;
        `}
      />
    </>
  );
};
export default memo(AvailableVars);
