const primaryColor = "#856cc9";
const theme = {
  color: {
    primary: primaryColor,
    primary_2: "#AA95E4",
    primary_3: "#B9AAE4",
    primary_4: "#706297",
    touchable: "#F4F2FD",
    positive: "#59d29a",
    negative: "#f484a1",
    warn: "#FFD6C5",
    golden: "#FFD6C5",
  },
  font: {
    main: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    accent: "Adlery, cursive",
  },
  gradient: {
    primary: {
      normal: "linear-gradient(123.82deg, #4b3094 11.86%, #856cc9 71.71%)",
      hover: primaryColor,
    },
  },
  shadow: {
    1: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    0: "0 7px 15px rgba(72,69,50,0.15)",
    light: "0 3px 10px rgba(72,69,50,0.15)",
    golden: "0px 0px 11px 2px rgba(212,175,55, .5)",
    mobileBottomBar: "4px 0px 50px 0px rgba(157, 155, 155, 0.35)",
    mobileBottomBarFab: "4px 4px 25px 0px rgba(157, 129, 234, 0.25)",
  },
  border: {
    lightColor: "rgba(0,0,0, 0.075)",
    mediumColor: "rgba(0,0,0, 0.3)",
    light: "1px solid rgba(0,0,0,.075)",
    schedulerColor: "#ddd",
  },
  bg: {
    main: "#FFFFFF",
    paper: "#FFFFFF",
    elevate: "rgb(245, 242, 251)",
  },
  fontColor: {
    main: "rgba(0,0,0,.85)",
    secondary: "#627D8F",
  },
  mobileBp: "991px",
  rootBorderRadius: "12px",
  __name__: "light",
};

export default theme;
