import { createSelector } from "@reduxjs/toolkit";
import { IS_SERVER } from "@/misc/constants";
import { DOMAIN } from "./slice";

const r = (state) => state[DOMAIN];

const isCalendarVisited = (state) => r(state).isCalendarVisited;
const isCalendarReallyVisited = createSelector(
  isCalendarVisited,
  (isVisited) => {
    if (IS_SERVER || isVisited) return isVisited;
    const data = localStorage.getItem("calendar-visited");
    return Boolean(data);
  }
);

export const Selectors = {
  isCalendarVisited: isCalendarReallyVisited,
};
