import { createSelector } from "@reduxjs/toolkit";
import isempty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import meanBy from "lodash/meanBy";
import uniqBy from "lodash/uniqBy";
import flatten from "lodash/flatten";
import first from "lodash/first";
import { Selectors as ProfileSelectors } from './profile';

const getMasters = (state) => state.saloonProfile.saloon?.masters;
const getServicesWithMappedMasters = createSelector(getMasters, (masters) => {
  if (isempty(masters)) return [];
  return masters.reduce((acc, { _id: master }) => {
    const { user, _id, services } = master;
    services.forEach((service) => {
      acc.push({
        service,
        master: { user, _id },
      });
    });
    return acc;
  }, []);
});
const getServicesGroupedBySpec = createSelector(
  getServicesWithMappedMasters, (_state, specs) => specs,
  (services, specs) => {
    if(!specs) return [];
    const groupedBySpec = groupBy(services, "service.spec");
    return Object.keys(groupedBySpec).map((spec) => {
      const data = groupedBySpec[spec];
      const realSpec = specs[spec] ?? {};
      return {
        spec: {
          _id: spec,
          ...realSpec,
        },
        services: data,
      };
    });
  }
);

const getServicesForSEO = createSelector(getMasters, (masters) => {
  if (isempty(masters)) return [];
  const grouped = groupBy(
    flatten(masters.map(({ _id }) => _id.services)),
    "title"
  );
  return Object.entries(grouped).map(([title, services]) => ({
    name: title,
    "@context": "https://schema.org/",
    "@type": "Product",
    brand: "Simpo",
    offers: {
      "@type": "Offer",
      priceCurrency: first(services).price.currency,
      price: meanBy(services.map((service) => service.price.amount)),
    },
  }));
});
const getServicesGroupedByTitle = createSelector(
  getServicesGroupedBySpec,
  (groupedBySpec) => {
    return groupedBySpec.map(({ spec, services }) => {
      const groupedByTitle = groupBy(services, "service.title");
      return {
        spec,
        titles: Object.keys(groupedByTitle).map((title) => {
          const data = groupedByTitle[title];
          const masters = data.map(({ master, service }) => ({
            ...master,
            service: service._id,
          }));
          return {
            title,
            masters: uniqBy(masters, "_id"),
            avgPrice: {
              currency: data[0].service.price.currency,
              amount: meanBy(data.map(({ service }) => service.price.amount)),
            },
          };
        }),
      };
    });
  }
);

const canEditServices = createSelector(ProfileSelectors.isOwner, ProfileSelectors.isAdmin, (isOwner, isAdmin) => isOwner || isAdmin);

export const Selectors = {
  canEditServices,
  getServices: getServicesGroupedByTitle,
  getServicesForSEO,
  canEditServices,
};
