import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from "_c/Helmet";
import Advice from "_c/atoms/Advice";
import { useTranslation } from "react-i18next";
import { CLIENT_DISC_NS as ns } from "@/i18n/ns";
import { DiscountAPI } from "@/api/discount";
import { useNotify } from "@/hooks/useNotify";
import { HeartsPreloader } from "_c/preloader";
import Item from "./Item";
import isempty from "lodash/isEmpty";
import Empty from "_c/Empty";
import ProfileMenu from "_c/ProfileMenu";
import { Redirect } from "react-router-dom";
import { FullScreenSpin as Spin } from "_c/preloader";
import { useParams } from "react-router-dom";
import AuthOnly from "_c/AuthOnly";

const ClientDiscountsPage = (props) => {
  const { mode } = useParams();
  const { notifyError } = useNotify();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, ready } = useTranslation(["common", ns, "errors"], {
    useSuspense: false,
  });
  const filteredData = useMemo(() => {
    if (mode === "all") return data;
    if (mode === "single_service") {
      return data.filter(({ service }) => service);
    }
    if (mode === "all_services") {
      return data.filter(({ service }) => !service);
    }
    return data;
  }, [data, mode]);
  useEffect(() => {
    async function foo() {
      try {
        setLoading(true);
        const { data } = await DiscountAPI.getMyDiscounts();
        setData(data);
      } catch (e) {
        notifyError(t([`errors:${e}`, "errors:unspecified_service"]));
      } finally {
        setLoading(false);
      }
    }
    foo();
  }, [props.userId]);
  if (!ready) return <Spin size="xl" />;
  if (!mode) {
    return <Redirect to={"client/discounts/all"} />;
  }
  const advice = t(`${ns}:menu.${mode}.advice`);
  return (
    <AuthOnly>
      <div>
        <ProfileMenu t={t} items={props.menu} />
        <Advice title={t("common:advice")} advice={advice} />
        <Helmet title={t(`${ns}:page_title`)} />
        {loading && <HeartsPreloader centered style={{ width: "100px" }} />}
        {isempty(filteredData) ? (
          <Empty message={t("common:empty")} />
        ) : (
          <ul>
            {filteredData.map((disc) => (
              <Item key={disc._id} discount={disc} t={t} />
            ))}
          </ul>
        )}
      </div>
    </AuthOnly>
  );
};
export default ClientDiscountsPage;
