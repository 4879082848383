import React from "react";

const ModelSearchIcon = (props) => {
  const color = props.color || "currentColor";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.95"
      viewBox="0 0 97.95 100"
      {...props}
    >
      <g>
        <path
          fill={color}
          d="M15,51.54C15.7,53.3,18.53,54,18.53,54L12.47,60.9s-2.81,2.74-.09,3.9c1,.42,4.5,1.42,2.17,4.78-3,4.39,4.33,3.65,4.33,3.65s-7.34.33-3,4.24c2.76,2.52-2.28,11.3,6.33,9.73l9.31-1.73V100l23.35-5V81.31C64.1,76.17,69.09,67.5,69.09,57.84c0-17.91-14.47-27.34-28.28-27.34-12,0-18.77,4.67-21.29,10-2.8,5.9-.7,12.52-.7,12.52a4.49,4.49,0,0,1-3.46-1.57Z"
        />
        <path
          fill={color}
          d="M9.55,32.76a11.32,11.32,0,0,0,11.92-4.3c3.69-5.49.84-13-5-13.4-4.46-.27-7,3.28-6.45,6.39A4.56,4.56,0,0,1,16.89,18c2.56,1.44,3.07,4.91,1.53,7.73-1.45,2.66-4.89,3.18-7.33,2.36A8.78,8.78,0,0,1,5.75,21a9.81,9.81,0,0,1,4-8.34A12.59,12.59,0,0,1,18,10.53a13.64,13.64,0,0,1,7.92,4.72,13.66,13.66,0,0,1,2.72,8.59,16.61,16.61,0,0,1-3.22,9.05L23.37,35.8l7,5,2.06-2.91A22.62,22.62,0,0,0,35.3,16.52,20.27,20.27,0,0,0,18.92,3.73,20.5,20.5,0,0,0,11,4.5,17.08,17.08,0,0,0,5.43,7.77,16.42,16.42,0,0,0,0,20.59,13.43,13.43,0,0,0,9.55,32.76Z"
        />
        <path
          fill={color}
          d="M38.86,17.28a11.33,11.33,0,0,0,9.91,7.9c6.61.26,11.56-6.12,8.79-11.29-2.11-3.94-6.44-4.2-8.83-2.14a4.56,4.56,0,0,1,6.52,4.07c.12,2.93-2.57,5.18-5.78,5.35-3,.16-5.27-2.5-5.85-5a8.77,8.77,0,0,1,3.27-8.28,9.78,9.78,0,0,1,9.18-1,12.66,12.66,0,0,1,6.16,6,13.66,13.66,0,0,1,.13,9.22,13.62,13.62,0,0,1-5.89,6.82,16.65,16.65,0,0,1-9.39,2l-3.56-.23L43,39.23l3.56.23A22.6,22.6,0,0,0,66.19,30.7,20.28,20.28,0,0,0,68.5,10,20.33,20.33,0,0,0,63.68,3.7,16.69,16.69,0,0,0,58,.68,16.41,16.41,0,0,0,44.23,2.77,13.45,13.45,0,0,0,38.86,17.28Z"
        />
        <path
          fill={color}
          d="M82.3,63.54a11.32,11.32,0,0,0-10.12,7.63c-1.9,6.34,3,12.72,8.75,11.32,4.34-1.06,5.66-5.19,4.27-8a4.56,4.56,0,0,1-5.56,5.3c-2.88-.62-4.38-3.78-3.75-6.93.6-3,3.73-4.48,6.31-4.42a8.75,8.75,0,0,1,7.2,5.23,9.79,9.79,0,0,1-1.31,9.14,12.68,12.68,0,0,1-7.3,4.48,12.82,12.82,0,0,1-14.1-9.57,16.63,16.63,0,0,1,.39-9.6l1.11-3.39L60,62l-1.11,3.39A22.63,22.63,0,0,0,62.5,86.67,19.68,19.68,0,0,0,89.27,91a16.93,16.93,0,0,0,4.35-4.77A16.4,16.4,0,0,0,95,72.35,13.44,13.44,0,0,0,82.3,63.54Z"
        />
        <path
          fill={color}
          d="M71.59,30a11.32,11.32,0,0,0,.32,12.67c4,5.24,12.12,4.91,14.3-.53,1.66-4.15-.93-7.63-4-8.14a4.56,4.56,0,0,1,1.08,7.61c-2.17,2-5.63,1.37-7.82-1s-1.47-5.64.08-7.71A8.78,8.78,0,0,1,84,30.06a9.78,9.78,0,0,1,6.67,6.38A12.64,12.64,0,0,1,90,45a12.82,12.82,0,0,1-16,5.9A16.71,16.71,0,0,1,66.45,45l-2.11-2.88L57.41,47.2l2.11,2.88a22.64,22.64,0,0,0,19.36,9.43A20.28,20.28,0,0,0,96.19,48,20.51,20.51,0,0,0,98,40.23a17,17,0,0,0-1.35-6.31,16.45,16.45,0,0,0-10.45-9.19A13.44,13.44,0,0,0,71.59,30Z"
        />
      </g>
    </svg>
  );
};

export default ModelSearchIcon;
