import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

/* webpackPreload: true */
const LoadableScheduler = loadable(
  () =>
    import(
      /* webpackChunkName: "fullcalendar-scheduler"*/
      "./main"
    ),
  {
    fallback: <Spin size="xl" />,
    ssr: false,
  }
);
export default LoadableScheduler;
