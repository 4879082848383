import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";

const buildByIdQueryKey = (id) => {
  return [QueryKeys.singleMasterService, id];
};
export const MasterServicesCacheService = {
  buildByIdQueryKey,
  addService({ service }) {
    queryClient.setQueryDataIfCached(buildByIdQueryKey(service._id), service);
  },
  replaceService({ service }) {
    queryClient.setQueryDataIfCached(buildByIdQueryKey(service._id), service);
  },
  removeService({ service }) {
    queryClient.setQueryDataIfCached(buildByIdQueryKey(service._id), null);
  },
};
