import { useEffect, useRef } from "react";

export const useFocusRestore = (refToFocus) => {
  const oldFocus = useRef();
  useEffect(() => {
    oldFocus.current = document.activeElement;
    if (refToFocus.current) {
      refToFocus.current.focus();
    }
    return () => {
      if (oldFocus.current) {
        oldFocus.current.focus();
      }
    };
  }, [refToFocus]);
  const restore = () => {
    if (oldFocus.current) {
      oldFocus.current.focus();
    }
  };
  return restore;
};
