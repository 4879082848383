import { createSelector } from "@reduxjs/toolkit";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as FavoriteSelectors } from "@/store/favorites/selectors";

const getProfile = (state) => state.saloonProfile.saloon;
const getOwner = createSelector(getProfile, (saloon) => saloon?.owner);
const getLogo = createSelector(getProfile, (saloon) => saloon?.logo);
const getDesc = createSelector(getProfile, (saloon) => saloon?.desc || "");
const getRate = createSelector(getProfile, (saloon) => saloon?.rate || 0);
const getReviewsCount = createSelector(
  getProfile,
  (saloon) => saloon?.reviews_count || 0
);
const getHrAddress = createSelector(
  getProfile,
  (saloon) => saloon?.location_hr || ""
);
const getCoords = createSelector(
  getProfile,
  (saloon) => saloon?.location?.coordinates
);
const getTitle = createSelector(
  getProfile,
  (saloon) => [saloon?.type, saloon?.title].filter(Boolean).join(" ") || ""
);
const getId = createSelector(getProfile, (saloon) => saloon?._id);
const isOwner = createSelector(
  getOwner,
  UserSelectors.getUserId,
  (ownerId, authUserId) => {
    return ownerId === authUserId;
  }
);
const getAdmins = createSelector(getProfile, saloon => (saloon?.admins || []).map(a => a?._id));
const isAdmin = createSelector(getAdmins, UserSelectors.getUserId, (admins, authUserId) => {
  return admins.includes(authUserId);
});
const isPremium = createSelector(getProfile, (saloon) => saloon?.premium);
const getInteriorPhotos = createSelector(
  getProfile,
  (saloon) => saloon?.interior_photos || []
);
const getWeekSchedule = createSelector(
  getProfile,
  (saloon) => saloon?.schedule?.for_week || []
);
const getMasters = createSelector(getProfile, (saloon) =>
  saloon?.masters?.map(({ _id }) => ({
    _id: _id._id,
    user: _id.user,
  }))
);
const isFavorite = createSelector(
  getProfile,
  FavoriteSelectors.getFavoriteSaloons,
  (saloon, favorites) => {
    return favorites.includes(saloon?._id);
  }
);
const getFavoriteId = createSelector(getProfile, (saloon) => saloon?._id);

export const Selectors = {
  isFavorite,
  getFavoriteId,
  getId,
  getInteriorPhotos,
  getLogo,
  getDesc,
  getRate,
  getTitle,
  getMasters,
  getReviewsCount,
  getHrAddress,
  getCoords,
  getWeekSchedule,
  isOwner,
  isAdmin,
  isPremium,
  getOwner,
};
