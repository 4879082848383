export const PORTFOLIO_COMMENT_TYPE = 1;
export const NEW_TASK_TYPE = 2;
export const ACCEPT_TASK_TYPE = 3;
export const PHOTO_REQUEST_MASTER_TYPE = 4;
export const PHOTO_REQUEST_CLIENT_TYPE = 5;
export const MASTER_OPEN_CLAIM = 6;
export const CLIENT_OPEN_CLAIM = 7;

export const NEW_REVIEW = 9;
export const MASTER_CANCEL_TASK_TYPE = 10;
export const CLIENT_CANCEL_TASK_TYPE = 11;

export const SUCCESS_ORDER = 12;

export const NOT_ACCEPTED_CLIENT = 15;
export const NOT_ACCEPTED_MASTER = 16;
export const NOT_PAID_MASTER = 17;
export const NOT_PAID_CLIENT = 18;

export const ORDER_PAID_MASTER = 20;
export const ORDER_PAID_CLIENT = 21;
export const SOON_TASK_CLIENT = 22;
export const SOON_TASK_MASTER = 23;
export const PHOTO_MARKED_TYPE = 25;
export const PERSONAL_DISCOUNT = 26;

export const REVIEW_COMMENT = 28;
export const ACCOUNT_COMMIT = 29;

export const PREMIUM_EXPIRED_RECENTLY = 33;
export const PREMIUM_EXPIRES_SOON = 34;
export const PREMIUM_PAID = 35;
export const TASK_CLAIM_APPROVE = 36;
export const TASK_CLAIM_REJECT = 37;
export const SALOON_INVITE = 38;
export const SALOON_INVITE_RESULT = 39;
export const TASK_UPDATED = 40;

export const CLIENT_INVITE_ACCEPTED = 42;
export const CLIENT_INVITE_REJECTED = 43;
export const CLIENT_INVITE_FRESH = 44;
export const NEW_CUSTOM_TASK_TO_CLIENT = 45;

export const SMS_PAYMENT = 56;

export const PREMIUM_SMS_ACC_GIFT = 58;
export const NEW_TASK_TO_SALOON_OWNER = 59;
export const CLIENT_BDAY_REMIND = 60;
export const NEW_MESSENGER_SUB = 61;
export const PREMIUM_EXPIRED = 62;
export const PREMIUM_EXPIRED_LAST_PHASE = 63;
export const NEW_TASK_TO_MASTER_BY_SALOON_ACTOR = 64;
export const TODO_REMIND = 65;
export const WAREHOUSE_PRODUCT_SHORTAGE = 68;
