import styled from 'styled-components';

export const Wrap = styled.div`
`;
export const Avatar = styled.img`
  width 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
`
export const Slide = styled.div`
  margin-right: 18px;
  :last-child {
    margin-right: 0;
  }
`;
export const Btn = styled.button`
  top: -6px;
  position: absolute;
  color: #a3a091;
  font-size: 18px;
  font-weight: 600;
  transition: opacity .4s ease;
  :hover, :focus {
    opacity: .6;
  }
`;
export const NextBtn = styled(Btn)`
  right: 6px;
`;
export const PrevBtn = styled(Btn)`
  right: 35px;
`;
