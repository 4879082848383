import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import createSagaMiddleware, { END } from "redux-saga";
import { PROD, IS_BROWSER } from "@/misc/constants";

const middleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
});
const create = (state = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: [...middleware, sagaMiddleware],
    preloadedState: state,
    devTools: !PROD,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export const createServerStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware, ...middleware],
  });
  store.runSaga = () => sagaMiddleware.run(rootSaga);
  store.close = () => store.dispatch(END);
  return store;
};
/**
 *
 * @returns {import('@reduxjs/toolkit').EnhancedStore}
 */
export const getReduxStore = () => {
  if (IS_BROWSER) return window.__reduxStore;
};
export const getReduxState = () => {
  const store = getReduxStore();
  if (store) return store.getState();
};
export { create as createStore };
