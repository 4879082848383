import React from "react";
import * as S from "./index-styled";
import AppStoreLink from "_c/native-app-link/AppStore";
import PlayMarketLink from "_c/native-app-link/PlayMarket";

const Apps = ({ t }) => {
  return (
    <S.NativeAppLinks>
      <PlayMarketLink t={t} />
      <AppStoreLink t={t} />
    </S.NativeAppLinks>
  );
};

export default Apps;
