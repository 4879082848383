import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";

const DOMAIN = "saloon";

const initialState = {
  loading: false,
  error: null,
  saloon: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadingSuccess(state, { payload }) {
      state.saloon = payload;
      state.loading = false;
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    createService(state, { payload }) {
      const master = state.saloon.masters.find(
        ({ _id }) => _id._id === payload.master
      );
      if (master) {
        master._id.services.push(payload);
      }
    },
    updateService(state, { payload }) {
      const master = state.saloon.masters.find(
        ({ _id }) => _id._id === payload.master
      );
      if (master) {
        const idx = master._id.services.findIndex(
          ({ _id }) => _id === payload._id
        );
        if (idx !== -1) {
          master._id.services[idx] = payload;
        }
      }
    },
    deleteService(state, { payload }) {
      for (const { _id: master } of state.saloon.masters) {
        const idx = master.services.findIndex(({ _id }) => _id === payload);
        if (idx !== -1) {
          master.services.splice(idx, 1);
          break;
        }
      }
    },
    updateSaloon(state, { payload }) {
      merge(state.saloon, payload);
    },
    updateSaloonSchedule(state, { payload }) {
      if (state.saloon) {
        state.saloon.schedule = payload;
      }
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
