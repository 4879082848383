import { UserSyncDb, ENTITIES } from "../../syncDb";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { Selectors as UserSelectors } from '@/store/user/selectors';
import { analyticsAction } from "@/utils/analytics";
const db = UserSyncDb.instance;

export const NotesService = {
  async createNote(data) {
    const reduxStore = window.__reduxStore;
    const isBiz = UserSelectors.isBusinessMode(reduxStore.getState());
    data.type = ENTITIES.note;
    await db.addDoc(data);
    analyticsAction({
      category: 'notes',
      action: isBiz ? "create_note_pro" : "create_note_todo",
    })
  },
  async updateNote(note, updates) {
    const updated = merge(cloneDeep(note), updates);
    await db.updateDoc(updated);
  },
  async removeNote(note) {
    await db.removeDoc(note._id);
  },
};
