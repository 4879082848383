import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useAppConfig } from "./useAppConfig";
import { UserPrefsService } from "@/services/userPrefs";

export const useTheme = () => {
  return useContext(ThemeContext);
};
export const useThemeId = () => {
  return useTheme().id;
};

export const useAvailableThemes = () => {
  return useAppConfig().themes;
};
export const useChangeTheme = () => {
  const appCfg = useAppConfig();
  return (theme) => {
    const themeObject = appCfg.themes.find(({ id }) => id === theme);
    if (!themeObject) {
      console.error("No theme in app cfg for: ", theme);
      return;
    }
    if (themeObject.__name__ === "dark") {
      return UserPrefsService.updatePrefs({
        color_scheme: "dark",
      });
    }
    return UserPrefsService.updatePrefs({
      theme,
      color_scheme: themeObject.__name__,
    });
  };
};
