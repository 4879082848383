import React from "react";
import { useTranslation } from "react-i18next";
import { Footer as BaseFooter } from "_c/Modals";

type tProps = {
  onClose: tVoidFn;
};
const Footer = ({ onClose }: tProps) => {
  const { t, ready } = useTranslation(["common"], {
    useSuspense: false,
  });
  if (!ready) return null;
  return (
    <BaseFooter
      css={`
        background-color: var(--bg-paper) !important;
        color: var(--font-color-main) !important;
        display: flex;
      `}
      onClick={onClose}
    >
      {t("common:close")}
    </BaseFooter>
  );
};

export default Footer;
