import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET,
  MAX_UPLOAD_PHOTO_SIZE,
} from "@/misc/constants";
import cloudinary from "cloudinary-tiny-js";
import ee from "@/utils/ee";
import translations from "./i18n.json";
import {DEFAULT_LOCALE} from "@/config";

let loadingScriptPromise = null;
const loadScript = async () => {
  if (loadingScriptPromise) return loadingScriptPromise;
  const url = "https://upload-widget.cloudinary.com/global/all.js";
  const scriptId = "cloudinary-upload-widget-script";
  const maybeCreateScript = () => {
    if (document.getElementById(scriptId)) return;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = scriptId;
    document.body.appendChild(script);
  };
  maybeCreateScript();
  if (window.cloudinary) {
    loadingScriptPromise = Promise.resolve();
  } else {
    loadingScriptPromise = new Promise((res) => {
      const interval = setInterval(() => {
        if (window.cloudinary) {
          clearInterval(interval);
          res();
        }
      }, 50);
    });
    return loadingScriptPromise;
  }
};

export const createUploadWidget = async () => {
  await loadScript();
  const maybeWidget = window.__cloudinaryWidget__;
  if (maybeWidget) {
    return maybeWidget;
  }
  const lang = window.__i18n?.language || DEFAULT_LOCALE;

  const widget = window.cloudinary.createUploadWidget(
    {
      cloudName: CLOUDINARY_CLOUD_NAME,
      uploadPreset: CLOUDINARY_UPLOAD_PRESET,
      language: lang,
      ...(lang === "ru" && {
        text: {
          ru: translations,
        },
      }),
      theme: "minimal",
      resource_type: "image",
      client_allowed_formats: ["jpg", "jpeg", "png"],
      multiple: false,
      cropping: false,
      // 10mb
      maxFileSize: MAX_UPLOAD_PHOTO_SIZE,
      sources: [
        "local",
        "url",
        "camera",
        "image_search",
        "facebook",
        "instagram",
      ],
    },
    (err, result) => {
      if (err) {
        console.error(err);
      } else if (result) {
        if (result.event === "success") {
          ee.emit("cloudinary_widget/completed", result.info);
        } else if (result.event === "close") {
          ee.emit("cloudinary_widget/closed");
        }
      }
    }
  );
  window.__cloudinaryWidget__ = widget;
  return widget;
};

export const openUploadWidget = async () => {
  const widget = await createUploadWidget();
  widget.open();
};

const cl = cloudinary({
  cloudName: CLOUDINARY_CLOUD_NAME,
});
export const generateUrl = (publicId, params = {}) => {
  if (!publicId) return null;
  return cl(publicId, {...params, secure: true});
};
