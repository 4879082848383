import * as React from "react";

function SvgHairRemoval({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 86.31"
      {...props}
    >
      <path d="M2.86 17.6a3.88 3.88 0 00-2.73 4.74l4.68 17.45a3.87 3.87 0 007.47-2L7.6 20.33a3.87 3.87 0 00-4.74-2.73zm12.4-6.24a3.87 3.87 0 00-2.73 4.74L18.67 39a3.87 3.87 0 107.47-2L20 14.1a3.87 3.87 0 00-4.74-2.74zm13.86-.8a3.88 3.88 0 00-2.74 4.74l4.68 17.45a3.87 3.87 0 107.47-2L33.85 13.3a3.87 3.87 0 00-4.73-2.74zM42 70.63c-1.89-7.94-8.9-17.94-10.65-20.93-1.76 3-8.77 13-10.63 20.93a19.93 19.93 0 00-.52 4.54 11.15 11.15 0 0022.29 0 20.51 20.51 0 00-.49-4.54zM26.49 63c.43 8.83 4.85 16.37 13.55 16.16-8.37 9.52-21.3-.58-13.55-16.16zM99.34 9.64a6.73 6.73 0 11-3.17-9 6.73 6.73 0 013.17 9zm-16.41.74a10.94 10.94 0 01-.56-4.73l-44.95 12 2 7.47 45-12a11.19 11.19 0 01-1.49-2.74z" />
    </svg>
  );
}

export default SvgHairRemoval;
