export const QueryKeys = {
  appCfg: "appCfg",
  accountingCfg: "accountingCfg",
  appStoriesCfg: "appStoriesCfg",

  master: "master",

  freeSlots: "masterFreeSlots",
  taskEditorBootstrap: "taskEditorBootstrap",
  tasksSearch: "tasksSearch",
  singleTask: "singleTask",
  scheduler: "scheduler",
  businessTasksAggr: "businessTasksAggr",

  singleClient: "singleClient",
  clients: "clients",
  clientsInvites: "clientsInvites",
  clientMessengerSub: "clientMessengerSub",
  clientTasksHistory: "clientTasksHistory",

  accountingTxn: "accountingTxn",

  singleReview: "singleReview",

  todoLists: "todoLists",
  repeatableTodos: "repeatableTodos",

  allSpecs: "specs",
  searchableSpecs: "searchableSpecs",

  premiumPrice: "premiumPrice",

  warehouseProductsSearch: "warehouseProductsSearch",
  warehouseSingleProduct: "warehouseSingleProduct",
  warehouseAllProductCategories: "warehouseAllProductCategories",
  warehouseTxnsSearch: "warehouseTxnsSearch",
  warehouseTaskTxns: "warehouseTaskTxns",
  warehouseTxns: "warehouseTxns",

  bizTaskPhotos: "bizTaskPhotos",

  mySaloons: "mySaloons",
  saloonInvites: "saloonInvites",
  saloonDashboardMasters: "saloonDashboardMasters",
  saloonDashboardAdmins: "saloonDashboardAdmins",

  singleMasterService: "singleMasterService",

  discountsByMaster: "discountsByMaster",

  externalNotificationTmpls: "externalNotificationTmpls",
  externalNotificationSupportedTmpls: "externalNotificationSupportedTmpls",

  smsAccount: "smsAccount",
  smsJobs: "smsJobs",

  convertableCurrenciesList: "convertableCurrenciesList",
  currencyConvert: "currencyConvert",
};
