import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const SearchPage = loadable(
  () =>
    import(
      /* webpackChunkName: "search-page" */
      "."
    ),
  {
    fallback: <Spin size="xl" />,
  }
);

export default SearchPage;
