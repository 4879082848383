import { QueryKeys } from "../../network/queryKeys";
import { queryClient } from "../../network/queryClient";

const buildAdminsQueryKey = (saloonId) => {
  const base = QueryKeys.saloonDashboardAdmins;
  if (!saloonId) return base;
  return [base, saloonId];
};

const buildMastersQueryKey = (saloonId) => {
  const base = QueryKeys.saloonDashboardMasters;
  if (!saloonId) return base;
  return [base, saloonId];
};

const invalidateMasters = (saloonId) => {
  queryClient.invalidateQueries(buildMastersQueryKey(saloonId));
};
const invalidateAdmins = (saloonId) => {
  queryClient.invalidateQueries(buildAdminsQueryKey(saloonId));
};
const invalidateAll = (saloonId) => {
  invalidateAdmins(saloonId);
  invalidateMasters(saloonId);
};

export const SaloonStaffCacheService = {
  buildAdminsQueryKey,
  buildMastersQueryKey,
  invalidateAdmins,
  invalidateMasters,
  invalidateAll,
  removeMaster({ id, saloonId }) {
    queryClient.setQueryDataIfCached(
      buildMastersQueryKey(saloonId),
      (masters) => {
        return masters.filter(({ master }) => master._id !== id);
      }
    );
  },
  removeAdmin({ id, saloonId }) {
    queryClient.setQueryDataIfCached(
      buildAdminsQueryKey(saloonId),
      (admins) => {
        return admins.filter(({ admin }) => admin._id !== id);
      }
    );
  },
};
