import React from "react";
import Layout from "@/layouts/Root";
import Search from "_p/search/container";
import Home from "_p/home/container";
import { Route, Switch } from "react-router-dom";
import NotFound from "_p/404";

const HomeAndSearch = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/search/:loc?/:spec?" component={Search} />
        <Route
          render={({ staticContext }) => {
            if (staticContext) {
              staticContext.notFound = true;
            }
            return <NotFound />;
          }}
        />
      </Switch>
    </Layout>
  );
};
export default HomeAndSearch;
