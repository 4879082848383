import React from "react";

const BlogSvg = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    stroke={color || "currentColor"}
    strokeWidth="1.5"
    fill="none"
    {...props}
  >
    <path
      d="M3 17c0 2.4 2 3 3 3 5.5 0 5-8 10.5-8 2.5 0 4.5 2.0147 4.5 4.5S18.9853 21 16.5 21a4.4784 4.4784 0 01-2.5-.7578M6.5 13.5l8-8L12 3l-8 8-.5 3 3-.5z"
      strokeLinecap="round"
    ></path>
  </svg>
);
export default BlogSvg;
