import { connect } from "react-redux";
import { Selectors } from "@/store/saloonProfile/selectors";
import SaloonPage from "./loadable";

const mSp = (state) => ({
  loading: Selectors.isLoading(state),
  error: Selectors.getError(state),
});

export default connect(mSp)(SaloonPage);
