import pick from "lodash/pick";
import { SaloonAPI } from "@/api/saloon";
import { SaloonsCacheService } from "./cache";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { analyticsAction } from "@/utils/analytics";
import { MarketplaceAPI } from "@/api/marketplace";
import { SaloonMutationHandler } from "./mutationHandler";
import websocket from "@/websocket";

export const SaloonsService = {
  async createSaloon(data) {
    const { data: saloon } = await SaloonAPI.create(data);
    const reduxStore = window.__reduxStore;
    const reduxState = reduxStore.getState();
    const owner = UserSelectors.getProfile(reduxState);
    analyticsAction({
      category: "salon_dashboard",
      action: "create_salon",
    });
    SaloonsCacheService.addSaloon({
      saloon: {
        ...saloon,
        owner: pick(owner, [
          "_id",
          "first_name",
          "last_name",
          "middle_name",
          "sex",
          "online",
          "avatar",
          "phone",
          "master",
          "email",
        ]),
      },
    });
    return saloon;
  },
  async registerMarketplaceShop({ saloonId, data }) {
    const { data: mpId } = await MarketplaceAPI.register({
      saloon: saloonId,
      data,
    });
    SaloonsCacheService.updateSaloon({
      id: saloonId,
      updates: {
        mp_id: mpId,
      },
    });
    analyticsAction({
      category: "salon",
      action: "reg_shop_salon",
    });
  },
  async updateSaloon({ id, updates }) {
    await SaloonAPI.updateById(id, updates);
    websocket.execIfNotConnected(() => {
      SaloonMutationHandler.updated({ saloon_id: id, data: updates });
    });
  },
  async updateSaloonSchedule({ id, data }) {
    const normalized = {
      schedule: pick(data, "for_week"),
    };
    await SaloonAPI.updateSchedule(id, normalized);
    websocket.execIfNotConnected(() => {
      SaloonMutationHandler.baseScheduleUpdated({
        saloon_id: id,
        data: normalized,
      });
    });
  },
  async removeSaloon(id) {
    await SaloonAPI.deleteById(id);
    websocket.execIfNotConnected(() => {
      SaloonMutationHandler.removed({ saloon_id: id });
    });
  },
};
