import range from "lodash/range";
import {
  DEFAULT_FEMALE_AVATAR,
  DEFAULT_MALE_AVATAR,
  DEFAULT_PROFILE_PHOTO,
  DEFAULT_PROFILE_PHOTO_MALE,
} from "@/misc/constants";
import { IS_SERVER } from "@/misc/constants";
import { generateUrl } from "./cloudinary";

export const wait = (ms) => new Promise((r) => setTimeout(r, ms));
export const isObject = (o) => {
  return typeof o === "object" && o !== null;
};
export const getAccessToken = () => {
  if (!IS_SERVER) {
    return window.access_token;
  }
  return null;
};
export function generateId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}
export function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
}
export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
export function getFormattedDaysOfMonth(date) {
  let copiedDate = new Date(date.getTime());
  const amount = getDaysInMonth(date.getMonth() + 1, date.getFullYear());
  return range(1, amount + 1).map((day) => {
    copiedDate.setDate(day);
    return formatDate(copiedDate);
  });
}
export function getFormattedDaysOfWeek(date) {
  let copiedDate = new Date(date.getTime());
  copiedDate.setDate(copiedDate.getDate() - 1);
  return range(7).map((day) => {
    copiedDate.setDate(copiedDate.getDate() + 1);
    return formatDate(copiedDate);
  });
}
export function getFormattedDaysOfWeekFromMonday(date) {
  const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  const monday = new Date(date.getTime());
  monday.setDate(diff);
  return getFormattedDaysOfWeek(monday);
}
export function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

//https://gist.github.com/realmyst/1262561
export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

export function formatDuration(duration) {
  let h, m;
  if (Number(duration[0])) {
    h =
      String(duration[0]) +
      " " +
      declOfNum(duration[0], ["час", "часа", "часов"]);
  }
  if (duration[1]) {
    m =
      duration[1] + " " + declOfNum(duration[1], ["минута", "минуты", "минут"]);
  }
  return [h, m].filter(Boolean).join(" ");
}
export function filterObject(obj, predicate) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

export function parseServiceBonus(bonusStr) {
  return bonusStr.match(/\d+/)[0];
}
export function parseTaskFullPrice(fullPriceStr) {
  return fullPriceStr.match(/\d+/)[0];
}
export function parseScheduleIntervals(str) {
  if (Array.isArray(str)) return str;
  const sp = str.split(",");
  const start = sp[0].match(/\d+\.\d+/)[0];
  const end = sp[1].match(/\d+\.\d+/)[0];
  return [start, end];
}

export const formatTime = ([h, m]) => {
  return `${h > 9 ? h : "0" + h}:${m > 9 ? m : "0" + m}`;
};

function isInt(n) {
  return n % 1 === 0;
}

export const normalizeRating = (r) => {
  if (!r) return "0.0";
  return isInt(r) ? "" + r + ".0" : "" + Number(r).toFixed(1);
};
export const getAvatarPlaceholder = (isMale, _reverse, rect) => {
  if (isMale) {
    if (rect) return DEFAULT_PROFILE_PHOTO_MALE;
    return DEFAULT_MALE_AVATAR;
  } else {
    if (rect) return DEFAULT_PROFILE_PHOTO;
    return DEFAULT_FEMALE_AVATAR;
  }
};
export const normalizeAvatar = ({ isMale, avatar }) => {
  if (avatar) return avatar;
  return isMale ? DEFAULT_MALE_AVATAR : DEFAULT_FEMALE_AVATAR;
};
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
export function scrollToBottom($el) {
  if ($el) {
    $el.scrollTop = $el.scrollHeight;
  }
}

export const generateUserLink = (user) => {
  return `${user.master ? "/master" : "/client"}?id=${
    user.master
      ? isObject(user.master)
        ? user.master.id
        : user.master
      : user.id
  }`;
};

export const isScrollBottom = (elem, offset = 10) => {
  return elem.scrollTop >= elem.scrollHeight - elem.offsetHeight - offset;
};
export const isScrollTop = (elem, offset = 0) => {
  return elem.scrollTop <= offset;
};

export const openLinkInNewTab = (link) => {
  const win = window.open(link, "_blank");
  win.focus();
};
export const openLinkInSameTab = (link) => {
  window.open(link, "_self");
};
export const createQueue = async (items) => {
  for (const item of items) {
    await item();
  }
};
export const generateAvatar = ({
  url,
  sex,
  reverseColors,
  width,
  height,
  rect,
  circle,
}) => {
  if (url) {
    return generateUrl(url, {
      width: width || 80,
      height: height || 80,
      crop: "thumb",
      gravity: "face",
      ...(circle && { radius: "max" }),
    });
  } else {
    return getAvatarPlaceholder(sex, reverseColors, rect);
  }
};

export const fileToBase64 = (file) =>
  new Promise((res) => {
    if (!IS_SERVER) {
      // Define the FileReader which is able to read the contents of Blob
      var reader = new window.FileReader();

      // The magic always begins after the Blob is successfully loaded
      reader.onload = function () {
        // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
        var b64 = reader.result.replace(/^data:.+;base64,/, "");
        res(b64);
      };

      // Since everything is set up, let’s read the Blob and store the result as Data URI
      reader.readAsDataURL(file);
    }
  });

export const copyToClipboard = (text) => {
  if (!IS_SERVER) {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
};

export const getAffiliateCode = ({ affiliateData, isMaster }) => {
  const type = affiliateData && affiliateData.affiliate_type;
  if (affiliateData) {
    if ((isMaster && type === "master") || (!isMaster && type === "client")) {
      return affiliateData.affiliate_code;
    }
  }
};
export function getCookie(name) {
  if (!IS_SERVER) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
}
export const not = (b) => !b;

export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const stripPhone = (phone) => {
  if (!phone) {
    return "";
  }
  return phone.replace(/\+| |-/g, "");
};

export const mapNumber = (x, a, b, c, d) => (x - a) * ((d - c) / (b - a)) + c;
