import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "@/store/modals/selectors";
import { Actions } from "@/store/modals/slice";
import PhotosGallery from "./loadable";

const PhotosGalleryGlobalModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(Selectors.isImagesGalleryOpen);
  const params = useSelector(Selectors.getImagesGalleryParams);
  const onClose = useCallback(() => {
    dispatch(Actions.closeImagesGallery());
  }, []);
  if (!isOpen) return null;
  return <PhotosGallery onClose={onClose} {...params} />;
};

export default PhotosGalleryGlobalModal;
