import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useAllWarehouseProductCategoriesQuery } from "@/hooks/queries/useAllWarehouseProductCategoriesQuery";
import { useToggle } from "@/hooks/useToggle";
import { Button, CircleButton } from "_c/button";
import CategoriesList from "./CategoriesList";
import TrashIcon from "_c/icon/Trash";
import PlusIcon from "_c/icon/Plus";
import PencilIcon from "_c/icon/Pencil";
import { i18nNs } from "./config";
import { analyticsAction } from "@/utils/analytics";

const CategoryField = ({ t, master, saloon }) => {
  const [isListOpen, toggle] = useToggle();
  const { register, watch, setValue } = useFormContext();
  const query = useAllWarehouseProductCategoriesQuery({ master, saloon });
  const category = watch("category");
  useEffect(() => {
    register({ name: "category" });
  }, [register]);
  const onChange = (v) => setValue("category", v);
  return (
    <>
      <div
        css={`
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          min-height: 40px;
        `}
      >
        <Button
          type="button"
          onClick={() => {
            toggle();
            if (!category) {
              analyticsAction({
                category: "warehouse",
                action: "saloon"
                  ? "add_good_category_salon"
                  : "add_good_category",
              });
            }
          }}
          withIcon
          small
          transparent
          css={`
            padding-inline-start: 0;
            font-size: 16px;
          `}
        >
          {category ? (
            <>
              <PencilIcon width="18" />
              <span>{category.title}</span>
            </>
          ) : (
            <>
              <PlusIcon width="18" />
              <span>{t(i18nNs + "no_category")}</span>
            </>
          )}
        </Button>
        {category && (
          <CircleButton
            onClick={() => onChange(null)}
            transparent
            medium
            type="button"
          >
            <TrashIcon width="20" color="var(--color-danger)" />
          </CircleButton>
        )}
      </div>
      <CategoriesList
        master={master}
        saloon={saloon}
        options={query.data || []}
        toggle={toggle}
        t={t}
        selected={category}
        isOpen={isListOpen}
        onChange={onChange}
      />
    </>
  );
};

export default CategoryField;
