import { UserAPI } from "@/api/user";
import { MasterAPI } from "@/api/master";
import { Selectors } from "./selectors";
import { Actions } from "@/store/user/slice";
import { Selectors as PremiumSelectors } from "../premium/selectors";
import { Effects as PremiumEffects } from "../premium/effects";
import { analyticsAction } from "@/utils/analytics";
import { FeaturesAPI } from "@/api/features";
import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";
import { TaskEditorCacheService } from "@/services/taskEditor/cache";

const changeAppUsageMode = (mode) => async (dispatch, getState) => {
  const state = getState();

  const isClientMode = mode === "client";

  const isMasterCreated = Selectors.isMaster(state);
  if (!isMasterCreated && mode === "business") {
    await dispatch(createMaster());
  }

  if (mode === "business") {
    analyticsAction({
      category: "settings",
      action: "addpro_features",
    });
  } else if (mode === "std") {
    analyticsAction({
      category: "settings",
      action: "removepro_features",
    });
  }
  await dispatch(
    updateUser({
      app_usage_mode: isClientMode ? "std" : mode,
      is_client: isClientMode,
    })
  );

  const isPremiumTrialAllowed = PremiumSelectors.getAllowTrial(state);
  const isPremium = PremiumSelectors.isActive(state);
  if (isPremiumTrialAllowed && !isPremium) {
    try {
      await dispatch(PremiumEffects.activateTrial());
    } catch (e) {
      console.error(e);
    }
  }
};
const updateUser = (payload) => async (dispatch, getState) => {
  const id = Selectors.getUserId(getState());
  await UserAPI.updateById(id, payload);
  dispatch(Actions.updateUser(payload));
};
const updateMaster = (payload) => async (dispatch, getState) => {
  const id = Selectors.getMasterId(getState());
  await MasterAPI.updateById(id, payload);
  dispatch(Actions.updateMaster(payload));
  TaskEditorCacheService.updateMaster({ masterId: id, updates: payload });
};
const updateAvatar = (avatar) => async (dispatch) => {
  await dispatch(updateUser({ avatar }));
};
const createMaster = () => async (dispatch) => {
  const { data: master } = await MasterAPI.createMaster();
  dispatch(Actions.createMaster(master));
};
const updateLocale = (locale) => async (dispatch, getState) => {
  const state = getState();
  const userId = Selectors.getUserId(state);
  if (userId) {
    await UserAPI.updateLocale(userId, locale);
    dispatch(Actions.updateUser({ locale }));
  }
};
const updateCurrency = (currency) => async (dispatch, getState) => {
  const state = getState();
  const userId = Selectors.getUserId(state);
  if (userId) {
    await UserAPI.updateCurrency(userId, currency);
    dispatch(Actions.updateUser({ currency }));
  }
};
const activateServerFeature = (feat) => async (dispatch, getState) => {
  await FeaturesAPI.enableFeature(feat);
  if (feat === "expenses_to_accounting_txns") {
    queryClient.invalidateQueries(QueryKeys.accountingTxn);
  }
  const state = getState();
  const features = Selectors.getServerFeatures(state);
  dispatch(
    Actions.updateUser({
      enabled_features: [...features, feat],
    })
  );
};

export const Effects = {
  changeAppUsageMode,
  createMaster,
  updateAvatar,
  updateMaster,
  updateUser,
  updateLocale,
  updateCurrency,
  activateServerFeature,
};
