import { createSelector } from "reselect";
import { SEARCH_PER_PAGE } from "@/misc/constants";

const $ = (state) => state.masterSearch;
const isLoading = (state) => $(state).loading;
const isGeoLoading = (state) => $(state).loadingGeo;
const getGeoResults = (state) => $(state).geoResults;
const getResults = (state) => $(state).results;
const getResultsLength = (state) => $(state).results?.length || 0;
const hasMore = (state) => $(state).hasMore;
const getMapPopup = (state) => $(state).map.popup;
const getMapFocus = (state) => $(state).map.focus;
const getCurrentPage = createSelector(getResults, (results) => {
  return Math.max(0, Math.ceil(results.length / SEARCH_PER_PAGE) - 1);
});
const isMapOpen = (state) => $(state).map.open;
const isFiltersOpen = (state) => $(state).filters.open;
const getLocMeta = (state) => $(state).locMeta;
const getLocCoords = createSelector(getLocMeta, (meta) => meta?.coords);
const getLocName = createSelector(getLocMeta, (meta) => meta?.label);
const getLocSlug = createSelector(getLocMeta, (meta) => meta?.slug);
const hasCoords = createSelector(getLocCoords, (coords) => {
  if (!coords) {
    return false;
  }
  if (coords.lat == 0 && coords.lng == 0) {
    return false;
  }
  return true;
});

const getSeoMeta = (state) => $(state).seoMeta;

export const Selectors = {
  getGeoResults,
  getMapFocus,
  getMapPopup,
  getResults,
  getCurrentPage,
  isGeoLoading,
  isLoading,
  isFiltersOpen,
  isMapOpen,
  hasMore,
  getLocMeta,
  getLocCoords,
  getLocName,
  getLocSlug,
  hasCoords,
  getResultsLength,
  getSeoMeta,
};
