import { useRef, useEffect } from "react";
import { createUploadWidget, openUploadWidget } from "@/utils/cloudinary";
import ee from "@/utils/ee";

const ev = "cloudinary_widget/completed";
const closeEv = "cloudinary_widget/closed";
// TODO:: memory leak
export const useCloudinaryWidget = () => {
  useEffect(() => {
    createUploadWidget();
  }, []);
  const cb = useRef();
  const closeCb = useRef();
  const uploadPhoto = () =>
    new Promise(async (resolve) => {
      cb.current = ({ public_id }) => {
        ee.off(ev, cb.current);
        cb.current = undefined;
        resolve(public_id);
      };
      closeCb.current = () => {
        ee.off(closeEv, closeCb.current);
        closeCb.current = undefined;
        if (cb.current) {
          ee.off(ev, cb.current);
          cb.current = undefined;
        }
      };
      await openUploadWidget();
      ee.on(ev, cb.current);
      ee.on(closeEv, closeCb.current);
    });
  useEffect(() => {
    return () => {
      if (cb.current) {
        ee.off(ev, cb.current);
        cb.current = undefined;
      }
      if (closeCb.current) {
        ee.off(closeEv, closeCb.current);
        closeCb.current = undefined;
      }
    };
  }, []);
  return {
    uploadPhoto,
  };
};
