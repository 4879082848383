import { WarehouseAPI } from "@/api/warehouse";
import {
  iCreateWarehouseProductCategoryDto,
  iUpdateWarehouseProductCategoryDto,
} from "@/types";
import { analyticsAction } from "@/utils/analytics";
import ws from "@/websocket";
import { WarehouseProductCategoryMutationHandler } from "./mutationHandler";

const createCategory = async ({
  data,
}: {
  data: iCreateWarehouseProductCategoryDto;
}) => {
  const res = await WarehouseAPI.createProductCategory(data);
  ws.execIfNotConnected(() => {
    WarehouseProductCategoryMutationHandler.categoryCreated(res.data);
  });
  analyticsAction({
    category: "warehouse",
    action: data.saloon ? "good_category_added_salon" : "good_category_added",
  });
  return res.data;
};
const updateCategory = async ({
  category,
  data,
}: {
  category: string;
  data: iUpdateWarehouseProductCategoryDto;
}) => {
  const res = await WarehouseAPI.updateProductCategory(category, data);
  ws.execIfNotConnected(() => {
    WarehouseProductCategoryMutationHandler.categoryUpdated(res.data);
  });
  return res.data;
};
const removeCategory = async ({ category }: { category: string }) => {
  const res = await WarehouseAPI.removeProductCategory(category);
  ws.execIfNotConnected(() => {
    WarehouseProductCategoryMutationHandler.categoryRemoved(res.data);
  });
  return res.data;
};

export const WarehouseProductCategoriesService = {
  createCategory,
  updateCategory,
  removeCategory,
};
