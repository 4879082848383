import React from "react";
import loadable from "@loadable/component";
import { FullScreenSpin as Spin } from "_c/preloader";

const BlogPage = loadable(
  () => import(/* webpackChunkName: "blog-page" */ "_p/blog/index"),
  {
    fallback: <Spin size="xl" />,
  }
);

export default BlogPage;
