const init = {
  notif_sounds: true,
  message_sounds: true,
};
export const getUserSettings = () => {
  try {
    const history = JSON.parse(localStorage.getItem('user-settings'));
    return history || init;
  } catch (e) {
    return init;
  }
};
export const updateUserSettings = payload => {
  const oldSettings = getUserSettings();
  localStorage.setItem(
    'user-settings',
    JSON.stringify({...oldSettings, ...payload}),
  );
};
export const removeUserSettings = () => {
  localStorage.removeItem('user-settings');
};
