import React, { memo } from "react";
import * as S from "./styled";
import PT from "prop-types";
import { useSearchableSpecsQuery } from '@/hooks/queries/useSearchableSpecsQuery';

const SpecsList = ({ color: baseColor, className }) => {
  const specs = useSearchableSpecsQuery().data ?? [];
  const color = baseColor || "var(--font-color-accent)";
  return (
    <S.Navbar className={className}>
      <S.NavbarList color={color}>
        {specs.slice(0, 6).map(({ _id, title, desc }) => (
          <S.NavBarLi key={_id}>
            <S.NavbarLink title={desc} to={`/search?spec=${_id}`}>
              {title}
            </S.NavbarLink>
          </S.NavBarLi>
        ))}
      </S.NavbarList>
    </S.Navbar>
  );
};
SpecsList.propTypes = {
  color: PT.string,
};

export default memo(SpecsList);
