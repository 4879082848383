import React from "react";
import s from "styled-components";
import PT from "prop-types";

const Wrap = s.div`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  margin: ${({ centered }) => (centered ? "auto" : "")};
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }
  color: var(--font-color-main);
  .empty-message {
    text-align: ${({ centered }) => (centered ? "center" : "left")};
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
`;
const Empty = (props) => {
  return (
    <Wrap {...props}>
      {props.message && <div className="empty-message">{props.message}</div>}
    </Wrap>
  );
};
Empty.defaultProps = {
  centered: true,
};
Empty.propTypes = {
  message: PT.any,
  width: PT.number,
  centered: PT.bool,
};

export default Empty;
