import React, { useState } from "react";
import * as S from "./styled";
import PT from "prop-types";
import debounce from "lodash/debounce";
import { SearchAPI } from "@/api/search";
import isempty from "lodash/isEmpty";
import last from "lodash/last";
import first from "lodash/first";
import { useColorScheme } from "@/hooks/useColorScheme";

const ServiceAutocomplete = (props) => {
  const colorScheme = useColorScheme();
  const [autocomplete, setAutocomplete] = useState([]);
  const [loading, setLoading] = useState(false);
  const onChange = ({ target }) => {
    props.onChange(target.value);
    updateAutocomplete(target.value);
  };
  const updateAutocomplete = debounce(async (service) => {
    setLoading(true);
    try {
      const { data } = await SearchAPI.autocomplete({
        query: service,
      });
      setAutocomplete(
        data.map(({ _id, title }) => ({
          label: title,
          value: _id,
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, 150);
  const onSelect = (service) => {
    props.onChange(service);
    setAutocomplete([]);
  };
  const onBlur = () => {
    setTimeout(() => setAutocomplete([]), 175);
  };
  const onFocus = () => {
    props.onFocus();
    if (props.service) {
      updateAutocomplete(props.service);
    }
  };
  const onKeyDown = ({ key }) => {
    if (key === "Enter") {
      setAutocomplete([]);
    } else if (key === "ArrowUp" || key === "ArrowDown") {
      let next = key === "ArrowUp";
      if (isempty(autocomplete)) {
        return;
      }
      const idx = autocomplete.findIndex(
        ({ label }) => label === props.service
      );
      if (idx === -1) {
        props.onChange(
          next ? last(autocomplete).label : first(autocomplete).label
        );
      } else {
        const len = autocomplete.length - 1;
        if (next) {
          props.onChange(
            idx - 1 < 0 ? last(autocomplete).label : autocomplete[idx - 1].label
          );
        } else {
          props.onChange(
            idx + 1 > len
              ? first(autocomplete).label
              : autocomplete[idx + 1].label
          );
        }
      }
    }
  };

  return (
    <S.InputWrap>
      <S.Label htmlFor="search_service">Введите наименование услуги</S.Label>
      <S.Input
        aria-expanded={!isempty(autocomplete)}
        aria-autocomplete="list"
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        autoComplete="off"
        onChange={onChange}
        value={props.service}
        id="search_service"
        placeholder={props.t("common:search_form.service")}
      />
      {!isempty(autocomplete) && (
        <S.AutocompleteList>
          {autocomplete.map(({ label, value }) => (
            <S.AutocompleteItem
              role="button"
              onClick={() => onSelect(label)}
              key={value}
            >
              {label}
            </S.AutocompleteItem>
          ))}
        </S.AutocompleteList>
      )}
      {loading ? (
        <S.Preloader />
      ) : (
        <S.SearchIcon
          color={colorScheme === "dark" ? "#fff" : "#d1179e"}
          width="20"
        />
      )}
    </S.InputWrap>
  );
};
ServiceAutocomplete.propTypes = {
  service: PT.string,

  onFocus: PT.func.isRequired,
  onChange: PT.func.isRequired,
  t: PT.func.isRequired,
};
export default ServiceAutocomplete;
