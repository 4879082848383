import { Actions as MasterProfileActions } from "@/store/masterProfile/slice";
import { Selectors as MasterProfileSelectors } from "@/store/masterProfile/selectors";
import { TaskEditorCacheService } from "../taskEditor/cache";
import { MasterDiscountsCacheService } from "./cache";

export const MasterDiscountMutationHandler = {
  discountCreated({ discount }) {
    const store = window.__reduxStore;
    if (shouldUpdateMasterProfilePage(discount)) {
      store.dispatch(MasterProfileActions.createDiscount(discount));
    }
    TaskEditorCacheService.addDiscount({ discount });
    MasterDiscountsCacheService.addDiscount({ discount });
  },
  discountUpdated({ updated_discount: discount }) {
    const store = window.__reduxStore;
    if (shouldUpdateMasterProfilePage(discount)) {
      store.dispatch(MasterProfileActions.updateDiscount(discount));
    }
    TaskEditorCacheService.replaceDiscount({ discount });
    MasterDiscountsCacheService.replaceDiscount({ discount });
  },
  discountRemoved({ discount }) {
    const store = window.__reduxStore;
    if (shouldUpdateMasterProfilePage(discount)) {
      store.dispatch(MasterProfileActions.deleteDiscount(discount._id));
    }
    TaskEditorCacheService.removeDiscount({ discount });
    MasterDiscountsCacheService.removeDiscount({ discount });
  },
};

const shouldUpdateMasterProfilePage = (discount) => {
  const store = window.__reduxStore;
  return (
    MasterProfileSelectors.getMasterId(store.getState()) === discount.master
  );
};
