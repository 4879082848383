import React, { useEffect } from "react";
import * as S from "./styled";
import { useTranslation } from "react-i18next";
import { Button } from "_c/button";
import { ProgressEmitter } from "_c/GlobalProgress/emitter";
import { useHistory, useLocation } from "react-router-dom";
import { useNotify } from "@/hooks/useNotify";
import { useSelector, useDispatch } from "react-redux";
import { Selectors as PremiumSelectors } from "@/store/premium/selectors";
import { Effects as PremiumEffects } from "@/store/premium/effects";
import { analyticsAction } from "@/utils/analytics";

const PremiumModalMain = (props) => {
  useEffect(() => {
    analyticsAction({
      category: "common",
      action: "premium_show",
    });
  }, []);
  const history = useHistory();
  const location = useLocation();
  const isAllowTrial = useSelector(PremiumSelectors.getAllowTrial);
  const dispatch = useDispatch();
  const { t, ready } = useTranslation(["common", "errors"]);
  const { notify, notifyError } = useNotify();
  const connectPremium = async () => {
    if (isAllowTrial) {
      try {
        ProgressEmitter.start();
        await dispatch(PremiumEffects.activateTrial());
        notify(t("common:premium_popover.trial_ok"));
      } catch (e) {
        notifyError(t([`errors:${e}`, "errors:unspecified_server"]));
      } finally {
        ProgressEmitter.end();
      }
    } else {
      if (location.pathname.includes("/salon/dashboard")) {
        history.push("/salon/dashboard/premium");
      } else {
        history.push("/app/settings/premium");
      }
    }
    props.onClose();
  };
  if (!ready) return null;
  return (
    <S.Wrap>
      <S.Title>{t("common:premium_popover.title")}</S.Title>
      <S.Buttons>
        <Button onClick={connectPremium} small greenish>
          {t("common:premium_popover.accept")}
        </Button>
        <Button onClick={() => props.onClose()} small negative>
          {t("common:premium_popover.reject")}
        </Button>
      </S.Buttons>
    </S.Wrap>
  );
};

export default PremiumModalMain;
