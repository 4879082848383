import { SaloonsCacheService } from "./cache";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Actions as UserActions } from "@/store/user/slice";
import { TasksCacheService } from "../tasks/cache";
import { SchedulerCacheService } from "../scheduler/cache";
import { TaskEditorCacheService } from "../taskEditor/cache";
import { Actions as SaloonProfileActions } from "@/store/saloonProfile/slice";
import { Selectors as SaloonProfileSelectors } from "@/store/saloonProfile/selectors";

export const SaloonMutationHandler = {
  updated({ saloon_id, data }) {
    SaloonsCacheService.updateSaloon({ id: saloon_id, updates: data });
    const reduxStore = window.__reduxStore;
    const reduxState = reduxStore.getState();
    if (UserSelectors.getSaloonId(reduxState) === saloon_id) {
      reduxStore.dispatch(UserActions.updateMaster({ saloon: data }));
    }
    if (shouldUpdateSaloonProfilePage(saloon_id)) {
      reduxStore.dispatch(SaloonProfileActions.updateSaloon(data));
    }
  },
  baseScheduleUpdated({ saloon_id, data }) {
    const reduxStore = window.__reduxStore;
    if (shouldUpdateSaloonProfilePage(saloon_id)) {
      reduxStore.dispatch(
        SaloonProfileActions.updateSaloonSchedule(data.schedule)
      );
    }
    SaloonsCacheService.updateSaloon({ id: saloon_id, updates: data });
  },
  removed({ saloon_id }) {
    SaloonsCacheService.removeSaloon(saloon_id);
    const reduxStore = window.__reduxStore;
    const reduxState = reduxStore.getState();
    if (UserSelectors.getSaloonId(reduxState) === saloon_id) {
      reduxStore.dispatch(
        UserActions.updateMaster({ saloon: null, saloon_meta: null })
      );
      TaskEditorCacheService.replaceSaloon({
        masterId: UserSelectors.getMasterId(reduxState),
        saloon: null,
      });
      TasksCacheService.invalidateAll();
      SchedulerCacheService.invalidateAll();
    }
    // TODO:: there must be a lot of stuff to do
  },
};

const shouldUpdateSaloonProfilePage = (id) => {
  const store = window.__reduxStore;
  const state = store.getState();
  return SaloonProfileSelectors.getId(state) === id;
};
