import React, { PureComponent } from "react";
import PT from "prop-types";
import * as S from "./index-styled";
import { DEFAULT_FEMALE_AVATAR, DEFAULT_MALE_AVATAR } from "@/misc/constants";
import isempty from "lodash/isEmpty";
import { Link as RouterLink } from "react-router-dom";

const placeholder = [
  {
    url: "/",
    avatar: DEFAULT_FEMALE_AVATAR,
    id: 1
  },
  {
    url: "/",
    avatar: DEFAULT_FEMALE_AVATAR,
    id: 2
  },
  {
    url: "/",
    avatar: DEFAULT_MALE_AVATAR,
    id: 3
  },
  {
    url: "/",
    avatar: DEFAULT_FEMALE_AVATAR,
    id: 4
  },
  {
    url: "/",
    avatar: DEFAULT_MALE_AVATAR,
    id: 5
  },
  {
    url: "/",
    avatar: DEFAULT_FEMALE_AVATAR,
    id: 6
  },
  {
    url: "/",
    avatar: DEFAULT_FEMALE_AVATAR,
    id: 7
  }
];
const config = {
  groupCells: false,
  pageDots: false,
  lazyLoad: false,
  wrapAround: false,
  contain: true,
  cellAlign: "left",
  prevNextButtons: false
};

class List extends PureComponent {
  state = {
    Flickity: null
  };
  fl = null;
  componentDidMount() {
    import("react-flickity-component").then(module =>
      this.setState({ Flickity: module })
    );
  }
  next = () => this.fl.next();
  prev = () => this.fl.previous();
  render() {
    const { Flickity } = this.state;
    if (!Flickity) {
      return null;
    }
    const slides = isempty(this.props.data) ? placeholder : this.props.data;
    return (
      <div>
        <S.NextBtn onClick={this.next}>»</S.NextBtn>
        <S.PrevBtn onClick={this.prev}>«</S.PrevBtn>
        <S.Wrap>
          <Flickity.default options={config} flickityRef={f => (this.fl = f)}>
            {this.props.type === "users" && (
              <React.Fragment>
                {slides.map(({ url, avatar, id, title }) => (
                  <S.Slide as={RouterLink} title={title} key={id} to={url}>
                    <S.Avatar src={avatar} />
                  </S.Slide>
                ))}
              </React.Fragment>
            )}
            {this.props.type === "custom" && (
              <React.Fragment>
                {slides.map(({ img }) => (
                  <S.Slide as="div" key={img}>
                    <S.Avatar src={img} />
                  </S.Slide>
                ))}
              </React.Fragment>
            )}
          </Flickity.default>
        </S.Wrap>
      </div>
    );
  }
}
List.propTypes = {
  type: PT.oneOf(["users", "custom"]),
  data: PT.arrayOf(
    PT.shape({
      title: PT.string,
      avatar: PT.string,
      url: PT.string
    })
  )
};
List.defaultProps = {
  type: "users"
};

export default List;
