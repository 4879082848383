import React from "react";
import Scheduler from "./Scheduler/container";
import { useTranslation } from "react-i18next";
import { SCHEDULE_NS as ns } from "@/i18n/ns";
import { Helmet } from "_c/Helmet";
import { FullScreenSpin as Spin } from "_c/preloader";
import AuthOnly from "_c/AuthOnly";

//const ShareModal = loadable(() => import("./ShareModal"), {
//  ssr: false,
//});

const Schedule = (props) => {
  //const { weekSchedule } = props;
  //const [shareOpen, setShareOpen] = useState(false);
  //const openShare = () => setShareOpen(true);
  //const closeShare = () => setShareOpen(false);

  const { t, ready } = useTranslation(["common", "errors", ns], {
    useSuspense: false,
  });
  if (!ready) return <Spin size="xl" />;
  return (
    <AuthOnly>
      <Helmet title={t(`${ns}:page_title`)} />
      {/*<ShareModal
        masterId={props.masterId}
        t={t}
        weekSchedule={weekSchedule?.for_week}
        isOpen={shareOpen}
        onClose={closeShare}
      />*/}
      <Scheduler t={t} />
    </AuthOnly>
  );
};

export default Schedule;
