import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "clientProfile";

const initialState = {
  loading: true,
  client: null,
  achieves: [],
  error: null,
  redirect: {
    isRedirected: false,
    redirectId: null,
  },
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadingStart(state) {
      state.error = null;
      state.loading = true;
      state.redirect = initialState.redirect;
    },
    loadingSuccess(state, { payload }) {
      const { achieves, ...client } = payload;
      state.client = client;
      state.achieves = achieves;
      state.loading = false;
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    loadingRedirect(state, { payload }) {
      state.redirect.isRedirected = true;
      state.redirect.redirectId = payload;
      state.loading = false;
    },
  },
});
export const Actions = slice.actions;
export default slice.reducer;
