import s from "styled-components";
import BaseFooter from "_c/footer";
import ServiceSearch from "_c/service-search";
import {
  Wrap as SearchBg,
  Head as SearchHead,
} from "_c/service-search/Popular/styled";

export const Wrap = s.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 140vh;
`;
export const Footer = s(BaseFooter)`
  margin-top: auto;
`;
export const Main = s.div`
  margin-top: 90px;
  @media(max-width: 500px) {
    margin-top: 50px;
  }
`;
export const Hero = s.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  h2 {
    font-family: ${({ theme }) => theme.font.accent};
    color: var(--color-primary);
    font-size: 100px;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 25px;
    margin-top: 0;
  }
  p {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 7px;
    color: #484748;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  button, a {
    margin-top: 25px;
  }
  @media(max-width: 500px) {
    h2 {
      font-size: 64px;
    }
  }
`;
export const SearchWrap = s.div`
  margin-top: 150px;
  margin-bottom: 100px;
  @media(max-width: 991px) {
    margin-top: 50px;
  }
  @media(max-width: 767px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;
export const Search = s(ServiceSearch)`
  ${SearchBg} {
    background: var(--grad-primary-n);
    ::before {
      background: none;
      filter: none;
    }
  }
  @media(max-width: 575px) {
    position: relative;
    z-index: 3;
    margin-bottom: 30px;
    color: white;
  }
  ${SearchHead} {
    @media(max-width: 575px) {
      color: white;
    }
  }
`;
