import styled from "styled-components";
import uiVars from "./ui-vars";
import { minViewportHeightMixin } from "@/styles/mixins";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const ChildrenAndSidebarContainer = styled.div`
  @media (min-width: ${uiVars.mobileBp}px) {
    position: relative;
    padding-left: ${({ isCollapsed, isAuth }) => {
      if (!isAuth) return 0;
      return isCollapsed ? uiVars.collapsedWidth : uiVars.uncollapsedWidth;
    }};
    transition: padding-left ${uiVars.desktopAnimDuration}ms ease;
  }
`;
export const Children = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${minViewportHeightMixin};
  padding-top: ${uiVars.headDesktopHeight};
  @media (max-width: ${uiVars.mobileBp}px) {
    padding-top: ${uiVars.headMobileHeight};
    padding-bottom: ${uiVars.bottomMobileNavHeight};
  }
`;
