import moment from "moment";
import { NotificationsAPI } from "@/api/notifications";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as PrefsSelectors } from "@/store/prefs/selectors";
import { UserPrefsService } from "@/services/userPrefs";

class AutoScheduler {
  static _instance;
  static get instance() {
    if (!AutoScheduler._instance) {
      AutoScheduler._instance = new AutoScheduler();
    }
    return AutoScheduler._instance;
  }
  _appVisibilityListener = () => {
    const state = document.visibilityState;
    if (state === "hidden") {
      DayOverallStatsNotificationService.schedule();
    }
  };
  enable() {
    document.addEventListener("visibilitychange", this._appVisibilityListener);
  }
  disable() {
    document.removeEventListener(
      "visibilitychange",
      this._appVisibilityListener
    );
  }
}

const schedule = async () => {
  const reduxStore = window.__reduxStore;
  const reduxState = reduxStore.getState();
  const prefs = PrefsSelectors.getOverallStatsNotification(reduxState);
  if (prefs.disabled) return;
  const notifyMinutes = prefs.minutes;
  const notifyAt = moment()
    .hours(0)
    .minutes(notifyMinutes)
    .seconds(0)
    .milliseconds(0);
  const currentMinutes = moment.duration(moment().format("HH:mm")).asMinutes();
  if (currentMinutes >= notifyMinutes) {
    notifyAt.add(1, "day");
  }
  const user = UserSelectors.getUserId(reduxState);
  try {
    await NotificationsAPI.scheduleDayOverallStatsNotification({
      date: notifyAt.toISOString(),
      user,
    });
  } catch (e) {
    console.error(e);
  }
};

export const DayOverallStatsNotificationService = {
  enableAutoScheduler() {
    AutoScheduler.instance.enable();
  },
  disableAutoScheduler() {
    AutoScheduler.instance.disable();
  },
  async changeNotificationTime(minutes) {
    await UserPrefsService.changeDayOverallStatsNotification({
      disabled: false,
      minutes,
    });
    await schedule();
  },
  async disable() {
    await UserPrefsService.changeDayOverallStatsNotification({
      disabled: true,
    });
    await NotificationsAPI.disableDayOverallStatsNotification();
  },
  async enable() {
    await UserPrefsService.changeDayOverallStatsNotification({
      disabled: false,
    });
    await schedule();
  },
  schedule,
};
