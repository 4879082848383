import { API_URL } from "@/misc/constants";
import { get, put, remove } from "./";
import { portfolioCache } from "@/misc/httpCache";
import { mastersCache } from "@/misc/httpCache";

const BASE = `${API_URL}/portfolio`;
const findById = (id) => {
  return get(`${BASE}/${id}`, { cache: portfolioCache });
};
const createPortfolio = async (payload) => {
  const res = await put(BASE, payload);
  portfolioCache.reset();
  mastersCache.reset();
  return res;
};
const deleteById = async (id) => {
  const res = await remove(`${BASE}/${id}`);
  portfolioCache.reset();
  mastersCache.reset();
  return res;
};
const deleteMe = async (id) => {
  const res = await remove(`${BASE}/${id}/me`);
  portfolioCache.reset();
  mastersCache.reset();
  return res;
};

export const PortfolioAPI = {
  createPortfolio,
  deleteById,
  deleteMe,
  findById,
};
