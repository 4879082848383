import React from "react";
import * as S from "./nav-styled";
import baseLinks from "./links-list";
import { Link } from "react-router-dom";
import PT from "prop-types";
import MessagePopover from "_c/Popover/Message";
import { TECH_USER_ID, IDEA_USER_ID } from "@/misc/constants";
import qs from "query-string";

const Nav = ({ sendMessage, isAuth, t, openAuthModal, hl, locale }) => {
  const onSend = (to, text) => {
    sendMessage(to, text);
  };
  return (
    <S.Wrap>
      <S.InnerWrap>
        {baseLinks.map(({ route, __key, key }, i) => {
          const name = t(`footer.links.${key}`);
          if (__key === "support") {
            if (!isAuth) {
              return (
                <S.NavItem onClick={openAuthModal} key={i}>
                  {name}
                </S.NavItem>
              );
            }
            return (
              <MessagePopover
                key={i}
                onSubmit={(text) => onSend(TECH_USER_ID, text)}
                t={t}
              >
                <S.NavItem>{name}</S.NavItem>
              </MessagePopover>
            );
          }
          if (__key === "idea") {
            if (!isAuth) {
              return (
                <S.NavItem key={i} onClick={openAuthModal}>
                  {name}
                </S.NavItem>
              );
            }
            return (
              <MessagePopover
                key={i}
                onSubmit={(text) => onSend(IDEA_USER_ID, text)}
                t={t}
              >
                <S.NavItem>{name}</S.NavItem>
              </MessagePopover>
            );
          }
          return (
            <S.NavItem
              as={Link}
              to={
                hl
                  ? qs.stringifyUrl({
                      url: route,
                      query: { hl },
                    })
                  : route
              }
              key={i}
            >
              {name}
            </S.NavItem>
          );
        })}
        <S.NavItem
          target="_blank"
          rel="noopener noreferrer"
          as="a"
          href={`/pdf/privacy-policy-${locale}.pdf`}
         //href={`/privacy-policy`}
        >
          {t(`footer.links.politics`)}
        </S.NavItem>
        <S.NavItem
          target="_blank"
          rel="noopener noreferrer"
          as="a"
          href={`/pdf/user-agreement-${locale}.pdf`}
        >
          {t(`footer.links.agreement`)}
        </S.NavItem>
      </S.InnerWrap>
    </S.Wrap>
  );
};
Nav.propTypes = {
  isAuth: PT.bool,
  hl: PT.string,
  locale: PT.string,

  openAuthModal: PT.func.isRequired,
  sendMessage: PT.func.isRequired,
  t: PT.func.isRequired,
};

export default Nav;
