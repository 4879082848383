import { get, post, put } from "./";
import { API_URL } from "@/misc/constants";
import { mastersCache } from "@/misc/httpCache";
const BASE = `${API_URL}/review`;

const createReview = async (payload) => {
  const res = await put(BASE, payload);
  mastersCache.reset();
  return res;
};
const updateById = async (id, payload) => {
  const res = await post(`${BASE}/${id}`, payload);
  mastersCache.reset();
  return res;
};
const findById = async (id) => {
  return get(`${BASE}/${id}`, { cache: false });
};

export const ReviewsAPI = {
  findById,
  createReview,
  updateById,
};
