import { Selectors } from "../store/profileLinks/selectors";

export const COMMON_INITIAL_SCREENS = [
  "salon",
  "dashboard",
  "todos",
  "schedule",
];
export const MASTER_INITIAL_SCREENS = [
  ...COMMON_INITIAL_SCREENS,
  "profile",
  "clients",
  "orders",
];
export const INITIAL_SCREENS_TO_ROUTE_SELECTOR_MAP = {
  dashboard: Selectors.getDashboard,
  todos: Selectors.getTodos,
  schedule: Selectors.getSchedule,
  profile: Selectors.getProfile,
  clients: Selectors.getClients,
  orders: Selectors.getOrders,
  salon: Selectors.getSaloonDashboard,
};
