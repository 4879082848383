import styled from "styled-components";
import { CircleButton } from "_c/button";
import uiVars from "../../ui-vars";
import { Link } from "react-router-dom";
export { Wrap, Logo } from "../styled";

const colPad = "30px";
const mobileColPad = "10px";
export const LeftCol = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: ${colPad};
  @media (max-width: ${uiVars.mobileBp}px) {
    margin-inline-start: ${mobileColPad};
  }
`;
export const RightCol = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-inline-end: ${colPad};
  @media (max-width: ${uiVars.mobileBp}px) {
    margin-inline-end: ${mobileColPad};
  }
`;
export const NotifierWrap = styled.div`
  position: relative;
  width: 30px;
  margin: 0 5px;
`;
export const Notifier = styled(CircleButton)`
  min-width: 30px;
  position: relative;
  background: var(--grad-primary-n);
`;
export const NotifierCount = styled.span`
  position: absolute;
  top: -4px;
  color: white;
  right: -8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.negative};
  width: 16px;
  height: 16px;
  font-size: 10px;
  text-align: center;
  padding-top: 2px;
`;
export const RoleSpecificLinks = styled.ul`
  margin-left: 15px;
  li {
    margin-right: 15px;
  }
`;
export const RoleSpecificLink = styled(Link)`
  color: var(--color-cta);
  display: flex;
  align-items: center;
  transition: opacity 0.25s ease;
  padding: 5px;
  span {
    margin-inline-start: 5px;
  }
  :hover,
  :focus {
    opacity: 0.6;
  }
  @media (max-width: ${uiVars.mobileBp}px) {
    display: none;
  }
`;
