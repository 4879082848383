import { API_URL } from "@/misc/constants";
import { get, put, remove } from "./";
import { commentsCache } from "@/misc/httpCache";

const createComment = async (entity, id, payload) => {
  const res = await put(`${API_URL}/${entity}/${id}/comment`, payload);
  commentsCache.reset();
  return res;
};
const getComments = (entity, id) => {
  return get(`${API_URL}/${entity}/${id}/comments`, { cache: commentsCache });
};
const deleteComment = async (entity, id, commentId) => {
  const res = await remove(`${API_URL}/${entity}/${id}/comment/${commentId}`);
  commentsCache.reset();
  return res;
};

export const CommentsAPI = {
  createComment,
  deleteComment,
  getComments,
};
