import { queryClient } from "../../network/queryClient";
import { QueryKeys } from "../../network/queryKeys";
import isempty from "lodash/isEmpty";

const buildClientHistoryQueryKey = (clientId) => {
  const base = QueryKeys.clientTasksHistory;
  if (!clientId) return base;
  return [base, clientId];
};
const buildSingleTaskQueryKey = (taskId) => {
  const base = QueryKeys.singleTask;
  if (!taskId) return base;
  return [base, taskId];
};
const buildTasksSearchQueryKey = (args) => {
  const base = QueryKeys.tasksSearch;
  if (!args) return base;
  return [base, args];
};

const removeTaskFromClientHistory = (task) => {
  queryClient.setInfiniteQueriesDataIfCached(
    { active: true, queryKey: buildClientHistoryQueryKey(task.client) },
    (pages) => {
      return pages
        .map((page) => page.filter((task_) => task_._id !== task._id))
        .filter((page) => !isempty(page));
    }
  );
};

const invalidateClientHistory = (clientId) => {
  queryClient.invalidateQueries(buildClientHistoryQueryKey(clientId));
};
const invalidateSingleTask = (taskId) => {
  queryClient.invalidateQueries(buildSingleTaskQueryKey(taskId));
};
const invalidateTasksSearch = (args) => {
  queryClient.invalidateQueries(buildTasksSearchQueryKey(args));
};
const invalidateAll = () => {
  invalidateClientHistory();
  invalidateSingleTask();
  invalidateTasksSearch();
};

export const TasksCacheService = {
  buildClientHistoryQueryKey,
  buildSingleTaskQueryKey,
  buildTasksSearchQueryKey,
  invalidateClientHistory,
  invalidateAll,
  addTask(task) {
    queryClient.setQueryDataButKeepStale(
      buildSingleTaskQueryKey(task._id),
      task
    );
    if (task.client) {
      invalidateClientHistory(task.client);
    }
    if (task.consumer) {
      invalidateTasksSearch({
        consumer: task.consumer,
      });
    }
    invalidateTasksSearch({ master: task.master });
  },
  replaceTask(updated, oldTask) {
    queryClient.setQueryDataButKeepStale(
      buildSingleTaskQueryKey(updated._id),
      updated
    );

    if (!updated.personal) {
      if (oldTask && oldTask.client !== updated.client) {
        removeTaskFromClientHistory(oldTask);
      }
      if (updated.client) {
        queryClient.setInfiniteQueriesDataIfCached(
          {
            active: true,
            queryKey: buildClientHistoryQueryKey(updated.client),
          },
          (pages) => {
            return pages.map((page) =>
              page.map((task) => (task._id === updated._id ? updated : task))
            );
          }
        );
      }
    }

    queryClient.setInfiniteQueriesDataIfCached(
      {
        active: true,
        queryKey: buildTasksSearchQueryKey(),
      },
      (pages) => {
        return pages.map((page) => {
          return {
            tasks: page.tasks.map((task) =>
              task._id === updated._id ? updated : task
            ),
            next: page.next,
          };
        });
      }
    );
  },
  removeTask(task) {
    invalidateSingleTask(task._id);

    if (!task.personal) {
      removeTaskFromClientHistory(task);
    }

    if (task.consumer) {
      invalidateTasksSearch({
        consumer: task.consumer,
      });
    }
    invalidateTasksSearch({ master: task.master });
  },
  updateTask(id, updates) {
    queryClient.setQueryDataIfCached(buildSingleTaskQueryKey(id), (task) =>
      task ? { ...task, ...updates } : task
    );

    // TODO:: perf. we should invalidate only one query key for specific client
    queryClient.setInfiniteQueriesDataIfCached(
      {
        active: true,
        queryKey: buildClientHistoryQueryKey(),
      },
      (pages) => {
        return pages.map((page) =>
          page.map((task) =>
            task._id === id
              ? {
                  ...task,
                  ...updates,
                }
              : task
          )
        );
      }
    );

    queryClient.setInfiniteQueriesDataIfCached(
      {
        active: true,
        queryKey: buildTasksSearchQueryKey(),
      },
      (pages) => {
        return pages.map((page) => {
          return {
            tasks: page.tasks.map((task) =>
              task._id === id
                ? {
                    ...task,
                    ...updates,
                  }
                : task
            ),
            next: page.next,
          };
        });
      }
    );
  },
};
