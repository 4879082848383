import React from "react";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import { FullScreenSpin as Spin } from "_c/preloader";

const Ambassador = loadable(
  () => import(/* webpackChunkName: "ambassador-page" */ "./"),
  {
    fallback: <Spin size="xl" />,
  }
);

const mSp = (state) => ({
  isAuth: AuthSelectors.isAuth(state),
  isMaster: UserSelectors.isMaster(state),
});
const mDp = {
  openAuthModal: ModalActions.openAuth,
};

export default connect(mSp, mDp)(Ambassador);
