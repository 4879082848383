import styled from "styled-components";
import { Link } from "react-router-dom";

const tabletBp = "991px";
const mobileBp = "500px";
export const Wrap = styled.div`
  display: flex;
  background: var(--grad-primary-n);
  padding-inline-start: 46px;
  padding-top: 14px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -10px;
  overflow: hidden;
  color: white;
  position: relative;
  border-radius: 9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  @media (max-width: ${tabletBp}) {
    padding: 20px;
  }
  @media (max-width: ${mobileBp}) {
    width: 95%;
    margin: 0 auto;
    padding-inline-start: 30px;
    padding-bottom: 30px;
    border-radius: 9px;
    color: black;
    height: ${({ isOpen }) => (isOpen ? "auto" : "280px")};
    ${({ isOpen }) => {
      if (!isOpen) {
        return `
          a:nth-child(6) {
            visibility: hidden;
            pointer-events: none;
          } 
        `;
      }
      return "";
    }};
  }
`;
export const Head = styled.h3`
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 20px;
  @media (max-width: ${tabletBp}) {
    text-align: center;
    margin-bottom: 15px;
  }
  @media (max-width: ${mobileBp}) {
    margin-bottom: 25px;
  }
`;
export const Slide = styled(Link)`
  border: none;
  box-shadow: none;
  margin-bottom: 14px;
  display: flex;
  font-size: 16px;
  width: 33.333%;
  color: ${({ $isActive }) => ($isActive ? "var(--color-cta)" : "white")};
  align-items: center;
  transition: opacity 0.35s ease;
  font-weight: 400;
  :hover {
    opacity: 0.7;
  }
  @media (max-width: ${tabletBp}) {
    width: auto;
    margin-right: 15px;
    margin-bottom: 10px;
  }
  @media (max-width: ${mobileBp}) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;
export const IconWrap = styled.div`
  width: 40px;
  line-height: 0;
`;
export const Slider = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${mobileBp}) {
    flex-direction: column;
  }
`;
export const ShowMoreBtn = styled.button`
  @media (min-width: ${mobileBp}) {
    display: none;
  }
  position: absolute;
  bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
`;
