import React from "react";
import { LinkGenerator } from "@/utils/LinkGenerator";
import { useParams } from "react-router";
import { Button } from "_c/button";
import { useTranslation } from "react-i18next";
import { Helmet } from "_c/Helmet";

const ViberSubscribe = () => {
  const { token } = useParams();
  const { t, ready } = useTranslation("common", {
    useSuspense: false,
  });
  if (!ready) {
    return null;
  }
  return (
    <>
      <Helmet
        title="Подписка"
        meta={[
          //{
          //  property: "og:image",
          //  content: avatarLink,
          //},
          {
            name: "description",
            content: "Описание подписки"
          },
        ]}
      />
      <div
        css={`
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          a {
            display: block;
            margin-top: 15px;
          }
          img {
            width: 75px;
          }
        `}
      >
        <img src="/icons/viber.svg" />
        <Button
          greenish
          medium
          as="a"
          title="Viber"
          href={LinkGenerator.viberBot(token)}
        >
          {t("common:subscribe")}
        </Button>
      </div>
    </>
  );
};
export default ViberSubscribe;
