import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Actions } from "@/store/basket/slice";
import isempty from "lodash/isEmpty";
import { FEAT_FLAG_MULTI_ITEM_BASKET } from "@/misc/featureFlags";

export const useRehydrateBasket = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (FEAT_FLAG_MULTI_ITEM_BASKET) {
      try {
        const basket = JSON.parse(localStorage.getItem("basket"));
        if (!isempty(basket)) {
          dispatch(Actions.restoreBasket(basket));
        }
      } catch (_e) {}
    }
  }, []);
};
