import React from "react";
import {
  Beautician,
  Barber,
  Brows,
  Eyelashes,
  HairColorist,
  HairRemoval,
  NailArtist,
  Pedicure,
  Hairdresser,
  Visagist,
  PermanentMakeup,
  Masseur,
  Tatoo,
  StylistImagemaker,
} from "_c/icon/masterSpecs";
import Stylist from "_c/icon/Lady";

const baseWidth = "22";
const iconsMap = {
  manicure: <NailArtist width="26" />,
  pedicure: <Pedicure width={baseWidth} />,
  hairdresser: <Hairdresser width={baseWidth} />,
  colorist: <HairColorist width="18" />,
  barber: <Barber width={baseWidth} />,
  "eyebrow-artist": <Brows width="18" />,
  "makeup-artist": <Visagist width={baseWidth} />,
  lashmaker: <Eyelashes width={baseWidth} />,
  "permanent-makeup": <PermanentMakeup width="26" />,
  cosmetologist: <Beautician width={baseWidth} />,
  "massage-therapist": <Masseur width={baseWidth} />,
  "hair-removal": <HairRemoval width="24" />,
  stylist: <Stylist width={baseWidth} />,
  "tattoo-artist": <Tatoo width="26" />,
  "stylist-imagemaker": <StylistImagemaker width={baseWidth} />,
};

export const useSpecIcon = (spec) => iconsMap[spec];
