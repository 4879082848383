import styled from "styled-components";
import Search from "_c/service-search";
import {
  Wrap as SearchBg,
  Head as SearchHead,
} from "_c/service-search/Popular/styled";
//import uiVars from "_c/AppShell/ui-vars";

export const HeaderSection = styled.header`
  background-color: ${({ theme }) => {
    if (theme.__name__ === "light") {
      return "rgb(255, 175, 195)";
    }
    if (theme.__name__ === "dark") {
      return "var(--bg-elevate)";
    }
  }};
  position: relative;
  padding-bottom: 200px;
  overflow: hidden;
  min-height: 680px;
  @media (max-width: 575px) {
    padding-bottom: 60px;
  }
`;

export const Tagline = styled.header`
  h2 {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 530px;
    color: white;
    color: #ffffff;
    font-weight: 400;
    font-size: 39px;
    letter-spacing: 2.05px;
    text-align: center;
  }
  margin-top: 30px;
  padding: 35px 0 10px 0;
  position: relative;
  font-family: ${({ theme }) => theme.font.accent};
  background-image: url(/images/titleBg.png);
  background-repeat: no-repeat;
  background-size: 540px 100%;
  background-position: 51% center;
  @media (max-width: 560px) {
    h2 {
      font-size: 26px;
    }
    background-size: 350px 100%;
    background-position: center center;
    padding: 25px 0 10px 0;
  }
  @media (max-width: 560px) {
    h2 {
      max-width: 320px;
      font-size: 22px;
    }
    //background-size: 310px 100%;
    //background-position: 60% center;
  }
`;

export const BgCircles = styled.div`
  position: absolute;
  pointer-events: none;
  display: ${({ theme }) => {
    if (theme.__name__ === "dark") {
      return "none";
    } else {
      return "block";
    }
  }};
  top: 201px;
  left: 50%;
  transform: translateX(-50%);
  width: 630px;
  height: 630px;
  z-index: 0;
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    text-align: center;
    vertical-align: center;
    span {
      position: absolute;
      display: inline-block;
      margin: 0 auto;
      padding: 34%;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.15);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      span {
        padding: 27%;
      }
    }
  }
`;
export const StyledSearch = styled(Search)`
  margin-top: 35px;
  ${SearchBg} {
    ::before {
      background: none;
      filter: none;
    }
  }
  @media (max-width: 575px) {
    position: relative;
    z-index: 3;
    margin-bottom: 30px;
  }
  ${SearchHead} {
    @media (max-width: 575px) {
      color: white;
    }
  }
`;
