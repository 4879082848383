import { WarehouseAPI } from "@/api/warehouse";
import { iCreateWarehouseTxnDto, iUpdateWarehouseTxnDto } from "@/types";
import { analyticsAction } from "@/utils/analytics";
import ws from "@/websocket";
import { WarehouseTxnMutationHandler } from "./mutationHandler";

const createTxn = async ({ data }: { data: iCreateWarehouseTxnDto }) => {
  const { data: resData } = await WarehouseAPI.createTxn(data);
  ws.execIfNotConnected(() => {
    WarehouseTxnMutationHandler.txnCreated(resData);
  });
  if (data.direction === "in") {
    analyticsAction({
      category: "warehouse",
      action: data.saloon ? "good_replenished_salon" : "good_replenished",
    });
  } else if (data.direction === "out") {
    analyticsAction({
      category: "warehouse",
      action: data.task
        ? data.saloon
          ? "good_debited_task_salon"
          : "good_debited_task"
        : data.saloon
        ? "good_debited_warehouse_salon"
        : "good_debited_warehouse",
    });
  }
  return resData;
};
const updateTxn = async ({
  txn,
  data,
}: {
  txn: string;
  data: iUpdateWarehouseTxnDto;
}) => {
  const { data: resData } = await WarehouseAPI.updateTxn(txn, data);
  ws.execIfNotConnected(() => {
    WarehouseTxnMutationHandler.txnUpdated(resData);
  });
  return resData;
};
const removeTxn = async ({ txn }: { txn: string }) => {
  const { data } = await WarehouseAPI.removeTxn(txn);
  ws.execIfNotConnected(() => {
    WarehouseTxnMutationHandler.txnRemoved(data);
  });
  if (data.txn) {
    analyticsAction({
      category: "warehouse",
      action: data.txn.saloon
        ? "good_transaction_removed_salon"
        : "good_transaction_removed",
    });
  }
  return data;
};
const cancelTxn = async ({ txn }: { txn: string }) => {
  const { data } = await WarehouseAPI.cancelTxn(txn);
  ws.execIfNotConnected(() => {
    WarehouseTxnMutationHandler.txnCancelled(data);
  });
  if (data.txn) {
    analyticsAction({
      category: "warehouse",
      action: data.txn.saloon
        ? "good_transaction_cancelled_salon"
        : "good_transaction_cancelled",
    });
  }
  return data;
};

export const WarehouseTxnsService = {
  createTxn,
  updateTxn,
  removeTxn,
  cancelTxn,
};
