import React from "react";

const WalletIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M16.214 2.5C19.404 2.5 22 5.155 22 8.419v7.162c0 3.264-2.596 5.919-5.786 5.919a.675.675 0 0 1-.666-.682c0-.377.298-.682.666-.682 2.455 0 4.453-2.043 4.453-4.555V9.865h-3.284c-1.078 0-1.957.9-1.958 2.004 0 1.104.88 2.002 1.958 2.003h1.365c.368 0 .666.306.666.682a.675.675 0 0 1-.666.682h-1.365c-1.814 0-3.29-1.511-3.291-3.367 0-1.857 1.477-3.367 3.291-3.368h3.284v-.082c0-2.512-1.998-4.555-4.453-4.555h-8.43c-1.978 0-3.638 1.335-4.217 3.164h8.788c.368 0 .666.306.666.682a.674.674 0 0 1-.666.683H3.336l-.003.026v7.162c0 2.512 1.997 4.555 4.452 4.555h4.24c.369 0 .667.305.667.682a.675.675 0 0 1-.666.682H7.785C4.595 21.5 2 18.845 2 15.581V8.42C2 5.155 4.595 2.5 7.785 2.5Zm1.617 8.625c.368 0 .666.306.666.682a.675.675 0 0 1-.666.682h-.303a.675.675 0 0 1-.667-.682c0-.376.299-.682.667-.682Z"
    />
  </svg>
);

export default WalletIcon;
