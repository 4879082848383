import React from "react";

const OrdersPageIcon = ({
  color = "currentColor",
  isFilled = false,
  ...props
}) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    {isFilled ? (
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.45 4.88h3.07c3.69 0 5.49 1.97 5.48 6.01v4.87c0 3.86-2.38 6.24-6.25 6.24H8.24C4.39 22 2 19.62 2 15.75V8.24C2 4.1 3.84 2 7.47 2h1.58c.93-.01 1.8.42 2.37 1.15l.88 1.17c.28.35.7.56 1.15.56ZM7.37 15.29h9.26c.41 0 .74-.34.74-.75a.74.74 0 0 0-.74-.75H7.37c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Z"
        clipRule="evenodd"
      />
    ) : (
      <path
        fill={color}
        d="M21.235 12.677a.765.765 0 0 0 .765-.765v-1.02c0-4.046-1.802-6.016-5.495-6.016H13.78a1.424 1.424 0 0 1-1.134-.554l-.879-1.17A2.989 2.989 0 0 0 9.402 2H7.486C3.846 2 2 4.102 2 8.253v7.502C2 19.608 4.391 22 8.25 22h7.49c3.851 0 6.251-2.392 6.251-6.245a.774.774 0 0 0-1.538 0c0 3.035-1.67 4.715-4.712 4.715h-7.5c-3.041 0-4.711-1.671-4.711-4.715v-.73h12.307a.765.765 0 0 0 0-1.53H3.53V8.218c0-3.307 1.178-4.723 3.947-4.723H9.41c.444.007.86.214 1.134.563l.88 1.161a2.962 2.962 0 0 0 2.355 1.179h2.717c2.857 0 3.965 1.275 3.965 4.494v1.02a.765.765 0 0 0 .773.765Z"
      />
    )}
  </svg>
);

export default OrdersPageIcon;
