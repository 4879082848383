import type { i18n } from "i18next";

export const useAvailableUnits = ({ i18n }: { i18n: i18n }): string[] => {
  const units: string[] = i18n.getResource(
    i18n.language,
    "common",
    "warehouse.product_units"
  );
  if (Array.isArray(units)) return units;
  return ["Штука", "Литр"];
};
