import { UserAPI } from "@/api/user";
import { Actions } from "./slice";
import { Actions as PortfolioActions } from "@/store/portfolio/slice";
import { Actions as FeedActions } from "@/store/clientFeed/slice";
import { Effects as PortfolioEffects } from "@/store/portfolio/effects";
import { batch } from "react-redux";

const loadProfile = (id) => async (dispatch) => {
  dispatch(Actions.loadingStart());
  try {
    const { data, status } = await UserAPI.getProfile(id);
    if (status === 301) {
      dispatch(Actions.loadingRedirect(data));
    } else {
      const { portfolio, feed_initial_chunk, ...rest } = data;
      batch(() => {
        dispatch(Actions.loadingSuccess(rest));
        dispatch(PortfolioActions.setPortfolio(portfolio));
        dispatch(FeedActions.setFeed(feed_initial_chunk));
      });
    }
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};

// proxy around portfolio effect to keep client feed in sync
const addPortfolio = (payload) => async (dispatch) => {
  const portfolio = await dispatch(PortfolioEffects.addPortfolio(payload));
  dispatch(FeedActions.addPortfolio(portfolio));
};
const removePortfolio = (id) => async (dispatch) => {
  await dispatch(PortfolioEffects.removePortfolio(id));
  dispatch(FeedActions.removePortfolio(id));
};

export const Effects = {
  addPortfolio,
  removePortfolio,
  loadProfile,
};
