import { Actions } from "./slice";
import { TasksAPI } from "@/api/tasks";

const loadTask = (id, success) => async (dispatch) => {
  dispatch(Actions.setSuccessStatus(success));
  dispatch(Actions.loadingStart());
  try {
    const { data } = await TasksAPI.findById(id);
    dispatch(Actions.loadingSuccess(data));
  } catch (e) {
    dispatch(Actions.loadingError(e));
  }
};
export const Effects = {
  loadTask,
};
