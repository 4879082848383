import React from 'react';
import { connect } from "react-redux";
import { Selectors } from "@/store/eventNotifications/selectors";
import { Effects } from "@/store/eventNotifications/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import Loadable from "./loadable";
import { notificationsVisibilityAtom } from "./atoms/visibility";
import { useAtomValue } from "jotai/utils";
import Animated from "_c/Animated";

const mSp = (state) => ({
  loading: Selectors.isLoading(state),
  notifications: Selectors.getNotificationsWithFreshParam(state),
  empty: Selectors.isNotificationsEmpty(state),
  isMore: Selectors.isMore(state),
});
const mDp = {
  loadNotifications: Effects.loadNotifications,
  clearFresh: Effects.clearFresh,
  sendExternalNotification: ModalActions.openSendNotification,
};
const ConnectedNotifications = connect(mSp, mDp)(Loadable);

const NotificationsContainer = () => {
  const isVisible = useAtomValue(notificationsVisibilityAtom);
  return (
    <Animated visible={isVisible}>
      <ConnectedNotifications />
    </Animated>
  );
};

export default NotificationsContainer;
