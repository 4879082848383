import { connect } from "react-redux";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Actions as ModalActions } from "@/store/modals/slice";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import PartnersPage from "./loadable";

const mSp = (state) => ({
  isAuth: AuthSelectors.isAuth(state),
  isMaster: UserSelectors.isMaster(state),
  locale: UserSelectors.getLocale(state),
});
const mDp = {
  openAuthModal: ModalActions.openAuth,
};
export default connect(mSp, mDp)(PartnersPage);
