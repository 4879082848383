import { WarehouseAPI } from "@/api/warehouse";
import {
  iCreateWarehouseProductDto,
  iUpdateWarehouseProductDto,
} from "@/types";
import { analyticsAction } from "@/utils/analytics";
import ws from "@/websocket";
import { WarehouseProductMutationHandler } from "./mutationHandler";

const createProduct = async ({
  data,
}: {
  data: iCreateWarehouseProductDto;
}) => {
  const res = await WarehouseAPI.createProduct(data);
  ws.execIfNotConnected(() => {
    WarehouseProductMutationHandler.productCreated(res.data);
  });
  return res.data;
};
const updateProduct = async ({
  product,
  data,
}: {
  product: string;
  data: iUpdateWarehouseProductDto;
}) => {
  const res = await WarehouseAPI.updateProduct(product, data);
  ws.execIfNotConnected(() => {
    WarehouseProductMutationHandler.productUpdated(res.data);
  });
  return res.data;
};
const removeProduct = async ({ product }: { product: string }) => {
  const res = await WarehouseAPI.removeProduct(product);
  ws.execIfNotConnected(() => {
    WarehouseProductMutationHandler.productRemoved(res.data);
  });
  if (res.data?.product) {
    analyticsAction({
      category: "warehouse",
      action: res.data.product.saloon ? "good_removed_salon" : "good_removed",
    });
  }
  return res.data;
};

export const WarehouseProductsService = {
  createProduct,
  updateProduct,
  removeProduct,
};
