import { useQuery } from "react-query";
import { WarehouseProductCategoriesCacheService } from "@/services/warehouse/productCategories/cache";
import { WarehouseAPI } from "@/api/warehouse";
import ms from "ms";

type tArgs = {
  master?: string;
  saloon?: string;
};
export const useAllWarehouseProductCategoriesQuery = ({
  master,
  saloon,
}: tArgs) => {
  const args = saloon ? { saloon } : { master };
  return useQuery(
    WarehouseProductCategoriesCacheService.buildAllCategoriesQueryKey(args),
    () => WarehouseAPI.getProductCategories(args),
    {
      staleTime: ms("10 min"),
      cacheTime: Infinity,
    }
  );
};
