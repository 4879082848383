export { COUCHDB_URL as REMOTE_URL } from "@/misc/constants";
export const SYNC_DB_EVENTS = {
  READY: "ready",
};

export const SYNC_DB_ENTITY_TYPES = {
  todoTitle: "todo_title",
  todo: "todo",
  repeatableTodo: "repeatable_todo",
  todoList: "todo_list",
  note: "note",
  accountingCategory: "accounting_category",
  savedGeoLocation: "saved_geo_loc",
  entityTemplate: "entity_tmpl",

  publicAppConfig: "app_cfg",
  publicAppStories: "app_stories",
};
