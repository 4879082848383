import { generatePath } from "react-router-dom";
import qs from "query-string";
import { filterObject } from "@/utils";

const generator = ({ params, query: rawQuery }) => {
  const query = filterObject(
    rawQuery,
    (key) => key !== undefined && key !== false && key !== "false" && key !== ""
  );
  let url;
  let finalQuery = { ...query };
  if (params?.loc) {
    url = generatePath("/search/:loc/:spec?", {
      loc: params.loc,
      spec: params.spec,
    });
    if (params.spec) {
      delete finalQuery.spec;
    }
  } else {
    url = "/search";
  }
  return qs.stringifyUrl({
    url,
    query: finalQuery,
  });
};
export const useSearchUrlGenerator = () => generator;
