import React, { useMemo, useState } from "react";
import TextInput from "_c/form/TextInput";
import Empty from "_c/Empty";
import isempty from "lodash/isEmpty";
import BottomSheet from "_c/BottomSheet";
import { CircleButton } from "_c/button";
import PlusIcon from "_c/icon/Plus";
import { useMut } from "@/hooks/useMut";
import { WarehouseProductCategoriesService } from "@/services/warehouse";
import { Menu, MenuItem } from "_c/Menu";
import DotsIcon from "_c/icon/Dots";

const CategoriesList = ({
  t,
  onChange,
  options,
  isOpen,
  toggle,
  master,
  saloon,
  selected,
}) => {
  const onSelect = (data) => {
    onChange(data);
    toggle();
  };
  const [search, setSearch] = useState("");
  const trimmedSearch = search.trim();
  const uniqTitles = useMemo(() => {
    return new Set(options.map((o) => o.title));
  }, [options]);
  const filteredItems = useMemo(() => {
    if (!search) return options;
    const lower = search.toLowerCase();
    return options.filter(({ title }) => title.toLowerCase().includes(lower));
  }, [options, search]);
  const createCategoryMut = useMut(
    () =>
      WarehouseProductCategoriesService.createCategory({
        data: {
          title: trimmedSearch,
          ...(saloon ? { saloon } : { master }),
        },
      }),
    {
      t,
      notifyOnError: true,
      onSuccess({ category }) {
        setSearch("");
        onChange(category);
        toggle();
      },
    }
  );
  return (
    <>
      <BottomSheet
        zIndex={1}
        disablePortal
        defaultHeight={window.innerHeight * 0.75}
        header={<div />}
        isOpen={isOpen}
        onClose={toggle}
      >
        <div
          css={`
            color: var(--font-color-main);
            padding: 10px;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              margin-bottom: 15px;
            `}
          >
            <TextInput
              css={`
                flex: 1;
                margin-inline-end: 5px;
              `}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("common:search")}
            />
            <CircleButton
              disabled={
                createCategoryMut.isLoading ||
                !trimmedSearch ||
                uniqTitles.has(trimmedSearch)
              }
              greenish
              onClick={() => createCategoryMut.mutate()}
              large
              type="button"
            >
              <PlusIcon color="white" />
            </CircleButton>
          </div>
          {isempty(filteredItems) ? (
            <Empty message={t("common:empty")} />
          ) : (
            <ul>
              {filteredItems.map((item) => (
                <Item
                  t={t}
                  selected={selected}
                  key={item._id}
                  onSelect={onSelect}
                  item={item}
                />
              ))}
            </ul>
          )}
        </div>
      </BottomSheet>
    </>
  );
};
const Item = ({ t, onSelect, item, selected }) => {
  const isSelected = selected?._id == item._id;
  const removeMut = useMut(
    () =>
      WarehouseProductCategoriesService.removeCategory({ category: item._id }),
    {
      t,
      notifyOnError: true,
      confirm: true,
      onSuccess() {
        if (isSelected) {
          onSelect(null);
        }
      },
    }
  );
  return (
    <li
      style={{
        backgroundColor: isSelected ? "var(--color-cta)" : "var(--bg-elevate)",
        color: isSelected ? "white" : "inherit",
      }}
      css={`
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        padding: 0 10px;
        border-radius: 9px;
      `}
    >
      <div
        onClick={() => onSelect(item)}
        role="button"
        css={`
          padding: 10px 0;
          padding-right: 5px;
          cursor: pointer;
          flex: 1;
        `}
      >
        {item.title}
      </div>
      <Menu
        portal
        menuButton={
          <CircleButton medium type="button" transparent>
            <DotsIcon />
          </CircleButton>
        }
      >
        <MenuItem
          onClick={removeMut.mutate}
          style={{
            color: "var(--color-danger)",
          }}
        >
          {t("common:remove")}
        </MenuItem>
      </Menu>
    </li>
  );
};
export default CategoriesList;
