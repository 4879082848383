import s from "styled-components";

export const Content = s.main`
  position: relative;
  margin-top: 100px;
  color: var(--font-color-main);
  h1, h2  {
    margin-top: 70px;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 1.5em;
  }
  p {
    font-weight: 500;
    line-height: 1.3em;
  }
  a {
    color: var(--color-cta);
  }
  li {
    text-decoration: underline;
  }
    h1, h2 {
    }
  margin-bottom: 40px;
`;
