import { createSlice } from "@reduxjs/toolkit";

export const DOMAIN = "paymentResult";
const initialState = {
  loading: false,
  error: null,
  success: false,
  task: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    setSuccessStatus(state, { payload }) {
      state.success = payload;
    },
    loadingStart(state) {
      state.error = null;
      state.loading = true;
    },
    loadingSuccess(state, { payload }) {
      state.loading = false;
      state.task = payload;
    },
    loadingError(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default slice.reducer;
export const Actions = slice.actions;
