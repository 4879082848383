import { get } from "./";
import { GEO_SERVICE_API_KEY } from "@/config";
import type { tGeoLocation } from "@/types";

const BASE = "https://suggestions.dadata.ru/suggestions/api/4_1/rs";

const headers = {
  Authorization: `Token ${GEO_SERVICE_API_KEY}`,
  "Cache-Control": "max-age=604800",
};

type tReverseGeocodeArgs = {
  lat: number;
  lng: number;
  radius?: number;
  locale?: string;
};
export const GeoApi = {
  async reverseGeocode({
    lat,
    lng,
    locale,
    radius,
  }: tReverseGeocodeArgs): Promise<Maybe<tGeoLocation>> {
    const {
      data: {
        suggestions: [data],
      },
    } = await get(BASE + "/geolocate/address", {
      params: {
        lat,
        lon: lng,
        count: 1,
        language: locale,
        radius_meters: radius,
      },
      headers,
      withCredentials: false,
    });
    if (!data) return null;
    return {
      city: data.data.city || "",
      name: data.value || data.unrestricted_value,
      fullName: data.unrestricted_value,
      country: data.data.country || "",
      lat,
      lng,
      flat: data.data.flat,
      house: data.data.house,
      street: data.data.street,
    };
  },
};
