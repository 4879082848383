import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const DOMAIN = "prefs";
export const initialState = {
  initial_screen: null,
  day_overall_stats_notification: {
    disabled: false,
    minutes: 540,
  },
  todo_completion_prob_tip: true,
  daily_sleep: 540,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    initPrefs(state, { payload = {} }) {
      merge(state, payload);
    },
    replacePrefs(_state, { payload }) {
      return payload;
    },
    globalReset() {
      return initialState;
    },
  },
});
export default slice.reducer;
export const Actions = slice.actions;
