import { IS_SERVER } from "@/misc/constants";
import { urlBase64ToUint8Array } from "@/utils";
import { PUBLIC_VAPID_KEY } from "@/misc/constants";

const getWorker = () => (IS_SERVER ? null : window.__pushWorker__);
const isSupported = () => (IS_SERVER ? false : "Notification" in window);
const getPerm = () => isSupported() && Notification.permission;
const requestPerm = () =>
  new Promise((res, rej) => {
    if (isSupported) {
      Notification.requestPermission(res);
    } else {
      rej();
    }
  });
const subscribe = () => {
  const worker = getWorker();
  if (worker) {
    return worker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
    });
  }
};
const getEndpoint = async () => {
  const worker = getWorker();
  if (worker) {
    const sub = await worker.pushManager.getSubscription();
    return sub?.endpoint;
  }
};
export const PushNotification = {
  getWorker,
  isSupported,
  getPerm,
  requestPerm,
  subscribe,
  getEndpoint,
};
