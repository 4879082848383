import { API_URL } from "@/misc/constants";
import { get, post, put } from "./index";

const getPrice = async (params) => {
  return get(`${API_URL}/premium/price/v2`, { cache: false, params }).then(
    ({ data }) => data
  );
};
const getPremium = (id) => {
  return get(`${API_URL}/user/${id}/premium`, { cache: false });
};
const activateTrial = () => {
  return post(`${API_URL}/premium/activateTrial`);
};

// @deprecated
const cancel = () => {
  return post(`${API_URL}/premium/cancel`);
};

const createPayment = (payload) => {
  return put(`${API_URL}/premium/payment`, payload);
};

export const PremiumAPI = {
  getPrice,
  getPremium,
  activateTrial,
  cancel,
  createPayment,
};
