import React, { memo } from "react";
import * as S from "./styled";
import Avatar from "_c/Avatar";
import { Link } from "react-router-dom";
import { LinkGenerator } from "@/utils/LinkGenerator";
import { mul } from "@/utils/money";
import { CurrencyFormatter } from "@/i18n/currency";
import { Money } from "@/utils/money";
import { CLIENT_DISC_NS as ns } from "@/i18n/ns";

const DiscountItem = ({ discount, t }) => {
  const { master } = discount;
  return (
    <S.Item as="li">
      <S.AvatarAndName>
        <Avatar
          avatar={master.user.avatar}
          sex={master.user.sex}
          online={master.user.online}
        />
        <div>
          <S.Name>
            {[master.user.first_name, master.user.last_name]
              .filter(Boolean)
              .join(" ")}
          </S.Name>
          <S.Amount>-{mul(discount.amount, 100)}%</S.Amount>
        </div>
      </S.AvatarAndName>
      <S.Status>
        {discount.service ? (
          <div>
            <div>
              <span>{t(`${ns}:on_service`)}</span>{" "}
              <S.ServiceName>{discount.service.title}</S.ServiceName>
            </div>
            <div>
              <span>{t(`${ns}:old_price`)}:</span>
              <S.OldPrice>
                <strike>
                  {CurrencyFormatter.format(discount.service.price)}
                </strike>
              </S.OldPrice>
            </div>
            <div>
              <span>{t(`${ns}:actual_price`)}:</span>
              <S.ActualPrice>
                {CurrencyFormatter.format(
                  Money.subtractPercent(discount.service.price, discount.amount)
                )}
              </S.ActualPrice>
            </div>
          </div>
        ) : (
          <div>{t(`${ns}:on_all_services`)}</div>
        )}
      </S.Status>
      <div style={{ display: "flex" }}>
        <S.GoProfile
          as={Link}
          to={LinkGenerator.masterProfile(master._id)}
          small
          secondary
        >
          {t(`${ns}:go_profile`)}
        </S.GoProfile>
      </div>
    </S.Item>
  );
};
DiscountItem.displayName = "DiscountItem";

export default memo(DiscountItem);
