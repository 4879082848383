const MAX = 5;
const KEY = "user-history";

export const getClientHistory = () => {
  return JSON.parse(localStorage.getItem(KEY)) || [];
};

export const addToClientHistory = (item) => {
  const history = getClientHistory();
  const index = history.findIndex(({ _id }) => _id === item._id);
  if (index !== -1) {
    history[index] = item;
  } else if (history.length >= MAX) {
    history.pop();
    history.unshift(item);
  } else {
    history.unshift(item);
  }
  localStorage.setItem(KEY, JSON.stringify(history));
};
export const clearClientHistory = () => localStorage.removeItem(KEY);
