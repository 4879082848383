import s from "styled-components";

export const Aside = s.aside`
  background-color: var(--bg-elevate);
  border-radius: var(--root-br);
  padding-top: 25px;
  section {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    &::after {
	    content: '';
      position: absolute;
      width: calc(40px + 100%);
      height: 2px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: white
    }
    &:last-child::after {
      display: none;
    }
  }
  h3 {
    color: var(--font-color-emp);
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const ArticlesList = s.ul`
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    overflow: hidden;
  }
  a {
    flex: 1;
    margin-left: 9px;
    color: var(--font-color-accent);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .25s ease;
    :focus, :hover {
      opacity: .7;
    }
  }
  svg {
    width: 13px;
    min-width: 13px;
    position: relative;
    top: 5px;
  }
`;
