import qs from "query-string";
import { useSelector } from "react-redux";
import { Selectors } from "@/store/locale/selectors";

export const useNormalizedLink = (link) => {
  const locale = useSelector(Selectors.getLocale);
  const baseLink =
    (typeof link === "string" ? link : null) || window.location.href;
  const parsed = qs.parseUrl(baseLink);
  const normalized = qs.stringifyUrl({
    url: parsed.url,
    query: {
      ...parsed.query,
      hl: parsed.query.hl || locale,
    },
  });
  return normalized;
};
