import OrdersPage from "_p/orders/loadable";
import ClientsPage from "_p/masterClients/loadable";
//import SmsPage from "_p/sms-notifications/loadable";
import PartnersPage from "_p/partners/loadable";
import TaskEditor from "_c/Modals/task-editor/loadable";
import Scheduler from "_c/Scheduler/loadable";
import AuthModal from "_c/Modals/auth-modal/loadable";
// import Settings from "_p/settings/loadable";
import TodoEditor from "_c/Modals/simple-todo-creator/loadable";
import { usePreloadModules } from "./usePreloadModules";
import { useSelector } from "react-redux";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import Calendar from "_c/Calendar";
import Notifications from "_c/AppShell/Header/Notifications/loadable";

const authModules = [
  Scheduler,
  OrdersPage,
  ClientsPage,
  //SmsPage,
  TaskEditor,
  Calendar,
  // Settings,
  Notifications,
  TodoEditor,
];
const nonAuthModules = [PartnersPage, AuthModal, Calendar];
const delay = 1500;
export const usePreloadOftenUsedModules = () => {
  const isAuth = useSelector(AuthSelectors.isAuth);
  usePreloadModules({
    modules: isAuth ? authModules : nonAuthModules,
    delay,
  });
};
