import s, { css } from "styled-components";
import { Button } from "../button";
import { Container as BootstrapContainer } from "reactstrap";
import { Link } from "react-router-dom";
import BaseLogo from "_c/Logo";
import { StyledRoot as StyledAppLink } from "_c/native-app-link/styled";

const desktopMenuPaddingMixin = css`
  @media (max-width: 1325px) and (min-width: 992px) {
    padding-left: 75px;
  }
`;
export const Footer = s.footer`
  position: relative;
  padding-bottom: 70px;
  pointer-events: none;
  padding-top: 190px;
  background: ${({ theme }) => {
    return `url(/blobs/footer-v2_${theme.__name__}.svg) no-repeat center center`;
  }};
  background-size: 100% 100%;
  @media(max-width: 1200px) {
    background-size: 120% 100%;
  }
  @media(max-width: 767px) {
    margin-top: 25px;
    background: none;
    padding-top: 0;
  }
`;
export const Container = s(BootstrapContainer)`
  pointer-events: auto;
`;

export const H2 = s.h2`
  color: #87a59e;
  font-size: 36px;
  font-weight: 300;
  line-height: 35px;
  margin-bottom: 23px;
  margin-top: 45px;
  ${desktopMenuPaddingMixin};
  @media(max-width: 991px) {
    font-size: 24px;
    line-height: normal;
  }
  @media(max-width: 767px) {
    text-align: center;
  }
`;
const BaseInput = s.input`
	background-color: white;
	border-radius: 30px;
  margin-bottom: 10px;
	font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  padding: 11px 20px;
  border: none;
  :placeholder {
    color: #7f7f7f;
  }
`;
export const NameInput = s(BaseInput).attrs({
  type: "text",
  placeholder: "Ваше имя",
  required: true,
})`
`;
export const EmailInput = s(BaseInput).attrs({
  type: "email",
  required: true,
  placeholder: "E-mail",
})``;
export const Socials = s.ul`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media(max-width: 991px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media(max-width: 767px) {
    margin-top: 15px;
  }
`;
export const Social = s.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0 9px;
  a {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  @media(max-width: 991px) {
    margin: 0 8px;
    margin-bottom: 8px;
  }
`;
export const NativeAppLinks = s.div`
  ${desktopMenuPaddingMixin};
  @media(max-width: 765px) {
    text-align: center;
  }
  ${StyledAppLink} {
    margin: 5px;
  }
`;

export const SimpleLink = s.a``;
export const Bottom = s.div`
  display: flex;
  @media(max-width: 767px) {
    flex-direction: column;
  }
`;
export const Label = s.label`
  font-size: 0;
  opacity: 0;
  height: 0;
`;
export const Form = s.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media(max-width: 767px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`;
export const MiddleWrap = s.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media(max-width: 767px) {
    margin-top: 25px;
    align-items: center;
  }
`;
export const Submit = s(Button).attrs({
  type: "submit",
  secondary: true,
})`
  padding: 13px 33px;
  @media(max-width: 767px) {
    margin: 10px auto;
  }
`;
export const Logo = s(BaseLogo)`
  width: 100px;
  height: auto;
  margin-bottom: 10px;
`;

export const SpecsList = s.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  a {
  transition: opacity .25s ease;
    font-size: 14px;
    font-weight: 500;
    color: var(--font-color-emp);
    :focus, :hover {
      opacity: .7;
    }
  }
  li {
    margin: 5px;
  }
  @media(max-width: 767px) {
    margin-top: 15px;
  }
`;

export const ChangeLang = s.button`
  font-size: 14px;
  font-weight: 700;
  color: var(--color-primary);
  transition: opacity .25s ease;
  margin-top: 15px;
  outline: none;
  :hover, :focus {
    opacity: .7;
  }
`;
