import React from "react";

const SmsPageIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12.007 2c1.617 0 3.23.4 4.661 1.154 4.875 2.572 6.75 8.631 4.18 13.508A10.027 10.027 0 0 1 11.995 22a9.926 9.926 0 0 1-2.148-.235.75.75 0 0 1 .325-1.463c3.725.829 7.57-.959 9.351-4.338 2.186-4.146.592-9.299-3.553-11.485a8.524 8.524 0 0 0-3.964-.981c-4.696 0-8.509 3.813-8.509 8.5a8.496 8.496 0 0 0 .963 3.933l.192.375c.235.44.287.988.134 1.483a17.12 17.12 0 0 0-.53 1.632c.562-.169 1.248-.418 1.744-.599l.203-.073a.748.748 0 0 1 .509 1.408l-.2.073c-.742.269-1.756.638-2.442.791a.768.768 0 0 1-.177.019c-.46 0-.74-.186-.894-.343-.237-.24-.35-.578-.337-1.002a.766.766 0 0 1 .02-.148c.178-.757.41-1.513.69-2.245a.47.47 0 0 0-.05-.31l-.193-.374A10.006 10.006 0 0 1 2 11.998C2 6.485 6.484 2 11.995 2Zm-.011 8.81a1.19 1.19 0 0 1 0 2.378 1.19 1.19 0 0 1 0-2.379Zm4.403 0a1.19 1.19 0 0 1 0 2.378 1.19 1.19 0 0 1 0-2.379Zm-8.807 0a1.19 1.19 0 1 1-.001 2.38 1.19 1.19 0 0 1 .001-2.38Z"
    />
  </svg>
);

export default SmsPageIcon;
