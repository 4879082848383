import { createSlice } from "@reduxjs/toolkit";

export const DOMAIN = "msgrSubPage";
const initialState = {
  isLoading: true,
  data: null,
  error: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    loadingStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loadingSuccess(state, { payload }) {
      state.isLoading = false;
      state.data = payload;
    },
    loadingError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
