import styled from "styled-components";
import BaseLogo from "_c/Logo";
import uiVars from "../ui-vars";

export const Wrap = styled.div.attrs({
  id: "site-top-head",
})`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-paper);
  border-bottom: ${({ theme }) => theme.border.light};
  transition: all 0.25s ease;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: ${uiVars.headDesktopHeight};
  @media (max-width: ${uiVars.mobileBp}px) {
    height: ${uiVars.headMobileHeight};
  }
`;

export const Logo = styled(BaseLogo)`
  width: 65px;
  height: auto;
  position: relative;
  top: 3px;
  @media (max-width: ${uiVars.mobileBp}px) {
    width: 55px;
  }
`;
