import { get, post, put, remove } from "./index";
import { API_URL } from "@/misc/constants";
import type {
  tCreateExternalNotificationTmplArgs,
  tExternalNotificationTmpl,
  tHttpClientRes,
  tLocale,
  tUpdateExternalNotificationTmplArgs,
  tSupportedExternalNotificationTmpls,
} from "@/types";
import { tSendBulkExternalNotificationWithTmpl } from "@/types/externalNotifications";

const BASE_URL = `${API_URL}/externalNotification`;
const TEMPLATES_BASE = `${BASE_URL}/template`;

const getMyTemplates = (): tHttpClientRes<tExternalNotificationTmpl[]> =>
  get(`${TEMPLATES_BASE}/myTemplates`, { cache: false });
const getDefaultMessages = (locale: tLocale): tHttpClientRes<tStringDict> =>
  get(`${TEMPLATES_BASE}/defaultMessages`, {
    cache: false,
    params: { locale },
  });

const getSupportedTemplates = ({
  locale,
}: {
  locale: tLocale;
}): tHttpClientRes<tSupportedExternalNotificationTmpls> => {
  return get(`${TEMPLATES_BASE}/supportedTemplates`, {
    cache: false,
    params: { locale },
  });
};
const createTemplate = async (
  payload: tCreateExternalNotificationTmplArgs
): tHttpClientRes<tExternalNotificationTmpl> => {
  const res = await put(TEMPLATES_BASE, payload);
  return res;
};
const updateTemplateById = async (
  id: string,
  payload: tUpdateExternalNotificationTmplArgs
): tHttpClientRes<tExternalNotificationTmpl> => {
  const res = await post(`${TEMPLATES_BASE}/${id}`, payload);
  return res;
};
const deleteTemplateById = async (
  id: string
): tHttpClientRes<tExternalNotificationTmpl> => {
  const res = await remove(`${TEMPLATES_BASE}/${id}`);
  return res;
};
const getMetadata = async (params: any) => {
  return get(`${BASE_URL}/metadata`, { cache: false, params });
};

const sendBulkWithTemplate = async (
  payload: tSendBulkExternalNotificationWithTmpl
) => {
  return post(`${BASE_URL}/sendBulk/withTemplate`, payload);
};

export const ExternalNotificationsAPI = {
  getMyTemplates,
  getDefaultMessages,
  getSupportedTemplates,
  createTemplate,
  updateTemplateById,
  deleteTemplateById,
  getMetadata,
  sendBulkWithTemplate,
};
