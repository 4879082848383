import React, { MouseEventHandler } from "react";
import { Modal as StyledModal } from "./styled";
import Lock from "_c/ScrollLock";
export { Header, Content, Footer } from "./styled";
import type { tAbstractModalProps } from "@/types/ui";
import type { Styles } from "react-modal";
import { Button } from "_c/button";
import { tProps as tButtonProps } from "_c/button";

type tProps = tAbstractModalProps<{
  children: any;
  className?: string;
  style?: Styles;
  /**
   * breakpoint for mobile devices
   */
  mobileBp?: number;
}>;

const AbstractModal = ({
  isOpen,
  mobileBp = 600,
  onClose,
  children,
  ...props
}: tProps) => {
  if (!isOpen) return null;
  return (
    <StyledModal
      ariaHideApp={false}
      overlayClassName={"modal-overlay"}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      isOpen
      mobileBp={mobileBp}
      {...props}
    >
      <Lock />
      {children}
    </StyledModal>
  );
};

export default AbstractModal;

type tFooterV2Props = {
  children: any;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonType?: "submit" | "button";
  buttonForm?: string;
  buttonProps?: tButtonProps;
};
export const FooterV2 = (props: tFooterV2Props) => {
  const buttonProps = props.buttonProps || { greenish: true };
  return (
    <div
      className={props.className}
      css={`
        flex-shrink: 0;
        padding: 10px;
        position: relative;
      `}
    >
      <Button
        form={props.buttonForm}
        type={props.buttonType}
        onClick={props.onClick}
        disabled={props.disabled}
        {...buttonProps}
        css={`
          display: block;
          width: 100%;
        `}
      >
        {props.children}
      </Button>
    </div>
  );
};
