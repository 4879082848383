import React from 'react';

const SvgDeny = props => (
  <svg width="12" viewBox="0 0 12 12" {...props}>
    <defs>
      <path
        id="svg_deny"
        d="M779.241 1555.387l3.743 3.731 3.731-3.743a1.321 1.321 0 0 1 1.872 1.866l-3.732 3.743 3.743 3.73a1.321 1.321 0 1 1-1.866 1.872l-3.742-3.731-3.732 3.742a1.321 1.321 0 1 1-1.871-1.865l3.731-3.743-3.742-3.731a1.321 1.321 0 0 1 1.865-1.871z"
      />
    </defs>
    <use
      fill={props.color}
      xlinkHref="#svg_deny"
      transform="translate(-777 -1555)"
    />
  </svg>
);

export default SvgDeny;
