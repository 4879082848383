const SOUNDS_DIR = '/sounds';

const baseConfig = {
  autoplay: false,
  loop: false,
  volume: 0.6,
};
class Sound {
  constructor(config) {
    this.config = {...baseConfig, ...config};
    this.sound = null;
  }
  play = async () => {
    const {Howl} = await import('howler');
    if (!this.sound) {
      this.sound = new Howl(this.config);
    }
    this.sound.play();
  };
  stop = () => {
    this.sound && this.sound.stop();
  };
}
export const messageSound = new Sound({
  src: `${SOUNDS_DIR}/message.mp3`,
});
export const notifSound = new Sound({
  src: `${SOUNDS_DIR}/notif.mp3`,
});
