import { TELEGRAM_BOT, WA_BOT, VIBER_BOT } from "@/misc/constants";
export const LinkGenerator = {
  masterProfile(id) {
    if (!id) {
      return "/master";
    }
    return `/master?id=${id}`;
  },
  clientProfile(id) {
    if (!id) {
      return "/client";
    }
    return `/client?id=${id}`;
  },
  saloonProfile(id) {
    return `/salon?id=${id}`;
  },
  blogArticle(id) {
    return `/blog/article/${id}`;
  },
  blogHome() {
    return "/blog?page=0";
  },
  blogEditor(id) {
    if (id) {
      return `/blog/editor/${id}`;
    }
    return "/blog/editor";
  },
  portfolio(id) {
    return `/portfolio/${id}`;
  },
  review(id) {
    return `/review/${id}`;
  },
  masterOrders() {
    return "/master/orders";
  },
  masterSchedule() {
    return "/master/schedule";
  },
  masterClients() {
    return "/master/clients";
  },
  clientOrders() {
    return "/client/orders";
  },
  saloonDashboard() {
    return "/salon/dashboard";
  },
  masterSettings() {
    return "/master/settings";
  },
  clientSettings() {
    return "/client/settings";
  },
  telegramBot(token) {
    return `https://t.me/${TELEGRAM_BOT}?start=${token}`;
  },
  viberBot(token) {
    return `viber://pa?chatURI=${VIBER_BOT}&text=!sub ${token}`;
  },
  whatsappBot(token) {
    return `https://wa.me/${WA_BOT}?text=!sub ${token}`;
  },
  insta(id) {
    return `https://instagram.com/${id}`;
  },
  vk(id) {
    return `https://vk.com/${id}`;
  },
  tg(id) {
    return `https://t.me/${id}`;
  },
  fb(id) {
    return `https://www.facebook.com/${id}`;
  },
  phone(phone) {
    return `tel:${phone}`;
  },
  email(email) {
    return `mailto:${email}`;
  },
  sms(phone) {
    return `sms:${phone}`;
  },
  help(locale) {
    if (locale === "en") {
      return "https://special.simpo.online/en/help?utm_source=partners";
    }
    return "https://special.simpo.online/help?utm_source=partners";
  },
};
