import { connect } from "react-redux";
import { Selectors as BasketSelectors } from "@/store/basket/selectors";
import { Effects as BasketEffects } from "@/store/basket/effects";
import { Effects as MessengerEffects } from "@/store/messenger/effects";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import loadable from "@loadable/component";
import { Selectors as UserSelectors } from "@/store/user/selectors";

const BookingModal = loadable(
  () => import(/* webpackChunkName: "booking-modal" */ "."),
  { ssr: false }
);

const mSp = (state) => ({
  basket: BasketSelectors.getBasket(state),
  masterId: BasketSelectors.getMasterId(state),
  sessionDuration: BasketSelectors.getSessionDuration(state),
  finalPrice: BasketSelectors.getFinalPrice(state),
  authUserId: UserSelectors.getUserId(state),
  isAuth: AuthSelectors.isAuth(state),
});
const mDp = {
  removeFromBasket: BasketEffects.removeFromBasket,
  clearBasket: BasketEffects.clearBasket,
  sendMessage: MessengerEffects.createMessageOutsideMessenger,
};

export default connect(mSp, mDp)(BookingModal);
