import React, { useState } from "react";
import PT from "prop-types";
import { InputWrap, Input, PinIcon } from "./styled";
import BasePlacesAutocomplete from "_c/PlacesAutocomplete";
import { useColorScheme } from "@/hooks/useColorScheme";

const PlacesAutocomplete = (props) => {
  const colorScheme = useColorScheme();
  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    props.onFocus();
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };
  return (
    <InputWrap>
      <BasePlacesAutocomplete
        onSelect={props.onSelect}
        uid="search-addr-select"
        onFocus={onFocus}
        onBlur={onBlur}
        value={props.value}
        id="master-search-city-input"
        onChange={props.onChange}
        InputComponent={Input}
        placeholder={props.t("common:search_form.city")}
      />
      <PinIcon
        color={colorScheme === "dark" ? "#fff" : "#d1179e"}
        style={{
          opacity: focused ? 1 : 0.3,
        }}
      />
    </InputWrap>
  );
};
PlacesAutocomplete.propTypes = {
  value: PT.string,

  onSelect: PT.func.isRequired,
  onChange: PT.func,
  onFocus: PT.func.isRequired,
  t: PT.func.isRequired,
};
PlacesAutocomplete.defaultProps = {
  value: "",
};

export default PlacesAutocomplete;

export const PlacesAutocompletePlaceholder = ({ t, theme }) => (
  <InputWrap>
    <Input placeholder={t("common:search_form.city")} />
    <PinIcon
      style={{
        opacity: 0.3,
      }}
      color={theme === "dark" ? "#fff" : "#d1179e"}
    />
  </InputWrap>
);
