import { object, string, ref, mixed } from "./";
import { passwordSchema } from "./common";
import { phoneSchema as rawPhoneSchema } from "./phone";

export const restorePasswordSchema = object().shape({
  password: passwordSchema.required(),
  repeat_password: string()
    .oneOf([ref("password")], "diff_passwords")
    .required(),
});

export const restorePasswordRequestSchema = object().shape({
  strategy: mixed(),
  email: mixed().when("strategy", {
    is: "email",
    then: string().required(),
    otherwise: mixed(),
  }),
  phone: mixed().when("strategy", {
    is: "phone",
    then: rawPhoneSchema.required(),
    otherwise: mixed(),
  }),
});
