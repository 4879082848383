import React from "react";

const SvgEnvelope = (props) => (
  <svg width="14" viewBox="0 0 14 10" {...props}>
    <path
      d="M7 6.545L-.01.659V0H14v.654zm0 1.774L-.01 2.437V10H14V2.437z"
      fill={props.color || "currentColor"}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgEnvelope;
