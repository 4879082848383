import React from "react";
import Layout from "@/layouts/Profile";
import Favorites from "_p/favorites/container";
import Orders from "_p/orders/container";
import Messages from "_p/messages/container";
import Schedule from "_p/schedule";
import Profile from "_p/client/container";
import Bonus from "_p/bonus/container";
import Settings from "_p/settings/container";
import Achieves from "_p/achieves/container";
import Discounts from "_p/client-discounts/container";
import { Route } from "react-router-dom";

const ClientContainer = (props) => {
  const { match } = props;
  return (
    <Layout>
      <Route exact path={`${match.url}`} component={Profile} />
      <Route
        exact
        key="orders"
        path={`${match.url}/orders`}
        component={Orders}
      />
      <Route
        key="orders1"
        path={`${match.url}/orders/:mode`}
        component={Orders}
      />
      <Route path={`${match.url}/messages`} component={Messages} />
      <Route
        exact
        key="favorites"
        path={`${match.url}/favorites`}
        component={Favorites}
      />
      <Route
        key="favorites1"
        path={`${match.url}/favorites/:type`}
        component={Favorites}
      />
      <Route path={`${match.url}/schedule`} component={Schedule} />
      <Route exact key="bonus" path={`${match.url}/bonus`} component={Bonus} />
      <Route key="bonus1" path={`${match.url}/bonus/:mode`} component={Bonus} />
      <Route exact path={`${match.url}/discounts`} component={Discounts} />
      <Route path={`${match.url}/discounts/:mode`} component={Discounts} />
      <Route
        exact
        key="settings"
        path={`${match.url}/settings`}
        component={Settings}
      />
      <Route
        key="settings1"
        path={`${match.url}/settings/:mode`}
        component={Settings}
      />
      <Route
        key="achievements"
        path={`${match.url}/achievements`}
        component={Achieves}
      />
    </Layout>
  );
};
export default ClientContainer;
