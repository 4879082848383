import React from "react";
import * as S from "./index-styled";
import { Row, Col } from "reactstrap";
import MiddlePart from "./middle-part";
import Nav from "./nav";
import Specs from "./specs";
import { connect } from "react-redux";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import { Effects as MessengerEffects } from "@/store/messenger/effects";
import { Actions as ModalActions } from "@/store/modals/slice";
import { useTranslation } from "react-i18next";
import Apps from "./apps";
import { useExtractHL } from "@/hooks/useExtractHL";
import { IS_STANDALONE } from "@/config";
import { useLocation } from "react-router-dom";
import { scheduleBase } from "@/store/profileLinks/selectors";

const Footer = (props) => {
  const { t, ready } = useTranslation(["common"], {
    useSuspense: false,
  });
  if (!ready) return null;
  return (
    <S.Footer className={props.className}>
      {props.children}
      <S.Container>
        <S.H2>
          {t("common:footer.apps.head.1")}
          <br />
          {t("common:footer.apps.head.2")}
        </S.H2>
        <Row>
          <Col md="4">
            <Apps t={t} />
          </Col>
          <Col md="4">
            <MiddlePart locale={props.locale} t={t} />
          </Col>
          <Col md="4">
            <Nav
              hl={props.hl}
              t={t}
              isAuth={props.isAuth}
              openAuthModal={props.openAuthModal}
              sendMessage={props.sendMessage}
              locale={props.locale}
            />
          </Col>
        </Row>
        {/*
        <Row>
          <Col>
            <Specs hl={props.hl} />
          </Col>
        </Row>
        */}
      </S.Container>
    </S.Footer>
  );
};

const mSp = (state) => ({
  isAuth: AuthSelectors.isAuth(state),
  locale: LocaleSelectors.getLocale(state),
});
const mDp = {
  openAuthModal: ModalActions.openAuth,
  sendMessage: MessengerEffects.createMessageOutsideMessenger,
};
const ConnectedFooter = connect(mSp, mDp)(Footer);
const FooterContainer = () => {
  const hl = useExtractHL();
  const { pathname } = useLocation();
  if (
    IS_STANDALONE ||
    pathname?.endsWith("/todos") ||
    pathname === scheduleBase
  ) {
    return null;
  }
  return <ConnectedFooter hl={hl} />;
};

export default FooterContainer;
