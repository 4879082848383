import loadable from "@loadable/component";
import { connect } from "react-redux";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as FavSelectors } from "@/store/favorites/selectors";
import { Selectors as ReviewSelectors } from "@/store/reviews/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Selectors as ProfileSelectors } from "@/store/masterProfile/selectors";
import { Effects } from "@/store/reviews/effects";
import { Actions as ModalActions } from "@/store/modals/slice";

const ReviewPage = loadable(() =>
  import(
    /* webpackChunkName: "review-page" */
    "."
  )
);

const mSp = state => ({
  review: ReviewSelectors.getReview(state),
  loading: ReviewSelectors.isLoading(state),
  error: ReviewSelectors.getError(state),
  favorites: FavSelectors.getFavoriteReviews(state),
  isAuth: AuthSelectors.isAuth(state),
  authUserId: UserSelectors.getUserId(state),
  masterId: ProfileSelectors.getMasterId(state),
  reviewMaster: ReviewSelectors.getReviewMaster(state),
  reviewSaloon: ReviewSelectors.getReviewSaloon(state)
});
const mDp = {
  loadReview: Effects.loadReview,
  toggleLike: Effects.toggleLike,
  addComment: Effects.addComment,
  removeComment: Effects.removeComment,
  loadComments: Effects.loadComments,
  openShareModal: ModalActions.openShare,
  updateReview: Effects.updateReview
};

export default connect(mSp, mDp)(ReviewPage);
