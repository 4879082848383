import { createSelector } from "@reduxjs/toolkit";
import { DOMAIN } from "./slice";

const $ = (state) => state[DOMAIN];
const getForm = (state) => $(state).form;
const isRoleSelected = (state) => {
  const form = getForm(state);
  return (
    form.is_client || form.is_master || form.is_saloon_owner || form.is_todo
  );
};
const getStep = (state) => $(state).step;
const getStrategy = (state) => $(state).strategy;
const getCompletedSteps = createSelector(
  [getForm, getStrategy, isRoleSelected],
  (form, strategy, isRoleSelected) => {
    const acc = [];
    if (isRoleSelected) {
      acc.push(1);
    }
    if (
      form.first_name &&
      ((strategy === "email" && form.email) ||
        (strategy === "phone" && form.phone))
    ) {
      acc.push(2);
    }
    if (form.password) {
      acc.push(3);
    }
    return acc;
  }
);
const getStepsAmount = () => 3;

export const Selectors = {
  getForm,
  getStep,
  getStrategy,
  getStepsAmount,
  getCompletedSteps,
  isRoleSelected,
};
