import React from "react";
import { connect } from "react-redux";
import { Selectors } from "@/store/saloonDashboard/selectors";
import { Selectors as PremiumSelectors } from "@/store/premium/selectors";
import { Effects as PremiumEffects } from "@/store/premium/effects";
import SaloonDashboard from "./loadable";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";
import { useMySaloonsQuery } from "./hooks/useMySaloonsQuery";
import QueryRequest from "@/components/QueryRequest";

// TODO:: broken ssr
const SaloonDashboardContainer = (props) => {
  const mySaloonsQuery = useMySaloonsQuery();
  return (
    <QueryRequest query={mySaloonsQuery}>
      <SaloonDashboard {...props} />
    </QueryRequest>
  );
};
const mSp = (state) => ({
  mode: Selectors.getMode(state),
  activeSaloonId: Selectors.getActiveSaloonId(state),
  isSaloonOwner: Selectors.isSaloonOwner(state),
  policies: Selectors.getSaloonPolicies(state),
  isAllowTrial: PremiumSelectors.getAllowTrial(state),
  menu: LinksSelectors.getSaloonDashboardChildren(state),
  hasAnySaloons: Selectors.hasAnySaloons(state),
});
const mDp = {
  activateTrial: PremiumEffects.activateTrial,
};

const connected = connect(mSp, mDp)(SaloonDashboardContainer);
export default connected;
