import React, { useCallback, useEffect, useState, useMemo } from "react";
import Form from "./Form";
import Popular from "./Popular";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Selectors as SearchSelectors } from "@/store/masterSearch/selectors";
import { useParsedQuery } from "@/hooks/useParsedQuery";
import { Container } from "reactstrap";
import { matchPath, useLocation } from "react-router-dom";
import { SEARCH_NS } from "@/i18n/ns";
import { preloadNS } from "@/i18n/utils";
import { preloadModules } from "@/utils/loadable";
import SearchPageLoadable from "_p/search/loadable";
import { useSearchableSpecsQuery } from "@/hooks/queries/useSearchableSpecsQuery";

const Search = ({ className, coords, placeName }) => {
  const specs = useSearchableSpecsQuery().data;
  const preloadSearchPage = useCallback(() => {
    preloadNS([SEARCH_NS]);
    preloadModules([SearchPageLoadable]);
  }, []);
  const { pathname } = useLocation();
  const matchedPath = useMemo(() => {
    return matchPath(pathname, { path: "/search/:loc/:spec?" });
  }, [pathname]);
  const [manuallyChangedLocation, setManuallyChangedLocation] = useState(null);
  const { t, ready } = useTranslation(["common", "errors"], {
    useSuspense: false,
  });
  const parsedQuery = useParsedQuery();
  useEffect(() => {
    setManuallyChangedLocation(null);
  }, [parsedQuery, matchedPath]);
  if (!ready) return null;
  return (
    <Container className={className}>
      <Form
        matchedPath={matchedPath}
        coords={coords}
        preloadSearchPage={preloadSearchPage}
        onLocationChange={setManuallyChangedLocation}
        name={placeName}
        t={t}
        query={parsedQuery}
      />
      {specs && (
        <Popular
          matchedPath={matchedPath}
          query={parsedQuery}
          t={t}
          manualLocation={manuallyChangedLocation}
          specs={specs}
        />
      )}
    </Container>
  );
};

export default connect((state) => ({
  coords: SearchSelectors.getLocCoords(state),
  hasCoords: SearchSelectors.hasCoords(state),
  placeName: SearchSelectors.getLocName(state),
}))(Search);
