import React from "react";

const SvgCamera = ({ color, ...props }) => (
  <svg width="20" viewBox="0 0 20 18" {...props}>
    <path
      d="M16.36 16.21v-1.8h.909a.909.909 0 0 0 .909-.9v-7.2a.9.9 0 0 0-.909-.9H4.546a.9.9 0 0 0-.909.9v4.5H1.819v-5.4a1.805 1.805 0 0 1 1.818-1.8h2.727v-1.8A1.811 1.811 0 0 1 8.181.01h5.453a1.812 1.812 0 0 1 1.818 1.8v1.8h1.817v-.9a.909.909 0 0 1 1.818 0v.9H20v10.8a1.812 1.812 0 0 1-1.818 1.8H16.36zm-2.726-13.5a.9.9 0 0 0-.909-.9H9.09a.9.9 0 0 0-.909.9v.9h5.453v-.9zM1.819 13.51a.909.909 0 0 1 1.818 0v.9h.909a.9.9 0 1 1 0 1.8h-.909v.9a.909.909 0 0 1-1.818 0v-.9H.911a.9.9 0 1 1 0-1.8h.908v-.9zm12.724-1.35a3.636 3.636 0 1 1-3.635-3.6 3.617 3.617 0 0 1 3.635 3.6zm-3.635 1.8a1.8 1.8 0 1 0-1.818-1.8 1.812 1.812 0 0 0 1.818 1.8z"
      fill={color || "currentColor"}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgCamera;
