import { SearchAPI } from "@/api/search";
import qs from "query-string";
import { SITE_URL } from "@/misc/constants";
import isempty from "lodash/isEmpty";
import { initialState } from "../slice";
import isequal from "lodash/isEqual";

/**
 * @typedef {string} SeoQueryKey
 * @typedef {any} SeoQueryValue
 * @typedef {string} SeoStrategy
 * @typedef {[SeoQueryKey, SeoQueryValue, SeoStrategy]} SeoTriple
 * @type {SeoTriple}
 */
const seoTriple = [
  ["sort", "price", "price"],
  ["sort", "-rate", "rate"],
  ["discounts", "true", "disc"],
  ["at_saloon", "true", "saloon"],
  ["the_road", "true", "road"],
  ["at_home", "true", "home"],
  ["model_search", "true", "model"],
];

const defaultMeta = initialState.seoMeta;

export const extractSeoMeta = async ({ params, query }) => {
  if (!params.loc || !params.spec) {
    return defaultMeta;
  }
  let meta;
  try {
    meta = await SearchAPI.getMasterSearchSeoMeta(params.loc, params.spec).then(
      (d) => d.data
    );
  } catch (_e) {
    meta = null;
  }
  if (!meta) return defaultMeta;
  let strategy = "raw",
    canonicalQuery = {};
  for (const [k, v, s] of seoTriple) {
    if (query[k] === v) {
      if (!meta[s]) return defaultMeta;

      strategy = s;
      canonicalQuery[k] = v;
      break;
    }
  }
  if (!meta || !meta[strategy]) return defaultMeta;
  const canonicalUrl = extractCanonicalUrl({ params, canonicalQuery, query });
  return {
    ...(canonicalUrl
      ? {
          canonical_url: canonicalUrl,
        }
      : {}),
    ...meta[strategy],
    no_index: false,
  };
};

const extractCanonicalUrl = ({ params, canonicalQuery, query }) => {
  const baseUrl = `${SITE_URL}/search/${params.loc}/${params.spec}`;
  const hasQuery = !isempty(query);
  const hasCanonicalQuery = !isempty(canonicalQuery);
  if (hasCanonicalQuery) {
    if (isequal(query, canonicalQuery)) {
      return null;
    } else {
      return qs.stringifyUrl({
        url: baseUrl,
        query: canonicalQuery,
      });
    }
  } else if (hasQuery) {
    return baseUrl;
  } else return null;
};
