import { createSelector } from "@reduxjs/toolkit";
import { Selectors as PortfolioSelectors } from "@/store/portfolio/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import groupBy from "lodash/groupBy";
import {
  mul,
  reduceServiceDiscounts,
  Money,
  checkIfDiscountPersonal,
} from "@/utils/money";
import { Selectors as ProfileSelectors } from "./profile";

const getServicesFilter = (state) => state.masterProfile.servicesFilter;
const getNormalizedServicesFilter = createSelector(
  getServicesFilter,
  (filter) => filter.trim().toLowerCase()
);
const getDiscounts = (state) => state.masterProfile.discounts;
const getPublicDiscounts = createSelector(getDiscounts, (discounts) => {
  return discounts.filter((discount) => !discount.client);
});
const getServices_ = (state) => {
  return state.masterProfile.services;
};
const getServices = createSelector(
  [getServices_, ProfileSelectors.isOwner],
  (services, isOwner) => {
    if (isOwner) return services;
    return services.filter((s) => !s.disable_booking);
  }
);
const getFilteredServices = createSelector(
  [getServices, getServicesFilter],
  (services, filter) => {
    if (!filter) return services;
    const filterL = filter.toLowerCase();
    return services.filter(({ title }) =>
      title.toLowerCase().includes(filterL)
    );
  }
);
const getServicesForSelect = createSelector(getServices, (services) =>
  services.map(({ _id, title }) => ({
    label: title,
    value: _id,
  }))
);
const getNormalizedServices = createSelector(
  getFilteredServices,
  getNormalizedServicesFilter,
  getDiscounts,
  // TODO:: optimize memo for portfolio, we only interested in indexes
  PortfolioSelectors.getRawPortfolio,
  UserSelectors.getUserId,
  (services, filter, discounts, portfolio, authUserId) => {
    return services.reduce((acc, service) => {
      if (filter && !service.title.toLowerCase().includes(filter)) {
        return acc;
      }
      const appliedDiscounts = discounts.reduce((acc, discount) => {
        if (!discount.service || discount.service === service._id) {
          acc.push(discount);
        }
        return acc;
      }, []);
      const finalDiscount = reduceServiceDiscounts({
        discounts: appliedDiscounts,
        userId: authUserId,
      });
      const isDiscountPersonal = finalDiscount
        ? checkIfDiscountPersonal(finalDiscount)
        : false;
      const finalPrice = finalDiscount
        ? Money.subtractPercent(service.price, finalDiscount.amount)
        : service.price;
      const photoIdx = portfolio?.findIndex(
        ({ service: serviceId }) => serviceId && serviceId === service._id
      );
      acc.push({
        ...service,
        finalDiscount,
        isDiscountPersonal,
        finalPrice,
        discounts: appliedDiscounts,
        photoIdx: photoIdx === -1 ? undefined : photoIdx,
      });
      return acc;
    }, []);
  }
);
const getPublicDiscountsWithDescription = createSelector(
  getPublicDiscounts,
  getServices,
  (discounts, services) => {
    return discounts.map((discount) => {
      let desc = "";
      const amount = mul(discount.amount, 100);
      if (!discount.service) {
        desc = {
          type: "all",
          amount,
        };
      } else if (discount.service) {
        const service = services.find(({ _id }) => _id === discount.service);
        if (service) {
          desc = {
            type: "service",
            amount,
            service: service.title,
          };
        }
      }
      return {
        ...discount,
        desc,
      };
    });
  }
);
const getServicesGroupedBySpec = createSelector(getServices, (services) => {
  const grouped = groupBy(services, "spec");
  return grouped;
});
const getStringifiedSpecs = createSelector(
  getServicesGroupedBySpec,
  (_state, specs) => specs,
  (services, specs) => {
    if (!specs) return [];
    const acc = [];
    for (const key in services) {
      if (key in specs) {
        acc.push(specs[key].title);
      }
    }
    return acc.join(", ").trim();
  }
);
const getRenderReadyServices = createSelector(
  getNormalizedServices,
  (_state, specs) => specs,
  (services, specs) => {
    if (!specs) return [];
    const grouped = groupBy(services, "spec");
    return Object.keys(grouped).reduce((acc, key) => {
      const spec = specs[key];
      if (spec) {
        acc.push({
          spec: {
            ...spec,
            _id: key,
          },
          services: grouped[key],
        });
      }
      return acc;
    }, []);
  }
);
const getModelSearchService = createSelector(
  getServices,
  getDiscounts,
  (services, discounts) => {
    const modelDiscount = discounts.find((d) => d.model_search && d.service);
    if (!modelDiscount) return null;
    const service = services.find(({ _id }) => _id === modelDiscount.service);
    return service ? service : null;
  }
);

export const Selectors = {
  getStringifiedSpecs,
  getDiscounts,
  getModelSearchService,
  getServices,
  getServicesForSelect,
  getServicesGroupedBySpec,
  getRenderReadyServices,
  getPublicDiscountsWithDescription,
  getServicesFilter,
};
