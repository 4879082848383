import React from "react";
import Modal from "../base-modal";
import Main from "./main";
import PT from "prop-types";

const ShareModal = (props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      overlay="share-overlay"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Main {...props} />
    </Modal>
  );
};
ShareModal.propTypes = {
  isOpen: PT.bool.isRequired,
  shareLink: PT.any,

  onClose: PT.func.isRequired,
};

export default ShareModal;
