import * as React from "react";

function SvgHairColorist({ color = "currentColor", ...props }) {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67.44 100"
      {...props}
    >
      <path d="M43.51 1.47A35.94 35.94 0 0033.17 0c-10.44 0-22.23 4.34-25 14-1.63 5.66 1.1 8 9.33 13.33 7.66 4.94 18.15 11.71 18.15 26.42 0 6.35-2.69 10.92-5.06 15-2.89 4.94-4.64 7.92-2.58 13.36 1.24 3.28 4.59 4.8 8.83 6.72 4.79 2.18 10.71 4.86 14 11.19 9-13 6.17-17.22 3.19-21.7-1.63-2.45-3.49-5.24-4.07-9-1.17-7.68 3.53-13.13 8.07-18.41 4.83-5.6 9.39-10.9 9.39-19.63.02-10.41-6.24-24.43-23.91-29.81z" />
      <path d="M26.13 66.12c2.23-3.81 4.34-7.41 4.34-12.35 0-11.89-8.39-17.31-15.78-22.08-2.13-1.38-4.22-2.74-6.06-4.2A20.21 20.21 0 009 37.21s-1.25.84-2.48-.94c-.1-.14-.16-.27-.18-.19-.26.86 2.28 2.48 2.28 2.48C4.9 45 2.11 47.34.43 49.77-.73 51.46.68 52.31 2 52.84s3.32 1.08 2.47 3c-.14.3-.28.56-.42.8-1.05 1.85-2.37 1.87-.21 4.41.48.56-1.87 1.23-.65 3.69.75 1.43 1.74 1.76 2.1 2.81.88 2.51-3.8 8.32 2.21 9.94 3.66 1 9.2.19 14.48-.7.27-4.02 2.16-7.29 4.15-10.67z" />
    </svg>
  );
}

export default SvgHairColorist;
