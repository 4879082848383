import { createSlice } from "@reduxjs/toolkit";

const DOMAIN = "auth";

const initialState = {
  isAuth: false,
  socketToken: null,
  couchdbToken: null,
  affiliateData: null,
  registerLoading: false,
  registerError: null,
};
const slice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    updateLoginForm(state, { payload }) {
      state.login.form[payload.key] = payload.value;
    },
    loginSuccess(state, { payload }) {
      state.isAuth = true;
      state.socketToken = payload.socketToken;
      state.couchdbToken = payload.couchdbToken;
      state.affiliateData = null;
    },
    registerStart(state) {
      state.registerLoading = true;
    },
    registerSuccess(state) {
      state.registerLoading = false;
      state.affiliateData = null;
    },
    registerError(state, { payload }) {
      state.registerLoading = false;
      state.registerError = payload;
    },
    globalReset() {
      return initialState;
    },
  },
});

export const Actions = slice.actions;
export default slice.reducer;
