import React from "react";
import s from "styled-components";
import { useTheme } from "@/hooks/useTheme";

const StyledLogo = s.img`
  width: 120px;
  height: auto;
`;

const Logo = (props) => {
  const theme = useTheme();
  const isDark = theme.__name__ === "dark";
  return (
    <StyledLogo
      src={isDark ? "/svg/new_logo_dark.svg" : "/svg/new_logo.svg"}
      {...props}
    />
  );
};

export default Logo;
