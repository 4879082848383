import { createGlobalStyle } from "styled-components";
import { rippleMixin } from "@/styles/mixins";
import appShellUiVars from "_c/AppShell/ui-vars";

const mobileNavHeight = appShellUiVars.bottomMobileNavHeight;
const fullScreenHeightMobileOffset =
  parseInt(appShellUiVars.headMobileHeight) +
  parseInt(mobileNavHeight) +
  20 +
  "px";
export const GlobalStyle = createGlobalStyle`
  :root {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-full-screen-height: calc(100vh - ${
      appShellUiVars.headDesktopHeight
    } - 20px);
    --mobile-nav-height: ${mobileNavHeight};
    ${({ theme }) => `
      --color-primary: ${theme.color.primary}; 
      --color-primary-2: ${theme.color.primary_2}; 
      --color-primary-3: ${theme.color.primary_3}; 
      --color-primary-4: ${theme.color.primary_4}; 
      --color-cta: ${theme.color.positive};
      --color-danger: ${theme.color.negative};
      --color-warn: ${theme.color.warn || "yellow"};
      --color-progress-bar ${theme.color.progressBar || theme.color.primary};
      --font-color-main: ${theme.fontColor.main};
      --font-color-accent: ${theme.fontColor.secondary};
      --font-color-emp: ${theme.fontColor.header || theme.color.primary};
      --font-color-touchable: ${theme.fontColor.secondary};
      --font-color-touchable-active: white;
      --bg-root: ${theme.bg.main};
      --bg-paper: ${theme.bg.paper};
      --bg-elevate: ${theme.bg.elevate};
      --bg-touchable: ${theme.color.touchable};
      --bg-touchable-active: ${theme.color.primary};
      --grad-primary-n: ${theme.gradient.primary.normal};
      --grad-primary-h: ${theme.gradient.primary.hover || theme.color.primary};
      --root-br: ${theme.rootBorderRadius};
    `}
  }
  @media(max-width: ${appShellUiVars.mobileBp}px) {
    :root {
      --safe-full-screen-height: calc(100vh - ${fullScreenHeightMobileOffset});
      --safe-full-screen-height: calc(var(--initial-vh, 1vh) * 100 - ${fullScreenHeightMobileOffset});
    }
  }
  *, *:after, *:before {
    box-sizing: border-box;
  }
  input[type="text"] {
    appearance: none;
  }
  body {
    font-family: ${({ theme }) => theme.font.main};
    margin: 0;
    padding: 0;
    background-color: var(--bg-root);
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .container, .container-fluid {
    padding: 0 !important;
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    outline: none;

    background: transparent;
    color: inherit;
    font: inherit;
    cursor: pointer;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
  .twitter-picker {
    background-color: var(--bg-paper) !important;
    input {
      background-color: var(--bg-touchable);
      color: var(--font-color-main) !important;
    }
  }
/* красивый скролл */
body::-webkit-scrollbar-track {
  background-color: var(--bg-paper);
  border-radius: var(--root-br);
}
body::-webkit-scrollbar {
  width: 10px;
  background-color: var(--bg-paper);
  border-radius: var(--root-br);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: var(--root-br);
}

.Toastify__progress-bar--success {
  background: hsla(0,0%,100%,.7) !important;
}
.Toastify__toast--success {
  color: white !important;
  button {
    color: white !important;
    opacity: 0.7 !important;
  }
}
.Toastify {
  .Toastify {
    &__toast--success {
      background: var(--color-cta);
    }
    &__toast--error {
      background: var(--color-danger);
    }
    &__toast {
      font-family: ${({ theme }) => theme.font.main};
      padding: 10px;
    }
  }
}
.tippy-tooltip {
  font-size: 16px !important;
  background-color: var(--bg-paper) !important;
}
  // выпадающий список для places autocomplete
  .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    left: 0;
    background-color: var(--bg-paper);
    margin-top: 5px;
    z-index: 21;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.4);
    max-height: 250px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 45px;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
      border-radius: 45px;
    }
    ::-webkit-scrollbar-thumb {
	    background-image: var(--grad-primary-n);
      border-radius: 45px;
    }
  }
  .suggestion-item--active, .suggestion-item {
    color: var(--font-color-accent);
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
    border-bottom: 1px solid rgba(127,127,127, .3);
    transition: opacity .35s ease-in-out;
    cursor: pointer;
    :hover {
      opacity: .6;
    }
  }

 .PhoneInputInternationalIconGlobe {
  stroke: var(--font-color-main);
 }
 .PhoneInputInternationalIconPhone {
   fill: var(--font-color-main);
 }

 // flatpickr

  .flatpickr-calendar {
    .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
      background: var(--bg-elevate);
      color: var(--font-color-main);
    }
    .flatpickr-innerContainer, .flatpickr-weekdays, span.flatpickr-weekday, .flatpickr-months .flatpickr-month {
      background: var(--bg-paper);
    }
    .flatpickr-current-month .flatpickr-monthDropdown-months {
      background: var(--bg-touchable);
    }
    span.flatpickr-weekday, .flatpickr-day, .flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
      color: var(--font-color-main);
      fill: var(--font-color-main);
    }
    .flatpickr-day:hover {
      background-color: var(--bg-elevate);
      border-color: var(--bg-elevate);
    }
    .flatpickr-days {
      border: none;
    }
    .flatpickr-innerContainer {
      border-bottom: none;
    }
    .flatpickr-day.today {
      background: var(--color-cta) !important;
      border-color: var(--color-cta) !important;
    }
    .flatpickr-day.selected, .flatpickr-day.endRange {
      background: var(--color-primary) !important;
      border-color: var(--color-primary) !important;
    }
    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
      box-shadow: -10px 0 0 var(--color-primary);
    }
    .flatpickr-time {
      border-color: var(--bg-paper) !important;
      background: var(--bg-paper);
      input, .flatpickr-time-separator {
        color: var(--font-color-main) !important;
        background: transparent !important;
      }
    }
    .flatpickr-day.inRange {
      background: var(--bg-elevate);
      border-color: var(--bg-elevate);
      box-shadow: -5px 0 0 var(--bg-elevate), 5px 0 0 var(--bg-elevate);
    }
    .flatpickr-current-month input, .flatpickr-monthDropdown-months {
      color: var(--font-color-main) !important;
    }

  }
  .flatpickr-input:not(.fltpckr-noop), .flatpickr-wrapper .form-control.input {
    width: 100%;
    border-radius: var(--root-br);
    border: 1px solid transparent;
    padding: 15px 10px;
    outline: none;
    background: var(--bg-touchable);
    color: var(--font-color-main);
    transition: border-color .25s ease;
    :focus {
      border-width: 1px;
      border-color: var(--color-primary);
    }
    ::placeholder {
      color: var(--font-color-accent);
    }
  }

  // select
 .simpo-select {
   &__control {
     border-radius: var(--root-br) !important;
     border: 1px solid transparent !important;
     padding: 8px 10px;
     background-color: var(--bg-touchable) !important;
     box-shadow: none !important;
     transition: all .25s ease !important;
     &--is-focused {
       border-color: var(--color-primary) !important;
       outline: none !important;
     }
   }
   &__input {
     color: var(--font-color-main) !important;
   }
   &__single-value {
     color: var(--font-color-main) !important;
   }
   &__value-container {
     padding: 0 !important;
     padding-right: 3px !important;
     //overflow: visible !important;
   }
 }
  
  .rc-slider-track {
    background-color: var(--color-primary) !important;
  }
  .rc-slider-dot {
    &-active {
      border-color: var(--color-primary) !important;
    }
  }
  .rc-slider-handle {
    border: 2px solid var(--color-primary) !important;
    &-dragging {
      box-shadow: 0 0 0 2px var(--color-primary) !important;
    }
    &:active, &:focus {
      box-shadow: 0 0 5px var(--color-primary) !important;
    }
  }

  .recharts-legend-item-text {
    color: var(--font-color-main);
  }
/* активный класс для ссылок кабинета */
.profile-menu-active {
  background-color: var(--color-primary) !important;
  color: white !important;
}
.profile-active-route {
  color: var(--color-cta) !important;
}
.profile-active-route:hover {
  opacity: 1;
}

/* master search popup */
.master-search-popup .mapboxgl-popup-content {
  background: var(--grad-primary-n);
  border-radius: var(--root-br);
  border-bottom-left-radius: 0;
  padding: 0;
}

.ripple-effect-light {
  ${rippleMixin};
  background-color: rgba(0,0,0, 0.2);
}
.ripple-effect-dark {
  ${rippleMixin};
  background-color: rgba(255,255,255, 0.4);
}

.half-opacity {
  opacity: .5;
}
.ReactTable .-loading.-active {
  background: rgba(0,0,0,.5);
}
.ReactTable .-loading > div {
  color: white !important;
}

// modal
.simpo-modal--overlay {
  z-index: 1000;
  position: relative;
}
.simpo-native-select {
  :focus {
    border-width: 1px;
    border-color: var(--color-primary);
  }
  :disabled {
    opacity: .7;
  }
  ::placeholder {
    color: var(--font-color-accent);
  }
  display: block;
  outline: none;
  // appearance: none;
  // -moz-appearance: none;
  // -webkit-appearance: none;
  background-color: transparent;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  width: 100%;
  border-radius: var(--root-br);
  border: 1px solid transparent;
  cursor: pointer;
  padding: 15px 10px;
  background: var(--bg-touchable);
  color: var(--font-color-main);
  transition: border-color .25s ease;
}
.szh-menu {
  z-index: 99999 !important;
}
body .react-dadata {
  &__suggestions {
     background-color: var(--bg-elevate);
     color: var(--font-color-main);
  }
}
`;
