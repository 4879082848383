import React from "react";

const BonusPageIcon = ({ color = "currentColor", ...props }) => (
  <svg width="24" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12.006 3.54a6.565 6.565 0 0 1 5.515-.737 6.208 6.208 0 0 1 3.714 3.19 7.9 7.9 0 0 1 .336 5.96 12.518 12.518 0 0 1-2.965 4.765 23.865 23.865 0 0 1-2.815 2.472l-.053.045a.724.724 0 0 1-1.006-.198.748.748 0 0 1 .177-1.043 23.351 23.351 0 0 0 2.647-2.328 10.946 10.946 0 0 0 2.647-4.18 6.345 6.345 0 0 0-.3-4.8 4.78 4.78 0 0 0-2.85-2.445 5.042 5.042 0 0 0-4.615.818.73.73 0 0 1-.882 0 5.033 5.033 0 0 0-4.615-.818 4.778 4.778 0 0 0-2.876 2.436 6.4 6.4 0 0 0-.283 4.782A10.981 10.981 0 0 0 6.43 15.63a33.042 33.042 0 0 0 5.753 4.495c.264.186.38.524.288.837a.743.743 0 0 1-.694.538.706.706 0 0 1-.405-.126 34.307 34.307 0 0 1-5.992-4.683 12.364 12.364 0 0 1-2.973-4.764 7.945 7.945 0 0 1 .37-5.933 6.208 6.208 0 0 1 3.715-3.191 6.565 6.565 0 0 1 5.515.737Zm3.77 2.957h.112a3.466 3.466 0 0 1 2.374 3.012l.005.117a.746.746 0 0 1-.153.432.72.72 0 0 1-.487.278.744.744 0 0 1-.83-.648 1.972 1.972 0 0 0-1.359-1.797.762.762 0 0 1-.311-.877.741.741 0 0 1 .761-.517Z"
    />
  </svg>
);

export default BonusPageIcon;
