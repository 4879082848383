import { queryClient } from "@/network/queryClient";
import { QueryKeys } from "@/network/queryKeys";
import omit from "lodash/omit";

const buildQueryKey = (masterId) => {
  const base = QueryKeys.taskEditorBootstrap;
  if (!masterId) return base;
  return [base, masterId];
};
const invalidateAll = () => {
  queryClient.invalidateQueries(buildQueryKey());
};
const invalidateByMaster = (masterId) => {
  queryClient.invalidateQueries(buildQueryKey(masterId));
};
export const TaskEditorCacheService = {
  invalidateAll,
  invalidateByMaster,
  buildQueryKey,
  addDiscount({ discount }) {
    queryClient.setQueryDataIfCached(buildQueryKey(discount.master), (data) => {
      return {
        ...data,
        discounts: data.discounts.concat(discount),
      };
    });
  },
  replaceDiscount({ discount: updated }) {
    queryClient.setQueryDataIfCached(buildQueryKey(updated.master), (data) => {
      return {
        ...data,
        discounts: data.discounts.map((discount) =>
          discount._id === updated._id ? updated : discount
        ),
      };
    });
  },
  removeDiscount({ discount }) {
    queryClient.setQueryDataIfCached(buildQueryKey(discount.master), (data) => {
      return {
        ...data,
        discounts: data.discounts.filter((s) => s._id !== discount._id),
      };
    });
  },
  addService({ service }) {
    queryClient.setQueryDataIfCached(buildQueryKey(service.master), (data) => {
      return {
        ...data,
        services: data.services.concat(service),
      };
    });
  },
  replaceService({ service: updated }) {
    queryClient.setQueryDataIfCached(buildQueryKey(updated.master), (data) => {
      return {
        ...data,
        services: data.services.map((service) =>
          service._id === updated._id ? updated : service
        ),
      };
    });
  },
  removeService({ service }) {
    queryClient.setQueryDataIfCached(buildQueryKey(service.master), (data) => {
      return {
        ...data,
        services: data.services.filter((s) => s._id !== service._id),
      };
    });
  },
  updateMaster({ masterId, updates }) {
    queryClient.setQueryDataIfCached(buildQueryKey(masterId), (data) => {
      return {
        ...data,
        master: {
          ...data.master,
          ...omit(updates, "saloon"),
        },
      };
    });
  },
  replaceSaloon({ masterId, saloon }) {
    queryClient.setQueryDataIfCached(buildQueryKey(masterId), (data) => {
      return {
        ...data,
        master: {
          ...data.master,
          saloon,
        },
      };
    });
  },
};
