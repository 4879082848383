import FavoritesPage from "./loadable";
import { Selectors as LinksSelectors } from "@/store/profileLinks/selectors";
import { connect } from "react-redux";

const mSp = (state) => ({
  menu: LinksSelectors.getFavoritesChildren(state),
  routePrefix: LinksSelectors.getRoutePrefix(state),
});

export default connect(mSp)(FavoritesPage);
