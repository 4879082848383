import { Selectors as UserSelectors } from "@/store/user/selectors";
import { createSelector } from "@reduxjs/toolkit";

const getAchieves = (state) => state.achieves.items;
const getLocalizedAchieves = createSelector(
  [getAchieves, UserSelectors.getLocale],
  (achieves, locale) => {
    return achieves.map(({ ranks, ...rest }) => ({
      ...rest,
      ranks: ranks.map(({ title, desc, ...rest }) => ({
        ...rest,
        title: title[locale],
        desc: desc[locale],
      })),
    }));
  }
);
const isLoading = (state) => state.achieves.loading;
const getError = (state) => state.achieves.error;

export const Selectors = {
  getAchieves: getLocalizedAchieves,
  isLoading,
  getError,
};
