import { createSelector } from "@reduxjs/toolkit";
import { Selectors as ServicesSelectors } from "./services";
import { Selectors as PortfolioSelectors } from "@/store/portfolio/selectors";
import { Selectors as ProfileSelectors } from "./profile";
import {
  PROFILE_PROGRESS_PORTFOLIO_COUNT,
  PROFILE_PROGRESS_SERVICES_COUNT,
} from "@/misc/constants";
import { Selectors as UserSelectors } from "@/store/user/selectors";

const getServicesProgress = createSelector(
  ServicesSelectors.getServices,
  (services) => {
    return services.length >= PROFILE_PROGRESS_SERVICES_COUNT;
  }
);
const getPortfolioProgress = createSelector(
  PortfolioSelectors.getPortfolio,
  (portfolio) => {
    return portfolio.length >= PROFILE_PROGRESS_PORTFOLIO_COUNT;
  }
);
const getProfileProgress = createSelector(
  [
    ProfileSelectors.isOwner,
    ProfileSelectors.getMaster,
    getServicesProgress,
    getPortfolioProgress,
    UserSelectors.getEmail,
    UserSelectors.getPhone,
    UserSelectors.getAvatar,
  ],
  (isOwner, master, sProgress, pProgress, email, phone, avatar) => {
    if (!isOwner) {
      return null;
    }
    return {
      portfolio: pProgress,
      services: sProgress,
      phone: Boolean(phone),
      email: Boolean(email),
      avatar: Boolean(avatar),
      bio: Boolean(master?.user?.bio),
      address: master?.location?.coordinates?.every(Boolean),
    };
  }
);

export const Selectors = {
  getServicesProgress,
  getPortfolioProgress,
  getProfileProgress,
};
