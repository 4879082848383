import React from "react";
import * as S from "./styled";
import Main from "./main";

const PremiumModal = (props) => {
  return (
    <S.Modal overlay="modal-overlay-elevate" {...props}>
      <S.CloseOverlay onClick={props.onClose} />
      <Main onClose={props.onClose} />
    </S.Modal>
  );
};

export default PremiumModal;
