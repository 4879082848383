import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";
import { IS_SERVER } from "@/misc/constants";
import * as rax from "retry-axios";
import ms from "ms";

const getAdapters = () => {
  if (IS_SERVER) {
    // @ts-ignore
    return cacheAdapterEnhancer(axios.defaults.adapter);
  } else {
    return throttleAdapterEnhancer(
      // @ts-ignore
      cacheAdapterEnhancer(axios.defaults.adapter)
    );
  }
};
class Api {
  private client: AxiosInstance;
  constructor() {
    this.client = axios.create({
      headers: { "Cache-Control": "no-cache" },
      adapter: getAdapters(),
      withCredentials: true,
    });
    this.client.defaults.timeout = ms("10 seconds");
    this.client.defaults.raxConfig = {
      instance: this.client,
    };
    rax.attach(this.client);

    this.client.interceptors.response.use(
      (r) => r,
      (e) => {
        console.error(e);
        let err;
        const message = e?.response?.data;
        if (e?.response?.status < 400) {
          return Promise.resolve(e.response);
        }
        if (e?.response?.data) {
          err = message;
        } else if (e?.response?.data.status === 401) {
          err = "UNAUTHORIZED";
        } else if (e?.message === "Network Error") {
          err = "NETWORK_ERROR";
        } else {
          err = "unspecified_server";
        }
        return Promise.reject(err);
      }
    );
  }

  post = async (url: string, data?: any, params?: AxiosRequestConfig) => {
    const result = await this.client.post(url, data, params);
    return result;
  };

  get = async (url: string, params: AxiosRequestConfig = {}) => {
    const result = await this.client.get(url, params);
    return result;
  };

  put = async (url: string, data?: any, params?: AxiosRequestConfig) => {
    const result = await this.client.put(url, data, params);
    return result;
  };

  remove = async (url: string, params?: AxiosRequestConfig) => {
    const result = await this.client.delete(url, params);
    return result;
  };
}

const api = new Api();

export const { post, get, put, remove } = api;

export default api;
