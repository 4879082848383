import { createSelector } from "@reduxjs/toolkit";
import { detectTimezone } from "@/utils/time";
import { Selectors as PremiumSelectors } from "@/store/premium/selectors";
import { Selectors as LocaleSelectors } from "@/store/locale/selectors";
import { DEFAULT_LOCALE } from "@/config";
import isempty from "lodash/isEmpty";

const getProfile = (state) => state.user.profile;
const isPremium = PremiumSelectors.isActive;
const getUserId = (state) => state.user.profile?._id;
const isMaster = (state) => Boolean(state.user.profile?.master);
const getRoles = (state) => state.user.profile?.roles;
const isSaloonOwnerOrAdmin = createSelector(getRoles, (roles) =>
  roles?.some((role) => role === "saloonOwner" || role === "saloonAdmin")
);
const isSaloonOwner = createSelector(getRoles, (roles) =>
  roles?.some((role) => role === "saloonOwner")
);
const isAdmin = createSelector(getRoles, (roles) => {
  return roles?.includes("admin");
});
const getEmail = createSelector(getProfile, (user) => user?.email);
const getPhone = createSelector(getProfile, (user) => user?.phone);
const getMaster = createSelector(getProfile, (user) => user?.master);
const getSaloon = createSelector(getMaster, (master) => master?.saloon);
const getSaloonId = createSelector(getSaloon, (saloon) => saloon?._id);
const getMasterId = createSelector(getMaster, (master) => master?._id);
const isFirstLogin = createSelector(
  getProfile,
  (user) => !Boolean(user?.online_at)
);

const getFirstName = (state) => state.user.profile?.first_name;
const getLastName = (state) => state.user.profile?.last_name;
const getMiddleName = (state) => state.user.profile?.middle_name;
const getFullName = createSelector(
  [getFirstName, getLastName],
  (first, last) => {
    return [first, last].filter(Boolean).join(" ");
  }
);
const getAvatar = (state) => state.user.profile?.avatar;
const isTaskEduCompleted = createSelector(
  getMaster,
  (master) => master?.task_edu_completed
);
const hasServices = createSelector(
  getMaster,
  (master) => !isempty(master?.services)
);
const isTaskEduActive = createSelector(
  getMaster,
  (master) => master?.task_edu_active
);
const isTaskEduAllowed = createSelector(
  [isTaskEduActive, isTaskEduCompleted, hasServices],
  (active, completed, hasServices) => {
    return !active && !completed && hasServices;
  }
);
const getSex = (state) => state.user.profile?.sex;
const getTimezone = (state) => state.user.profile?.timezone || detectTimezone();
const getLocationHr = (state) => state.user.profile?.location_hr;
const getMasterSchedule = createSelector(
  getMaster,
  (master) => master?.schedule
);
const getMasterWeekBusinessHours = createSelector(
  getMasterSchedule,
  (schedule) => schedule?.for_week || []
);
const isWorkingHoursEstablished = createSelector(
  getMasterSchedule,
  (schedule) => {
    if (!schedule) return false;
    return !schedule.for_week.every(
      ({ opening_hours: [start, end] }) => start === 0 && end === 1439
    );
  }
);
const getCityLocation = (state) => state.user.profile?.location;
const getBio = (state) => state.user.profile?.bio;
const getRawLocale = (state) => state.user.profile?.locale;
const getLocale = createSelector(
  [getRawLocale, LocaleSelectors.getLocale],
  (maybeLocale, locale) => {
    return maybeLocale || locale || DEFAULT_LOCALE;
  }
);
const getMasterLocationHr = createSelector(
  getMaster,
  (master) => master?.location_hr
);
const getGoogleId = (state) => state.user.profile?.google_id;
const getVKId = (state) => state.user.profile?.vk_id;
const getSoundSettings = () => {
  const init = {
    notif_sounds: true,
    message_sounds: true,
  };
  try {
    const history = JSON.parse(localStorage.getItem("user-settings"));
    return history || init;
  } catch (e) {
    return init;
  }
};
const isMarketplaceRegistered = createSelector(getMaster, (master) =>
  Boolean(master?.mp_id)
);
const getFeedActivities = (state) => state.user.profile?.show_activities;

const getCurrency = (state) => state.user.profile?.currency || "USD";

const hasSmsAccount = (state) => Boolean(state.user.profile?.has_sms_acc);

const getProfileLink = createSelector(
  [isMaster, getUserId, getMasterId],
  (isMaster, userId, masterId) => {
    return isMaster ? `/master?id=${masterId}` : `/client?id=${userId}`;
  }
);

const getRawAppUsageMode = (state) => getProfile(state)?.app_usage_mode;
const isClientMode = (state) => {
  return Boolean(getProfile(state)?.is_client);
};
const getAppUsageMode = createSelector(
  [getRawAppUsageMode, isMaster, isClientMode],
  (raw, isMaster, isClientMode) => {
    if (isClientMode) return "std";
    if (raw) return raw;
    else if (isMaster) return "business";
    else return "std";
  }
);
const isStandardMode = (state) => getAppUsageMode(state) === "std";
const isBusinessMode = (state) => getAppUsageMode(state) === "business";

const getServerFeatures = (state) => getProfile(state)?.enabled_features ?? [];

const getClientsConfig = (state) => getMaster(state)?.clients_config;

export const Selectors = {
  getFeedActivities,
  getGoogleId,
  getVKId,
  getAvatar,
  getBio,
  getCurrency,
  getFirstName,
  getLastName,
  getLocale,
  getSoundSettings,
  getEmail,
  getPhone,
  getLocationHr,
  getMiddleName,
  getFullName,
  getSex,
  getSaloon,
  getProfile,
  getRoles,
  getUserId,
  getMaster,
  getProfileLink,
  getMasterId,
  getMasterLocationHr,
  getSaloonId,
  getMasterSchedule,
  getMasterWeekBusinessHours,
  getTimezone,
  getCityLocation,
  hasSmsAccount,
  isMaster,
  isWorkingHoursEstablished,
  isMarketplaceRegistered,
  isTaskEduAllowed,
  isTaskEduCompleted,
  isFirstLogin,
  isPremium,
  isAdmin,
  isSaloonOwner,
  isSaloonOwnerOrAdmin,
  getAppUsageMode,
  isStandardMode,
  isBusinessMode,
  isClientMode,
  getRawAppUsageMode,
  hasServices,
  getServerFeatures,
  getClientsConfig,
};
