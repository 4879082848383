import { createSelector } from "@reduxjs/toolkit";
import { Selectors as ProfileSelectors } from "./profile";
import { Selectors as ServicesSelectors } from "./services";
import { Selectors as ProgressSelectors } from "./progress";
import { Selectors as SeoSelectors } from "./seo";
import { Selectors as AchieveSelectors } from "./achieves";
import { DOMAIN } from "../slice";

const root = (state) => state[DOMAIN];
const getError = (state) => root(state).error;
const isLoading = (state) => root(state).loading;

const getArticles = (state) => root(state).articles;
const getClients = (state) => root(state).clients;
const getClientsForBonusForm = createSelector(getClients, (clients) => {
  return clients.map(({ first_name, last_name, _id, avatar, sex }) => ({
    value: _id,
    label: [first_name, last_name].filter(Boolean).join(" "),
    avatar,
    sex,
  }));
});
const getAssociates = (state) => root(state).associates;
const getRedirectMeta = (state) => root(state).redirect;
const isFreeSlotsOpen = (state) => root(state).isFreeSlotsOpen;

export const Selectors = {
  ...ProfileSelectors,
  ...ServicesSelectors,
  ...ProgressSelectors,
  ...SeoSelectors,
  ...AchieveSelectors,
  getAssociates,
  getArticles,
  getError,
  getRedirectMeta,
  getClientsForBonusForm,
  getClients,
  isLoading,
  isFreeSlotsOpen,
};
